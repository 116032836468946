package model

import apiclient.auth.ApiUser
import apiclient.customfields.FieldValue
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LinkRel
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.OpenGraphMetadata
import apiclient.geoobjects.ReadOnlyTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.TaskState
import apiclient.geoobjects.TaskTemplate
import apiclient.polls.PollOption
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.TagEnum
import auth.CurrentWorkspaceStore
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Theme
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import mainmenu.Pages
import web.geolocation.GeolocationWatchId
import webcomponents.KeywordTagActionType

/**
 * Extension function to convert ApiUser to User, which then can be used by Lenses
 */
fun ApiUser.toUser(): User {
    return with(this) {
        User(
            userId = userId,
            firstName = firstName,
            lastName = lastName,
            valid = true,
            apiUser = this,
        )
    }
}

fun ApiUser.isInternalAdmin(): Boolean {
    return isAdmin
}

fun ApiUser.isGroupAdmin(): Boolean {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    return groups.firstOrNull { it.groupId == currentWorkspaceStore.current?.groupId }?.roles?.contains("group_admin") == true
}

fun ApiUser.isGroupOwner(): Boolean {
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()
    return groups.firstOrNull { it.groupId == currentWorkspaceStore.current?.groupId }?.roles?.contains("group_owner") == true
}

fun ApiUser.isGroupAdminOrGroupOwner(): Boolean {
    return isGroupAdmin() || isGroupOwner()
}

fun ApiUser.isInternalAdminGroupAdminOrGroupOwner(): Boolean {
    return isInternalAdmin() || isGroupAdmin() || isGroupOwner()
}

/**
 * Data class for temporarily store user credentials @login
 */
data class Credentials(
    val email: String = "",
    val password: String = "",
    val rememberMe: Boolean = true,
) {
    companion object
}

/**
 * User data class holding relevant info of the ApiUser and the ApiUser itself (to use Lenses)
 */
@Serializable
data class User(
    val userId: String,
    val firstName: String,
    val lastName: String,
    val valid: Boolean = false,
    val apiUser: ApiUser?,
) {
    val isAnonymous get() = userId == "anonymous"

    // needed for fritz2 lenses
    companion object
}

fun User.getFirstGroupIdOrNull(): String? {
    return apiUser?.groups?.firstOrNull()?.groupId
}

fun User.getGroupIdsOrNull(): List<String>? {
    return apiUser?.groups?.map { it.groupId }
}

fun User.isInternalAdmin(): Boolean {
    return apiUser?.isAdmin == true
}

fun User.isGroupAdmin(): Boolean {
    return apiUser?.groups?.firstOrNull()?.roles?.contains("group_admin") == true
}

fun User.isGroupOwner(): Boolean {
    return apiUser?.groups?.firstOrNull()?.roles?.contains("group_owner") == true
}

fun User.isInternalAdminGroupAdminOrGroupOwner(): Boolean {
    return isInternalAdmin() || isGroupAdmin() || isGroupOwner()
}

data class LocationUploadState(
    val backgroundJob: Job? = null,
    val positionEngineWatchId: GeolocationWatchId? = null,
) {
    val isActive get() = backgroundJob != null && backgroundJob.isActive && !backgroundJob.isCancelled

    companion object
}

data class LocationFollowState(
    val backgroundLoop: Job? = null,
    val positionWatchJobId: GeolocationWatchId? = null,
) {
    val isActive get() = backgroundLoop != null && backgroundLoop.isActive && !backgroundLoop.isCancelled

    companion object
}

fun GeoObjectDetails.toSearchResults(): ObjectSearchResults {
    return with(this) {
        ObjectSearchResults(
            from = 0,
            pageSize = 0,
            total = 1,
            time = 0,
            hits = listOf(
                ObjectSearchResult(
                    distanceMeters = 0.0,
                    //score = 0.0,
                    hit = this,
                ),
            ),
        )
    }
}

fun GeoObjectDetails.toSearchResult(): ObjectSearchResult {
    return with(this) {
        ObjectSearchResult(
            hit = this,
            score = null,
            distanceMeters = null,
            layerId = null,
        )
    }
}

fun List<GeoObjectDetails>.toSearchResults(): ObjectSearchResults {
    return with(this) {
        ObjectSearchResults(
            from = 0,
            pageSize = 0,
            total = this.size,
            time = 0,
            hits = this.map {
                ObjectSearchResult(
                    distanceMeters = 0.0,
                    //score = 0.0,
                    hit = it,
                )
            },
        )
    }
}

enum class NotificationType { Alert, Ticker }
enum class ConfirmationType { Close, AutoClose, UserAction, AppVersionExpired, Generic }

enum class SplashType { AppStartSplash, LoggingInSplash, TokenSplash }

data class OverlayState(
    val overlay: Overlay? = null,
    val enabled: Boolean = false,
    val timer: Job? = null,
) {
    companion object
}

sealed class Overlay {

    abstract val durationSeconds: Int?
    abstract val text: Flow<String>?
    abstract val icon: IconDefinition?
    abstract val bgColor: String
    abstract val textColor: String
    abstract val geoObject: GeoObjectDetails?

    data class NotificationToast(
        val notificationType: NotificationType,
        override val durationSeconds: Int?,
        override val text: Flow<String>? = flowOf(""),
        override val icon: IconDefinition? = null,
        override val bgColor: String = "gray",
        override val textColor: String = "#FFFFFF",
        override val geoObject: GeoObjectDetails? = null,
        val primaryClickHandlers: List<SimpleHandler<Unit>>? = null,
    ) : Overlay() {
        companion object
    }

    data class SplashScreen(
        val splashType: SplashType,
        override val durationSeconds: Int?,
        override val text: Flow<String>? = flowOf(""),
        override val icon: IconDefinition? = null,
        override val bgColor: String = "gray",
        override val textColor: String = "#FFFFFF",
        override val geoObject: GeoObjectDetails? = null,
        val primaryClickHandlers: List<SimpleHandler<Unit>>? = null,
    ) : Overlay() {
        companion object
    }

    data class ConfirmationScreen(
        val confirmationType: ConfirmationType,
        override val durationSeconds: Int? = null,
        override val text: Flow<String>? = flowOf(""),
        override val icon: IconDefinition? = null,
        override val bgColor: ColorProperty = Theme().colors.primary.main,
        override val textColor: String = Theme().colors.neutral.main,
        override val geoObject: GeoObjectDetails? = null,
        val primaryClickHandlers: List<SimpleHandler<Unit>>? = null,
        val primaryActionName: Flow<String>? = null,
        val secondaryClickHandlers: List<SimpleHandler<Unit>>? = null,
        val secondaryActionName: Flow<String>? = null,
        val content: (RenderContext.() -> Unit)? = null,
    ) : Overlay() {
        companion object
    }

    companion object
}

data class NotificationContent(
    val type: NotificationType,
    val text: Flow<String>? = flowOf(""),
    val icon: MarkerIcon? = null,
    val bgColor: String = "gray",
    val textColor: String = "#FFFFFF",
) {
    companion object
}

data class TermsState(
    val read: Boolean = false,
    val agreedLatest: Boolean? = null,
    val agreeDate: String? = null,
) {
    companion object
}

data class SearchFilterButtonState(
    val people: Boolean = false,
    val places: Boolean = false,
    val things: Boolean = false,
    val notifications: Boolean = false,
) {
    companion object
}

enum class CodeTech {
    QR, NFC, LINK
}

enum class ScanPurpose {
    OpenCode, ConnectQRToObject, ReadCodeAndStore, TakePhoto
}

enum class CodeTypeMessage : Translatable {
    Unused,
    ObjectId,
    ActionTrigger,
    UserVerification,
    User,
    NoAccess,
    NotChecked
    ;

    override val prefix: String = "codetypemessage"
}

enum class CodeType(val message: Translatable) : Translatable {
    Unused(CodeTypeMessage.Unused),
    ObjectId(CodeTypeMessage.ObjectId),
    ActionTrigger(CodeTypeMessage.ActionTrigger),
    UserVerification(CodeTypeMessage.UserVerification),
    User(CodeTypeMessage.User),
    NoAccess(CodeTypeMessage.NoAccess),
    NotChecked(CodeTypeMessage.NotChecked),
    ;

    override val prefix: String = "codetype"
}

enum class OperationType { VERIFY }

data class ScannedCode(
    val extOrIntObjIdOrActionId: String? = null,
    val userId: String? = null,
    val fallback: String? = null,
    val codeTech: CodeTech? = CodeTech.QR,
    val scanPurpose: ScanPurpose? = ScanPurpose.OpenCode,
    val operation: OperationType? = null,
    val color: MarkerColor? = null,
    val icon: MarkerIcon? = null,
    val shape: MarkerShape? = null,
    val token: String? = null,
    val loginToken: String? = null,
    val tag: String? = null,
    val page: Pages? = null,
    val workspace: String? = null,
    val scan: Boolean = false,
) {
    companion object
}

data class NotificationTagData(
    val triggeredBy: String? = null,
    val triggeredByName: String? = null,

    val meetingInviteStatus: String? = null,
    val meetingInvitedBy: String? = null,
    val meetingInvitedByName: String? = null,

    val taskAssignedBy: String? = null,
    val taskAssignedByName: String? = null,
    val taskStatus: TaskState? = null,

    val COType: ObjectType? = null,
    val COTitle: String? = null,
    val COTitleOld: String? = null,
    val CODescription: String? = null,
    //val latLon: LatLon? = null,
    val COConnectedTo: String? = null,
    val COColor: MarkerColor? = null,
    val COIconCategory: MarkerIcon? = null,
    val COShape: MarkerShape? = null,

    val modifiedBy: String? = null,
    val atTime: String? = null,
)

data class Feedback(
    val subject: String,
    val message: String,
) {
    companion object
}

data class NestedObjects(
    val activeParentObjectId: String? = null,
    val activeParentObjectType: ObjectType? = null,
    val total: Int? = null,
    val fetched: List<ObjectSearchResult> = emptyList()
) {
    companion object
}

data class SearchPage(
    val from: Int = 0,
    val size: Int = 15,
)

data class NotificationNumbers(
    val read: List<GeoObjectDetails> = emptyList(),
    val readNumber: Int = read.size,
    val unread: List<GeoObjectDetails> = emptyList(),
    val unreadNumber: Int = unread.size,
) {
    companion object
}

data class SuggestedTagsContext(
    val ctx: SearchQueryContext,
    val prefix: String,
    val limit: Int? = null
) {
    companion object
}

data class Camera(
    val id: String,
    val label: String
) {
    companion object
}

sealed class PreAttachment {
    abstract val id: String

    override fun toString(): String {
        return Json.encodeToString(kotlinx.serialization.serializer(), this)
    }

    data class PreImage(
        override val id: String,
        val href: String? = null,
        val width: Int? = null,
        val height: Int? = null,
        val key: String? = null,
        val thumbNail: Content? = null,
        val mimeType: String? = null,
        val prevName: String,
        val prevBytes: ByteArray,
        val title: String? = null,
    ) : PreAttachment() {
        override fun equals(other: Any?): Boolean {
            if (this === other) return true
            if (other == null || this::class != other::class) return false

            other as PreImage

            if (id != other.id) return false
            if (href != other.href) return false
            if (width != other.width) return false
            if (height != other.height) return false
            if (key != other.key) return false
            if (thumbNail != other.thumbNail) return false
            if (mimeType != other.mimeType) return false
            if (prevName != other.prevName) return false
            if (!prevBytes.contentEquals(other.prevBytes)) return false
            if (title != other.title) return false

            return true
        }

        override fun hashCode(): Int {
            var result = id.hashCode()
            result = 31 * result + (href?.hashCode() ?: 0)
            result = 31 * result + (width ?: 0)
            result = 31 * result + (height ?: 0)
            result = 31 * result + (key?.hashCode() ?: 0)
            result = 31 * result + (thumbNail?.hashCode() ?: 0)
            result = 31 * result + (mimeType?.hashCode() ?: 0)
            result = 31 * result + prevName.hashCode()
            result = 31 * result + prevBytes.contentHashCode()
            result = 31 * result + (title?.hashCode() ?: 0)
            return result
        }

    }

    data class PreSvgImage(
        override val id: String,
        val svgContent: String,
        val title: String? = null
    ) : PreAttachment()

    data class PreWebLink(
        override val id: String,
        val href: String,
        val mimeType: String? = null,
        val rel: LinkRel? = null,
        val title: String,
        val htmlPreview: String?,
        val actionId: String?,
        val openGraphMetadata: OpenGraphMetadata?
    ) : PreAttachment()

    data class PreMarkdown(
        override val id: String,
        val text: String,
        val htmlPreview: String? = null,
        val title: String? = null
    ) : PreAttachment()

    data class PreGeoObject(
        override val id: String,
        val objectId: String,
        val rel: LinkRel? = null,
        val title: String,
        val htmlPreview: String?,
    ) : PreAttachment()

    data class PrePoll(
        override val id: String,
        val title: String,
        val actionId: String?,
        val options: List<PollOption>,
        val maxVotesPerUser: Int = 1,
    ) : PreAttachment()

    data class PreScanToCreateTask(
        override val id: String,
        val actionId: String?,
        val title: String?,
        val taskTemplate: TaskTemplate?,
    ) : PreAttachment()
}

data class ImageFileData(
    val id: String? = null,
    val prevName: String,
    val mimeType: String? = null,
    val prevBytes: ByteArray? = null,
    val href: String? = null,
    val width: Int? = null,
    val height: Int? = null,
    val title: String = ""
) {
    companion object {
        val EMPTY = ImageFileData(prevName = "")
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as ImageFileData

        if (id != other.id) return false
        if (prevName != other.prevName) return false
        if (mimeType != other.mimeType) return false
        if (prevBytes != null) {
            if (other.prevBytes == null) return false
            if (!prevBytes.contentEquals(other.prevBytes)) return false
        } else if (other.prevBytes != null) return false
        if (href != other.href) return false
        if (width != other.width) return false
        if (height != other.height) return false
        if (title != other.title) return false

        return true
    }

    override fun hashCode(): Int {
        var result = id?.hashCode() ?: 0
        result = 31 * result + prevName.hashCode()
        result = 31 * result + (mimeType?.hashCode() ?: 0)
        result = 31 * result + (prevBytes?.contentHashCode() ?: 0)
        result = 31 * result + (href?.hashCode() ?: 0)
        result = 31 * result + (width ?: 0)
        result = 31 * result + (height ?: 0)
        result = 31 * result + title.hashCode()
        return result
    }
}

data class MarkdownData(
    val id: String? = null,
    val text: String,
    val htmlPreview: String = "",
    val title: String = ""
) {
    companion object {
        val EMPTY = MarkdownData(text = "")
    }
}

data class WebLinkData(
    val id: String? = null,
    val href: String,
    val rel: String = LinkRel.related.name,
    val title: String = "",
    val actionId: String? = null
) {
    companion object {
        val EMPTY = WebLinkData(href = "")
    }
}

data class GeoObjectData(
    val id: String? = null,
    val objectId: String,
    val rel: String = "",
    val title: String = ""
) {
    companion object {
        val EMPTY = GeoObjectData(objectId = "")
    }
}

data class
PollData(
    val id: String? = null,
    val title: String,
    val actionId: String = "",
    val options: List<PollOption> = emptyList(),
    val maxVotesPerUser: Int = 1,
) {
    companion object {
        val EMPTY = PollData(title = "")
    }
}

data class TaskTemplateData(
    val id: String? = null,
    val title: String,
    val actionId: String? = null,
    val taskTemplate: TaskTemplate? = null,
) {
    companion object {
        val EMPTY = TaskTemplateData(title = "")
    }
}

enum class AppPhase {
    Unknown,
    Checking,
    NotAcceptedCookies,
    NotAcceptedDisclaimer,
    NotLoggedIn,
    NoValidToken,
    LoggedInAnonymous,
    NotActivated,
    NotAcceptedTerms,
    NewPasswordNeeded,
    LoggedIn
}

enum class InitializationState {
    UNKNOWN,
    RUNNING,
    DONE
}

data class AppState(
    val apiUserBusy: InitializationState? = null,
    val termsBusy: InitializationState? = null,
    val apiUser: ApiUser? = null,
    val localSettings: LocalSettings? = null,
    val acceptCookies: Boolean? = null,
    val acceptDisclaimer: Boolean? = null,
    val agreedTermsState: Boolean? = null
) {

    private val notInitialized get() = acceptCookies == null && this.acceptDisclaimer == null && agreedTermsState == null
    private val apiUserAndTermsCheckReady get() = apiUserBusy == InitializationState.DONE && termsBusy == InitializationState.DONE
    private val isChecking get() = apiUserBusy == InitializationState.RUNNING || termsBusy == InitializationState.RUNNING
    val isActivated get() = apiUserAndTermsCheckReady && apiUser?.hasActivated ?: false
    val agreedTerms get() = apiUserAndTermsCheckReady && agreedTermsState == true

    val appPhase: AppPhase
        get() = when {
            // ApiUser check or terms check not ready
            isChecking || notInitialized -> AppPhase.Checking
            // Not accepted cookies
            acceptCookies == false -> AppPhase.NotAcceptedCookies
            // Not accepted disclaimer
            acceptDisclaimer == false -> AppPhase.NotAcceptedDisclaimer
            // Not logged in
            apiUser == null -> AppPhase.NotLoggedIn
            // No valid Token
            apiUser.apiRefreshToken.expirationInstant < Clock.System.now() -> AppPhase.NoValidToken
            // *** Logged in anonymously! ***
            apiUser.isAnonymous -> AppPhase.LoggedInAnonymous
            // Not activated
            !apiUser.hasActivated -> AppPhase.NotActivated
            // Not agreed terms
            agreedTermsState != true -> AppPhase.NotAcceptedTerms
            // New password needed
            apiUser.groups.firstOrNull { it.groupName == apiUser.workspaceName }?.userProfile?.newPasswordNeeded == true -> AppPhase.NewPasswordNeeded
            // *** Logged in! ***
            else -> AppPhase.LoggedIn
        }

    companion object
}

data class ResetPasswordData(
    val email: String = "",
    val token: String = ""
) {
    companion object
}

data class FirebaseMessage(
    val aps: Pair<String, Any>? = null,
    val data: Pair<String, Any>? = null,
    val gcm: Pair<String, Any>? = null,
    val messageId: String? = null,
    val sentTime: Instant? = null
) {
    companion object
}

data class KeywordTag(
    val fieldText: String, // e.g. demo-content
    val valueText: String? = null, // e.g. red
    val actionType: KeywordTagActionType,
    val readOnlyType: TagEnum? = null,
    val readOnlyStringValue: String? = null, // tagValue e.g. Creator:userName
    val customFieldValue: FieldValue? = null
)
