package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.OpenGraphMetadata
import apiclient.geoobjects.UpsertWebLink
import apiclient.opengraph.linkPreview
import apiclient.util.isNotNullOrEmpty
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.filterNotNull
import overlays.withBusyApplyContentChange
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonHighlight

fun RenderContext.linkEditor(
    objectId: String,
    content: Content.WebLink,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val linkStore = storeOf(content.href)
    val preview = storeOf<OpenGraphMetadata?>(null)

    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonHighlight(icon = FormationUIIcons.Save.icon) {
                linkStore.data.render { link ->
                    disabled(link.isBlank())
                }

                clicks handledBy {
                    withBusyApplyContentChange(
                        objectId,
                        UpsertWebLink(
                            id = content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                            href = linkStore.current,
                            title = "",
                        ),
                    )
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                }
            }
        }
        linkStore.data.debounce(50.milliseconds).filter { it.startsWith("http") } handledBy { link ->
            withKoin {
                val client = get<FormationClient>()

                preview.update(
                    client.linkPreview(link).getOrNull()
                )
            }
        }
        twInputField(linkStore) {
            twInputTextField {
                placeholder("https://www.youtube.com/watch?v=dQw4w9WgXcQ")
            }
        }
        preview.data.filterNotNull().render { ogm ->
            renderWebLink(
                Content.WebLink(
                    content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                    ogm.url ?: "",
                    title = content.title.takeIf { it.isNotNullOrEmpty() } ?: ogm.title,
                    openGraphMetadata = ogm,
                ),
            )
        }
    }
}
