package data.users.views

import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.h6
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.download
import dev.fritz2.core.href
import dev.fritz2.styling.params.rgba
import dev.fritz2.styling.params.shadow
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import dev.fritz2.styling.theme.Property
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import mainmenu.RouterStore
import theme.FormationColors
import theme.FormationDefault
import webcomponents.ellipseText

fun <T> RenderContext.iconInfoFieldButton(
    icon: (Icons.() -> IconDefinition)? = null,
    iconBackground: (Colors.() -> Property)? = null,
    iconShadow: Boolean = false,
    initials: String? = null,
    title: Flow<String>,
    subtitle: Flow<String?>? = null,
    routingMap: Map<String, String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    href: String? = null,
    download: String? = null,
    value: T,
    valueHandlers: List<SimpleHandler<T>>? = null
) {
    val routerStore: RouterStore by koinCtx.inject()

    fun RenderContext.buttonContent() {
        lineUp({
            height { maxContent }
            width { full }
            alignItems { center }
            display { flex }
            padding { tiny }
            radius(FormationDefault.formationStyles.buttonRadius)
            hover {
                background { color { FormationColors.GrayLight.color } }
            }
            background { color { secondary.main } }
            border {
                width(FormationDefault.formationStyles.borderWidth)
                color { primary.main }
            }
            overflowX { hidden }
        }) {
            spacing { small }
            items {
                // ICON BOX
                flexBox({
                    flex {
                        grow { "0" }
                        shrink { "0" }
                        basis { "60px" }
                    }
                    width { "60px" }
                    height { "60px" }
                    justifyContent { center }
                    alignItems { center }
                }) {
                    // ICON ON CIRCLE
                    flexBox({
                        width { "48px" }
                        height { "48px" }
                        color { secondary.main }
                        background { color(iconBackground ?: { primary.main }) }
                        radius { full }
                        padding { tiny }
                        justifyContent { center }
                        alignItems { center }
                        flex {
                            grow { "0" }
                            shrink { "0" }
                            basis { "48px" }
                        }
                        if (iconShadow) {
                            boxShadow {
                                shadow("2px", "4px", "4px", color = rgba(0, 0, 0, 0.25))
                            }
                        }
                    }) {
                        if (initials != null) {
                            span({ fontSize { large } }) { +initials }
                        } else {
                            if (icon != null) {
                                icon({ size { huge } }) { fromTheme(icon) }
                            }
                        }
                    }
                }
                // TITLE & SUBTITLE
                stackUp({
                    overflowX { hidden }
                }) {
                    spacing { tiny }
                    items {
                        ellipseText(styleParams = {
                            width { full }
                            fontSize { normal }
                            fontWeight { bold }
                            textAlign { left }
                        }) { title.renderText(into = this) }
                        subtitle?.render { sub -> if (!sub.isNullOrBlank()) h6({ textAlign { left } }) { +sub } }
                    }
                }
            }
        }
    }

    if (href != null) {
        a({
            width { full }
            height { auto }
        }) {
            href(href)
            download?.let {
                download(it)
            }
            buttonContent()
            with(clicks) {
                clickHandlers?.forEach { handler ->
                    this handledBy handler
                }
                valueHandlers?.forEach { handler ->
                    this.map { value } handledBy handler
                }
                if (routingMap != null) {
                    this.map {
                        routingMap
                    } handledBy routerStore.validateInternalRoute
                }
            }
        }
    } else {
        button({
            width { full }
            height { auto } // maybe define fixed height of button later
        }) {
            buttonContent()
            with(clicks) {
                clickHandlers?.forEach { handler ->
                    this handledBy handler
                }
                valueHandlers?.forEach { handler ->
                    this.map { value } handledBy handler
                }
                if (routingMap != null) {
                    this.map {
                        routingMap
                    } handledBy routerStore.validateExternalRoute
                }
            }
        }
    }
}
