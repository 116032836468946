package qrcode

import auth.ApiUserStore
import apiclient.geoobjects.ObjectType
import data.objects.ActiveObjectStore
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.span
import koin.koinCtx
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.ScannedCode
import model.type
import utils.getIcon
import webcomponents.*

fun RenderContext.cardCreateTrackedObject() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
//    val scannedCode = scannedCodeStore.map(ScannedCode.extObjId()).data.map { it?: "" }
    val scannedCode = scannedCodeStore.data.map { it.extOrIntObjIdOrActionId ?: it.fallback ?: "" }
    val codeIcon = scannedCodeStore.map(ScannedCode.type()).data.map { it?.getIcon() }

    baseLayout(
        expandable = false,
        header = {
            codeIcon.render { icon ->
                if (icon != null) {
                    cardTitleWithSubtitle(
                        title = translation[TL.CodeScanning.CODE_ENTERED],
                        subtitle = scannedCode
                    ) { icon }
                } else {
                    cardTitleWithSubtitle(
                        title = translation[TL.CodeScanning.CODE_ENTERED],
                        subtitle = scannedCode
                    )
                }
            }
        },
        content = {
            contentScrollBox {
                stackUp({
                    width { full }
                    height { full }
                    alignItems { center }
                    justifyContent { center }
                }) {
                    spacing { small }
                    items {
                        span({
                            width { full }
                            textAlign { center }
                            fontWeight { bold }
                            css(
                                """
                                overflow-wrap: break-word;
                                word-wrap: break-word;
                            """.trimIndent()
                            )
                        }) { scannedCode.renderText(into = this) }
                        cardSubtitle(translation[TL.AlertNotifications.SCANNED_OBJECT_NOT_FOUND])
                        if (!apiUserStore.current.isAnonymous) {
                            cardSubtitle(translation[TL.CodeScanning.CREATE_OBJECT_QUESTION])
                        } else {
                            cardSubtitle(translation[TL.CodeScanning.CREATE_NOT_ALLOWED])
                        }
                    }
                }
            }

        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.General.CREATE],
                primaryState = apiUserStore.data.map { !it.isAnonymous },
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.prefillTitleWithCode),
                primaryAddOrReplaceRoute = mapOf(
                    "add" to ObjectType.ObjectMarker.name
                ),
                primaryAnalyticsEventProvider = null,
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryClickHandlers = listOf(scannedCodeStore.reset, routerStore.goToMap),
                secondaryAnalyticsEventProvider = null,
            )
        }
    )
}
