package workspacetools

import apiclient.validations.parseEnumValue
import dev.fritz2.core.RenderContext
import koin.withKoin
import localization.translate
import mainmenu.Pages
import mainmenu.RouterStore
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twIconMedium
import twcomponents.twIconMediumToLarge
import twcomponents.twLargeIconButton
import twcomponents.twRowOf
import workspacetools.usermanagement.userManagement
import workspacetools.workspaceoptions.workspaceOptions


fun RenderContext.workplaceTools() {
    withKoin {

        val router = get<RouterStore>()

        div("fixed top-0 left-0 flex flex-col w-screen h-screen gap-1 sm:gap-5 bg-gray-100") {
            div("flex flex-row justify-between w-screen bg-white place-items-center p-2 gap-2 sm:gap-5") {
                twRowOf {
                    twIconMediumToLarge(FormationIcons.Map.icon)
                    h1("flex flex-col sm:flex-row text-sm sm:text-2xl my-0") {
                        span("font-bold") {
                            +"Workspace"
                        }
                        span {
                            +"Tools"
                        }
                    }
                }
                twRowOf {
                    WorkspaceToolsMenu.entries.forEach { screen ->
                        twRowOf {
                            twIconMedium(screen.icon)
                            a("cursor-pointer text-sm sm:text-base") {
                                translate(screen)
                            }
                            clicks handledBy {
                                router.validateInternalRoute(
                                    screen.route,
                                )
                            }
                        }
                    }
                }
                div {
                    twLargeIconButton(FormationUIIcons.ArrowLeft.icon) {
                        clicks handledBy router.goToMap
                    }
                }
            }
            div("grow bg-white w-max-content sm:rounded-lg shadow-sm sm:mx-5 p-5 overflow-y-auto") {

                router.router.select(WorkspaceToolsMenu.routeKey).render { (subPage, params) ->
                    params[Pages.routeKey].takeIf {
                        it == Pages.AdminDashboard.name
                    }?.let {
                        val selectedSubPage = parseEnumValue<WorkspaceToolsMenu>(subPage) ?: WorkspaceToolsMenu.WorkspaceOptions
                        when (selectedSubPage) {
                            WorkspaceToolsMenu.WorkspaceOptions -> workspaceOptions()
                            WorkspaceToolsMenu.UserManagement -> userManagement()
                        }
                    }
                }
            }
        }
    }
}
