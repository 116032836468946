package auth

import apiclient.FormationClient
import apiclient.auth.createSignInToken
import com.tryformation.localization.Translatable
import dev.fritz2.components.modal
import dev.fritz2.core.RootStore
import koin.withKoin
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.launch
import localization.TL
import localization.translate
import qrcode.userverification.urlToSvgQrCode
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twLargeIconButton
import twcomponents.twMarkdownContent

enum class OpenOnAnotherDeviceTexts : Translatable {
    Explainer
    ;

    override val prefix = "open-on-another-device"
}

fun openAnotherDevicePopup() = modal(
    {
        flex {
            shrink { "1" }
        }
        width { "500px" }
        maxWidth { full }
        height { maxContent }
        maxHeight { full }
        radius { "24px" }
    },
) {
    placement { center }
    hasCloseButton(false)
    content { closeHandler ->
        withKoin {
            // create a new store when this is rendered; that way it should go away when the user navigates away as well.
            val signinUrlStore = AutoRefreshingQrCodeLinkStore(job)

            div("flex flex-col w-full h-full") {
                div("flex w-full h-max items-start justify-end gap-2") {
                    twLargeIconButton(icon = FormationUIIcons.Close.icon) {
                        clicks handledBy closeHandler
                    }
                }
                div("flex flex-col justify-between w-full px-2 lg:px-5") {
                    twColOf {
                        h3 {
                            translate(TL.MainMenu.LoginWithDifferentDevice)
                        }

                        signinUrlStore.data.filter { it.isNotBlank() }.render { qrCode ->
                            svg("w-full") {}.domNode.apply {
                                outerHTML = qrCode
                            }
                        }

                        twMarkdownContent(OpenOnAnotherDeviceTexts.Explainer)
                    }
                }
            }
        }
    }
}

class AutoRefreshingQrCodeLinkStore(job: Job) : RootStore<String>("", job) {
    suspend fun getNewCode() {
        withKoin {
            val client = get<FormationClient>()
            val currentWorkspaceStore = get<CurrentWorkspaceStore>()
            val apiUserStore = get<ApiUserStore>()
            currentWorkspaceStore.current?.name?.let { workspaceName ->

                client.createSignInToken(apiUserStore.current.userId, workspaceName).getOrNull()?.let { token ->
                    update(urlToSvgQrCode("https://app.tryformation.com/#logintoken=${token.token}"))
                }
            }
        }
    }

    init {
        // launches this in the coroutine scope associated with this component
        // hopefully it will get destroyed as well
        CoroutineScope(job).launch {
            while (true) {
                try {
                    console.log("Getting new code")
                    getNewCode()
                } catch (t: Throwable) {
                    console.error(t)
                }
                delay(20.seconds)
            }
        }
    }
}
