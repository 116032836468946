package data.objects.views

import analyticsdashboard.PathActiveHighlightedObjectStore
import analyticsdashboard.PathSearchResultsStore
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.HistoryArgs
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.search.ObjectSearchResult
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import data.objects.objecthistory.ActiveHistoryEntryStore
import data.objects.objecthistory.DisplayedPathObjectResultsStore
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.objects.objecthistory.ObjectHistoryResultsStore
import data.objects.objecthistory.ShowObjectHistoryPathStore
import data.objects.objecthistory.changeType
import data.objects.objecthistory.hasLocation
import data.objects.objecthistory.historyEntryTypes
import data.objects.objecthistory.parentHasHistoryPath
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.Style
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import search.selectableHistoryPathSearchResultsListEntry
import search.separationLine
import styling.primaryButtonStyleParams
import theme.FormationColors
import utils.formatDateTimeForObjectHistory
import utils.getIcon
import utils.getObjectHistoryMessage
import utils.parseInstant
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.iconInfoField
import webcomponents.mainTitle
import webcomponents.oneButtonFooter

fun RenderContext.cardObjectHistory() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val objectHistoryResultsStore: ObjectHistoryResultsStore = koinCtx.get()

    baseLayout(
        header = {
            // TITLE
            flexBox(
                {
                    width { full }
                    alignItems { center }
                    justifyContent { center }
                },
            ) { mainTitle(translation[TL.CardObjectHistory.CARD_TITLE]) }
        },
        content = {
            contentScrollBox {
                // RESULTSLIST
                objectHistoryList(results = objectHistoryResultsStore.data)
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.BACK],
                styleParams = primaryButtonStyleParams,
                value = Unit,
                clickHandlers = listOf(routerStore.back),
            )
        },
    )
}

fun RenderContext.objectHistoryList(
    results: Flow<Map<GeoObjectDetails, List<ObjectSearchResult>>>,
    multiList: Boolean = false,
    interactive: Boolean = false
) {

    val translation: Translation by koinCtx.inject()
    val showObjectHistoryPathStore by koinCtx.inject<ShowObjectHistoryPathStore>()
    val displayedPathObjectResultsStore: DisplayedPathObjectResultsStore by koinCtx.inject()
    val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
    val pathSearchResultsStore: PathSearchResultsStore by koinCtx.inject()
    val pathActiveHighlightedObjectStore: PathActiveHighlightedObjectStore by koinCtx.inject()

    results.render { resultsList ->
        showObjectHistoryPathStore.data.render { historyPathActive ->
            if (resultsList.isNotEmpty()) {
                stackUp(
                    {
                        width { full }
                        alignItems { center }
                        paddings { vertical { normal } }
                    },
                    id = "list-entry-stack-up",
                ) {
                    spacing { small }
                    items {
                        resultsList.forEach { (obj, results) ->
                            if (multiList) {
                                selectableHistoryPathSearchResultsListEntry(
                                    obj,
                                    selected = displayedPathObjectResultsStore.data.map { obj.id in it.keys },
                                    highlighted = pathActiveHighlightedObjectStore.data.mapNotNull { obj.id == it },
                                    selectHandlers = listOf(objectHistoryResultsCache.getTrackedObjectHistory),
                                    clickHandlers = listOf(pathSearchResultsStore.centerToObject),
                                    icon = { close },
                                    iconBgColor = { FormationColors.RedError.color },
                                )
                                separationLine(margins = { vertical { tiny } })
                            }

                            results.takeIf { it.isNotEmpty() }?.forEach { result ->
                                val connectedObjType = parseEnumValue<ObjectType>(result.hit.tags.getUniqueTag(ObjectTags.ConnectedObjectType))
                                objectHistoryListEntry(
                                    obj = result,
                                    linkToMap = interactive
                                        && result.hasLocation()
                                        && result.parentHasHistoryPath()
                                        && historyPathActive
                                        && connectedObjType?.historyEntryTypes()?.contains(result.changeType()) == true,
                                )
                            } ?: div("w-full flex items-center justify-center") {
                                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                            }

                            div(
                                {
                                    height { larger }
                                },
                            ) { }
                        }
                    }
                }
            } else {
                div("w-full flex items-center justify-center") {
                    span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                }
            }
        }
    }
}

fun RenderContext.objectHistoryListEntry(obj: ObjectSearchResult, linkToMap: Boolean) {
    val activeHistoryEntryStore by koinCtx.inject<ActiveHistoryEntryStore>()

    val geoObject = obj.hit
    val triggeredByUserName = geoObject.tags.getUniqueTag(HistoryArgs.TriggeredByName)
        ?: geoObject.tags.getUniqueTag(HistoryArgs.TriggeredBy)?.let { userId ->
            if (userId == "anonymous") "Guest User" else "User: $userId"
        } // TODO translate

    geoObject.changeType()?.let { changeType ->
        changeType.getObjectHistoryMessage(geoObject)?.let { message ->
            if (linkToMap) {
                button(
                    {
                        width { full }
                    },
                    id = "${obj.hit.id}-history-button",
                ) {
                    activeHistoryEntryStore.data.render { activeEntry ->
                        val isActive = activeEntry?.id == obj.hit.id
                        iconInfoField(
                            icon = { changeType.getIcon().icon },
                            pretitle = geoObject.createdAt.parseInstant()
                                ?.let { flowOf("${it.formatDateTimeForObjectHistory()}${triggeredByUserName?.let { userName -> ", $userName" }}") },
                            title = message,
                            subtitle = flowOf(geoObject.tags.getUniqueTag(HistoryArgs.Destination)),
                            ellipsed = false,
                            hoverStyle = historyEntryHoverStyle,
                            additionalStyle = if (isActive) activeHistoryEntryStyle else historyEntryWithLocationStyle,
                        )
                    }
                    clicks.map { obj.hit } handledBy activeHistoryEntryStore.selectHistoryEntry
                }
            } else {
                iconInfoField(
                    icon = { changeType.getIcon().icon },
                    pretitle = geoObject.createdAt.parseInstant()
                        ?.let { flowOf("${it.formatDateTimeForObjectHistory()}${triggeredByUserName?.let { userName -> ", $userName" }}") },
                    title = message,
                    subtitle = flowOf(geoObject.tags.getUniqueTag(HistoryArgs.Destination)),
                    ellipsed = false,
                )
            }
        }
    }
}

val activeHistoryEntryStyle: Style<BasicParams> = {
    background { color { primary.main } }
    color { secondary.main }
    hover {
        background { color { primary.main } }
    }
}

val historyEntryWithLocationStyle: Style<BasicParams> = {
    background { color { FormationColors.GrayPrivate.color } }
    color { primary.main }
}

val historyEntryHoverStyle: Style<BasicParams> = {
    hover {
        background { color { FormationColors.GrayDisabled.color } }
    }
}
