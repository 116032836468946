package search.searchlayer

import analyticsdashboard.AnalyticsTimeFilterStore
import apiclient.FormationClient
import apiclient.geoobjects.Bbox
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.restSearchForIds
import apiclient.groups.Group
import apiclient.groups.LayerStatus
import apiclient.markers.MapLayerContext
import apiclient.tags.*
import apiclient.util.formatIsoDate
import apiclient.util.isNotNullOrEmpty
import apiclient.util.withDuration
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.objecthistory.ObjectHistoryResultsCache
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.routing.MapRouter
import dev.fritz2.tracking.tracker
import koin.koinCtx
import koin.withKoin
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.time.Duration.Companion.seconds
import kotlin.time.measureTimedValue
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.cancelAndJoin
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.conflate
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.builtins.serializer
import layercache.GeoObjectDetailsCache
import layercache.LayerCache
import layercache.LayerCache.Companion.searchLayers
import layercache.getLayerClients
import localstorage.LocalStoringStore
import mainmenu.AppStateStore
import mainmenu.Pages
import mainmenu.PeriodicSearchTimeUnitStore
import mainmenu.PeriodicSearchTimeValueStore
import map.MapLayerUserSettingsStore
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.AppPhase
import model.LocalSettings
import model.MapState
import model.SearchContexts
import model.manualSearch
import model.mapSearch
import model.periodicSearch
import network.NetworkState
import network.NetworkStateStore
import search.SearchContextsStore
import search.hasUserAndGroupIds
import utils.getMapLayersToClean
import utils.insertObjectsInCachesAndMap
import utils.parseInstant
import utils.removeObjectsInCachesAndMap

class SyncTimestampStore : LocalStoringStore<String>("", "", "last-synced", String.serializer()) {
    fun getLastSynced(): Instant? {
        return current.takeIf { it.isNotNullOrEmpty() }?.parseInstant()
    }

    fun markLastSynced() {
        update(Clock.System.now().formatIsoDate())
    }

}

class MapSearchClientsStore : RootStore<List<LayerCache>>(
    initialData = emptyList(),
    job = Job(),
) {

    private val syncTimestampStore = SyncTimestampStore()
    private val router: MapRouter by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    private val searchContextsStore: SearchContextsStore by koinCtx.inject()
    private val mapStateStore: MapStateStore by koinCtx.inject()
    private val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    private val searchManually = localSettingsStore.map(LocalSettings.manualSearch())
    private val networkStateStore: NetworkStateStore by koinCtx.inject()
    private val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    private val geoObjectDetailsCache: GeoObjectDetailsCache by koinCtx.inject()
    private val objectHistoryResultsCache: ObjectHistoryResultsCache by koinCtx.inject()
    private val analyticsTimeFilterStore: AnalyticsTimeFilterStore by koinCtx.inject()

    val mapSearchTracker = tracker(debounceTimeout = 800)

    val updateObject = handle<GeoObjectDetails> { old, obj ->
        console.log("Updating object in layer caches")
        mapSearchResultsStore.insert(obj)
        geoObjectDetailsCache.update(obj.id, obj)
        old
    }

    fun updateObjects(objects: List<GeoObjectDetails>) {
//    val updateObjects = handle<List<GeoObjectDetails>> { old, objects ->
        console.log("Updating objects in layer caches")
        mapSearchResultsStore.insertMultiple(objects.filter { it.objectType.includeInSearch })
//        objects.forEach { obj ->
//            geoObjectDetailsCache.update(obj.id, obj)
//        }
    }

    val removeObjects = handle<Set<String>> { old, objectIds ->
        console.log("Removing objects in layer caches")
        mapSearchResultsStore.removeMultiple(objectIds)
        objectIds.forEach { objId ->
            geoObjectDetailsCache.delete(objId)
        }
        old
    }

    val reset = handle {
        console.log("Reset MapSearchClientsStore")
        emptyList()
    }

    val updateMapSearchClients = handle<List<Group>?> { current, groups ->
        val mapLayerUserSettingsStore by koinCtx.inject<MapLayerUserSettingsStore>()
        // get clients for all layers in all groups
        val user = apiUserStore.current
        val backupGroupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId }?.toList() ?: emptyList()
        val groupIds = if (!groups.isNullOrEmpty()) groups.map { it.groupId }.toList() else backupGroupIds
        CoroutineScope(EmptyCoroutineContext).launch {
            try {
                if (groupIds.isNotEmpty()) {
                    console.log("Try to get map search layer clients for groups:", groupIds.toString())
                    val clients = getLayerClients(
                        client = formationClient,
                        userId = user.userId,
                        groupIds = groupIds,
                        debug = false,
                    )
//                    val clients =
//                        if(featureFlagStore.current[Features.DisableClustering] == true) {
//                            getLayerClients(
//                                client = formationClient,
//                                userId = user.userId,
//                                groupIds = groupIds,
//                                debug = false
//                            )
//                        } else {
//                            getLayerClients(
//                                client = formationClient,
//                                userId = user.userId,
//                                groupIds = groupIds,
//                                debug = false,
//                                overrideLayerCacheSettings = LayerCacheSettings(
//                                    capacityForContentCells = 25000,
//                                    zoomLevelThresholdContent = 8.0,
//                                    ttlInSeconds = 300,
//                                    minDelayBetweenRequestsInSeconds = 10,
//                                    searchPageSize = 50
//                                )
//                            )
//                        }
                    console.log("Got map search layer clients:", clients.map { it.layerId }.toString())
                    if (user.isAnonymous) {
                        update(
                            clients.filter { client ->
                                client.layerId in (mapLayerUserSettingsStore.current.filter { layerSetting ->
                                    layerSetting.layerStatus == LayerStatus.ON
                                }.map { it.layerId })
                            },
                        )
                    } else {
                        update(clients)
                    }
                    mapSearch(null)
                } else {
                    console.log("Could not get search layer clients, groups are empty")
                    update(emptyList())
                }
            } catch (e: Exception) {
                console.log("Could not get search layer clients", e.message)
                update(emptyList())
            }
        }
        current
    }

    val triggerMapSearchManually = handle { current ->
        mapSearch(null)

        mapStateStore.resetMovedState()
        current
    }

    private var searchJob: Job? = null

    val mapSearch = handle<MapState?> { current, mapState ->
        val maplibreMap: MaplibreMap by koinCtx.inject()
        val appStateStore by koinCtx.inject<AppStateStore>()
        val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
        val newMapState = mapState ?: mapStateStore.current
        val online = networkStateStore.current == NetworkState.Online
        val loggedIn =
            appStateStore.current.appPhase.let { it == AppPhase.LoggedIn || it == AppPhase.LoggedInAnonymous }

        console.warn("MAP has moved ->", newMapState?.hasMoved)

        if (newMapState != null && maplibreMap.isNotMoving() && online && loggedIn) {
            val bbox = newMapState.tl?.let { tl -> newMapState.br?.let { br -> Bbox(topLeft = tl, bottomRight = br) } }
            // trigger bbox search (but not when search page is up)
            if (router.current["page"] != Pages.Search.name && router.current["page"] != Pages.Hub.name && bbox != null) { // && !routerStore.history.current.contains(mapOf("page" to Pages.Search.name))) {
                if ((searchContextsStore.current.mapSearch).hasUserAndGroupIds()) {
                    coroutineScope {
                        searchJob?.run {
                            if (isActive) {
                                console.warn("cancelling old search")
                            }
                            cancelAndJoin()
                        }
                        searchJob = launch(CoroutineName("map-search")) {
//                            layerCacheMain.updateSearchParameters(
//                                formationClient = formationClient,
//                                layerCaches = current,
//                                bbox = bbox,
//                                mapLayerContext = searchContextsStore.current.mapSearch,
//                                zoomLevel = maplibreMap.getZoom()
//                            )

                            mapSearchTracker.track {
                                measureTimedValue {
                                    searchLayers(
                                        formationClient = formationClient,
                                        layerCaches = current,
                                        bbox = bbox,
                                        mapLayerContext = searchContextsStore.current.mapSearch,
                                        searchResultStore = mapSearchResultsStore,
                                    )
                                }.withDuration {
                                    console.log("SEARCH TOOK", duration.toString())
                                }
//                                    measureTime {
//                                        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, results))
//                                    }.also { time ->
//                                        console.log("RENDER TOOK", time.toString())
//                                    }
                            }

                        }
                    }
                }
            }
        }
//        maplibreMap.reRenderMarker(activeObjectStore.current.id, activeObjectStore.current.objectType)
        current
    }

    val invalidateLayerCaches = handle<ObjectType> { current, objType ->
        val layerCaches = current.filter { it.layerId in objType.getMapLayersToClean() }
        layerCaches.forEach { cache -> cache.clear() }
        console.log("Cache(s) cleared for:", layerCaches.map { it.layerId }.toString())
        mapSearch(null)
        current
    }

    fun clearAllLayerCaches() {
        current.forEach { layerCache ->
            layerCache.clear()
        }
    }

    fun insertId(id: String, latLon: LatLon) {
        current.forEach { layerCache ->
            layerCache.insertId(
                id = id,
                latLon = latLon,
                mapLayerContext = searchContextsStore.current.mapSearch,
            )
        }
    }

    private val mapPageListener = SimpleHandler<Map<String, String>> { routeData, _ ->
        routeData handledBy { route ->
            if (route.keys.size <= 2 && route["ws"] != null
                && route["page"] == Pages.Map.name
            ) {
                mapSearch(null)
            }
        }
    }


    private suspend fun syncChangedSince(
        changedAfter: Instant,
        formationClient: FormationClient,
//            layerCaches: List<LayerCache>,
//            bbox: Bbox,
        mapLayerContext: MapLayerContext,
        includeDeleted: Boolean = false,
        onUpdate: suspend (Pair<List<GeoObjectDetails>, Set<String>>) -> Unit = {}
    ) = coroutineScope {
        val searchContext = SearchQueryContext(
            groupIds = mapLayerContext.groupIds,
            changedAfter = changedAfter.toString(),
            includeDeleted = includeDeleted,
        )
        val result = formationClient.restSearchForIds(searchContext)

        when {
            result.isSuccess -> {
                try {
                    val changedIds = result.getOrThrow().hits
                    if (changedIds.isNotEmpty()) {
                        val updatedObjects = measureTimedValue {
                            geoObjectDetailsCache.multiGet(
                                ids = changedIds,
                                formationClient = formationClient,
                                forceUpdate = true,
                            )
                        }.withDuration {
                            console.info("sync-changes multiget took $duration")
                        }.filterNot { it.tags.isDeleted } // filter out deleted objects
                        val deletedIds = changedIds.toSet() - updatedObjects.map { it.id }.toSet()
                        onUpdate(updatedObjects to deletedIds)
                    } else {
                    }
                } catch (e: Throwable) {
                    console.error("sync-changes failed", e.message)
                }
            }

            else -> {
                console.warn("sync-changes failed")
                console.warn("sync-changes failed", result.exceptionOrNull()?.stackTraceToString())
            }
        }
    }

    private suspend fun doSync(
        changedAfter: Instant
    ) {
        val appStateStore by koinCtx.inject<AppStateStore>()
        val mapState = mapStateStore.current ?: return
        if (mapState.tl == null || mapState.br == null) {
            return
        }
        val online = networkStateStore.current == NetworkState.Online
        val loggedIn =
            appStateStore.current.appPhase.let { it == AppPhase.LoggedIn || it == AppPhase.LoggedInAnonymous }

        if (online && loggedIn) {
            syncChangedSince(
                formationClient = formationClient,
                mapLayerContext = searchContextsStore.current.mapSearch,
                changedAfter = changedAfter,
                includeDeleted = true,
            ) { (newObjects, deletedIds) ->
                removeObjectsInCachesAndMap(deletedIds)
                insertObjectsInCachesAndMap(newObjects)
                analyticsTimeFilterStore.refreshTimeFilter()
                objectHistoryResultsCache.refetchAll()

                if(newObjects.isNotEmpty() || deletedIds.isNotEmpty()) {
                    withKoin {
                        console.log("syncing map")
                        val maplibreMap = get<MaplibreMap>()
                        maplibreMap.syncMarkersNow()
                    }
                    console.info("updated objects: " +newObjects.size + " deleted object: ${deletedIds.size}")
                }
            }
        }
    }

    init {
        // log last sync and force store to initialize
        console.log("lastSynced", syncTimestampStore.getLastSynced())
        router.data.conflate() handledBy mapPageListener
        mapStateStore.data.combine(searchManually.data) { mapState, manualSearch ->
            if (manualSearch) null else mapState
        }.filterNotNull().debounce(300) handledBy mapSearch
        searchContextsStore.map(SearchContexts.mapSearch()).data.map {
            null
        } handledBy mapSearch

        CoroutineScope(CoroutineName("periodic-sync")).launch {
            // don't start syncing right away
            delay(10.seconds)
            while (true) {
                try {
                    val lastSynced = syncTimestampStore.getLastSynced() ?: ("2000-01-01T00:00:00.000Z".parseInstant()?: error("invalid date in code"))
                    // allow for a little grace period of overlap
                    doSync(lastSynced - 10.seconds)
                    syncTimestampStore.markLastSynced()
                    // sync occasionally but not all the time
                    delay(5.seconds)
                } catch (e: Exception) {
                    // failures should not be permanent
                    console.error("sync failed", e)
                }
            }
        }
//        CoroutineScope(CoroutineName("cache-listener")).launch {
////            layerCacheMain.startSearch(formationClient)
//            layerCacheMain.valueFlow()
//                .onCompletion { throwable ->
//                    if(throwable != null) {
//                        console.error("cache listener flow encountered error", throwable)
//                    }
//                }
//                .collect { results ->
//                    searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, results))
//                }
//        }
    }

}

class PeriodicSearchStore : RootStore<Job?>(
    initialData = null,
    job = Job(),
) {
    private val periodicSearchTimeUnitStore by koinCtx.inject<PeriodicSearchTimeUnitStore>()
    private val periodicSearchTimeValueStore by koinCtx.inject<PeriodicSearchTimeValueStore>()
    private val mapSearchClientsStore by koinCtx.inject<MapSearchClientsStore>()
    private val localSettingsStore by koinCtx.inject<LocalSettingsStore>()
    private val periodicSearch = localSettingsStore.map(LocalSettings.periodicSearch())

    val initialize = handle { it }

    val startPeriodicSearch = handle { searchJob ->
        if (searchJob != null && searchJob.isActive) {
            console.log("Periodic search is already active")
            searchJob
        } else {
            console.log("Start search periodically every ${periodicSearchTimeValueStore.current} ${periodicSearchTimeUnitStore.current.name.lowercase()}")
            val periodicSearchJob = CoroutineScope(CoroutineName("auto-search")).launch {
                while (true) {
                    // Validate input duration. Make sure duration is >= 1 second
                    val durationSetting =
                        periodicSearchTimeUnitStore.current.asDuration(periodicSearchTimeValueStore.current)
                    val delayDuration = if (durationSetting >= 1.seconds) durationSetting else 1.seconds
                    delay(delayDuration)
                    console.log("auto search (every ${periodicSearchTimeValueStore.current} ${periodicSearchTimeUnitStore.current.name.lowercase()})")
                    mapSearchClientsStore.mapSearch(null)
                }
            }
            periodicSearchJob
        }
    }

    val stopPeriodicSearch = handle { current ->
        current?.let {
            console.log("Stop periodic search")
            it.cancel("Stop periodic search by user")
            null
        }
    }

    private val handleSetting = handle<Boolean> { current, setting ->
        if (setting) startPeriodicSearch() else stopPeriodicSearch()
        current
    }

    private val changeInterval = handle { current ->
        if (current != null) {
            console.log("Restart periodic search")
            current.cancel("Stop periodic search by user")
            startPeriodicSearch()
        }
        current
    }

    init {
        console.log("PeriodicSearchStore initialized")
        periodicSearch.data handledBy handleSetting
        periodicSearchTimeValueStore.data.combine(periodicSearchTimeUnitStore.data) { value, unit ->
            // Validate input duration. Make sure duration is >= 1 second
            if (unit.asDuration(value) >= 1.seconds) Unit else null
        }.mapNotNull { it } handledBy changeInterval

    }
}
