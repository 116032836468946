package workspacetools.workspaceoptions

import auth.CurrentWorkspaceStore
import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.GroupNotificationTags
import apiclient.groups.PrivateObject
import apiclient.groups.configureGroupNotification
import apiclient.groups.listSlackHooks
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import koin.withKoin
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import localization.translate
import overlays.BusyStore
import twcomponents.twCheckButton
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMarkdownContent
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow

enum class WorkspaceOptionsSlackhandles : Translatable {
    Explanation,
    Fetching,
    SlackHandleEditorHeader,
    TaskNotificationsHandle,
    PollNotificationsHandle,
    CommentsNotificationHandle,
    ;

    override val prefix = "workspace-options-slackhandles"
}


fun RenderContext.slackHandleEditor(group: Group) {
    h2 {
        translate(WorkspaceOptionsSlackhandles.SlackHandleEditorHeader)
    }
    twMarkdownContent(WorkspaceOptionsSlackhandles.Explanation)
    fetchSlackHooks(group).render { hooks ->
        if (hooks == null) {
            p {
                +"Fetching current slack hooks"
            }
        } else {
            val hooksMap = hooks.associateBy { it.name }
            h3 {
                translate(WorkspaceOptionsSlackhandles.PollNotificationsHandle)
            }

            editSlackHook(group, GroupNotificationTags.SlackHookForPolls, hooksMap)

            h3 {
                translate(WorkspaceOptionsSlackhandles.CommentsNotificationHandle)
            }

            editSlackHook(group, GroupNotificationTags.SlackHookForComments, hooksMap)

            h3 {
                translate(WorkspaceOptionsSlackhandles.TaskNotificationsHandle)
            }

            editSlackHook(group, GroupNotificationTags.SlackHookForTasks, hooksMap)
        }
    }
}

fun RenderContext.editSlackHook(
    group: Group,
    hookTag: GroupNotificationTags,
    hooksMap: Map<String, PrivateObject.GroupOwnedObject.Credential.SlackCredential>
) {
    val oldHandle = hooksMap[hookTag.name]?.url ?: ""
    val handleStore = storeOf(oldHandle)
    val handleStateStore = storeOf(SlackHandleState.Same)
    handleStore.data handledBy {
        checkHandle(oldHandle, it, handleStateStore)
    }
    div("w-full sm:w-[700px]") {
        twInputField(handleStore) {
            twInputTextField {
                placeholder("https://hooks.slack.com/services/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")
            }

            twRightAlignedButtonRow {
                handleStateStore.data.render { state ->
                    when (state) {
                        SlackHandleState.Same -> {
                            twRevertButton(handleStore, oldHandle)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        SlackHandleState.Invalid -> {
                            div {
                                +"Invalid"
                            }
                            twRevertButton(handleStore, oldHandle)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        SlackHandleState.Valid -> {
                            twRevertButton(handleStore, oldHandle)
                            twCheckButton {
                                clicks handledBy {
                                    setSlackHook(group, hookTag, handleStore.current)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun setSlackHook(group: Group, notificationTag: GroupNotificationTags, url: String) {
    withKoin {
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()

        busyStore.withBusy(
            {
                formationClient.configureGroupNotification(group.groupId, notificationTag, url)
            },
        ) { updatedGroup ->
            currentWorkspaceStore.update(updatedGroup)
        }
    }
}

enum class SlackHandleState {
    Same,
    Invalid,
    Valid,
}


fun fetchSlackHooks(group: Group): Flow<List<PrivateObject.GroupOwnedObject.Credential.SlackCredential>?> {
    return flow {
        emit(null)
        console.log("fetching")
        emit(getSlackHandles(group))
        console.log("fetched")
    }
}


suspend fun getSlackHandles(group: Group): List<PrivateObject.GroupOwnedObject.Credential.SlackCredential> {
    withKoin {
        val formationClient = get<FormationClient>()
        return formationClient.listSlackHooks(group.groupId).getOrThrow().also {
            console.log("done")
        }
    }
}

fun checkHandle(old: String?, new: String, stateStore: Store<SlackHandleState>) {
    console.log("checking", new)
    when {
        old.orEmpty() == new -> stateStore.update(SlackHandleState.Same)
        new.validUrl -> stateStore.update(SlackHandleState.Valid)
        else -> stateStore.update(SlackHandleState.Invalid)
    }
}

val String.validUrl: Boolean
    get() {
        val urlPattern = "^((http|https|ftp)://)?(([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\\.)+[a-zA-Z]{2,})(:[0-9]{1,5})?(/.*)?$".toRegex()
        return this.matches(urlPattern)
    }
