@file:Suppress("unused")

package layercache

import apiclient.FormationClient
import apiclient.geoobjects.Bbox
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.center
import apiclient.geoobjects.floorsForBuilding
import apiclient.geoobjects.geojsonBoundingBox
import apiclient.geoobjects.jsonMsearch
import apiclient.geoobjects.newContext
import apiclient.geoobjects.restSearch
import apiclient.geoobjects.toLatLon
import apiclient.geoobjects.unitsForFloor
import apiclient.groups.LayerCacheSettings
import apiclient.groups.LayerMetaData
import apiclient.markers.MapLayerContext
import apiclient.markers.QuadTreeUtil
import apiclient.markers.SearchContextFactory
import apiclient.search.ObjectSearchResult
import apiclient.search.ObjectSearchResults
import apiclient.tags.isDeleted
import apiclient.util.Cache
import apiclient.util.SimpleCache
import apiclient.util.withDuration
import com.jillesvangurp.geojson.BoundingBox
import com.jillesvangurp.geojson.bottomLeft
import com.jillesvangurp.geojson.bottomRight
import com.jillesvangurp.geojson.eastLongitude
import com.jillesvangurp.geojson.longitude
import com.jillesvangurp.geojson.northLatitude
import com.jillesvangurp.geojson.southLatitude
import com.jillesvangurp.geojson.topLeft
import com.jillesvangurp.geojson.zoomLevel
import data.users.settings.LocalSettingsStore
import koin.koinCtx
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.seconds
import kotlin.time.measureTimedValue
import kotlinx.datetime.Instant
import mu.KotlinLogging
import search.searchlayer.MapSearchResultsStore

private val logger = KotlinLogging.logger { }

private val geoObjectCache: GeoObjectDetailsCache by koinCtx.inject()

data class RequestKey(
    val cellPath: String,
    val mapLayerContext: MapLayerContext,
) {
    override fun toString(): String = "$cellPath-$mapLayerContext"
}

private data class PagedSearchKey(
    val requestKey: RequestKey,
    val layerId: String,
)

data class CacheCell(
    val lastUpdated: Instant,
    val content: Set<String>
) {
    val total = content.size
    fun update(
        lastUpdated: Instant,
        addedContent: Set<String>,
        deletedIds: Set<String>,
    ): CacheCell {
        return CacheCell(
            lastUpdated = lastUpdated,
            content = content + addedContent - deletedIds,
        )
    }
}

//suspend fun replaceObject(newVersion: GeoObjectDetails) {
//    geoObjectCache.update(newVersion.id, newVersion)
//}

//suspend fun insertObject(newVersion: GeoObjectDetails) {
//    if (newVersion.tags.getUniqueTag(ObjectTags.Archived).toBoolean() || newVersion.deleted) {
//        deleteObject(newVersion.id)
//    } else {
//        geoObjectCache.update(newVersion.id, newVersion)
//    }
//}

private suspend fun deleteObject(id: String) {
    geoObjectCache.delete(id)
}

data class LayerCache(
    private val layerMetaData: LayerMetaData,
    val searchContextFactory: SearchContextFactory,
    private val runtimeExcludeTags: List<String>?,
    val debug: Boolean = false,
    private val testing: Boolean = false,
) {
    private val cache: Cache<PagedSearchKey, Set<String>> = SimpleCache(
        layerMetaData.layerCacheSettings?.capacityForContentCells ?: LayerCacheSettings.DEFAULT.capacityForContentCells,
        (layerMetaData.layerCacheSettings?.ttlInSeconds ?: LayerCacheSettings.DEFAULT.ttlInSeconds) * 1000,
    )

    private val localSettingsStore: LocalSettingsStore by koinCtx.inject()

    private val isClustering: Boolean
        get() {
            return localSettingsStore.clusteringEnabled
        }

    val layerId by lazy { layerMetaData.id }

    private val contentCellSize: Double by lazy {
        (layerMetaData.layerCacheSettings?.zoomLevelThresholdContent
            ?: LayerCacheSettings.DEFAULT.zoomLevelThresholdContent) // + 3
        16.0
    }
    internal val contentPathLength: Int by lazy {
        contentCellSize.roundToInt()
    }

    private val zoomLevelThresholdContent: Double by lazy {
        if (localSettingsStore.clusteringEnabled) {
            10.0
        } else {
            8.0
        }
    }
    private val searchPageSize: Int by lazy {
        layerMetaData.layerCacheSettings?.searchPageSize ?: LayerCacheSettings.DEFAULT.searchPageSize
    }
    private val minDelayBetweenRequests: Duration by lazy {
        if (testing) {
            0.1.seconds
        } else {
            layerMetaData.layerCacheSettings?.minDelayBetweenRequestsInSeconds?.seconds
                ?: LayerCacheSettings.DEFAULT.minDelayBetweenRequestsInSeconds.seconds
        }
    }

    private fun createSearchContext(mapLayerContext: MapLayerContext) = searchContextFactory.invoke(mapLayerContext)

    private fun Set<String>.commonPrefixes(
        minPathLength: Int = 10,
    ): Set<String> {
        val paths = this
        ((contentPathLength - 1) downTo minPathLength).forEach { l ->
            val filteredPaths = paths.map { path ->
                path.take(l)
            }.toSet()
            if (filteredPaths.size <= 9) {
                return filteredPaths
            }
        }
        return paths.map { path ->
            path.take(minPathLength)
        }.toSet()
    }

    private val pathsCache = SimpleCache<Pair<Bbox, Int>, Set<String>>(
        capacity = 1000,
        maxAgeInMillis = 5.hours.inWholeMilliseconds,
    )

    private fun buildSearch(
        bbox: Bbox,
        mapLayerContext: MapLayerContext,
    ): List<Pair<RequestKey, SearchQueryContext>> {
        val zoomlevel = bbox.geojsonBoundingBox.zoomLevel()

        return if (zoomlevel >= zoomLevelThresholdContent) {

            val paths = QuadTreeUtil.calculateOptimizedPathsForLength(
                bbox = bbox,
                pathLengths = contentPathLength,
            )

            val pathPrefixes = paths
                .commonPrefixes(minPathLength = maxOf(contentPathLength - 6, 5))

            pathPrefixes.map { path ->
                val requestKey = RequestKey(cellPath = path, mapLayerContext = mapLayerContext)
                val tileBBox = QuadTreeUtil.bboxFromPath(path)
                val searchContext = createSearchContext(mapLayerContext).let { baseContext ->
                    val context = baseContext.copy(
                        bbox = tileBBox,
                        centroid = bbox.center,
                        from = 0,
                        size = searchPageSize,
                        debug = debug,
                        includeDeleted = true,
                    )

                    if (runtimeExcludeTags.isNullOrEmpty()) {
                        context
                    } else {
                        context.copy(excludeTags = baseContext.excludeTags.orEmpty() + runtimeExcludeTags)
                    }
                }

                requestKey to searchContext
            }
        } else {
            emptyList()
        }
    }

    fun clear() {
        cache.clear()
    }

    fun insertId(id: String, latLon: LatLon, mapLayerContext: MapLayerContext) {
        val key = PagedSearchKey(
            requestKey = RequestKey(
                cellPath = QuadTreeUtil.getPath(
                    latLon = latLon,
                    pathLength = contentPathLength,
                ),
                mapLayerContext = mapLayerContext,
            ),
            layerId = layerId,
        )
        val oldValue = cache[key].orEmpty()
        cache[key] = oldValue + id
    }

    companion object {
        private const val ONLY_REQUEST_STALE_CELLS: Boolean = false

        suspend fun searchLayers(
            formationClient: FormationClient,
            layerCaches: List<LayerCache>,
            bbox: Bbox,
            mapLayerContext: MapLayerContext,
            searchResultStore: MapSearchResultsStore
        ) {

            // build list of queries that we need #layers * #cells
            val pagedSearchContext =
                layerCaches.flatMap { layerCache ->
                    layerCache.buildSearch(
                        bbox = bbox,
                        mapLayerContext = mapLayerContext,
                    )
                        .map { (key, searchQueryContext) ->
                            PagedSearchKey(key, layerCache.layerId) to searchQueryContext
                        }
                }

            // calculate sub grid for each SearchRequest and reconstruct if available

            val cachedResults = layerCaches.flatMap { layerCache ->
                val filteredContexts = pagedSearchContext.filter { it.first.layerId == layerCache.layerId }
                filteredContexts.mapNotNull { (k, _) ->
                    val retrievedValues =
                        QuadTreeUtil.subPaths(k.requestKey.cellPath, layerCache.contentPathLength)
                            .map { cellPath ->
                                k.copy(
                                    requestKey = k.requestKey.copy(
                                        cellPath = cellPath,
                                    ),
                                )
                            }
                            .flatMap { subKey ->
                                layerCache.cache[subKey] ?: run {
                                    return@mapNotNull null
                                }
                            }

                    k to retrievedValues
                }
            }.toMap()

            val filteredContexts = pagedSearchContext.filter { (k, _) ->
                k !in cachedResults
            }

            val pagedResults =
                filteredContexts.chunked(50).flatMap { entries ->
                    formationClient.pagedSearchIdOnly(entries.toMap()).map { (key, value) ->
                        key to value
                    }
                }.toMap()


            val pagedResultsFlattened = pagedResults
                .mapValues { p ->
                    p.value.flatMap { v ->
                        v.hits
                    }
                }

            val ids =
                layerCaches.flatMap { layerCache ->
                    (pagedResultsFlattened)
                        .filterKeys { key -> key.layerId == layerCache.layerId }
                        .values
                        .flatten()
                }

            val cachedObjects = geoObjectCache.getExisting(ids)
            val deletedIds = cachedObjects.filter { it.tags.isDeleted }.map { it.id }
            val unresolvedIds = (ids - cachedObjects.map { it.id }.toSet()).toMutableSet()

            searchResultStore.set(cachedObjects.filter { it.id !in deletedIds })

            while (unresolvedIds.isNotEmpty()) {
                val (objects, fetchedIds) = resolveIds(unresolvedIds, formationClient)
                unresolvedIds -= fetchedIds.toSet()

                val objLookup = objects.associateBy { it.id }
                layerCaches.map { layerCache ->
                    val filteredPagedSearchResults = pagedResultsFlattened
                        .filterKeys { it.layerId == layerCache.layerId }

                    val flattenedIds = filteredPagedSearchResults.values.flatten()

                    val objInPath = flattenedIds.mapNotNull { id -> objLookup[id] }
                    objInPath.groupBy { obj ->
                        QuadTreeUtil.getPath(
                            obj.latLon,
                            layerCache.contentPathLength,
                        )
                    }.forEach { (path, objects) ->
                        val subKey = PagedSearchKey(
                            requestKey = RequestKey(path, mapLayerContext),
                            layerId = layerCache.layerId,
                        )
                        val newIds = objects.map { it.id }.toSet()
                        val oldIds = layerCache.cache[subKey].orEmpty()
                        layerCache.cache.put(subKey, oldIds + newIds)
                    }
                }
                searchResultStore.add(objects)
            }
        }

        private suspend fun resolveIds(
            ids: Set<String>,
            formationClient: FormationClient
        ): Pair<List<GeoObjectDetails>, List<String>> {
            var totalduration = Duration.ZERO
            val results = mutableListOf<GeoObjectDetails>()
            val fetchedIds = mutableListOf<String>()
            for (idsChunk in ids.chunked(50)) {
                if (totalduration > 1.seconds) {
                    // this function is called iteratively so this is fine.
                    break
                }
                val objects = measureTimedValue {
                    geoObjectCache.multiGet(ids = idsChunk, formationClient = formationClient)
                        .also { multigetResult ->
                            val fetchedIdsSet = multigetResult.map { it.id }.toSet()
                            val missingIdsSet = idsChunk.toSet() - fetchedIdsSet
                            if (missingIdsSet.isNotEmpty()) {
                                console.error("missing from result of multiget", missingIdsSet.toTypedArray())
                            }
                        }.filter { obj ->
                            !obj.tags.isDeleted
                        }
                }.withDuration {
                    totalduration += duration
                }
                fetchedIds += idsChunk
                results += objects
            }
            return results to fetchedIds
        }
    }
}


fun calculateTileBboxesForBoundingBox(
    bbox: BoundingBox,
    height: Int = 512,
    width: Int = 512,
    minZoom: Double = 22.0,
    angleFactor: Double = 1.0,
): List<Bbox> {
    val zoomLevel = bbox.zoomLevel(height, width, minZoom)
    val factor = 2.0.pow(zoomLevel) * angleFactor

    val longitudalGridAngle = 360.0 / factor
    val latitudalGridAngle = 180.0 / factor
    val mostWest = bbox.bottomLeft.longitude - bbox.bottomLeft.longitude % longitudalGridAngle

    var lat = bbox.southLatitude - bbox.southLatitude % latitudalGridAngle
    val cells = mutableListOf<BoundingBox>()
    while (lat < bbox.northLatitude) {
        var lon = mostWest
        while (lon < bbox.eastLongitude) {
            cells.add(doubleArrayOf(lon, lat, lon + longitudalGridAngle, lat + latitudalGridAngle))
            lon += longitudalGridAngle
        }
        lat += latitudalGridAngle
    }
    return cells.map { Bbox(topLeft = it.topLeft.toLatLon(), bottomRight = it.bottomRight.toLatLon()) }
}

fun calculateTileBboxesForZoomlevel(
    bbox: Bbox,
    zoomLevel: Double = 22.0,
    angleFactor: Double = 1.0,
): List<Bbox> {
    val boundingBox = bbox.geojsonBoundingBox
    val factor = 2.0.pow(zoomLevel) * angleFactor

    val longitudalGridAngle = 360.0 / factor
    val latitudalGridAngle = 180.0 / factor
    val mostWest = boundingBox.bottomLeft.longitude - (boundingBox.bottomLeft.longitude % longitudalGridAngle)

    var lat = boundingBox.southLatitude - (boundingBox.southLatitude % latitudalGridAngle)
    val cells = mutableListOf<BoundingBox>()
    while (lat < boundingBox.northLatitude) {
        var lon = mostWest
        while (lon < boundingBox.eastLongitude) {
            cells.add(doubleArrayOf(lon, lat, lon + longitudalGridAngle, lat + latitudalGridAngle))
            lon += longitudalGridAngle
        }
        lat += latitudalGridAngle
    }
    return cells.map { Bbox(topLeft = it.topLeft.toLatLon(), bottomRight = it.bottomRight.toLatLon()) }
}

fun calculateTileBboxForZoomlevelAroundPoint(
    latLon: LatLon,
    zoomLevel: Double = 22.0,
    angleFactor: Double = 1.0,
): Bbox {
    val factor = 2.0.pow(zoomLevel) * angleFactor

    val longitudalGridAngle = 360.0 / factor
    val latitudalGridAngle = 180.0 / factor
    val lon = latLon.lon - (latLon.lon % longitudalGridAngle)
    val lat = latLon.lat - (latLon.lat % latitudalGridAngle)
    val cell = doubleArrayOf(lon, lat, lon + longitudalGridAngle, lat + latitudalGridAngle)
    return cell.let { Bbox(topLeft = it.topLeft.toLatLon(), bottomRight = it.bottomRight.toLatLon()) }
}

suspend fun FormationClient.searchLayerUnCached(
    searchContextFactory: SearchContextFactory,
    layerContext: MapLayerContext,
    centroid: LatLon,
    size: Int = 100,
): Result<ObjectSearchResults> {
    val searchQueryContext = searchContextFactory.invoke(layerContext)
    val context = searchQueryContext.copy(
        centroid = centroid,
        from = 0,
        size = size,
    )
    val searchResults = restSearch(context)

    return searchResults.map {
        updateCache(it)
    }
}

private fun updateCache(results: ObjectSearchResults) =
    results.copy(
        hits = results.hits.map { osr ->
            val cacheHit = geoObjectCache[osr.hit.id]
            cacheHit?.let { replacement ->
                if (replacement.updatedAt >= osr.hit.updatedAt) {
                    osr.copy(hit = replacement)
                } else {
                    osr
                }
            } ?: osr
        },
    )

suspend fun <T : Any> FormationClient.pagedSearch(
    searchQueryContexts: Map<T, SearchQueryContext>,
    defaultPageSize: Int = 100,
): Map<T, List<ObjectSearchResults>> {
    val results: Map<T, MutableList<ObjectSearchResults>> = searchQueryContexts.keys.associateWith {
        mutableListOf<ObjectSearchResults>()
    }.toMap()

    val mutableSearchQueryContexts: MutableMap<T, SearchQueryContext> = searchQueryContexts.toMutableMap()

    var page = 0

    while (mutableSearchQueryContexts.isNotEmpty()) {
        val pageKeys = mutableSearchQueryContexts.keys.toList()
        val mSearchQueryContexts = pageKeys.mapNotNull { key ->
            mutableSearchQueryContexts[key]
        }.map { context ->
            // FIXME when we switch to kt-search, we should use the search_after support for deep paging
            context.copy(
                from = page * (context.size ?: defaultPageSize),
                size = context.size ?: defaultPageSize,
            )
        }
        val pageResult = measureTimedValue {
            if (mSearchQueryContexts.isNotEmpty()) {
                jsonMsearch(mSearchQueryContexts).getOrThrow()
            } else emptyList()
        }.withDuration {
//            console.debug("mSearch took $duration")
        }
        pageResult.forEachIndexed { index, objectSearchResults ->
            val pageKey = pageKeys[index]
            val resultsLists = results[pageKey] ?: run {
                console.error("cannot look up results accumulator for $pageKey")
                return@forEachIndexed
            }
            resultsLists += objectSearchResults
            val searchQueryContext = mutableSearchQueryContexts[pageKey] ?: run {
                console.error("already removed $pageKey from search contexts")
                return@forEachIndexed
            }

            if (objectSearchResults.hits.size < (searchQueryContext.size ?: defaultPageSize)) {
                mutableSearchQueryContexts -= pageKey
            } else if (objectSearchResults.pageSize == 0) {
                mutableSearchQueryContexts -= pageKey
            }
        }
        page++
    }

    return results
}

suspend fun FormationClient.floorsForBuilding(
    groupIds: List<String>,
    buildingId: String,
): Map<ObjectSearchResult, ObjectSearchResults>? {
    val groupIdContext = SearchQueryContext.newContext(groupIds)
    val floors = this.restSearch(groupIdContext.floorsForBuilding(buildingId)).getOrNull()?.hits

    return floors?.map {
        groupIdContext.unitsForFloor(it.hit.id)
    }?.let { unitSearchContexts ->
        jsonMsearch(unitSearchContexts).getOrNull()
    }?.let { units ->
        units.indices.map {
            val floor = floors[it]
            val unitsForFloor = units[it]
            floor to unitsForFloor
        }
    }?.toMap()
}

