package wizard.onbaording

import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import com.tryformation.localization.Translatable
import data.users.settings.LocalSettingsStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.styling.theme.IconDefinition
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.Translation
import model.LocalSettings
import model.actionButtonStates
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import utils.makeRGBA
import utils.merge
import webcomponents.Position
import webcomponents.genericButton

enum class ActionButton : Translatable {
    StartOnboarding {
        override val icon = FormationIcons.Location.icon
        override val clickHandlers: List<SimpleHandler<Unit>> = listOf(
            wizardModal { close ->
                onBoardingWizard(close)
            },
        )
    },
    ContinueOnboarding {
        override val icon = FormationIcons.ArrowRight.icon
        override val clickHandlers: List<SimpleHandler<Unit>> = listOf(
            wizardModal { close ->
                onBoardingWizard(close)
            },
        )
    },
    UpgradePlan {
        override val icon = FormationIcons.UpgradePlan.icon
        override val clickHandlers: List<SimpleHandler<Unit>> = listOf(
            wizardModal { close ->
                onBoardingWizard(close)
            },
        )
    },
    ;

    abstract val icon: IconDefinition
    abstract val clickHandlers: List<SimpleHandler<Unit>>

    override val prefix = "actionbutton"

}

fun RenderContext.actionOButtonOverlayContainer() {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val anonymousFlow = apiUserStore.data.map { it.isAnonymous }
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val actionButtonStates = localSettingsStore.map(LocalSettings.actionButtonStates())
    combine(featureFlagStore.data, anonymousFlow) { f, a -> f to a }.render { (featureFlags, isAnonymus) ->
        if (featureFlags[Features.EnableTrackingOnboarding] == true && !isAnonymus) {
            div("flex flex-col w-max h-min absolute top-16 left-4 z-[1020] animate-[bounce_5s_infinite]") {
                actionButtonStates.data.render { statesMap ->
                    statesMap.forEach { (button, state) ->
                        if (state) actionButton(button)
                    }
                }
            }
        }
    }
}

fun RenderContext.actionButton(buttonType: ActionButton) {
    val translation: Translation by koinCtx.inject()
    val onboardingWizardPageStore: OnboardingWizardPageStore by koinCtx.inject()

    val additionalText = if (buttonType == ActionButton.ContinueOnboarding) {
        onboardingWizardPageStore.data.map { page ->
            val maxNumber = OnboardingWizardPage.entries.size
            val pageNumber = page.ordinal + 1
            "( $pageNumber / $maxNumber )"
        }
    } else null

    genericButton(
        title = additionalText?.let { translation[buttonType].merge(it) } ?: translation[buttonType],
        icon = { buttonType.icon },
        iconPosition = Position.Right,
        styleFlow = flowOf {
            primaryButtonStyleParams()
            background {
                color {
                    FormationColors.MarkerYou.color
                }
            }
            hover {
                background {
                    color {
                        makeRGBA(FormationColors.MarkerYou.color, 0.5)
                    }
                }
            }
        },
        width = { " 200px " },
        value = Unit,
        clickHandlers = buttonType.clickHandlers,
    )
}
