package model

import apiclient.search.ObjectSearchResult
import apiclient.markers.Marker

data class LiveData(
    val renderData: Map<String, ObjectSearchResult> = emptyMap(),
    val websocketData: Map<String, Marker> = emptyMap(),
    val searchData: Map<String, ObjectSearchResult> = emptyMap()
)
