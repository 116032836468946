package map

import auth.CurrentWorkspaceStore
import dev.fritz2.components.compat.div
import dev.fritz2.core.RenderContext
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.flow.combine
import mainmenu.AppStateStore
import map.views.crossHair
import map.views.mapProcessSpinnerOverlay
import map.views.pointerRoute
import map.views.searchManuallyButton
import map.views.workplacetools.ArchetypesStore
import maplibreGL.MaplibreMap
import model.AppPhase
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import utils.ResizeObserver
import wizard.onbaording.actionOButtonOverlayContainer

fun RenderContext.maplibreMapComponent() {

    val maplibreMap: MaplibreMap = koinCtx.get()
    val mapStateStore: MapStateStore = koinCtx.get()
    val appStateStore: AppStateStore = koinCtx.get()
    val currentWorkspaceStore: CurrentWorkspaceStore = koinCtx.get()
    val archetypesStore: ArchetypesStore = koinCtx.get()

    div(
        {
            width { full }
            flex {
                grow { "1" }
                shrink { "0" }
                basis { maxContent }
            }
//        position(
//            sm = { relative {
//                vertical { none }
//                horizontal { none }
//            } },
//            md = { fixed {
//                horizontal { none }
//            } }
//        )
            css(
                """
            .maplibregl-ctrl .mapboxgl-ctrl {
                background-color: white;
                color: black;
                border-color: black;
                border-width: 1px;
                radius: 100%;
            }
        """.trimIndent(),
            )
        },
        id = maplibreMap.targetId,
    ) {

        combine(appStateStore.data, currentWorkspaceStore.data) { a, w -> Pair(a, w) }.render { (appState, workspace) ->
            if (workspace != null && (appState.appPhase == AppPhase.LoggedIn || appState.appPhase == AppPhase.LoggedInAnonymous)) {
                mapProcessSpinnerOverlay()
                archetypesStore.data.render {
                    if (it.isNotEmpty()) {
                        crossHair()
                    }
                }
                // POINTER OVERLAY -> absolute positioned in the center of this box
                pointerRoute()
                searchManuallyButton()
                actionOButtonOverlayContainer()

                // handle resize events of map container and adjust map canvas size accordingly
                val observer = MutationObserver { _, mutationObserver ->
                    if (document.contains(domNode)) {
                        mapStateStore.insertMap()
                        maplibreMap.setRenderContext(this)
                        val resizeObserver = ResizeObserver { _, _ ->
                            maplibreMap.invalidateSize()
                        }
                        resizeObserver.observe(domNode)
                        mutationObserver.disconnect()
                    }
                }
                observer.observe(
                    document,
                    MutationObserverInit(attributes = true, childList = true, characterData = false, subtree = true),
                )
            } else {
                maplibreMap.removeMap()
//                p {
//                    +"Not logged in"
//                }
            }
        }
    }
}
