package twcomponents

import dev.fritz2.core.RenderContext
import dev.fritz2.core.viewBox
import dev.fritz2.styling.theme.IconDefinition

fun RenderContext.twIconSmall(icon: IconDefinition) {
    svg("h-4 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconMedium(icon: IconDefinition) {
    svg("h-5 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconLarge(icon: IconDefinition) {
    svg("h-8 flex-shrink-0") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconSmallToMedium(icon: IconDefinition) {
    svg("h-4 sm:h-5") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}

fun RenderContext.twIconMediumToLarge(icon: IconDefinition) {
    svg("h-5 sm:h-8") {
        viewBox(icon.viewBox)
        content(icon.svg)
    }
}
