package twcomponents

import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.Store
import dev.fritz2.core.placeholder
import dev.fritz2.headless.components.InputField
import dev.fritz2.headless.components.TextArea
import dev.fritz2.headless.components.inputField
import dev.fritz2.headless.components.textArea
import kotlinx.dom.addClass
import localization.getString
import localization.translate
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.HTMLTextAreaElement

/**
 * Simple full width text area (headless component).
 */
fun RenderContext.twFullWidthTextArea(
    valueStore: Store<String>,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    initialize: TextArea<HTMLDivElement>.() -> Unit,
) {
    textArea(
        id = id,
        scope = scope,
    ) {
        value(valueStore)

        inputs handledBy {
            val element = it.target as HTMLTextAreaElement
            valueStore.update(element.value)
        }

        initialize(this)
    }
}

/**
 * textareaTextfield for use in a twFullWidthTextArea. Has sensible default styling.
 */
fun TextArea<HTMLDivElement>.twOneLineTextareaTextfield(
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLTextAreaElement>.() -> Unit
) {
    textareaTextfield(
        "w-full h-10 bg-gray-100 p-2 outline-none border-2 border-gray-100 focus:border-highlight rounded-xl",
        scope = scope,
    ) {
        content(this)
    }
}

/**
 * textareaTextfield for use in a twFullWidthTextArea. Has sensible default styling.
 */
fun TextArea<HTMLDivElement>.twMultiLineTextareaTextfield(
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLTextAreaElement>.() -> Unit

) {
    textareaTextfield(
        "w-full h-10 max-h-48 min-h-10 bg-gray-100 p-2 outline-none border-2 border-gray-100 focus:border-highlight rounded-xl",
        scope = scope,
    ) {
        content(this)
        focuss handledBy {
            domNode.addClass("h-48")
        }
    }
}


fun RenderContext.twSimpleInputField(store: Store<String>, label: Translatable? = null, placeholder: String? = null) {
    inputField("flex flex-col gap-2") {
        label?.let {
            label {
                translate(label)
            }
        }

        value(store)

        inputs handledBy {
            val element = it.target as HTMLInputElement
            store.update(element.value)
        }
        inputTextfield(
            "w-full border-1 border-highlight focus:border-2 focus:border-highlight" +
                "placeholder:text-gray-300",
        ) {
            placeholder?.let {
                placeholder(placeholder)
            }
        }
    }
}

fun RenderContext.twSimpleInputField(store: Store<String>, label: Translatable? = null, placeholder: Translatable? = null) =
    twSimpleInputField(store, label = label,placeholder=placeholder.getString())


/**
 * Use together with twInputTextField to create direct editing
 * fields with buttons and other elements inside.
 */
fun RenderContext.twInputField(
    store: Store<String>,
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    initialize: InputField<HTMLDivElement>.() -> Unit
) {
    inputField(
        "flex flex-row w-full h-10 gap-2 bg-gray-100 px-2 border border-gray-100 border-2 focus-within:border focus-within:border-highlight focus-within:border-2 rounded-xl",
        id, scope,
    ) {
        value(store)

        inputs handledBy {
            val element = it.target as HTMLInputElement
            store.update(element.value)
        }
        initialize(this)
    }
}

fun InputField<HTMLDivElement>.twInputTextField(
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLInputElement>.() -> Unit
) {
    inputTextfield("appearance-none outline-none bg-gray-100 w-full h-full px-2", scope) {
        content(this)
    }
}

fun InputField<HTMLDivElement>.twRightAlignedButtonRow(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLDivElement>.() -> Unit
) {
    div("flex flex-row gap-2 place-items-center justify-end", id, scope) {
        content(this)
    }
}

fun RenderContext.twSelect(
    id: String? = null,
    store: Store<String>,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLSelectElement>.() -> Unit
) {
    select(
        "block appearance-none w-min-content bg-formationWhite border-2 border-gray-100 hover:border-highlight focus:border-2 focus:border-highlight text-formationBlack py-2 px-3 pr-8 rounded leading-tight focus:outline-none transition ease-in-out duration-150",
        id = id,
        scope = scope,
    ) {
        changes handledBy { selected ->
            val element = selected.target as HTMLSelectElement
            store.update(element.value)
        }
        content(this)
    }
}
