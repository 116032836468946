package location

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.ZoneInformation
import apiclient.geoobjects.Zones
import apiclient.geoobjects.tags
import apiclient.markers.ZoneService
import apiclient.websocket.LocationWebsocketModule
import apiclient.websocket.MessageToServer
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import location.geolocation.GeoPosition
import location.geolocation.Geolocation
import model.LocationUploadState
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import services.GeoPositionService
import theme.FormationColors
import websocket.WebsocketService

class LocationUploadStore(
    private val apiUserStore: ApiUserStore
) : RootStore<LocationUploadState>(
    initialData = LocationUploadState(),
    job = Job(),
) {

    private val geoPositionStore: GeoPositionStore by koinCtx.inject()
    private val locationModule: LocationWebsocketModule by koinCtx.inject()
    private val geoPositionService: GeoPositionService by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()
    private val websocketService: WebsocketService by koinCtx.inject()
    private val analyticsService: AnalyticsService by koinCtx.inject()
    private val zoneService: ZoneService by koinCtx.inject()
    private val zoneStore: RootStore<Zones> by koinCtx.inject()
    private val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    val startSendingLocationsHandler = handle<Boolean> { current, enabled ->
        if (enabled) {
            if (current.backgroundJob != null) {

//                current.positionEngineWatchId?.let {
//                    Geolocation.clearWatch(it)
//                    console.log("Stopped position watching (#$it)")
//                }

                current.backgroundJob.cancel()
            }
            val watchId = geoPositionService.getActiveWatchIdOrNew()
            val job = startSendingLocations()
            syncedUserPreferencesStore.updateSharingState(true)
            update(current.copy(backgroundJob = job, positionEngineWatchId = watchId))
        }
        current
    }

    val stopSendingLocationsHandler = handle<Boolean> { current, userDecision ->
        console.log("Stop location tracker")

        // ignore isActive here (the coroutine could have been killed, but we still need to clean up)
        if (current.backgroundJob != null) {
            if (userDecision) {
                syncedUserPreferencesStore.updateSharingState(current.isActive)
            } else syncedUserPreferencesStore.updateSharingState(false)

//            current.positionEngineWatchId?.let {
//                Geolocation.clearWatch(it)
//                console.log("Stopped position watching (#$it)")
//            }

            current.backgroundJob.cancel()
            current.copy(backgroundJob = null, positionEngineWatchId = null)
        } else {
            console.log("Location tracker was already disabled.")
            current
        }
    }

    val flipLocationSendingState = handle { current ->
        val newSwitchState = current.backgroundJob == null
        console.log("flip switch state $newSwitchState")
        analyticsService.withAnalytics(AnalyticsCategory.LocShare) {
            if (newSwitchState) {
                startSendingLocationsHandler(true)
                on()
            } else {
                stopSendingLocationsHandler(false)
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 3,
                        text = translation[TL.LocationSharing.LOCATION_SHARING_OFF],
                        bgColor = FormationColors.RedError.color,
                    ),
                )
                off()
            }
        }
        current
    }

    private suspend fun startSendingLocations() = CoroutineScope(CoroutineName("location-upload-loop")).launch {
        while (true) {
            try {
                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 3,
                        text = translation[TL.LocationSharing.LOCATION_SHARING_ON],
                        bgColor = FormationColors.MarkerYou.color,
                    ),
                )
//                var lastUploadedPosition: GeoPosition? = null
                val groupId = currentWorkspaceStore.current?.groupId ?: error("cannot send locations if you have no current group")
                while (true) {
                    val apiUser = apiUserStore.current.apiUser
                    val currentPos = geoPositionStore.current

                    val event = apiUser?.let { user ->
                        currentPos?.let { pos ->

                            val zones = zoneService.calculateZoneChanges(
                                user.groups.map { it.groupId },
                                LatLon(pos.coords.latitude, pos.coords.longitude, pos.coords.altitude),
                                zoneStore.current,
                                floorId = null, // FIXME floor id of selected floor?
                                includeFloorsAndBuildings = true,
                                backdatedTimestamp = null,
                            )
                            zoneStore.update(zones)
                            pos.toLocationUpdateEvent(groupId, user.userId, zones)
                        }
                    }
                    // TODO: Use this code to only upload position when it has changed, whenever backend is ready for it
//                    if (event != null && !currentPos.isSameLocationAs(lastUploadedPosition)) {
//                        currentPos?.let { console.log("uploading: ${it.coords.latitude} ${it.coords.longitude}") }
//                        locationModule.sendLocationEvent(event)
//                        lastUploadedPosition = currentPos
//                    } else console.log("Position has not changed")

                    if (event != null) {
                        currentPos?.let { console.log("uploading: ${it.coords.latitude} ${it.coords.longitude}") }
                        locationModule.sendLocationEvent(event)
                    }

                    delay(3_000)
                }
            } catch (e: CancellationException) {
                console.asDynamic().debug("Location upload websocket cancelled")
                delay(500)
                console.info("Location upload websocket restarting")
                websocketService.restartWebSocket()
            }
        }
    }
}

fun GeoPosition.toLocationUpdateEvent(groupId: String, objectId: String, zones: List<ZoneInformation>): MessageToServer.LocationUpdateEvent? {
    if (coords.latitude == 0.0 && coords.longitude == 0.0) return null
    return MessageToServer.LocationUpdateEvent(
        ts = timestamp,
        geo = listOfNotNull(
            coords.longitude,
            coords.latitude,
            coords.altitude,
        ),
        accuracy = coords.accuracy,
        verticalAccuracy = coords.altitudeAccuracy,
        bearing = coords.heading,
        bearingAccuracyDegrees = null,
        speed = coords.speed,
        speedAccuracyMetersPerSecond = null,
        objectId = objectId,
        groupId = groupId,
        tags = zones.tags(),
    )
}
