package dev.fritz2.components.foundations

import dev.fritz2.components.clickButton
import dev.fritz2.core.Listener
import dev.fritz2.core.RenderContext
import dev.fritz2.core.WithEvents
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.theme.*
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.MouseEvent

/**
 * An interface for exposing an HTML element. Use this vor components that more or less wrap a single basic HTML
 * tag like ``inputField`` or ``pushButton``.
 *
 * The offered [element] property enables the client to access the deeper features of an element even though the
 * component itself does not offer an appropriate functionality. A client should use this with caution,
 * as it might massively change the default behaviour of the component!
 *
 * Example usage:
 * ```
 * // apply interface to a component class
 * open class SomeComponent : ElementProperties<Div> by ElementMixin() {
 *                            ^^^^^^^^^^^^^     ^^^     ^^^^^^^^^^^^^^
 *                            implement the      |      mix in the default implementation
 *                            interface          |
 *                                              Expose the underlying element,
 *                                              in this case a ``Div``!
 * }
 *
 * // use the property offered by the interface
 * someComponent {
 *      element {
 *          // all properties of the ``Div`` element are accessible here
 *      }
 * }
 * ```
 *
 * Advice: Try to offer *all* useful properties for a component in a redundant way if the component usage will benefit
 * from a feature the underlying HTML element offers! This will make it much easier for the client user to use
 * a component and much easier to read and maintain at client side code.
 *
 * RFC: If the component itself offers some redundant property, the values from within the [element] property
 * context should win and used for the rendering! This is the *mandatory* behaviour!
 */
interface ElementProperties<T> {

    /**
     * This property enables the client to access the deeper features of an element even though the component itself
     * does not offer an appropriate functionality. A client should use this with caution, as it might massively change
     * the default behaviour of the component!
     */
    val element: ComponentProperty<T.() -> Unit>
}

/**
 * Default implementation of the [ElementProperties] interface in order to apply this as mixin for a component
 */
// TODO: Constraint für Typ: T : Tag<E> ?
class ElementMixin<T> : ElementProperties<T> {
    override val element: ComponentProperty<T.() -> Unit> = ComponentProperty {}
}

/**
 * An interface for exposing the events of a component. The type of the underlying HTML element must be specified.
 * This way *all* events of the wrapped element get exposed to the client via the [events] property.
 *
 * Example usage:
 * ```
 * // apply interface to a component class
 * open class SomeComponent : EventProperties<HTMLDivElement> by EventMixin() {
 *                            ^^^^^^^^^^^^^   ^^^^^^^^^^^^^^     ^^^^^^^^^^^^
 *                            implement the    |                 mix in the default implementation
 *                            interface        |
 *                                            Enables to access the underlying element,
 *                                            in this case a ``Div`` element in order
 *                                            to expose or wrap its events!
 * }
 *
 * // use the property offered by the interface
 * someComponent {
 *      events {
 *          // all events of the ``Div`` element are accessible here
 *          clicks.value handledBy someStore.someHandler
 *      }
 * }
 * ```
 *
 * RFC: If your component does not simply wrap some element and expose its events but instead has to offer its custom
 * and specific events, please offer an ``events`` property by yourself, so that the access remains unified
 * throughout this framework!
 */
interface EventProperties<T : Element> {

    /**
     * This property enables the client to access *all* events offered by the underlying HTML element.
     */
    val events: ComponentProperty<WithEvents<T>.() -> Unit>
}

/**
 * Default implementation of the [EventProperties] interface in order to apply this as mixin for a component
 */
class EventMixin<T : Element> : EventProperties<T> {
    override val events: ComponentProperty<WithEvents<T>.() -> Unit> = ComponentProperty {}
}

/**
 * This interface add typical state properties for en- or disabling form components.
 *
 * As it often depends on the specific use case, the two complementary properties [disabled] and [enabled] are both
 * offered. This is primarily for better readability at client side code.
 *
 * example usage:
 * ```
 * // apply interface to component class
 * open class MyControl : FormProperties by FormMixin() {
 * }
 *
 * // use the property offered by the interface
 * val disable = storeOf(true)
 * myControl {
 *      disabled(disable.value)
 * }
 *
 * // and instead of double negative expression ``val disable = storeOf(false)`` better use:
 * val enable = storeOf(false)
 * myControl {
 *      enabled(enable.value)
 * }
 * ```
 */
interface FormProperties {
    val disabled: DynamicComponentProperty<Boolean>

    fun enabled(value: Flow<Boolean>) {
        disabled(value.map { !it })
    }

    fun enabled(value: Boolean) {
        enabled(flowOf(value))
    }
}

/**
 * Default implementation of the [FormProperties] interface in order to apply this as mixin for a component
 */
open class FormMixin : FormProperties {
    override val disabled = DynamicComponentProperty(flowOf(false))
}

/**
 * This interface offers a convenience property for input form based components.
 *
 * By setting the [readonly] property to ``true`` the component should become readonly.
 *
 * Example usage:
 * ```
 * open class MyComponent : InputFormProperties by InputFormMixin() {
 * }
 *
 * // use the property offered by the interface
 * val readonly = storeOf(true)
 * myControl {
 *      readonly(readonly.value)
 * }
 * ```
 */
interface InputFormProperties : FormProperties {
    val readonly: DynamicComponentProperty<Boolean>
}

/**
 * Default implementation of the [InputFormProperties] interface in order to apply this as mixin for a component
 */
class InputFormMixin : InputFormProperties, FormMixin() {
    override val readonly = DynamicComponentProperty(flowOf(false))
}

/**
 * This interface offers a convenience property for inputField based components.
 *
 * Example usage:
 * ```
 * open class MyComponent : InputFieldProperties by InputFieldMixin() {
 * }
 *
 * // use the property offered by the interface
 * myControl {
 *      variant { outline }
 *      size { small }
 * }
 * ```
 */
interface InputFieldProperties {
    val variant: ComponentProperty<InputFieldVariants.() -> Style<BasicParams>>
    val size: ComponentProperty<FormSizesStyles.() -> Style<BasicParams>>
}

/**
 * Default implementation of the [InputFieldProperties] interface in order to apply this as mixin for a component
 */
class InputFieldMixin : InputFieldProperties {
    override val variant = ComponentProperty<InputFieldVariants.() -> Style<BasicParams>> {
        Theme().input.variants.outline
    }
    override val size = ComponentProperty<FormSizesStyles.() -> Style<BasicParams>> {
        Theme().input.sizes.normal
    }
}

/**
 * This interface offers some convenience properties for adding a close button to a component.
 *
 * If offers the possibilities to:
 * - change the styling of the default appearance
 * - decide whether there is a close button or not
 * - change the whole rendering by a custom implementation
 *
 * Example integration:
 * ```
 * open class MyComponent : Component<Unit>, CloseButtonProperty by CloseButtonMixin(ComponentProperty{})  {
 * //                                        ^^^^^^^^^^^^^^^^^^^    ^^^^^^^^^^^^^^^^ ^^^^^^^^^^^^^^^^^^^
 * //                                        implement interface    delegate to      inject empty custom
 * //                                                               mixin            styling
 *      override fun render(/* params omitted */) {
 *          div {
 *              // check if closeButton is needed and render it then
 *              if (hasCloseButton.value) {
 *                  closeButtonRendering.value(this) handledBy closeHandler
 *                  //                               ^^^^^^^^^^^^^^^^^^^^^^
 *                  //                               use return value (event)
 *                  //                               to handle it by your closing mechanism
 *              }
 *          }
 *      }
 * }
 * ```
 *
 * For some example usages, have a look at the following components:
 * @see [ModalComponent]
 * @see [PopoverComponent]
 * @see [ToastComponent]
 */
interface CloseButtonProperty {
    val closeButtonPrefix: String
    val closeButtonStyle: ComponentProperty<Style<BasicParams>>
    val closeButtonIcon: ComponentProperty<Icons.() -> IconDefinition>
    val hasCloseButton: ComponentProperty<Boolean>
    val closeButtonRendering: ComponentProperty<RenderContext.() -> Listener<MouseEvent, HTMLElement>>
}

/**
 * Default implementation of the [CloseButtonProperty] interface in order to apply this as mixin for a component
 *
 * @param closeButtonPrefix the prefix for the generated CSS class
 * @param defaultStyle define the default styling of the button fitting for the implementing component needs
 *                     (the placement within the component's space for example)
 */
class CloseButtonMixin(
    override val closeButtonPrefix: String = "close-button",
    private val defaultStyle: Style<BasicParams>
) : CloseButtonProperty {

    private val resetButtonStyles: Style<BasicParams> = {
        lineHeight { "unset" }
        radius { "unset" }
        fontWeight { "unset" }
        padding { "unset" }
        height { "unset" }
        minWidth { "unset" }
    }

    override val closeButtonStyle = ComponentProperty<Style<BasicParams>> {}
    override val closeButtonIcon = ComponentProperty<Icons.() -> IconDefinition> { Theme().icons.close }
    override val hasCloseButton = ComponentProperty(true)
    override val closeButtonRendering = ComponentProperty<RenderContext.() -> Listener<MouseEvent, HTMLElement>> {
        clickButton({
            resetButtonStyles()
            defaultStyle()
            closeButtonStyle.value()
        }, prefix = closeButtonPrefix) {
            variant { ghost }
            icon { closeButtonIcon.value(Theme().icons) }
        }
    }
}
