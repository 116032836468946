package data.objects.views.directediting

import apiclient.geoobjects.Content
import apiclient.geoobjects.UpsertMarkdown
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.flow.combine
import overlays.withBusyApplyContentChange
import theme.FormationUIIcons
import twcomponents.twColOf
import twcomponents.twFullWidthTextArea
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twMultiLineTextareaTextfield

fun RenderContext.mdEditor(
    objectId: String,
    content: Content.Markdown,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val titleFieldStore = storeOf(content.title ?: "")
    val mdStore = storeOf(content.text)
    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonHighlight(icon = FormationUIIcons.Save.icon) {
                combine(titleFieldStore.data, mdStore.data) { t, md ->
                    t.isBlank() && md.isBlank()
                }.render { bothBlank ->
                    disabled(bothBlank)
                }
                clicks handledBy {
                    withBusyApplyContentChange(
                        objectId,
                        UpsertMarkdown(
                            id = content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                            title = titleFieldStore.current,
                            content = mdStore.current,
                        ),
                    )
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                }
            }
        }
        twInputField(titleFieldStore) {
            twInputTextField {
                placeholder("Section") // TODO translate
            }
        }
        twFullWidthTextArea(mdStore) {
            twMultiLineTextareaTextfield {
                placeholder("Text") // TODO translate
            }
        }
    }
}
