package model

import apiclient.geoobjects.Content
import apiclient.users.UserFeatureFlag
import apiclient.users.UserPreferences

data class MyProfile(
    val userId: String = "",
    val firstName: String = "",
    val lastName: String = "",
    val jobTitle: String = "",
    val emails: String = "",
    val password: String = "****************",
    val phone: String = "",
    val preferences: UserPreferences? = null,
//    val responseStatus: StatusResult? = null,
    val profilePhoto: Content.Image? = null,
    val featureFlags: Map<UserFeatureFlag, Boolean>? = null,
    val keywords: List<String> = listOf()
) {
    companion object
}

val MyProfile.name get() = "$firstName $lastName"
