package qrcode.userverification

import dev.fritz2.core.RootStore
import dev.fritz2.core.Store
import kotlin.js.Promise
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch

suspend fun dataUrlImage(url: String): String {
    val p = toDataURL(url) as Promise<String>
    return p.await()
}

suspend fun toSvgQrCode(content: String) = toString(
    content,
    obj {
        this.type = "svg"
        // high error correction
        this.errorCorrectionLevel = "H"
    },
).await()

external class Object

inline fun obj(init: dynamic.() -> Unit): dynamic {
    return (Object()).apply(init)
}

suspend fun urlToSvgQrCode(url: String) =
    toString(
        url,
        obj {
            this.type = "svg"
            // high error correction
            this.errorCorrectionLevel = "H"
            this.margin = 0
        },
    ).await()


class QrCodeLinkStore<T>(linkSource: Store<T>, extractor: (T) -> String?) : RootStore<String?>(
    initialData = null,
    job = Job(),
) {

    val convert = handle<String?> { _, link ->
        coroutineScope {
            launch {
                if (link == null) {
                    update(null)
                } else {
                    update(dataUrlImage(link))
                }
            }
        }
        link
    }

    init {
        linkSource.data.map { extractor(it) } handledBy convert
    }
}
