package theme

import dev.fritz2.styling.theme.IconDefinition

enum class FormationShapes {
    Circle {
        override val shape = IconDefinition(
            displayName = "circle",
            svg = """
                <circle cx="50" cy="50" r="50" fill="currentColor"/>
                """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Square {
        override val shape = IconDefinition(
            displayName = "square",
            svg = """
                <path d="M0 13.3333C0 5.96954 5.96954 0 13.3333 0H86.6667C94.0305 0 100 5.96954 100 13.3333V86.6667C100 94.0305 94.0305 100 86.6667 100H13.3333C5.96954 100 0 94.0305 0 86.6667V13.3333Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Diamond {
        override val shape = IconDefinition(
            displayName = "diamond",
            svg = """
                <path d="M39.4012 4.39017C45.2547 -1.46339 54.7452 -1.46339 60.5988 4.39017L95.6098 39.4012C101.463 45.2548 101.463 54.7452 95.6098 60.5988L60.5988 95.6098C54.7452 101.463 45.2548 101.463 39.4012 95.6098L4.39017 60.5988C-1.46339 54.7452 -1.46339 45.2548 4.39017 39.4012L39.4012 4.39017Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    TriangleUp {
        override val shape = IconDefinition(
            displayName = "triangleUp",
            svg = """
                <path d="M43.676 8.71134C46.4867 3.76288 53.5133 3.76289 56.324 8.71134L99.0108 83.866C101.821 88.8144 98.3081 95 92.6868 95H7.31318C1.69187 95 -1.82144 88.8144 0.989217 83.866L43.676 8.71134Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    TriangleDown {
        override val shape = IconDefinition(
            displayName = "triangleDown",
            svg = """
                <path d="M56.324 91.2887C53.5133 96.2371 46.4867 96.2371 43.676 91.2887L0.989219 16.134C-1.82143 11.1856 1.69189 4.99999 7.31319 4.99999L92.6868 5C98.3081 5 101.821 11.1856 99.0108 16.134L56.324 91.2887Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    CircleWithPointer {
        override val shape = IconDefinition(
            displayName = "circleWithPointer",
            svg = """
                <path fill-rule="evenodd" clip-rule="evenodd" d="M49.625 0C27.1884 0 9 18.1506 9 40.5405C9 57.2262 19.1013 71.5574 33.5342 77.7768C33.6152 78.0434 33.7281 78.3074 33.8754 78.5648L45.0718 98.1361C46.4936 100.621 50.048 100.621 51.4698 98.1361L62.3959 79.0374C78.5759 73.695 90.25 58.4783 90.25 40.5405C90.25 18.1506 72.0616 0 49.625 0Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    SquarewithPointer {
        override val shape = IconDefinition(
            displayName = "squarewithPointer",
            svg = """
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5778 0C13.079 0 7 5.96953 7 13.3333V71.773C7 79.1368 13.079 85.1064 20.5778 85.1064H39.8148L47.7374 98.5816C48.8493 100.473 51.6291 100.473 52.7411 98.5816L60.6636 85.1064H80.0889C87.5877 85.1064 93.6667 79.1369 93.6667 71.7731V13.3333C93.6667 5.96954 87.5877 0 80.0889 0H20.5778Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Pentagon {
        override val shape = IconDefinition(
            displayName = "pentagon",
            svg = """
                <path d="M41.8666 5.56043C46.7336 2.14653 53.2664 2.14652 58.1334 5.56043L94.1225 30.8043C99.1254 34.3135 101.22 40.6201 99.289 46.3584L85.6504 86.8828C83.7518 92.5243 78.3912 96.3333 72.3505 96.3333H27.6495C21.6088 96.3333 16.2482 92.5243 14.3496 86.8828L0.711039 46.3584C-1.2202 40.6201 0.874623 34.3135 5.87754 30.8043L41.8666 5.56043Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    ArrowUp {
        override val shape = IconDefinition(
            displayName = "arrowUp",
            svg = """
                <path d="M46.0581 3.79352C47.7197 1.40216 51.2802 1.40216 52.9418 3.79353L93.2542 61.8107C95.166 64.5622 93.1808 68.3124 89.8123 68.3124H9.18766C5.81922 68.3124 3.83395 64.5622 5.7458 61.8107L46.0581 3.79352Z" fill="currentColor"/>
                <path d="M34.1716 66.5842H64.8283V97H34.1716V66.5842Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    ArrowDown {
        override val shape = IconDefinition(
            displayName = "arrowDown",
            svg = """
                <path d="M53.9419 96.2065C52.2803 98.5978 48.7198 98.5978 47.0582 96.2065L6.74581 38.1893C4.83396 35.4378 6.81923 31.6876 10.1877 31.6876L90.8123 31.6876C94.1808 31.6876 96.166 35.4378 94.2542 38.1893L53.9419 96.2065Z" fill="currentColor"/>
                <path d="M65.8284 33.4158L35.1717 33.4158L35.1717 3L65.8284 2.99999L65.8284 33.4158Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Egg {
        override val shape = IconDefinition(
            displayName = "egg",
            svg = """
                <path d="M10 40C10 17.9086 27.9086 0 50 0C72.0914 0 90 17.9086 90 40V60C90 82.0914 72.0914 100 50 100C27.9086 100 10 82.0914 10 60V40Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Trapezoid {
        override val shape = IconDefinition(
            displayName = "trapezoid",
            svg = """
                <path d="M10.8357 12.1243C11.8777 5.15034 17.7333 0 24.6202 0H86.052C94.5944 0 101.129 7.79679 99.8365 16.4471L89.1643 87.8757C88.1223 94.8497 82.2667 100 75.3798 100H13.948C5.4056 100 -1.12896 92.2032 0.163501 83.5529L10.8357 12.1243Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Star {
        override val shape = IconDefinition(
            displayName = "star",
            svg = """
                <path d="M46.5492 4.7704C48.2156 2.40987 51.7844 2.40987 53.4508 4.7704L68.8163 26.537C69.3589 27.3056 70.1539 27.87 71.0696 28.1367L97.003 35.6901C99.8154 36.5093 100.918 39.8259 99.1357 42.104L82.6987 63.1099C82.1183 63.8516 81.8146 64.7648 81.838 65.6983L82.5002 92.1331C82.572 94.9999 79.6848 97.0497 76.9167 96.0971L51.3926 87.3129C50.4913 87.0027 49.5087 87.0027 48.6074 87.3129L23.0833 96.0971C20.3152 97.0497 17.428 94.9999 17.4998 92.1331L18.162 65.6983C18.1854 64.7648 17.8817 63.8516 17.3013 63.1099L0.864334 42.104C-0.918214 39.8259 0.184624 36.5093 2.99704 35.6901L28.9304 28.1367C29.8461 27.87 30.6411 27.3056 31.1837 26.537L46.5492 4.7704Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Heart {
        override val shape = IconDefinition(
            displayName = "heart",
            svg = """
                <path d="M72.7999 4.00094C87.7916 4.08415 99.927 17.9189 100 35.0105C100 62.8381 60.5123 90.4257 51.7209 96.2229C50.6572 96.9244 49.346 96.9202 48.2864 96.2126C39.509 90.3504 0 62.4402 0 35.0105C0 17.8845 12.1778 4.00094 27.2001 4.00094C34.5914 3.93522 41.5959 7.32895 46.6604 13.2297C48.3383 15.1845 51.6756 15.1929 53.3602 13.2439C58.436 7.37158 65.4215 3.9806 72.7999 4.00094Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    Cross {
        override val shape = IconDefinition(
            displayName = "cross",
            svg = """
                <path fill-rule="evenodd" clip-rule="evenodd" d="M98.4351 37.1524C99.6428 35.9465 99.6428 33.9914 98.4351 32.7855L66.5065 0.904409C65.2988 -0.30147 63.3408 -0.301469 62.1331 0.90441L51.7826 11.2395C50.5749 12.4454 48.6169 12.4454 47.4092 11.2395L37.8669 1.71138C36.6592 0.505503 34.7012 0.505503 33.4935 1.71138L1.56489 33.5925C0.357216 34.7983 0.357215 36.7535 1.56489 37.9593L11.1072 47.4875C12.3149 48.6934 12.3149 50.6485 11.1072 51.8544L0.905758 62.0407C-0.301919 63.2465 -0.301919 65.2017 0.905757 66.4075L32.8344 98.2886C34.042 99.4945 36.0001 99.4945 37.2077 98.2886L47.4092 88.1023C48.6169 86.8964 50.5749 86.8964 51.7826 88.1023L62.7923 99.0956C63.9999 100.301 65.958 100.301 67.1656 99.0956L99.0942 67.2145C100.302 66.0086 100.302 64.0535 99.0942 62.8476L88.0846 51.8544C86.8769 50.6485 86.8769 48.6934 88.0846 47.4875L98.4351 37.1524Z" fill="currentColor"/>
                    """.trimIndent(),
            viewBox = "0 0 100 100",
        )
    },
    ;

    abstract val shape: IconDefinition
}
