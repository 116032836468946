package data.objects.views.cardinfo

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.geometry
import apiclient.tags.getUniqueTag
import com.jillesvangurp.geo.GeoGeometry
import data.connectableshapes.ActiveSourceConnectorStore
import data.connectableshapes.ActiveTargetConnectorStore
import data.connectableshapes.DeleteConnectionConnectorIdStore
import data.connectableshapes.NewConnectableShapeConnectionStore
import data.objects.ActiveObjectStore
import data.objects.views.attachments.toPreAttachment
import data.objects.views.directediting.directEditingCardContent
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.pushButton
import dev.fritz2.components.selectField
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import maplibreGL.MaplibreMap
import maplibreGL.getUniqueConnectionId
import model.L
import search.searchlayer.MapSearchResultsStore
import search.separationLine
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.getIcon
import utils.getName
import utils.respectFeatureFlags
import utils.roundTo
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.cardTitleWithSubtitleDirectEdit
import webcomponents.ellipseText
import webcomponents.genericButton

fun RenderContext.generalInfoCardContent() {

    val objectType = ObjectType.GeneralMarker
    val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.title)
    val description = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.description)
    val attachments =
        activeObjectStore.map(GeoObjectDetails.L.attachments).data.map { attachments ->
        attachments.respectFeatureFlags()?.mapNotNull { it.toPreAttachment() }
    }
    val geoReferencedConnectableObject =
        activeObjectStore.map(GeoObjectDetails.L.geoReferencedConnectableObject).data.map {
            Pair(
                activeObjectStore.current,
                it
            )
        }
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val canModify = activeObjectStore.map(GeoObjectDetails.L.canModify)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)

    baseLayout(
        header = {
            flexBox({
                direction { row }
                justifyContent { spaceBetween }
                alignItems { start }
                width { full }
                height { maxContent }
            }) {
                canManage.data.combine(canModify.data) { manage, modify -> manage || modify }
                    .render { editAccess ->
                        if (editAccess) {
                            cardTitleWithSubtitleDirectEdit(
                                titleFlow = title.data,
                                title = title.current,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                                changeHandler = activeObjectStore.editTitle
                            )
                        } else {
                            cardTitleWithSubtitle(
                                title = title.data,
                                subtitle = flowOf(objectType.getName()),
                                titleIconFlow = flowOf(objectType.getIcon().icon),
                                subtitleIconFlow = externalId.map { if (!it.isNullOrBlank()) FormationIcons.QRCode.icon else null },
                            )
                        }
                    }
                infoCardButtons()
            }
        },
        content = {
            directEditingCardContent {
                geoReferencedConnectableObject.render { (obj, geoReferencedConnectableObject) ->
                    if (geoReferencedConnectableObject != null) {
                        // check if any connector is free, check works only as long as there is only one connection per connector
                        if ((geoReferencedConnectableObject.connections?.size ?: 0)
                            < (geoReferencedConnectableObject.original.connectors?.size ?: 0)
                        ) {
                            separationLine(flowOf("New Connection"))
                            selectFromConnector(obj)
                            selectToConnector()
                            connectorConnectButton()
                        }
                        connectionsList(obj)
                    }
                }
            }
        },
        footer = {
            infoCardEditFooter(
                type = objectType,
                additionalSecondaryClickHandlers = listOf(newConnectableShapeConnectionStore.reset)
            )
//            twoButtonFooter(
//                primaryTitle = translation[TL.General.EDIT],
//                primaryStyleParams = primaryButtonStyleParams,
//                primaryRoutingMap = mapOf("page" to Pages.Map.name),
//                primaryClickHandlers = listOf(),
//                secondaryTitle = translation[TL.General.BACK],
//                secondaryStyleParams = secondaryButtonStyleParams,
//                secondaryClickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap, newConnectableShapeConnectionStore.reset),
//                secondaryRoutingMap = mapOf("page" to Pages.Map.name),
//            )
        }
    )
}

fun RenderContext.selectFromConnector(geoObject: GeoObjectDetails) {

    val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
    val activeSourceConnectorStore: ActiveSourceConnectorStore by koinCtx.inject()
    val connectors = geoObject.geoReferencedConnectableObject?.original?.connectors?.filter { connector ->
        geoObject.geoReferencedConnectableObject?.connections?.map { it.sourceConnectorId }
            ?.contains(connector.id) != true
    }

    if (!connectors.isNullOrEmpty()) {
        activeSourceConnectorStore.data.render { sourceConnector ->
            selectField(
                {
                    fontSize { small }
                    if (sourceConnector != null) {
                        border {
                            color { FormationColors.GreenActive.color }
                        }
                    }
                },
                items = connectors,
                value = activeSourceConnectorStore
            ) {
                label { connectorId -> "${geoObject.title} -> ${connectorId?.id}" }
                events {
                    selected.map {
                        Pair(
                            geoObject,
                            it
                        )
                    } handledBy newConnectableShapeConnectionStore.updateSourceConnector
                }
            }
        }
    }
}

fun RenderContext.selectToConnector() {

    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()
    val activeTargetConnectorStore: ActiveTargetConnectorStore by koinCtx.inject()
    val connectorsMap = mapSearchResultsStore.data.map { results ->
        results.mapNotNull { (id, obj) ->
            if (id != activeObjectStore.current.id) {
                obj.hit.geoReferencedConnectableObject?.original?.connectors?.let { connectors ->
                    obj.hit to connectors.filter { connector ->
                        obj.hit.geoReferencedConnectableObject?.connections?.map { connection ->
                            connection.sourceConnectorId
                        }?.contains(connector.id) != true
                    }
                }
            } else null
        }.toMap()
    }
    connectorsMap.render { connectorsOfShapes ->
        if (connectorsOfShapes.isNotEmpty()) {
            val items = connectorsOfShapes.flatMap { connectorsOfShape ->
                connectorsOfShape.value.map { connector ->
                    connectorsOfShape.key to connector
                }
            }
            activeTargetConnectorStore.data.render { (targetObj, targetConnector) ->
                selectField(
                    {
                        fontSize { small }
                        margins {
                            top { small }
                        }
                        if (targetConnector != null) {
                            border {
                                color { FormationColors.MarkerYou.color }
                            }
                        }
                    },
                    items = items,
                    value = activeTargetConnectorStore
                ) {
                    label { (geoObject, connector) -> "${geoObject?.title} -> ${connector?.id}" }
                    events {
                        selected.map { (geoObject, connector) ->
                            Pair(geoObject, connector)
                        } handledBy newConnectableShapeConnectionStore.updateTargetConnector
                    }
                }
            }
        }
    }
}

fun RenderContext.connectorConnectButton() {

    val newConnectableShapeConnectionStore: NewConnectableShapeConnectionStore by koinCtx.inject()

    genericButton(
        title = flowOf("CONNECT"),
        width = { auto },
        styleFlow = flowOf {
            primaryButtonStyleParams()
        },
        state = newConnectableShapeConnectionStore.data.map { newConnection ->
            newConnection.sourceConnectorId.isNotEmpty() &&
                    newConnection.targetConnectorId.isNotEmpty() &&
                    newConnection.targetMarkerId.isNotEmpty()
        },
        value = Unit,
        clickHandlers = listOf(newConnectableShapeConnectionStore.connect)
    )
}

fun RenderContext.connectionsList(geoObject: GeoObjectDetails) {
    val mapSearchResultsStore: MapSearchResultsStore by koinCtx.inject()
    val deleteConnectionConnectorIdStore: DeleteConnectionConnectorIdStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()

    val connectableShapes =
        maplibreMap.extractConnectableGeoShapesMap(mapSearchResultsStore.current.values.map { it.hit }.toSet())

    stackUp({
        width { full }
        alignItems { center }
        justifyContent { center }
    }) {
        spacing { small }
        items {
            geoObject.geoReferencedConnectableObject?.let { connectableObj ->
                connectableObj.connections?.let { connections ->
                    if (connections.isNotEmpty()) {
                        separationLine(title = flowOf("Connections"))
                        connections.forEach { connection ->
                            val thisConnector = connectableObj.original.connectors?.firstOrNull { thisConnector ->
                                thisConnector.id == connection.sourceConnectorId
                            }
                            val otherConnectableAndPosition = connectableShapes[connection.targetMarkerId]
                            val otherConnector =
                                otherConnectableAndPosition?.geoRefConnectableObject?.original?.connectors?.firstOrNull { otherConnector ->
                                    otherConnector.id == connection.targetConnectorId
                                }
                            val sourceCoordinate =
                                thisConnector?.geometry(connectableObj, geoObject.latLon)?.coordinates
                            val targetCoordinate =
                                otherConnector?.geometry(
                                otherConnectableAndPosition.geoRefConnectableObject,
                                otherConnectableAndPosition.position
                            )?.coordinates

                            val distance =
                                if (sourceCoordinate != null && targetCoordinate != null) GeoGeometry.distance(
                                    sourceCoordinate,
                                    targetCoordinate
                                ) else null

                            val min = thisConnector?.minDistance
                            val max = thisConnector?.maxDistance

                            val isValid = when {
                                distance != null && min != null && max != null -> distance in min..max
                                distance != null && min != null && max == null -> distance >= min
                                distance != null && min == null && max != null -> distance <= max
                                else -> true // no restrictions
                            }
                            connectionListEntry(
                                active = deleteConnectionConnectorIdStore.data.map {
                                    it == getUniqueConnectionId(
                                        connection
                                    )
                                },
                                sourceName = mapSearchResultsStore.data.map { results ->
                                    results[connection.sourceMarkerId]?.hit?.title ?: ""
                                },
                                sourceConnectorName = flowOf(connection.sourceConnectorId),
                                targetName = mapSearchResultsStore.data.map { results ->
                                    results[connection.targetMarkerId]?.hit?.title ?: ""
                                },
                                targetConnectorName = flowOf(connection.targetConnectorId),
                                distance = distance?.let { flowOf(it.roundTo(1).toString()) },
                                state = flowOf(isValid),
                                value = getUniqueConnectionId(connection),
                                valueHandlers = listOf(deleteConnectionConnectorIdStore.selectConnectionByUniqueId)
                            )
                        }
                    }
                }
            }
        }
    }
}

fun <T> RenderContext.connectionListEntry(
    active: Flow<Boolean>? = null,
    sourceName: Flow<String>,
    sourceConnectorName: Flow<String>,
    targetName: Flow<String>,
    targetConnectorName: Flow<String>,
    distance: Flow<String>? = null,
    state: Flow<Boolean>,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    value: T,
    valueHandlers: List<SimpleHandler<T>>? = null
) {
    val deleteConnectionConnectorIdStore: DeleteConnectionConnectorIdStore by koinCtx.inject()
    lineUp({
        width { full }
        alignItems { center }
        justifyContent { spaceBetween }
    }) {
        spacing { small }
        items {
            button({
                width { full }
                height { auto } // maybe define fixed height of button later
            }) {
                (active ?: flowOf(true)).combine(state) { a, s -> Pair(a, s) }.render { (isActive, isValid) ->
                    flexBox({
                        height { maxContent }
                        width { full }
                        justifyContent { spaceBetween }
                        alignItems { center }
                        display { flex }
                        padding { tiny }
                        radius(FormationDefault.formationStyles.buttonRadius)
                        if (isActive) background {
                            color { FormationColors.GrayLight.color }
                        }
                        hover {
                            background { color { FormationColors.GrayLight.color } }
                        }
                    }) {
                        lineUp({
                            width { full }
                            alignItems { center }
                            overflowX { hidden }
                        }) {
                            spacing { tiny }
                            items {
                                // Source & Target names
                                lineUp({
                                    width { full }
                                    alignItems { center }
                                    justifyContent { spaceBetween }
                                    overflowX { hidden }
                                }) {
                                    spacing { tiny }
                                    items {
                                        // Source
                                        stackUp({
                                            width { full }
                                            overflowX { hidden }
                                            alignItems { center }
                                            radius { large }
                                            padding { tiny }
                                            border {
                                                width { "2px" }
                                                color { FormationColors.GrayLight.color }
                                            }
                                        }) {
                                            spacing { tiny }
                                            items {
                                                ellipseText({
                                                    width { full }
                                                    fontSize { tiny }
                                                    fontWeight { bold }
                                                    textAlign { center }
                                                }) { sourceName.renderText(into = this) }
                                                ellipseText({
                                                    width { full }
                                                    fontSize { tiny }
                                                    textAlign { center }
                                                }) { sourceConnectorName.renderText(into = this) }
                                            }
                                        }
                                        // Link icon
                                        flexBox({
                                            flex {
                                                grow { "0" }
                                                shrink { "0" }
                                                basis { "20px" }
                                            }
                                            width { "20px" }
                                            height { "60px" }
                                            justifyContent { center }
                                            alignItems { center }
                                        }) {
                                            // STATE OR USER ICON ON CIRCLE
                                            icon({ size { large } }) { fromTheme { linkAlt } }
                                        }
                                        // Target
                                        stackUp({
                                            width { full }
                                            alignItems { center }
                                            overflowX { hidden }
                                            radius { large }
                                            padding { tiny }
                                            border {
                                                width { "2px" }
                                                color { FormationColors.GrayLight.color }
                                            }
                                        }) {
                                            spacing { tiny }
                                            items {
                                                ellipseText({
                                                    width { full }
                                                    fontSize { tiny }
                                                    fontWeight { bold }
                                                    textAlign { center }
                                                }) { targetName.renderText(into = this) }
                                                ellipseText({
                                                    width { full }
                                                    fontSize { tiny }
                                                    textAlign { center }
                                                }) { targetConnectorName.renderText(into = this) }
                                            }
                                        }
                                        // show distance
                                        distance?.let { distanceValue ->
                                            span({
                                                fontWeight { bold }
                                                fontSize { smaller }
                                                margins {
                                                    right { tiny }
                                                }
                                                if (isValid) {
                                                    color { FormationColors.GreenActive.color }
                                                } else {
                                                    color { FormationColors.RedError.color }
                                                }
                                            }) {
                                                distanceValue.map { "${it}m" }.renderText(this)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        lineUp({
                            alignItems { center }
                            justifyContent { center }
                        }) {
                            spacing { giant }
                            items {
                                active?.render { active ->
                                    if (active) {
                                        // DELETE BUTTON
                                        pushButton({
                                            radius { full }
                                            padding { tiny }
                                            margins {
                                                horizontal { tiny }
                                            }
                                            width { "40px" }
                                            height { "40px" }
                                        }) {
                                            size { small }
                                            type { danger }
                                            variant { outline }
                                            icon { FormationIcons.DeleteAlt.icon }
                                            events {
                                                clicks handledBy deleteConnectionConnectorIdStore.disconnect
                                            }
                                        }
                                    } else {
                                        // ICON ON CIRCLE
                                        div({
                                            width { "40px" }
                                            height { "40px" }
                                            color { secondary.main }
                                            radius { full }
                                            padding { smaller }
                                            margin { tiny }
                                            flex {
                                                grow { "0" }
                                                shrink { "0" }
                                                basis { "40px" }
                                            }
                                            if (active) {
                                                background { color { primary.main } }
                                            } else {
                                                border {
                                                    //                            color { FormationColors.GrayPrivate.color }
                                                    color { primary.main }
                                                    width { "1px" }
                                                }
                                            }
                                        }) {
//                                                if (active) icon({ size { larger } }) { fromTheme { FormationUIIcons.Check.icon } }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                with(clicks) {
                    clickHandlers?.forEach { handler ->
                        this handledBy handler
                    }
                    valueHandlers?.forEach { handler ->
                        this.map { value } handledBy handler
                    }
                }
            }
        }
    }
}