package workspacetools

import com.tryformation.localization.Translatable
import dev.fritz2.styling.theme.IconDefinition
import mainmenu.Pages
import routing.DestinationRoute
import theme.FormationIcons

enum class WorkspaceToolsMenu(val icon: IconDefinition) : DestinationRoute, Translatable {
    WorkspaceOptions(FormationIcons.Settings.icon),
    UserManagement(FormationIcons.User.icon),
    ;

    override val prefix = "workspace-tools-menu"

    override val route = mapOf(
        "page" to Pages.AdminDashboard.name,
        "subPage" to name,
    )


    override val routeKey: String = "subPage"

    companion object {
        val routeKey = "subPage"
    }
}
