package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Comment
import apiclient.geoobjects.DeleteComment
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.UpsertComment
import apiclient.geoobjects.applyObjectChanges
import data.objects.ActiveObjectStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.transition
import koin.koinCtx
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import model.L
import overlays.BusyStore
import overlays.withBusyApplyObjectChange
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.toggleClassOnElement
import twcomponents.twCardSectionTitle
import twcomponents.twCenteredLink
import twcomponents.twFeatureFlagDiv
import twcomponents.twFullWidthTextArea
import twcomponents.twIconSmall
import twcomponents.twMarkdownContent
import twcomponents.twMediumIconButtonRed
import twcomponents.twMultiLineTextareaTextfield
import twcomponents.twPrimaryButtonSmall
import twcomponents.twRowOfJustifyBetween
import utils.formatForComments
import workspacetools.usermanagement.confirm


fun RenderContext.commentSection() {
    withKoin {
        val activeObjectStore: ActiveObjectStore by koinCtx.inject()
        val objectComments = activeObjectStore.map(GeoObjectDetails.L.comments)
        val translation = koinCtx.get<Translation>()

        val prevAmount = 3

        twFeatureFlagDiv {
            val commentTextStore = storeOf("")

            div("flex flex-row gap-2 sm:gap-5 items-center justify-between") {
                // Comments header
                twCardSectionTitle {
                    twIconSmall(FormationIcons.Feedback.icon)
                    translation[TL.Comments.HEADER].renderText()
                }
            }
            // Comment input area
            div("flex flex-col gap-2 w-full pb-2") {
                twFullWidthTextArea(valueStore = commentTextStore) {
                    twMultiLineTextareaTextfield {
                        placeholder(translation[TL.Comments.PLACEHOLDER])
                    }
                }
                // Add button
                twPrimaryButtonSmall(
                    icon = FormationUIIcons.Send.icon,
                ) {
                    className("self-end")
                    translation[TL.Comments.ADD].renderText()
                    disabled(commentTextStore.data.map { it.isBlank() })
                    clicks handledBy {
                        sendComment(activeObjectStore.current.id, commentTextStore)
                    }
                }
            }

            objectComments.data.render { objComments ->
                val obj = activeObjectStore.current
                div("flex flex-col gap-2 w-full pb-2") {
                    val comments = objComments?.reversed()
                    if (comments != null) {

                        when {
                            comments.isEmpty() -> {}
                            comments.size <= prevAmount -> {
                                comments.forEach { c ->
                                    comment(obj, c)
                                }
                            }

                            else -> {
                                val showMoreStore = storeOf(false)
                                comments.take(prevAmount).forEach { c ->
                                    comment(obj, c)
                                }
                                showMoreStore.data.render { showMore ->
                                    if (showMore) {
                                        div("flex flex-col gap-2 w-full pb-2") {
                                            transition(
                                                "ease-in duration-500",
                                                "opacity-0",
                                                "opacity-100",
                                            )
                                            comments.subList(prevAmount, comments.size).forEach { c ->
                                                comment(obj, c)
                                            }
                                        }
                                        twCenteredLink {
                                            translation[TL.Comments.HIDE, mapOf("noComments" to comments.size - prevAmount)].renderText()

                                            clicks handledBy {
                                                showMoreStore.update(false)
                                            }
                                        }
                                    } else {
                                        twCenteredLink {
                                            translation[TL.Comments.SHOW_MORE, mapOf("noComments" to comments.size - prevAmount)].renderText()

                                            clicks handledBy {
                                                showMoreStore.update(true)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.comment(obj: GeoObjectDetails, comment: Comment) {
    val translation: Translation by koinCtx.inject()
    val id = Random.nextULong().toString()

    div("flex flex-col w-full gap-1 mb-2 pb-1 shadow-sm") {
        twMarkdownContent(comment.comment)
        twRowOfJustifyBetween {
            div("font-bold text-xs") {
                +(comment.senderName ?: "Anonymous")
                +", ${comment.createdAt.formatForComments()}"
            }
            if (obj.canManage) {
                div("invisible mr-2 py-2 px-4 place-items-center bg-gray-200 rounded-xl shadow", id = id) {
                    twMediumIconButtonRed(FormationIcons.DeleteAlt.icon) {
                        clicks handledBy confirm(
                            flowOf("Are you sure you want to delete this comment?"), // TODO translate
                            okHandlers = listOf(
                                SimpleHandler { data, _ ->
                                    data handledBy {
                                        withBusyApplyObjectChange(obj.id, DeleteComment(comment.id))
                                    }
                                },
                            ),
                        )
                    }
                }
            }
        }
        toggleClassOnElement(
            className = "invisible",
            elementId = id,
        )
    }
}

suspend fun sendComment(objectId: String, commentStore: Store<String>) {
    withKoin {
        val client = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val activeObjectStore = get<ActiveObjectStore>()
        // use lens here to only update comments on the object to prevent triggering re-renderings of other sections
        val objectComments = activeObjectStore.map(GeoObjectDetails.L.comments)

        busyStore.withBusy(
            block = {
                client.applyObjectChanges(ObjectChanges(objectId, UpsertComment(commentStore.current)))
            },
            processError = { error ->
                console.log("Failed to send comment: \"${commentStore.current}\".", error.message)
            },
        ) { updated ->
            commentStore.update("")
            objectComments.update(updated.firstOrNull { it.id == objectId }?.comments)
        }
    }
}
