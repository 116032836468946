package data.objects.views.attachments

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.LinkRel
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskTemplate
import apiclient.geoobjects.restGetObjectById
import apiclient.polls.PollOption
import apiclient.polls.PollResult
import apiclient.polls.pollResults
import auth.ApiUserStore
import auth.SessionIdStore
import data.objects.ActiveObjectStore
import data.objects.views.copyButton
import dev.fritz2.components.clickButton
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Id
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.crossOrigin
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.views.workplacetools.scrollArrowButton
import map.views.workplacetools.scrollSnapElement
import model.L
import model.PreAttachment
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import poll.ActivePollStore
import search.generalListEntry
import search.listEntryBox
import search.searchResultsListEntry
import search.separationLine
import search.subtitleBox
import search.subtitleInfoText
import search.titleBox
import search.titleSizedText
import search.titleSubtitleStack
import signup.textLinkButton
import styling.primaryButtonStyleParams
import svgmarker.defaultIconBoxSizeSearch
import svgmarker.userInitialsIconSizeSearch
import theme.FormationColors
import theme.FormationDefault
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import utils.getColorForIcon
import utils.getIcon
import utils.roundTo
import webcomponents.KeywordTagType
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.ellipseText

class AttachedGeoObjectsStore : RootStore<Map<String, GeoObjectDetails>>(
    initialData = emptyMap(),
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    private val preAttachmentsStore: PreAttachmentsStore by koinCtx.inject()
    private val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)

    // manual trigger when objects are needed
    val fetchAttachedGeoObjects = SimpleHandler<Unit> { data, _ ->
        data handledBy {
            val ids = attachments.current?.filterIsInstance<Content.GeoObject>()?.map { it.objectId }
            getAttachedGeoObjects(ids)
        }
    }

    val getAttachedGeoObjects = SimpleHandler<List<String>?> { data, _ ->
        data handledBy { ids ->
            if (!ids.isNullOrEmpty()) {
                CoroutineScope(CoroutineName("get-attached-objects-by-id")).launch {
                    val objectsMap = ids.mapNotNull { objectId ->
                        formationClient.restGetObjectById(objectId).fold(
                            { geoObject -> geoObject },
                            { error ->
                                console.log(error)
                                null
                            },
                        )
                    }.associateBy { if (it.objectType == ObjectType.UserMarker) it.ownerId else it.id }
                    console.log("fetched attached geoObjects:", objectsMap.map { it.value.title }.toList().toString())
                    addOrUpdate(objectsMap)
                }
            }
        }
    }

    val addOrUpdate = handle<Map<String, GeoObjectDetails>> { current, new ->
        current + new
    }

    // auto trigger when activeObject changes
    init {
        attachments.data.map { list ->
            list?.filterIsInstance<Content.GeoObject>()?.map { it.objectId }
        } handledBy getAttachedGeoObjects

        preAttachmentsStore.data.map { preAttachments ->
            preAttachments.map { it.value }.filterIsInstance<PreAttachment.PreGeoObject>().map { it.objectId }.toList()
        } handledBy getAttachedGeoObjects
    }
}

fun RenderContext.attachmentsList(preAttachments: Flow<List<PreAttachment>?>, manageable: Boolean = false) {

    preAttachments.render { attachmentsList ->
        if (!attachmentsList.isNullOrEmpty()) {
            stackUp(
                {
                    width { full }
                    alignItems { center }
                    paddings { vertical { normal } }
                },
                id = "list-entry-stack-up",
            ) {
                spacing { small }
                items {
                    attachmentsList.forEach { attachment ->
                        if (manageable) {
                            when (attachment) {
                                is PreAttachment.PreImage -> attachmentListEntry(attachment, removable = true)
                                is PreAttachment.PreMarkdown -> attachmentListEntry(
                                    attachment,
                                    editable = true,
                                    removable = true,
                                )

                                is PreAttachment.PreWebLink -> attachmentListEntry(
                                    attachment,
                                    editable = true,
                                    removable = true,
                                )

                                is PreAttachment.PreGeoObject -> attachmentListEntry(
                                    attachment,
                                    editable = true,
                                    removable = true,
                                )

                                is PreAttachment.PrePoll -> attachmentListEntry(
                                    attachment,
                                    editable = false,
                                    removable = true,
                                )

                                is PreAttachment.PreScanToCreateTask -> attachmentListEntry(
                                    attachment,
                                    editable = true,
                                    removable = true,
                                )

                                else -> attachmentListEntry(attachment)
                            }
                        } else attachmentListEntry(attachment)
                    }
                }
            }
        }
    }
}

fun RenderContext.attachmentListEntry(
    preAttachment: PreAttachment,
    editable: Boolean = false,
    removable: Boolean = false
) {

    val markdownPreviewStore: MarkdownPreviewStore by koinCtx.inject()
    val imagePreviewStore: ImagePreviewStore by koinCtx.inject()
    val attachedGeoObjectsStore: AttachedGeoObjectsStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val router: MapRouter by koinCtx.inject()
    val defaultTheme = koinCtx.get<FormationDefault>()
    val activePollStore: ActivePollStore by koinCtx.inject()

    when (preAttachment) {
        is PreAttachment.PreImage -> {
            // Displayed in edit mode
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { }, //{ left { tiny } }
                ) {
                    imageAttachmentEntry(preAttachment, withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                // Displayed on info card
                button(
                    {
                        width { full }
                        height { maxContent }
                        overflow { visible }
                    },
                    id = "attachment-list-entry-button",
                ) {
                    listEntryBox(
                        additionalStyle = { justifyContent { spaceBetween } },
                        paddings = {
//                            vertical { tiny }
//                            horizontal { tiny }
                        },
                    ) {
                        imageAttachmentEntry(preAttachment)
                    }
                    with(clicks) {
                        this.map { preAttachment.toAttachment() as? Content.Image } handledBy imagePreviewStore.update
                        this.map { mapOf("show" to "imagePrev") } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
        }

        is PreAttachment.PreSvgImage -> {
            // Displayed in edit mode
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { }, //{ left { tiny } }
                ) {
                    svgImageAttachmentEntry(preAttachment, withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                // Displayed on info card
                flexBox(
                    {
                        width { full }
                        height { maxContent }
                        overflow { visible }
                    },
                    id = "attachment-list-entry-button",
                ) {
                    svgImageAttachmentEntry(preAttachment)
                }
            }
        }

        is PreAttachment.PreMarkdown -> {
            // Displayed in edit mode
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { },
                ) {
                    markdownAttachmentEntry(preAttachment, withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                // Displayed on info card
                button(
                    {
                        width { full }
                        height { maxContent }
                        overflow { visible }
                    },
                    id = "attachment-list-entry-button",
                ) {
                    listEntryBox(
                        additionalStyle = { justifyContent { spaceBetween } },
                        paddings = {
                            vertical { tiny }
                            horizontal { tiny }
                        },
                    ) {
                        markdownAttachmentEntry(preAttachment)
                    }
                    with(clicks) {
                        this.map { preAttachment.toAttachment() as? Content.Markdown } handledBy markdownPreviewStore.update
                        this.map { mapOf("show" to "markdownPrev") } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
        }

        is PreAttachment.PreGeoObject -> {
            if (removable || editable) {
//                    attachedGeoObjectsStore.current[preAttachment.objectId]?.let { geoObject ->
//                            generalListEntry(geoObject, withButton = true)
//                    }
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { },
                ) {
//                        geoObjectAttachmentEntry(preAttachment, withButton = true)
                    stackUp(
                        {
                            width { full }
                            margins {
                                vertical { tiny }
                                right { tiny }
                            }
                            overflowX { hidden }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            attachedGeoObjectsStore.data.render(into = this) { geoObjects ->
                                cardSubtitle(
                                    flowOf(preAttachment.title),
                                    iconFlow = flowOf(defaultTheme.icons.linkAlt),
                                )
                                geoObjects[preAttachment.objectId]?.let { geoObject ->
                                    generalListEntry(
                                        geoObject,
                                        withBorder = false,
                                        tagsSearchable = false,
                                        keywordTagType = KeywordTagType.SearchTag,
                                    )
                                }
                            }
                        }
                    }

                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
//                    titleSizedText { +preAttachment.title }
                stackUp(
                    {
                        width { full }
                    },
                ) {
                    spacing { tiny }
                    items {
                        attachedGeoObjectsStore.data.render(into = this) { geoObjects ->
                            cardSubtitle(flowOf(preAttachment.title), iconFlow = flowOf(defaultTheme.icons.linkAlt))
                            geoObjects[preAttachment.objectId]?.let { geoObject ->
                                searchResultsListEntry(geoObject, keywordTagType = KeywordTagType.SearchTag)
                            }
                        }
                    }
                }
//                    button({
//                        width { full }
//                        height { maxContent }
//                        overflow { visible }
//                    }, id = "attachment-list-entry-button") {
//                        listEntryBox {
//                            geoObjectAttachmentEntry(preAttachment)
//                        }
//                        clicks.map { preAttachment.objectId } handledBy objectAndUserHandler.updateAndLocateActiveObjectById
//                    }
            }
        }

        is PreAttachment.PreWebLink -> {
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { },
                ) {
                    weblinkAttachmentPreview(preAttachment, withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                weblinkAttachmentPreview(preAttachment)
            }
        }

        is PreAttachment.PrePoll -> {
            // special container without button, as poll preview has its own button
            div(
                {
                    height { maxContent }
                    width { full }
                    justifyContent { start }
                    alignItems { center }
                    display { flex }
                    radius(formationStyles.buttonRadius)
                    background { color { secondary.main } }
                    border {
                        color { FormationColors.GrayDisabled.color }
                        width(formationStyles.borderWidth)
                    }
                    if (!(editable || removable)) {
                        padding { small }
                        css("cursor:pointer;")
                    }
                },
                id = "list-entry-box",
            ) {
                if (editable || removable) {
                    div(
                        {
                            height { full }
                            width { full }
                            justifyContent { start }
                            alignItems { center }
                            display { flex }
                            padding { small }
                            overflowX { hidden }
                        },
                    ) {
                        pollAttachmentEntry(preAttachment, isEditMode = true)
                    }
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                } else {
                    pollAttachmentEntry(preAttachment)
                    with(clicks) {
                        this.map { preAttachment.toAttachment() as? Content.Poll } handledBy activePollStore.update
                        this.map { mapOf("show" to "poll") } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
        }

        is PreAttachment.PreScanToCreateTask -> {
            // Displayed in edit mode
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { }, //{ left { tiny } }
                ) {
                    taskTemplateAttachmentEntry(preAttachment, withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                // Displayed on info card
                button(
                    {
                        width { full }
                        height { maxContent }
                        overflow { visible }
                    },
                    id = "attachment-list-entry-button",
                ) {
                    listEntryBox(
                        additionalStyle = { justifyContent { spaceBetween } },
                        paddings = {
//                            vertical { tiny }
//                            horizontal { tiny }
                        },
                    ) {
                        taskTemplateAttachmentEntry(preAttachment)
                    }
                    // TODO cleanup this and make a separate function for prefilling a new task from a template
                    with(clicks) {
                        if (!preAttachment.actionId.isNullOrBlank()) {
                            this.map { (Pages.Map.route + mapOf("id" to preAttachment.actionId)) } handledBy router.navTo
                        } else {
                            this.map {
                                val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
                                val preAttachmentsStore by koinCtx.inject<PreAttachmentsStore>()
                                // prefill attachment
                                preAttachmentsStore.addPreAttachmentAndSave(
                                    PreAttachment.PreGeoObject(
                                        id = "new-${Id.next()}",
                                        objectId = activeObjectStore.current.id,
                                        title = "Connected to",
                                        rel = LinkRel.related,
                                        htmlPreview = null,
                                    ),
                                )
                                // prefill task fields
                                activeObjectStore.resetStore()
                                val taskTemplate =
                                    (activeObjectStore.current.attachments?.firstOrNull { it.id == preAttachment.id } as? Content.ScanToCreateTask)?.taskTemplate
                                        ?: TaskTemplate("")
                                taskTemplate.title?.let { activeObjectStore.map(GeoObjectDetails.L.title).update(it) }
                                activeObjectStore.map(GeoObjectDetails.L.color).update(taskTemplate.color)
                                activeObjectStore.map(GeoObjectDetails.L.iconCategory).update(taskTemplate.iconCategory)
                                activeObjectStore.map(GeoObjectDetails.L.shape).update(taskTemplate.shape)
                                activeObjectStore.map(GeoObjectDetails.L.atTime).update(taskTemplate.atTimeInstant)
                                activeObjectStore.map(GeoObjectDetails.L.assignedTo).update(taskTemplate.assignedTo)
                                activeObjectStore.map(GeoObjectDetails.L.keywords).update(taskTemplate.keywords)
                                activeObjectStore.map(GeoObjectDetails.L.latLon)
                                    .update(taskTemplate.latLon ?: activeObjectStore.current.latLon)
                                taskTemplate.fieldValueTags?.let { activeObjectStore.readFieldValues(it) }
                                taskTemplate.textAttachment?.let {
                                    preAttachmentsStore.addPreAttachmentAndSave(
                                        PreAttachment.PreMarkdown(
                                            id = "new-${Id.next()}",
                                            text = it,
                                            title = "Task details", // TODO scan-to: Use description Title field if its there
                                            htmlPreview = null,
                                        ),
                                    )
                                }
                                activeObjectStore.focusThisObject()

                                Pages.Map.route + mapOf("add" to ObjectType.Task.name)

                            } handledBy router.navTo
                        }
                        // TODO scan-to: create card to see task template preview
//                        this.map { preAttachment.toAttachment() as Attachment.ScanToCreateTask } handledBy imagePreviewStore.update
//                        this.map { mapOf("show" to "imagePrev") } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
        }
        // keep else for forward compatibility with client chanhes
        else -> {
            // Default view, if attachment type not yet implemented
            if (editable || removable) {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { },
                ) {
                    defaultAttachmentEntry(withButton = true)
                    manageButtons {
                        if (editable) editAttachmentButton(preAttachment, single = !removable)
                        if (removable) {
                            if (editable) separationLine()
                            removeAttachmentButton(preAttachment, single = !editable)
                        }
                    }
                }
            } else {
                listEntryBox(
                    additionalStyle = { justifyContent { spaceBetween } },
                    paddings = { left { tiny } },
                ) {
                    defaultAttachmentEntry(withButton = true)
                }
            }
        }
    }
}

fun RenderContext.imageAttachmentEntry(imageAttachment: PreAttachment.PreImage, withButton: Boolean = false) {
    val thumbNail = imageAttachment.thumbNail as? Content.Image
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
//        if(withButton) paddings { vertical { tiny } }
        },
    ) {
//        simpleIconBox { image }
        flexBox(
            {
                width { full }
                alignItems { center }
                justifyContent { spaceBetween }
                direction { column }
            },
        ) {
//            titleSubtitleStack {
//                subtitleInfoText { +(imageAttachment.mimeType?: "") }
//                subtitleInfoText { +"${imageAttachment.height} x ${imageAttachment.width} px" }
//            }
            imageAttachment.title?.let { title ->
                if (title.isNotBlank()) {
                    span(
                        {
                            width { full }
                            fontWeight { bold }
                            fontSize { small }
                            textAlign { left }
                            padding { tiny }
                        },
                    ) { +title }
                }
            }
            flexBox(
                {
                    width { full }
//                maxHeight { "62px" }
                    alignItems { center }
                    justifyContent { flexEnd }
                },
            ) {
                img(
                    {
//                    maxHeight { "62px" }
                        width { full }
//                    maxWidth(sm = { full }, md = formationStyles.cardWidth)
//                    css("object-fit: contain;")
//                    border {
//                        color { primary.main }
//                        width(formationStyles.borderWidth)
//                    }
                        radii {
                            if (imageAttachment.title.isNullOrBlank()) {
                                left { larger }
                                if (!withButton) right { larger }
                            } else {
                                bottom { larger }
                            }
                        }
                    },
                ) {
                    src(imageAttachment.href ?: thumbNail?.href ?: "")
                }
            }
        }
//        if (!withButton) icon({ margins { left { tiny } } }) { fromTheme { chevronRight } }
    }
}

fun RenderContext.svgImageAttachmentEntry(svgImageAttachment: PreAttachment.PreSvgImage, withButton: Boolean = false) {

    flexBox(
        {
            width { full }
            height { "200px" }
            alignItems { center }
            justifyContent { start }
        },
    ) {
        flexBox(
            {
                width { full }
                height { "200px" }
                alignItems { center }
                justifyContent { spaceBetween }
                direction { column }
            },
        ) {
            svgImageAttachment.title?.let { title ->
                if (title.isNotBlank()) {
                    span(
                        {
                            width { full }
                            fontWeight { bold }
                            fontSize { small }
                            textAlign { left }
                            padding { tiny }
                        },
                    ) { +title }
                }
            }
            flexBox(
                {
                    width { "200px" }
                    height { "200px" }
                    alignItems { center }
                    justifyContent { flexEnd }
                    overflow { hidden }
                },
            ) {
                svg { }.domNode.apply {
                    outerHTML = svgImageAttachment.svgContent
                }
            }
        }
    }
}

fun RenderContext.markdownAttachmentEntry(markDownAttachment: PreAttachment.PreMarkdown, withButton: Boolean = false) {
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
            if (withButton) paddings {
                vertical { tiny }
                horizontal { tiny }
            }
            overflowX { hidden }
        },
    ) {
//        simpleIconBox { document }
        flexBox(
            {
                width { full }
                maxWidth(sm = { full }, md = formationStyles.cardWidth)
//            maxHeight { "62px" }
                alignItems { flexStart }
                justifyContent { flexStart }
                overflowY { hidden }
            },
        ) {
            titleSubtitleStack {
                markDownAttachment.title?.let { title ->
                    titleBox(
                        titleText = { titleSizedText { +title } },
                        titleInfo = null,
                    )
                }
                markDownAttachment.htmlPreview?.let { html ->
                    div("max-w-[400px] flex flex-wrap align-left") { }.domNode.innerHTML = html
                }
            }
        }
        if (!withButton) icon({ margins { left { tiny } } }) { fromTheme { chevronRight } }
    }
}

fun RenderContext.weblinkAttachmentEntry(weblinkAttachment: PreAttachment.PreWebLink, withButton: Boolean = false) {
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
            if (withButton) {
                padding { tiny }
                radii {
                    left(formationStyles.buttonRadius)
                }
            } else {
                padding { small }
                radius(formationStyles.buttonRadius)
                background {
                    color { FormationColors.GrayLight.color }
                }
            }
            overflowX { hidden }
        },
    ) {
        lineUp(
            {
                width { full }
                alignItems { center }
                justifyContent { spaceBetween }
            },
        ) {
            spacing { small }
            items {
                icon { FormationUIIcons.Link.icon }
                titleSubtitleStack {
                    titleBox(
                        titleText = {
                            titleSizedText { +weblinkAttachment.title }
                        },
                        titleInfo = null,
                    )
                    textLinkButton(
                        text = flowOf(weblinkAttachment.href),
                        fontSize = { smaller },
                        link = weblinkAttachment.href,
                    )
                }
                copyButton(
                    value = flowOf(weblinkAttachment.href),
                    alertMessage = flowOf("Link copied!"),
                )
            }
        }
    }
}

fun RenderContext.weblinkAttachmentPreview(weblinkAttachment: PreAttachment.PreWebLink, withButton: Boolean = false) {
    weblinkAttachment.openGraphMetadata?.let { linkMetadata ->
        flexBox(
            {
                direction { column }
                width { full }
                alignItems { stretch }
                justifyContent { start }
                padding { small }
                if (withButton) {
                    radii {
                        left(formationStyles.buttonRadius)
                    }
                } else {
                    radius(formationStyles.buttonRadius)
                }
                background {
                    color { FormationColors.GrayLight.color }
                }
            },
        ) {
            // logo, title, prevText
            lineUp(
                {
                    alignItems { start }
                    justifyContent { flexStart }
                },
            ) {
                spacing { small }
                items {
                    linkMetadata.siteIcon?.let { iconSrc ->
                        img(
                            {
                                css("object-fit: contain;")
                                maxWidth { "32px" }
                                radius { small }
                            },
                        ) {
                            crossOrigin("anonymous")
                            this.src(iconSrc)
                        }
                    }
                    linkMetadata.title?.let { title ->
                        stackUp(
                            {
                                width { full }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            spacing { small }
                            items {
                                titleSizedText { +title }
                                linkMetadata.description?.let { desc ->
                                    ellipseText(
                                        {
                                            fontSize { smaller }
                                            fontWeight { lighter }
                                        },
                                        maxLines = 3,
                                    ) { +desc }
                                }
                            }
                        }
                    }
                }
            }
            // image
            linkMetadata.image?.let { imgSrc ->
                img("mt-1 object-contain max-h-[200px]") {
                    crossOrigin("anonymous")
                    src(imgSrc)
                }
            }
            // linkIcon, clickable link text (ellipsed), copy button
            lineUp(
                {
                    alignItems { center }
                    justifyContent { spaceBetween }
                    margins {
                        top { small }
                    }
                },
            ) {
                spacing { small }
                items {
                    icon { FormationUIIcons.Link.icon }
                    textLinkButton(
                        text = flowOf(weblinkAttachment.href),
                        fontSize = { smaller },
                        link = weblinkAttachment.href,
                    )
                    copyButton(
                        value = flowOf(weblinkAttachment.href),
                        alertMessage = flowOf("Link copied!"),
                    )
                }
            }

        }
    } ?: run {
        // fallback to old design, without preview
        weblinkAttachmentEntry(weblinkAttachment, withButton = withButton)
    }
}

const val resultEntryWidth = 150

fun RenderContext.pollAttachmentEntry(pollAttachment: PreAttachment.PrePoll, isEditMode: Boolean = false) {
    val routerStore by koinCtx.inject<RouterStore>()
    val translation by koinCtx.inject<Translation>()
    val activePollStore by koinCtx.inject<ActivePollStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    val sessionIdStore: SessionIdStore by koinCtx.inject()
    val pollResults = activeObjectStore.current.pollResults(pollAttachment.id)
    val mostVotedOption = pollResults?.let { res ->
        res.votesByOption.maxByOrNull { it.value }
    }?.toPair()
    val mostVotedOptions = pollResults?.let { res ->
        val mostVoted = res.votesByOption.maxByOrNull { it.value }
        res.votesByOption.filter { it.value == mostVoted?.value }
    }
    val sortedOptions = pollResults?.let { res ->
        res.votesByOption.toList().sortedByDescending { it.second }
    }?.toMap()

    // Detect if user voted and what
    val userOrSessionId = if (apiUserStore.current.isAnonymous) {
        "anonymous-${sessionIdStore.current?.id}"
    } else {
        apiUserStore.current.userId
    }
    val userVotedFor = pollResults?.userVotes?.get(userOrSessionId)
    val userHasVoted = userVotedFor != null
    val noVotes = (pollResults?.totalVotes ?: 0) == 0

//    val allowedToResetPoll = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()
//            || activeObjectStore.current.tags.getUniqueTag(ObjectTags.OwnerId) == apiUserStore.current.userId

    stackUp(
        {
            width { full }
            alignItems { center }
            justifyContent { center }
            textAlign { center }
            overflowX { hidden }
            position { relative { } }
        },
    ) {
        spacing { small }
        items {
            // poll title question
            cardTitle(flowOf(pollAttachment.title), margins = {})
            // if no votes from this user yet
            if (!userHasVoted) {
                span(
                    {
                        fontSize { larger }
                        lineHeight { "1" }
                    },
                ) { translation[TL.Poll.GIVE_US_YOUR_OPINION].renderText(into = this) }
                // if no votes yet at all
                if (noVotes) {
                    span(
                        {
                            fontSize { small }
                            lineHeight { "1" }
                        },
                    ) { translation[TL.Poll.BE_FIRST_TO_VOTE].renderText(into = this) }
                }
            } else {
                if (pollAttachment.maxVotesPerUser > 1 && !sortedOptions.isNullOrEmpty() && sortedOptions.size > 1) {
                    // if people have voted, show all options with votes, sorted, in a side scroll container
                    flexBox(
                        {
                            direction { row }
                            alignItems { start }
                            width { full }
                            height { full }
                            maxHeight { full }
                            overflowY { hidden }
                            overflowX { scroll }
                            css(
                                """
                                -ms-overflow-style: none;  /* IE and Edge */
                                scrollbar-width: none;  /* Firefox */
                                """.trimIndent(),
                            )
                            css("scroll-snap-type: x mandatory;")
                            css("scroll-behavior: smooth;")

                        },
                        id = "voting-scroll-snap-container",
                    ) {
                        // scroll button left
                        scrollArrowButton(
                            id = "voting-scroll-button-left",
                            icon = { chevronLeft },
                            scrollStep = -(resultEntryWidth * 0.8).toInt(),
                            pos = { left { none } },
                            buttonWidth = resultEntryWidth,
                            buttonHeight = resultEntryWidth,
                        )
                        // dynamic whitespace start
                        scrollSnapElement {
                            flexBox(
                                {
                                    width(
                                        sm = { "calc(50vw - ${resultEntryWidth / 2 + 6 + 16}px)" },
                                        md = { "${200 - (resultEntryWidth / 2 + 6 + 16)}px" },
                                    )
                                    height { "100px" }
                                    css("scroll-snap-align: end;")
                                },
                            ) { }
                        }
                        // poll options
                        sortedOptions.forEach { (option, _) ->
                            scrollSnapElement {
                                pollResultPreviewEntry(option, pollResults)
                            }
                        }

                        // dynamic whitespace end
                        scrollSnapElement {
                            flexBox(
                                {
                                    width(
                                        sm = { "calc(50vw - ${resultEntryWidth / 2 + 6 + 16}px)" },
                                        md = { "${200 - (resultEntryWidth / 2 + 6 + 16)}px" },
                                    )
                                    height { "100px" }
                                    css("scroll-snap-align: end;")
                                },
                            ) { }
                        }
                        // scroll button right
                        scrollArrowButton(
                            id = "voting-scroll-button-right",
                            icon = { chevronRight },
                            scrollStep = (resultEntryWidth * 0.8).toInt(),
                            pos = { right { none } },
                            buttonWidth = resultEntryWidth,
                            buttonHeight = resultEntryWidth,
                        )
                        // set default scroll position
                        val observer = MutationObserver { _, observer ->
                            if (document.contains(domNode)) {
                                this.domNode.scrollTo(x = 50.0, y = 0.0)
                                // TODO addEventListener that checks if left/right scroll-end is reached
                                //  and disabled the left/right scroll button
                                observer.disconnect()
                            }
                        }
                        observer.observe(
                            document,
                            MutationObserverInit(
                                attributes = false,
                                childList = true,
                                characterData = false,
                                subtree = true,
                            ),
                        )
                    }
                } else {
                    // if people have voted, show most voted option
                    if (mostVotedOption != null) {
                        // poll option title
                        span(
                            {
                                fontSize { normal }
                                fontWeight { bold }
                                lineHeight { "1" }
                                mostVotedOption.first.color?.getColorForIcon()?.color?.let { color { it } }
                            },
                        ) { +mostVotedOption.first.title }
                        // poll option icon
                        icon(
                            {
                                size { "80px" }
                                mostVotedOption.first.color?.getColorForIcon()?.color?.let { color { it } }
                            },
                        ) {
                            fromTheme { mostVotedOption.first.iconCategory?.getIcon()?.icon ?: ban }
                        }
                        // poll option percentage
                        span(
                            {
                                fontSize { giant }
                                lineHeight { "1" }
                            },
                        ) { +"${((pollResults.relativeByOption[mostVotedOption.first] ?: 0.0) * 100).roundTo(0)}%" }
                        // poll option votes
                        span(
                            {
                                fontSize { small }
                                lineHeight { "1" }
                            },
                        ) {
                            translation[
                                TL.Poll.NUMBER_OF_TOTAL_VOTES,
                                mapOf(
                                    "number" to (pollResults.votesByOption[mostVotedOption.first] ?: 0),
                                    "total" to (pollResults.totalVotes),
                                ),
                            ].renderText(into = this)
                        }
                    }
                }
            }
//            // if in edit mode show Reset poll button
//            if(isEditMode && allowedToResetPoll) {
//                clickButton({
//                    primaryButtonStyleParams()
//                    width { maxContent }
//                    minWidth { "200px" }
//                }) {
//                    type { primary }
//                    text(translation[TL.Poll.RESET_POLL])
//                    element {
//                        clicks handledBy confirm(
//                            text = translation[TL.Poll.RESET_POLL_QUESTION],
//                            okHandlers = listOf(activePollStore.resetPoll)
//                        )
//                    }
//                }
//            } else {
            // else show button: "Vote" if user has not voted yet or no votes yet at all, else "Show results"
            clickButton(
                {
                    primaryButtonStyleParams()
//                fontSize { normal }
                    width { maxContent }
                    minWidth { "200px" }
                },
            ) {
                disabled(isEditMode)
                type { primary }
                text(if (userHasVoted) translation[TL.Poll.SEE_FULL_RESULTS] else translation[TL.Poll.GO_TO_VOTE])
                element {
                    with(clicks) {
                        this.map { pollAttachment.toAttachment() as? Content.Poll } handledBy activePollStore.update
                        this.map { mapOf("show" to "poll") } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
//            }
        }
    }
}

private fun RenderContext.pollResultPreviewEntry(
    option: PollOption,
    pollResults: PollResult,
) {
    val translation by koinCtx.inject<Translation>()

    stackUp(
        {
            flex {
                shrink { "0" }
            }
            width { "${resultEntryWidth}px" }
            height { full }
            alignItems { center }
            justifyContent { spaceBetween }
            textAlign { center }
            margin { tiny }
        },
    ) {
        spacing { small }
        items {
            // poll option title
            span(
                {
                    fontSize { small }
                    fontWeight { bold }
//                    lineHeight { "1" }
                    option.color?.getColorForIcon()?.color?.let { color { it } }
                },
            ) { +option.title }
            // subtitle
            ellipseText(
                styleParams = {
                    width { full }
                    fontSize { tiny }
                    textAlign { center }
                },
                maxLines = 4,
            ) { +(option.subtitle ?: "") }
            // poll option icon
            icon(
                {
                    size { "30px" }
                    option.color?.getColorForIcon()?.color?.let { color { it } }
                },
            ) {
                fromTheme { option.iconCategory?.getIcon()?.icon ?: ban }
            }
            // poll option percentage
            span(
                {
                    fontSize { large }
                    lineHeight { "1" }
                },
            ) { +"${((pollResults.relativeByOption[option] ?: 0.0) * 100).roundTo(0)}%" }
            // poll option votes
            span(
                {
                    fontSize { tiny }
                    lineHeight { "1" }
                },
            ) {
                translation[
                    TL.Poll.NUMBER_OF_TOTAL_VOTES,
                    mapOf(
                        "number" to (pollResults.votesByOption[option] ?: 0),
                        "total" to (pollResults.totalVotes),
                    ),
                ].renderText(into = this)
            }
        }
    }
}

fun RenderContext.geoObjectAttachmentEntry(
    geoObjectAttachment: PreAttachment.PreGeoObject,
    withButton: Boolean = false
) {
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
            if (withButton) paddings {
                vertical { tiny }
                right { tiny }
            }
        },
    ) {
        simpleIconBox { FormationIcons.Location.icon }
        titleSubtitleStack {
            titleBox(
                titleText = { titleSizedText { +geoObjectAttachment.title } },
                titleInfo = null,
            )
            subtitleBox(
                wrapText = true,
//            subtitleText = { subtitleInfoText { +geoObjectAttachment.type.toString() } },
                subtitleInfo = null,
            )
        }
    }
}

fun RenderContext.taskTemplateAttachmentEntry(
    taskTemplateAttachment: PreAttachment.PreScanToCreateTask,
    withButton: Boolean = false
) {
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
            if (withButton) paddings {
                vertical { tiny }
                right { tiny }
            }
            overflowX { hidden }
        },
    ) {
        simpleIconBox { FormationIcons.TaskAlt.icon }
        titleSubtitleStack {
            titleBox(
                titleText = {
                    titleSizedText {
                        +(taskTemplateAttachment.taskTemplate?.title ?: taskTemplateAttachment.actionId ?: "No title")
                    }
                }, // TODO scan-to translate
                titleInfo = null,
            )
            subtitleBox(
                wrapText = true,
                subtitleText = { subtitleInfoText { +"\"Task template\" - ${taskTemplateAttachment.actionId ?: "no actionId"}" } }, // TODO scan-to translate
                subtitleInfo = null,
            )
        }
    }
}

fun RenderContext.defaultAttachmentEntry(withButton: Boolean = false) {
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { start }
            if (withButton) paddings {
                vertical { tiny }
                right { tiny }
            }
            overflowX { hidden }
        },
    ) {
        simpleIconBox { FormationIcons.API.icon }
        titleSubtitleStack {
            titleBox(
                titleText = { titleSizedText { +"Unknown Attachment" } },
                titleInfo = null,
            )
            subtitleBox(
                wrapText = true,
//            subtitleText = { subtitleInfoText { +geoObjectAttachment.type.toString() } },
                subtitleInfo = null,
            )
        }
    }
}

fun RenderContext.simpleIconBox(icon: Icons.() -> IconDefinition) {
    flexBox(
        {
            flex {
                grow { "0" }
                shrink { "0" }
                basis { "${defaultIconBoxSizeSearch}px" }
            }
            width { "${defaultIconBoxSizeSearch}px" }
            height { "${defaultIconBoxSizeSearch}px" }
            margins { right { smaller } }
            justifyContent { center }
            alignItems { center }
        },
        id = "simple-icon-box",
    ) {
        flexBox(
            {
                flex {
                    grow { "0" }
                    shrink { "0" }
                    basis { "${userInitialsIconSizeSearch}px" }
                }
                width { "${userInitialsIconSizeSearch}px" }
                height { "${userInitialsIconSizeSearch}px" }
                margin { "${((defaultIconBoxSizeSearch - userInitialsIconSizeSearch) / 2).toInt()}px" }
                color { secondary.main }
                background { color { primary.main } }
                radius { full }
                padding { tiny }
                justifyContent { center }
                alignItems { center }
            },
        ) {
            icon({ size { larger } }) { fromTheme(icon) }
        }
    }
}

fun RenderContext.manageButtons(buttons: HtmlTag<HTMLDivElement>.() -> Unit) {
    flexBox(
        {
            direction { column }
            height { full }
            width { maxContent }
            alignItems { center }
            justifyContent { center }
            radii { right(formationStyles.buttonRadius) }
            borders {
                left {
                    color { FormationColors.GrayDisabled.color }
                    width { hair }
                }
            }
//        margins { left { tiny } }
        },
    ) {
        buttons.invoke(this)
    }
}

fun RenderContext.editAttachmentButton(preAttachment: PreAttachment, single: Boolean = false) {
    val attachmentsStore: AttachmentsStore by koinCtx.inject()

    button(
        {
            height { full }
        },
    ) {
        flexBox(
            {
                height { full }
                hover {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                }
                alignItems { center }
                justifyContent { center }
                padding { tiny }
                radii {
                    if (single) {
                        right(formationStyles.buttonRadius)
                    } else {
                        topRight(formationStyles.buttonRadius)
                    }
                }
            },
        ) {
            icon(
                {
                    size { larger }
                },
            ) { fromTheme { FormationIcons.Edit.icon } }
        }
        clicks.map {
            preAttachment.id
        } handledBy attachmentsStore.edit
    }
}

fun RenderContext.removeAttachmentButton(preAttachment: PreAttachment, single: Boolean = false) {
    val attachmentsStore: AttachmentsStore by koinCtx.inject()

    button(
        {
            height { full }
        },
    ) {
        flexBox(
            {
                height { full }
                hover {
                    color { secondary.main }
                    background {
                        color { FormationColors.RedError.color }
                    }
                }
                alignItems { center }
                justifyContent { center }
                padding { tiny }
                radii {
                    if (single) {
                        right(formationStyles.buttonRadius)
                    } else {
                        bottomRight(formationStyles.buttonRadius)
                    }
                }
            },
        ) {
            icon(
                {
                    size { larger }
                },
            ) { fromTheme { FormationIcons.DeleteAlt.icon } }
        }
        clicks.map { preAttachment.id } handledBy attachmentsStore.remove
    }
}
