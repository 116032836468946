package map.views.workplacetools

import analytics.AnalyticsService
import auth.CurrentWorkspaceStore
import apiclient.customfields.parseFieldValues
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectType
import apiclient.websocket.MessageToServer
import data.objects.ActiveObjectStore
import data.users.UserListStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.title
import dev.fritz2.styling.params.BasicParams
import dev.fritz2.styling.params.PositioningContext
import dev.fritz2.styling.params.Style
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.L
import org.w3c.dom.HTMLDivElement
import search.keywordTagsBox
import signup.popupSignUp
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.getColorForIcon
import utils.getIcon
import utils.makeRGBA
import utils.mergeTagLists
import utils.respectFeatureFlags
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.ellipseText

val closeCircleButtonStyle: Style<BasicParams> = {
    width { "46px" }
    height { "46px" }
    background { color { secondary.main } }
    color { primary.main }
    border {
        width(formationStyles.borderWidth)
        color { primary.main }
    }
//    boxShadow {
//        shadow("2px", "4px", "10px", color = rgba(0,0,0,0.35))
//    }
}

const val toolButtonIconSize = "2.6em"
const val toolButtonHeight = 110
const val toolButtonWidth = 90
const val toolButtonRadius = 20

const val libraryButtonHeight = 150
const val libraryButtonWidth = 150
const val libraryButtonRadius = 20
const val libraryInnerIconRadius = 15

fun RenderContext.objectToolButton(
    type: ObjectType,
    disabled: Flow<Boolean>,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    analyticsEventProvider: () -> MessageToServer.AnalyticsEvent
) {
    val translation: Translation by koinCtx.inject()

    val routerStore: RouterStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val userListStore: UserListStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    val objectType = activeObjectStore.map(apiclient.geoobjects.GeoObjectDetails.L.objectType)

    val icon: Icons.() -> IconDefinition
    val displayName: String

    when (type) {
        ObjectType.POI -> {
            icon = { FormationIcons.Location.icon }
            displayName = translation.getString(TL.WorkPlaceTools.POINT)
        }

        ObjectType.Task -> {
            icon = { FormationIcons.Task.icon }
            displayName = translation.getString(TL.WorkPlaceTools.TASK)
            userListStore.fetchGroupMembers.invoke(null)
        }

        ObjectType.Event -> {
            icon = { FormationIcons.Embrace.icon }
            displayName = translation.getString(TL.WorkPlaceTools.MEET)
        }

        ObjectType.Zone -> {
            icon = { FormationIcons.Zone.icon }
            displayName = translation.getString(TL.WorkPlaceTools.ZONE)
        }

        else -> {
            icon = { document }; displayName = "TOOL"
        }
    }

    disabled.render { isDisabled ->
        button(
            {
                display { flex }
                justifyContent { center }
                alignItems { center }
                margin { "6px" }
                width { "${toolButtonWidth}px" }
                height { "${toolButtonHeight}px" }
                color { secondary.main }
                if (isDisabled) {
                    background { color { FormationColors.GrayPrivate.color } }
                } else {
                    background { color { primary.main } }
                    hover {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                        border {
                            color { primary.main }
                            width { "2px" }
                        }
                    }
                }
                radius { "${toolButtonRadius}px" }
                padding { small }
            },
        ) {
//            disabled(isDisabled)
            title(
                translation.getString(
                    TL.CardToolSelect.ADD_TOOLTIP,
                    mapOf(
                        "tool" to displayName.lowercase()
                            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
                    ),
                ),
            )
            flexBox(
                {
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                attr("onClick", "blur();")
                icon(
                    {
                        size { toolButtonIconSize }
                    },
                ) { fromTheme(icon) }
                span({ fontSize { smaller }; fontWeight { lighter }; margins { top { small } } }) { +displayName }
            }
            if (isDisabled) {
                clicks handledBy popupSignUp()
            } else {
                with(clicks) {
                    this handledBy activeObjectStore.resetStore
                    this handledBy activeObjectStore.removeMapClickListener
                    this.map { type } handledBy objectType.update
                    clickHandlers?.forEach { handler ->
                        this handledBy handler
                    }
                    this.map {
                        mapOf(
                            "page" to Pages.Map.name,
                            "add" to type.name,
                        )
                    } handledBy routerStore.addOrReplaceRoute
                    this.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
                }
            }
        }
    }
}

fun <T> RenderContext.genericToolButton(
    icon: Icons.() -> IconDefinition,
    displayName: Flow<String>,
    toolTitle: Flow<String>,
    disabled: Flow<Boolean>,
    value: T,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    valueHandlers: List<SimpleHandler<T>>? = null,
    routingMap: Map<String, String>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    analyticsEventProvider: () -> MessageToServer.AnalyticsEvent
) {

    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()

    disabled.render { isDisabled ->
        button(
            {
                display { flex }
                justifyContent { center }
                alignItems { center }
                flex {
                    grow { "0" }
                    shrink { "0" }
                }
                margin { "6px" }
                width { "${toolButtonWidth}px" }
                height { "${toolButtonHeight}px" }
                color { secondary.main }
                if (isDisabled) {
                    background { color { FormationColors.GrayPrivate.color } }
                } else {
                    background { color { primary.main } }
                    hover {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                        border {
                            color { primary.main }
                            width { "2px" }
                        }
                    }
                }
                radius { "${toolButtonRadius}px" }
                padding { small }
            },
        ) {
//            disabled(isDisabled)
            title(
                toolTitle.map { title ->
                    title.lowercase()
                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                },
            )
            flexBox(
                {
                    direction { column }
                    justifyContent { center }
                    alignItems { center }
                    width { full }
                    overflowX { hidden }
                },
            ) {
                attr("onClick", "blur();")
                icon(
                    {
                        size { toolButtonIconSize }
                    },
                ) { fromTheme(icon) }
                ellipseText(
                    {
                        width { full }
                        fontSize { smaller }
                        fontWeight { lighter }
                        margins { top { small } }
                    },
                ) { displayName.renderText(into = this) }
            }
            if (isDisabled) {
                clicks handledBy popupSignUp()
            } else {
                with(clicks) {
                    clickHandlers?.forEach { handler ->
                        this handledBy handler
                    }
                    valueHandlers?.forEach { handler ->
                        this.map { value } handledBy handler
                    }
                    routingMap?.let { route -> this.map { route } handledBy routerStore.validateInternalRoute }
                    addOrReplaceRoute?.let { route -> this.map { route } handledBy routerStore.addOrReplaceRoute }
                    this.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
                }
            }
        }
    }
}

fun RenderContext.archetypeToolButton(
    archetype: GeoObjectDetails,
    disabled: Flow<Boolean>,
    routingMap: Map<String, String>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    valueHandlers: List<SimpleHandler<String>>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    analyticsEventProvider: () -> MessageToServer.AnalyticsEvent
) {

    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val fieldValueTags = currentWorkspaceStore.current?.fieldDefinitions?.let { definitions ->
        archetype.tags.respectFeatureFlags().parseFieldValues(definitions)
    }?.toKeyWordTagsList(KeywordTagActionType.DefaultFieldValue) ?: emptyList()

    disabled.render { isDisabled ->
        button(
            {
                display { flex }
                justifyContent { center }
                alignItems { center }
                flex {
                    grow { "0" }
                    shrink { "0" }
                }
                margin { "6px" }
                width { "${libraryButtonWidth}px" }
                height { "${libraryButtonHeight}px" }
                if (isDisabled) {
                    color { secondary.main }
                    background { color { FormationColors.GrayPrivate.color } }
//                css("cursor:not-allowed;")
                } else {
                    color { primary.main }
                    border {
                        color { primary.main }
                        width { "2px" }
                    }
                    background { color { secondary.main } }
                    hover {
                        color { secondary.main }
                        background {
                            color { primary.main }
                        }
                    }
                }
                radius { "${libraryButtonRadius}px" }
            },
        ) {
//            disabled(isDisabled)
            title(archetype.title.lowercase()
                    .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
            )
            stackUp(

                    {width { full }
                    height { full }
                    padding { tiny }
                    alignItems { stretch }
                    justifyContent { spaceBetween }
                },
            ) {
                spacing { none }
                items {
                    //iconBox
                    flexBox(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { center }
                            background {
                                color { archetype.color.getColorForIcon().color }
                            }
                            color { primary.main }
                            if (isDisabled) {
                                color { secondary.main }
                                background {
                                    color { FormationColors.GrayLight.color }
                                }
                            }
                            radius { "${libraryInnerIconRadius}px" }
                            padding { tiny }
                        },
                    ) {
                        icon(
                            {
                                size { toolButtonIconSize }
                            },
                        ) { fromTheme { archetype.iconCategory?.getIcon()?.icon ?: FormationIcons.API.icon } }
                    }
                    flexBox(
                        {
                            direction { column }
                            justifyContent { center }
                            alignItems { center }
                            width { full }
                            overflowX { hidden }
                        },
                    ) {
                        attr("onClick", "blur();")
                        ellipseText(
                            {
                                width { full }
                                fontSize { smaller }
                                fontWeight { lighter }
                                margins {
                                    horizontal { small }
                                }
                            },
                            maxLines = 2,
                        ) { +archetype.title }
                    }
                    keywordTagsBox(
                        keywords = mergeTagLists(
                            keywords = archetype.keywords,
                            fieldValueTags = fieldValueTags,
                        ),
                        searchable = !isDisabled,
                        keywordTagType = KeywordTagType.ArchetypeTag,
                        maxLines = 2,
                    )
                }
            }
            if (isDisabled) {
                clicks handledBy popupSignUp()
            } else {
                with(clicks) {
                    clickHandlers?.forEach { handler ->
                        this handledBy handler
                    }
                    valueHandlers?.forEach { handler ->
                        this.map { archetype.id } handledBy handler
                    }
                    routingMap?.let { route -> this.map { route } handledBy routerStore.validateInternalRoute }
                    addOrReplaceRoute?.let { route -> this.map { route } handledBy routerStore.addOrReplaceRoute }
                    this.map { analyticsEventProvider.invoke() } handledBy analyticsService.analyticsEvent
                }
            }
        }
    }
}

fun RenderContext.scrollSnapElement(block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    flexBox(
        {
            css("scroll-snap-align: center;")
            css("scroll-snap-stop: always;")
        },
    ) {
        block?.invoke(this)

        // size animation and dots?: https://stackoverflow.com/questions/53355384/indicators-dots-with-css-scroll-snap

        /*val observer = MutationObserver { records, observer ->
            if (document.contains(domNode)) {

                val intersectionObserver = utils.IntersectionObserver ({ entries, intersectObserver ->
                    val child = domNode.firstElementChild
                    if (child is HTMLButtonElement) {
                        if (entries[0].isIntersecting) {
                            console.log(("intersecting!"))
                            child.style.width = "120px"
                            child.style.height = "140px"
                        } else {
                            child.style.width = "80px"
                            child.style.height = "100px"
                        }
                    }

                }, utils.IntersectionObserverOptions {
                    root = domNode.parentElement
                    threshold = arrayOf(1.0)
                })

                console.log("root", intersectionObserver.root.id)
                intersectionObserver.observe(domNode)

                observer.disconnect()
            }
        }
        observer.observe(
            document,
            MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true)
        )*/
    }
}

fun RenderContext.scrollArrowButton(
    id: String,
    icon: Icons.() -> IconDefinition,
    scrollStep: Int,
    pos: PositioningContext.() -> Unit,
    buttonHeight: Int,
    buttonWidth: Int,
) {
    button(
        {
            display { flex }
            justifyContent { center }
            width { "${toolButtonWidth / 1.5}px" }
            height { "${toolButtonHeight}px" }
            alignItems { center }
            position { absolute(pos) }
            color { secondary.main }
            radius { huge }
            hover {
                background {
                    color { makeRGBA(primary.main, 0.05) }
                }
            }
        },
        id = id,
    ) {
        div(
            {
                width { minContent }
                height { minContent }
                radius { full }
                background {
                    color { makeRGBA(primary.main, 0.2) }
                }
                hover {
                    background {
                        color { makeRGBA(primary.main, 0.5) }
                    }
                }
            },
        ) {
            icon(
                {
                    size { larger }
                },
            ) { fromTheme(icon) }
        }
        clicks.map {
            it.stopPropagation()
            scrollStep
        } handledBy SimpleHandler { flow, job ->
            flow.onEach {
                val scrollContainer = domNode.parentElement
                if (scrollContainer != null) {
                    scrollContainer.scrollLeft += it
                }
            }.launchIn(MainScope() + job)
        }
    }
}
