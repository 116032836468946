package search.searchlayer

import apiclient.geoobjects.GeoObjectDetails
import apiclient.search.ObjectSearchResult
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.GeojsonConverter.activeObjectStore
import maplibreGL.MaplibreMap
import search.SearchResultsCoordinator
import search.SearchType

class MapSearchResultsStore : RootStore<Map<String, ObjectSearchResult>>(
    initialData = emptyMap(),
    job = Job(),
) {

    private val searchResultsCoordinator: SearchResultsCoordinator by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()

    val set = handle<List<GeoObjectDetails>> { _, objects ->
        val newData = objects.associate { obj ->
            obj.id to ObjectSearchResult(hit = obj)
        }
        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, newData.values.toList()))
        // Make sure current active Marker is always on top
        maplibreMap.addActiveObjectOverride(activeObjectStore.current)
        newData
    }

    val add = handle<List<GeoObjectDetails>> { current, objects ->
        val newData = current + objects.associate { obj ->
            obj.id to ObjectSearchResult(hit = obj)
        }
        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, newData.values.toList()))
        // Make sure current active Marker is always on top
        maplibreMap.addActiveObjectOverride(activeObjectStore.current)
        newData
    }

    /**
     * Inserts one object into [MapSearchResultsStore]
     */

    val insert = handle<GeoObjectDetails> { current, updatedObj ->
        val insertableMap = current.toMutableMap()
        insertableMap[updatedObj.id] = ObjectSearchResult(hit = updatedObj)
        insertableMap.toMap()
    }

    /**
     * Inserts multiple objects into [MapSearchResultsStore]
     */

    val insertMultiple = handle<List<GeoObjectDetails>> { current, updatedObjects ->
        val insertableMap = current.toMutableMap()
        updatedObjects.forEach { updatedObj ->
            insertableMap[updatedObj.id] = ObjectSearchResult(hit = updatedObj)
        }
        insertableMap.toMap()
    }

    /**
     * Removes multiple objects from [MapSearchResultsStore] by id
     */

    val removeMultiple = handle<Set<String>> { current, objectIds ->
        val removableMap = current.toMutableMap()
        removableMap - objectIds
        removableMap.toMap()
    }

    val clear = handle { _ ->
        searchResultsCoordinator.renderSearchResults(Pair(SearchType.Map, emptyList()))
        emptyMap()
    }
}
