package data.keywordlayer

import apiclient.FormationClient
import apiclient.customfields.FieldValue
import apiclient.customfields.fieldValueTag
import apiclient.customfields.parseFieldValues
import apiclient.customfields.tagValues
import apiclient.geoobjects.ObjectTags
import apiclient.groups.Group
import apiclient.groups.KeyWordLayerDefinition
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerMetaDataUpdate
import apiclient.groups.keywordLayerDefinitionsMap
import apiclient.groups.restCreateKeywordLayer
import apiclient.groups.restDeleteLayer
import apiclient.groups.restUpdateOrCreateKeywordLayer
import apiclient.groups.restUpdatelayerSetting
import apiclient.tags.addMultivaluedTag
import apiclient.tags.init
import auth.ApiUserStore
import auth.WorkspacesStore
import data.objects.CurrentActiveFieldValueStore
import dev.fritz2.core.Id
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import overlays.ApiNullResult
import overlays.BusyStore
import services.SuggestedTagsContextStore
import theme.FormationColors
import utils.graphqlScope

class ActiveKeywordLayerDefinitionStore : RootStore<KeyWordLayerDefinition>(
    initialData = emptyKeyWordLayerDefinition,
    job = Job(),
) {

    private val routerStore: RouterStore by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val workspacesStore: WorkspacesStore by koinCtx.inject()
    private val mapLayerTypeSwitchStore: MapLayerTypeSwitchStore by koinCtx.inject()
    private val activeKeywordLayerMetaDataStore: ActiveKeywordLayerMetaDataStore by koinCtx.inject()
    private val formationClient: FormationClient by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()
    private val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()
    private val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()

    private var initialState = emptyKeyWordLayerDefinition

    companion object {
        val emptyKeyWordLayerDefinition = KeyWordLayerDefinition(
            layerId = "new layer",
            keywords = emptyList(),
            fieldValues = emptyList()
        )
    }

    val addLayerKeywordFromInput = handle { current ->
        val prefix = suggestedTagsContextStore.current.prefix
        if (prefix.isNotBlank()) {
            suggestedTagsContextStore.resetPrefix()
            current.copy(keywords = (current.keywords + prefix).distinct())
        } else current
    }

    val addKeyWord = handle<String> { current, keyword ->
        current.copy(keywords = (current.keywords + keyword).distinct())
    }

    val removeKeyWord = handle<String> { current, keyword ->
        if (current.keywords.contains(keyword)) {
            current.copy(keywords = (current.keywords - keyword).distinct())
        } else current
    }

    val addFieldValue = handle<FieldValue?> { current, newFieldValue ->
        console.log("Add Field value to keyword layer", newFieldValue?.fieldValueTag)
        val currentFieldValues = current.fieldValues.getFieldValueList()
        if (newFieldValue != null && !currentFieldValues.map { it.field }.contains(newFieldValue.field)) {
            current.copy(fieldValues = (current.fieldValues ?: listOf()) + listOfNotNull(newFieldValue.fieldValueTag))
        } else current
    }

    val removeFieldValue = handle<FieldValue?> { current, newFieldValue ->
        val currentFieldValues = current.fieldValues.getFieldValueList()
        if (newFieldValue != null && currentFieldValues.map { it.field }.contains(newFieldValue.field)) {
            current.copy(fieldValues = ((currentFieldValues - newFieldValue).distinct()).tagValues())
        } else current
    }

    val addOrChangeFieldValue = handle { current ->
        val currentFieldValues = current.fieldValues.getFieldValueList()
        val activeFieldValue = currentActiveFieldValueStore.current
        console.log("AddOrChange Field value to keyword layer:", activeFieldValue?.fieldValueTag)
        if (activeFieldValue != null) {
            if (currentFieldValues.map { it.field }.contains(activeFieldValue.field)) {
                val newFieldValues = currentFieldValues.map { fieldValue ->
                    if (fieldValue.field == activeFieldValue.field) {
                        when {
                            fieldValue is FieldValue.CategoryValue
                                    && activeFieldValue is FieldValue.CategoryValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.DoubleValue
                                    && activeFieldValue is FieldValue.DoubleValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.EmptyValue
                                    && activeFieldValue is FieldValue.EmptyValue -> {
                                fieldValue
                            }

                            fieldValue is FieldValue.InstantValue
                                    && activeFieldValue is FieldValue.InstantValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.LongValue
                                    && activeFieldValue is FieldValue.LongValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            fieldValue is FieldValue.StringValue
                                    && activeFieldValue is FieldValue.StringValue -> {
                                fieldValue.copy(value = activeFieldValue.value)
                            }

                            else -> fieldValue
                        }
                    } else fieldValue
                }.distinct()
                current.copy(fieldValues = newFieldValues.tagValues())
            } else current.copy(fieldValues = (currentFieldValues + activeFieldValue).tagValues())
        } else current
    }

    val createKeyWordLayer = handle { current ->
        val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId ?: ""
        val currentTitle = activeKeywordLayerMetaDataStore.current.title
        val layerId = "${currentTitle?.substring(0, 6)}-${Id.next(9)}"

        val newKeyWordLayer = KeyWordLayerDefinition(
            layerId = layerId,
            keywords = current.keywords,
            or = current.or,
            excludeKeywords = current.excludeKeywords,
            fieldValues = current.fieldValues,
            excludeFieldValues = current.excludeFieldValues,
            customTags = current.customTags,
            excludeCustomTags = current.excludeCustomTags
        )
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.restCreateKeywordLayer(
                    groupId = groupId,
                    keyWordLayerDefinition = newKeyWordLayer,
                    metaData = with(activeKeywordLayerMetaDataStore.current) {
                        LayerMetaDataUpdate(
                            title = title,
                            defaultIconCategory = defaultIconCategory,
                            defaultColor = defaultColor,
                            defaultShape = defaultShape,
                            defaultOn = defaultOn,
                            layerVisibility = layerVisibility,
                            layerCacheSettings = layerCacheSettings
                        )
                    })
            },
            successMessage = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_CREATED, mapOf(
                "layerName" to (currentTitle ?: "")
            )],
            processResult = { group ->
                console.log("Layer $layerId successfully created.")
                console.log("Layer:", currentTitle, newKeyWordLayer)
                console.log("Updated Group:", group.name, group)
                workspacesStore.updateWorkspaces(listOf(group))
                reset()
            },
            errorMessage = translation[TL.AlertNotifications.MAP_LAYER_CREATION_FAILED, mapOf(
                "layerName" to (currentTitle ?: "")
            )],
        )
        current
    }

    val updateKeyWordLayer = handle { current ->
        if (current.layerId.isNotBlank()) {
            val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId
            val currentMetaData = activeKeywordLayerMetaDataStore.current

            groupId?.let { gId ->
                busyStore.handleApiCall(
                    supplier = suspend {
                        if (hasChanged()) {
                            formationClient.restUpdateOrCreateKeywordLayer(
                                groupId = groupId,
                                keyWordLayerDefinition = current
                            )
                        } else {
                            console.log("keywordLayerDefinition has not changed, nothing to update.")
                            null
                        }
                    },
                    // do not show success message here, it gets triggered from 2nd (nested) api call for metaDataUpdate
                    successMessage = null,
                    processResult = { groupOne ->
                        metaDataUpdateApiCall(
                            groupId = gId,
                            keywordLayerDefinition = current,
                            layerMetaData = currentMetaData,
                            nullResultMessage = ApiNullResult.IsSuccess,
                            processNullResult = {
                                keywordLayerSuccess(current, groupOne)
                            }
                        )
                    },
                    processNullResult = {
                        // heatmapLayerDefinition has not changed
                        metaDataUpdateApiCall(
                            groupId = gId,
                            keywordLayerDefinition = current,
                            layerMetaData = currentMetaData,
                            nullResultMessage = ApiNullResult.IsSuccess,
                        )
                    },
                    errorMessage = translation[TL.AlertNotifications.MAP_LAYER_UPDATE_FAILED, mapOf(
                        "layerName" to (currentMetaData.title ?: "")
                    )],
                )
            }
        }
        current
    }

    private suspend fun metaDataUpdateApiCall(
        groupId: String,
        keywordLayerDefinition: KeyWordLayerDefinition,
        layerMetaData: LayerMetaData,
        nullResultMessage: ApiNullResult,
        processNullResult: suspend () -> Unit = {}
    ) {
        busyStore.handleApiCall(
            supplier = suspend {
                if (activeKeywordLayerMetaDataStore.hasChanged()) {
                    formationClient.restUpdatelayerSetting(
                        groupId = groupId,
                        layerId = current.layerId,
                        update = with(layerMetaData) {
                            LayerMetaDataUpdate(
                                title = title,
                                defaultIconCategory = defaultIconCategory,
                                defaultColor = defaultColor,
                                defaultShape = defaultShape,
                                defaultOn = defaultOn,
                                layerVisibility = layerVisibility,
                                layerCacheSettings = layerCacheSettings
                            )
                        }
                    )
                } else {
                    console.log("keywordLayerMetaData has not changed, nothing to update.")
                    null
                }
            },
            successMessage = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_UPDATED, mapOf(
                "layerName" to (layerMetaData.title ?: "")
            )],
            processResult = { group ->
                keywordLayerSuccess(keywordLayerDefinition, group)
            },
            apiNullResultMessage = nullResultMessage,
            processNullResult = processNullResult,
            errorMessage = translation[TL.AlertNotifications.MAP_LAYER_UPDATE_FAILED, mapOf(
                "layerName" to (layerMetaData.title ?: "")
            )],
        )
    }

    private fun keywordLayerSuccess(
        current: KeyWordLayerDefinition,
        group: Group
    ) {
        console.log("Layer ${current.layerId} successfully updated.")
        console.log("Updated Group:", group.name, group)
        workspacesStore.updateWorkspaces(listOf(group))
        reset()
    }

    val deleteKeyWordLayer = handle { current ->
        if (current.layerId.isNotBlank()) {
            val groupId = apiUserStore.current.apiUser?.groups?.firstOrNull()?.groupId ?: ""
            val currentTitle = activeKeywordLayerMetaDataStore.current.title

            graphqlScope.launch {
                try {
                    val deleteResult = formationClient.restDeleteLayer(
                        groupId = groupId,
                        layerId = current.layerId
                    )
                    when {
                        deleteResult.isSuccess -> {
                            alertOverlayStore.show(
                                Overlay.NotificationToast(
                                    notificationType = NotificationType.Alert,
                                    durationSeconds = 3,
                                    text = translation[TL.AlertNotifications.MAP_LAYER_SUCCESSFULLY_DELETED, mapOf(
                                        "layerName" to (currentTitle ?: "")
                                    )],
                                    bgColor = FormationColors.GreenActive.color
                                )
                            )
                            console.log("KeywordLayer ${current.layerId} successfully deleted.")
                            routerStore.backXLevels(2)
                            deleteResult.getOrNull()?.let { group ->
                                console.log("Updated Group:", group.name, group)
                                workspacesStore.updateWorkspaces(listOf(group))
                            }
                            reset()
                        }

                        deleteResult.isFailure -> {
                            alertOverlayStore.show(
                                Overlay.NotificationToast(
                                    notificationType = NotificationType.Alert,
                                    durationSeconds = 3,
                                    text = translation[TL.AlertNotifications.MAP_LAYER_DELETION_FAILED, mapOf(
                                        "layerName" to (currentTitle ?: "")
                                    )],
                                    bgColor = FormationColors.RedError.color
                                )
                            )
                            console.warn(deleteResult.exceptionOrNull())
                        }
                    }
                } catch (e: Exception) {
                    console.log("$e - ${e.cause?.message}")
                }
            }
        }
        current
    }

    val updateKeyWordStores = handle<LayerMetaData> { current, layerMetaData ->
        val currentGroup = workspacesStore.current.firstOrNull()
        if (currentGroup != null) {
            activeKeywordLayerMetaDataStore.initialState = layerMetaData
            activeKeywordLayerMetaDataStore.update(layerMetaData)
            mapLayerTypeSwitchStore.update(layerMetaData.layerType)
            val keywordDefinition =
                currentGroup.keywordLayerDefinitionsMap[layerMetaData.id] ?: emptyKeyWordLayerDefinition
            initialState = keywordDefinition
            keywordDefinition
        } else current
    }

    val resetKeyWordStores = handle {
        activeKeywordLayerMetaDataStore.reset()
        current
    }

    val reset = handle {
        resetKeyWordStores()
        initialState = emptyKeyWordLayerDefinition
        emptyKeyWordLayerDefinition
    }

    private fun hasChanged(): Boolean {
        return current != initialState
    }

}

fun List<String>?.getFieldValueList(): List<FieldValue> {
    val workspacesStore by koinCtx.inject<WorkspacesStore>()
    val currentGroup = workspacesStore.current.firstOrNull()
    return if (currentGroup != null) {
        val tagList = init().addMultivaluedTag(ObjectTags.CustomField, this ?: listOf())
        tagList.parseFieldValues(currentGroup.fieldDefinitions ?: listOf())
    } else listOf()
}
