package location

import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import maplibreGL.MaplibreMap
import model.LocationFollowState
import model.NotificationType
import model.Overlay
import overlays.AlertOverlayStore
import services.GeoPositionService
import theme.FormationColors
import utils.graphqlScope

class LocationFollowStore : RootStore<LocationFollowState>(
    initialData = LocationFollowState(),
    job = Job(),
) {

    private val geoPositionService: GeoPositionService by koinCtx.inject()
    private val maplibreMap: MaplibreMap by koinCtx.inject()
    private val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val translation: Translation by koinCtx.inject()

    val startFollow = handle { current ->
        console.log("Start follow me mode")
        if (!current.isActive) {
            maplibreMap.on(type = "dragstart", fn = ::toggleFlip, fnId = "toggleFollow")
            val watchId = geoPositionService.getActiveWatchIdOrNew()

            val backgroundLoop = graphqlScope.launch(CoroutineName("tracking-followMe-loop")) {
                while (true) {
                    geoPositionService.locateMe()
                    delay(500)
                }
            }
            alertOverlayStore.show(
                Overlay.NotificationToast(
                    notificationType = NotificationType.Alert,
                    durationSeconds = 3,
                    text = translation[TL.AlertNotifications.LOCATION_FOLLOW_ON],
                    bgColor = FormationColors.MarkerDevices.color,
                ),
            )
//            console.log("current: ${current.copy(enabled = true, backgroundLoop = backgroundLoop, positionWatchJobId = watchId)}")
            current.copy(
                backgroundLoop = backgroundLoop,
                positionWatchJobId = watchId,
            )
        } else {
            console.log("Follow me mode was already enabled.")
            current
        }
    }

    val stopFollow = handle { current ->
        console.log("Stop follow me mode")
        if (current.backgroundLoop != null) {
            maplibreMap.off(type = "dragstart", fnId = "toggleFollow")
            current.positionWatchJobId?.let {

//                Geolocation.clearWatch(it)
//                console.log("stopped position watching (#$it)")

                alertOverlayStore.show(
                    Overlay.NotificationToast(
                        notificationType = NotificationType.Alert,
                        durationSeconds = 3,
                        text = translation[TL.AlertNotifications.LOCATION_FOLLOW_OFF],
                        bgColor = FormationColors.GrayPrivate.color,
                    ),
                )
            }
            current.backgroundLoop.cancel()
            current.copy(
                backgroundLoop = null,
                positionWatchJobId = null,
            )
        } else {
            console.log("Follow me mode was already disabled.")
            current
        }
    }

    val flip = handle { current ->
        val newSwitchState = current.backgroundLoop == null
        console.log("\"follow me\" switch state -> $newSwitchState")
        if (newSwitchState) {
            startFollow()
        } else {
            stopFollow()
        }
        current
    }

    private fun toggleFlip() {
        flip()
    }

//    val startLocationLock = handle { current ->
//        console.log("Start location lock mode")
//        if (!current.lockIsActive) {
//            geoPositionService.getPosition()
//
//            val locationLockBackgroundLoop = graphqlScope.launch(CoroutineName("location-lock-loop")) {
//                while(true) {
//                        geoPositionService.locateMe()
//                        delay(500)
//                }
//            }
//            current.positionWatchJobId?.let {
//                Geolocation.clearWatch(it)
//                console.log("stopped position watching (#$it)")
//                alertOverlayStore.show(
//                    Overlay.NotificationToast(notificationType = NotificationType.Alert,
//                        durationSeconds = 3,
//
//                            type = NotificationType.Alert,
//                            text = translation[TL.AlertNotifications.LOCATION_FOLLOW_OFF],
//                            bgColor = FormationColors.GrayPrivate.c
//                        )
//                    )
//                )
//            }
//            current.backgroundLoop?.cancel()
//            current.copy(
//                backgroundLoop = null,
//                positionWatchJobId = null,
//                locationLockBackgroundLoop = locationLockBackgroundLoop,
//            )
//        } else {
//            console.log("Location lock mode was already enabled.")
//            current
//        }
//
//    }
//
//    val stopLocationLock = handle { current ->
//        console.log("Stop location lock mode")
//        if (current.lockIsActive) {
//            current.locationLockBackgroundLoop?.cancel()
//            current.copy(
//                locationLockBackgroundLoop = null,
//            )
//        } else {
//            console.log("Location lock mode was already disabled.")
//            current
//        }
//    }
}
