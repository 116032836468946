package layercache

import apiclient.FormationClient
import apiclient.groups.LayerStatus
import apiclient.groups.LayerType
import apiclient.groups.LayerVisibility
import apiclient.groups.getGroup
import apiclient.users.restGetMapLayerSettings
import apiclient.markers.SearchLayers
import apiclient.markers.allTags
import apiclient.markers.searchContextFactory
fun getSearchLayerClients(): Map<String, LayerCache> {
    return SearchLayers.entries
        .map {
            LayerCache(
                it.mapLayerMetaData,
                it.searchContext,
                runtimeExcludeTags = null,
            )
        }
        .associateBy { it.layerId }
}


suspend fun getLayerClients(
    client: FormationClient,
    userId: String,
    groupIds: List<String>,
    debug: Boolean,
    testing: Boolean = false,
): List<LayerCache> {

    val group = groupIds.first().let {
        client.getGroup(it).getOrNull()
    } ?: error("we should have at least one group")

    val userSettingsByLayerId =
        if (userId != "anonymous") {
            (client.restGetMapLayerSettings(userId).getOrNull() ?: listOf())
                .let { layerSettingsList ->
                    layerSettingsList.associateBy { l -> l.layerId }
                }
        } else {
            mapOf()
        }

    val excludeKeywords = group.keywordLayerDefinitions?.flatMap { it.allTags }

    return group.layerDefaultSettings
        ?.filter { it.layerType != LayerType.Heatmap }
        ?.filter { layerMetaData ->
            // only create LayerCaches for layers that are on
            when (layerMetaData.layerVisibility) {
                // group API would not pass on admin only layers for non admins
                LayerVisibility.Disabled -> false
                else -> userSettingsByLayerId[layerMetaData.id]?.let {
                    when (it.layerStatus) {
                        LayerStatus.DEFAULT -> layerMetaData.defaultOn
                        LayerStatus.ON -> true
                        LayerStatus.OFF -> false
                    }
                }
            } ?: layerMetaData.defaultOn
        }
        ?.map {
            it to it.searchContextFactory(group)
        }
        ?.mapNotNull { (metaData, contextFactory) ->
            contextFactory?.let {
                LayerCache(
                    layerMetaData = metaData,
                    searchContextFactory = contextFactory,
                    runtimeExcludeTags = if (metaData.layerType == LayerType.Standard) excludeKeywords else null,
                    debug = debug,
                    testing = testing,
                )
            }
        } ?: listOf()
}
