package mainmenu

import com.tryformation.localization.Locale
import data.users.settings.LocalSettingsStore
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.components.compat.div
import koin.koinCtx
import kotlinx.coroutines.Job
import localization.LocaleStore
import localization.Locales
import localization.TL
import localization.Translation
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.oneButtonFooter

class DisclaimerLanguageStore(localeStore: LocaleStore) : RootStore<Locale>(
    initialData = localeStore.current,
    job = Job(),
) {
    private val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()

    private val syncUserPrefs = handle<Locale> { _, locale ->
        syncedUserPreferencesStore.updateLanguage(locale.id)
        locale
    }

    init {
        data handledBy syncUserPrefs
    }
}

fun RenderContext.pageDisclaimer() {

    val translation: Translation by koinCtx.inject()
    val localSettingsStore: LocalSettingsStore by koinCtx.inject()
    val disclaimerLanguageStore: DisclaimerLanguageStore by koinCtx.inject()

    baseLayout(
        expandable = false,
        header = {
            div({
                css("align-self: end;")
                width { maxContent }
            }) { languageSwitch(store = disclaimerLanguageStore, type = LanguageSwitchType.Disclaimer) }
//            mainTitle(translation[TL.PageDisclaimer.PAGE_TITLE])
        },
        content = {
            contentScrollBox {
                disclaimerLanguageStore.data.render { locale ->
                    when (locale) {
                        Locales.EN_GB, Locales.EN_US -> disclaimerTextEN()
                        Locales.DE_DE -> disclaimerTextDE()
                        Locales.ES_ES -> disclaimerTextES()
                        Locales.PT_PT -> disclaimerTextPT()
                        Locales.NL_NL -> disclaimerTextNL()
                        Locales.UA_UA -> disclaimerTextUA()
                        else -> disclaimerTextEN()
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.Cookie.ACCEPT],
                value = Unit,
                clickHandlers = listOf(localSettingsStore.readDisclaimer),
            )
        }
    )
}

fun RenderContext.disclaimerTextEN() {
    textPartTitle("DISCLAIMER") {
        textParagraphs {
            p {
                +"Last updated March 15, 2022"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"The information provided by FORMATION GmbH (“we,” “us”, or “our”) on our mobile application is for general informational purposes only. All information on our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON OUR MOBILE APPLICATION. YOUR USE OF OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK."
            }
        }
    }
    textPart("EXTERNAL LINKS DISCLAIMER") {
        textParagraphs {
            p {
                +"Our mobile application may contain (or you may be sent through our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES."
            }
        }
    }
}

fun RenderContext.disclaimerTextDE() {
    textPartTitle("HAFTUNGSAUSSCHLUSS") {
        textParagraphs {
            p {
                +"Stand März 15, 2022"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"Die von der FORMATION GmbH Workplace Technologies auf unserer App bereitgestellten Informationen dienen ausschließlich allgemeinen Informationszwecken. Alle Informationen auf unserer App werden nach bestem Wissen und Gewissen zur Verfügung gestellt, jedoch geben wir keinerlei ausdrückliche oder stillschweigende Zusicherungen oder Garantien hinsichtlich der Genauigkeit, Angemessenheit, Gültigkeit, Zuverlässigkeit, Verfügbarkeit oder Vollständigkeit der Informationen auf unserer mobilen Anwendung. UNTER KEINEN UMSTÄNDEN HAFTEN WIR IHNEN GEGENÜBER FÜR VERLUSTE ODER SCHÄDEN JEGLICHER ART, DIE DURCH DIE NUTZUNG UNSERER APP ODER DURCH DAS VERTRAUEN AUF INFORMATIONEN, DIE IN UNSERER APP BEREITGESTELLT WERDEN, ENTSTEHEN. DIE NUTZUNG UNSERER APP UND DAS VERTRAUEN AUF DIE INFORMATIONEN UNSERER APP ERFOLGT AUSSCHLIESSLICH AUF IHR EIGENES RISIKO."
            }
        }
    }
    textPart(title = "HAFTUNGSAUSSCHLUSS FÜR EXTERNE LINKS") {
        textParagraphs {
            p {
                +"Unsere App kann Links zu anderen Websites oder Inhalten, die Dritten gehören oder von ihnen stammen, oder Links zu Websites und Funktionen in Bannern oder anderer Werbung enthalten (oder Ihnen über unsere mobile Anwendung zugesandt werden). Solche externen Links werden von uns nicht untersucht, überwacht oder auf Richtigkeit, Angemessenheit, Gültigkeit, Zuverlässigkeit, Verfügbarkeit oder Vollständigkeit geprüft. WIR ÜBERNEHMEN KEINE GEWÄHRLEISTUNG, UNTERSTÜTZUNG, GARANTIE ODER VERANTWORTUNG FÜR DIE RICHTIGKEIT ODER ZUVERLÄSSIGKEIT VON INFORMATIONEN, DIE AUF ÜBER DIE APP VERLINKTEN WEBSITES VON DRITTANBIETERN ANGEBOTEN WERDEN, ODER VON WEBSITES ODER FUNKTIONEN, DIE IN BANNERN ODER SONSTIGER WERBUNG VERLINKT SIND. WIR SIND WEDER EINE PARTEI NOCH IN IRGENDEINER WEISE VERANTWORTLICH FÜR DIE ÜBERWACHUNG VON TRANSAKTIONEN ZWISCHEN IHNEN UND DRITTANBIETERN VON PRODUKTEN ODER DIENSTLEISTUNGEN."
            }
        }
    }
}

fun RenderContext.disclaimerTextES() {
    textPartTitle("DESCARGO DE RESPONSABILIDAD") {
        textParagraphs {
            p {
                +"Última actualización: 15 de marzo de 2022"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"La información proporcionada por FORMATION GmbH (\"nosotros\", \"nos\" o \"nuestro\") en nuestra aplicación móvil tiene únicamente fines informativos generales. Toda la información en nuestra aplicación móvil se proporciona de buena fe, sin embargo no hacemos ninguna representación o garantía de ningún tipo, expresa o implícita, con respecto a la exactitud, adecuación, validez, fiabilidad, disponibilidad o integridad de cualquier información en nuestra aplicación móvil. BAJO NINGUNA CIRCUNSTANCIA SEREMOS RESPONSABLES ANTE USTED POR CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO INCURRIDO COMO RESULTADO DEL USO DE NUESTRA APLICACIÓN MÓVIL O DE LA CONFIANZA EN CUALQUIER INFORMACIÓN PROPORCIONADA EN NUESTRA APLICACIÓN MÓVIL. EL USO QUE USTED HAGA DE NUESTRA APLICACIÓN MÓVIL Y LA CONFIANZA QUE DEPOSITE EN CUALQUIER INFORMACIÓN DE NUESTRA APLICACIÓN MÓVIL ES EXCLUSIVAMENTE POR SU CUENTA Y RIESGO."
            }
        }
    }
    textPart("AVISO LEGAL DE ENLACES EXTERNOS") {
        textParagraphs {
            p {
                +"Nuestra aplicación móvil puede contener (o se le puede enviar a través de nuestra aplicación móvil) enlaces a otros sitios web o contenidos pertenecientes u originados por terceros o enlaces a sitios web y funciones en banners u otros anuncios. Dichos enlaces externos no son investigados, supervisados o comprobados por nosotros en cuanto a su exactitud, adecuación, validez, fiabilidad, disponibilidad o integridad. NO GARANTIZAMOS NI AVALAMOS LA EXACTITUD O FIABILIDAD DE LA INFORMACIÓN OFRECIDA POR LOS SITIOS WEB DE TERCEROS ENLAZADOS A TRAVÉS DEL SITIO O DE CUALQUIER SITIO WEB O ELEMENTO ENLAZADO EN CUALQUIER BANNER U OTRO TIPO DE PUBLICIDAD. NO SEREMOS PARTE NI NOS RESPONSABILIZAREMOS EN MODO ALGUNO DE SUPERVISAR CUALQUIER TRANSACCIÓN ENTRE USTED Y TERCEROS PROVEEDORES DE PRODUCTOS O SERVICIOS."
            }
        }
    }
}

fun RenderContext.disclaimerTextPT() {
    textPartTitle("DISCLAIMER") {
        textParagraphs {
            p {
                +"Última actualização: 15 de Março de 2022"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"A informação fornecida pela FORMATION GmbH (\"nós\", \"nos\", ou \"nosso\") na nossa aplicação móvel é apenas para fins de informação geral. Todas as informações sobre a nossa aplicação móvel são fornecidas de boa fé, contudo não fazemos qualquer tipo de representação ou garantia, expressa ou implícita, relativamente à exactidão, adequação, validade, fiabilidade, disponibilidade ou completude de qualquer informação sobre a nossa aplicação móvel. SOB NENHUMA CIRCUNSTÂNCIA TEREMOS QUALQUER RESPONSABILIDADE PERANTE O UTILIZADOR POR QUALQUER PERDA OU DANO DE QUALQUER TIPO INCORRIDO COMO RESULTADO DA UTILIZAÇÃO DA NOSSA APLICAÇÃO MÓVEL OU DA CONFIANÇA EM QUALQUER INFORMAÇÃO FORNECIDA NA NOSSA APLICAÇÃO MÓVEL. A SUA UTILIZAÇÃO DA NOSSA APLICAÇÃO MÓVEL E A SUA CONFIANÇA EM QUALQUER INFORMAÇÃO SOBRE A NOSSA APLICAÇÃO MÓVEL É EXCLUSIVAMENTE POR SUA CONTA E RISCO."
            }
        }
    }
    textPart("LINKS EXTERNOS RENÚNCIA DE RESPONSABILIDADE") {
        textParagraphs {
            p {
                +"A nossa aplicação móvel pode conter (ou pode ser enviada através da nossa aplicação móvel) links para outros websites ou conteúdos pertencentes ou originários de terceiros ou links para websites e características em banners ou outra publicidade. Tais ligações externas não são investigadas, monitorizadas, ou verificadas por nós quanto à exactidão, adequação, validade, fiabilidade, disponibilidade ou completude. NÃO GARANTIMOS, ENDOSSAMOS, GARANTIMOS, OU ASSUMIMOS RESPONSABILIDADE PELA EXACTIDÃO OU FIABILIDADE DE QUALQUER INFORMAÇÃO OFERECIDA POR WEBSITES DE TERCEIROS LIGADOS ATRAVÉS DO WEBSITE OU DE QUALQUER WEBSITE OU CARACTERÍSTICA LIGADA EM QUALQUER BANNER OU OUTRA PUBLICIDADE. NÃO SEREMOS PARTE OU DE QUALQUER FORMA RESPONSÁVEIS PELO CONTROLO DE QUALQUER TRANSACÇÃO ENTRE O UTILIZADOR E FORNECEDORES DE PRODUTOS OU SERVIÇOS DE TERCEIROS."
            }
        }
    }
}

fun RenderContext.disclaimerTextNL() {
    textPartTitle("DISCLAIMER") {
        textParagraphs {
            p {
                +"Laatst bijgewerkt op 15 maart 2022"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"De informatie die door FORMATION GmbH (\"wij\", \"ons\", of \"onze\") in onze mobiele toepassing wordt verstrekt, is uitsluitend bedoeld voor algemene informatiedoeleinden. Alle informatie op onze mobiele applicatie wordt te goeder trouw verstrekt, maar wij geven geen verklaringen of garanties van welke aard dan ook, expliciet of impliciet, met betrekking tot de nauwkeurigheid, geschiktheid, geldigheid, betrouwbaarheid, beschikbaarheid of volledigheid van enige informatie op onze mobiele applicatie. ONDER GEEN ENKELE OMSTANDIGHEID ZIJN WIJ AANSPRAKELIJK JEGENS U VOOR ENIG VERLIES OF SCHADE VAN WELKE AARD DAN OOK DIE HET GEVOLG IS VAN HET GEBRUIK VAN ONZE MOBIELE APPLICATIE OF HET VERTROUWEN OP ENIGE INFORMATIE DIE OP ONZE MOBIELE APPLICATIE WORDT VERSTREKT. UW GEBRUIK VAN ONZE MOBIELE APPLICATIE EN UW VERTROUWEN OP ENIGE INFORMATIE OP ONZE MOBIELE APPLICATIE IS UITSLUITEND OP EIGEN RISICO."
            }
        }
    }
    textPart("DISCLAIMER EXTERNE LINKS") {
        textParagraphs {
            p {
                +"Onze mobiele applicatie kan links bevatten (of u kunt via onze mobiele applicatie links toegestuurd krijgen) naar andere websites of content die toebehoort aan of afkomstig is van derden of links naar websites en functies in banners of andere reclame. Dergelijke externe links worden niet door ons onderzocht, bewaakt of gecontroleerd op nauwkeurigheid, geschiktheid, geldigheid, betrouwbaarheid, beschikbaarheid of volledigheid. WIJ GARANDEREN, ONDERSCHRIJVEN, GARANDEREN OF NEMEN GEEN VERANTWOORDELIJKHEID OP ONS VOOR DE NAUWKEURIGHEID OF BETROUWBAARHEID VAN ENIGE INFORMATIE DIE WORDT AANGEBODEN DOOR WEBSITES VAN DERDEN WAARNAAR VIA DE SITE EEN LINK IS OPGENOMEN OF ENIGE WEBSITE OF FUNCTIE DIE IS OPGENOMEN IN EEN BANNER OF ANDERE RECLAME. WIJ ZULLEN GEEN PARTIJ ZIJN BIJ OF OP ENIGERLEI WIJZE VERANTWOORDELIJK ZIJN VOOR HET TOEZICHT OP EEN TRANSACTIE TUSSEN U EN DERDE AANBIEDERS VAN PRODUCTEN OF DIENSTEN."
            }
        }
    }
}

fun RenderContext.disclaimerTextUA() {
    textPartTitle("ВІДМОВА") {
        textParagraphs {
            p {
                +"Востаннє оновлено 15 березня 2022 року"
            }
        }
    }
    textPart {
        textParagraphs {
            p {
                +"Інформація, надана FORMATION GmbH («ми», «нас» або «наш») у нашому мобільному додатку, призначена лише для загальних інформаційних цілей. Вся інформація в нашому мобільному додатку надається добросовісно, однак ми не даємо жодних заяв або гарантій будь-якого роду, явних чи неявних, щодо точності, адекватності, дійсності, надійності, доступності або повноти будь-якої інформації в нашому мобільному додатку. ЗА НІ ЗА НІ ОБСТАВИНИ МИ НЕ НЕСЕМ ВІДПОВІДАЛЬНОСТІ ПЕРЕД ВАМИ ЗА БУДЬ-ЯКІ ВТРАТИ АБО ЗБИТКИ БУДЬ-ЯКОГО ВИДУ, ЩО ВИСТАНЕНИ В РЕЗУЛЬТАТІ ВИКОРИСТАННЯ НАШОГО МОБІЛЬНОГО ДОДАТКУ АБО ВІДПОВІДАННЯ НА БУДЬ-ЯКУ ІНФОРМАЦІЮ, НАДАНУУ НА НАШОМУ МОБІЛЬНОМУ ДОДАТКУ. ВИ ВИКОРИСТОВУЄТЕ НАШИЙ МОБІЛЬНИЙ ДОДАТОК ТА ВАШЕ ПОВІДАЮТЬСЯ НА БУДЬ-ЯКУ ІНФОРМАЦІЮ ПРО НАШИЙ МОБІЛЬНИЙ ДОДАТОК ВИШЕ НА ВАШ ВЛАСНИЙ ризик."
            }
        }
    }
    textPart("ЗОВНІШНІ ПОСИЛАННЯ") {
        textParagraphs {
            p {
                +"Наша мобільна програма може містити (або вам може бути надіслано через наш мобільний додаток) посилання на інші веб-сайти або вміст, що належить або походить від третіх сторін, або посилання на веб-сайти та функції в банерах чи іншій рекламі. Такі зовнішні посилання не досліджуються, відстежуються та не перевіряються на точність, адекватність, валідність, надійність, доступність чи повноту. МИ НЕ ГАРАНТУЄМО, НЕ ГАРАНТУЄМО, НЕ ГАРАНТУЄМО АБО ВІДПОВІДАЛЬНІСТЬ ЗА ТОЧНІСТЬ АБО НАДІЙНІСТЬ БУДЬ-ЯКОЇ ІНФОРМАЦІЇ, ЩО ПРОПОНУЄТЬСЯ СТОРІНІМИ ВЕБ-САЙТАМИ, ЩО ПОСИЛАЮТЬСЯ ЧЕРЕЗ САЙТ АБО БУДЬ-ЯКИЙ ВЕБ-САЙТ, ЩО ЩО ВІДПОВІДАЄ ВІДПОСИЛАННЯ АБО ІНШІ ПОСИЛАННЯ. МИ НЕ БУДЕМО СТОРОНОЮ І НЕ НЕСЕМ ВІДПОВІДАЛЬНОСТІ ЗА МОНІТОРИНГ БУДЬ-ЯКИХ ТРАНЗАКЦІЙ МІЖ ВАМИ ТА ТРЕТІМИ СТОРОННІМИ ПОСТАЧАЛЬНИКАМИ ПРОДУКТІВ ЧИ ПОСЛУГ."
            }
        }
    }
}
