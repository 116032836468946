package websocket

import auth.ApiUserStore
import apiclient.websocket.WebsocketApiClient
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import mainmenu.AppStateStore
import model.AppPhase
import network.NetworkState
import network.NetworkStateStore

enum class WebsocketState {
    INITIALIZED, RUNNING, STOPPED, UNKNOWN
}

class WebsocketService : RootStore<WebsocketState>(
    initialData = WebsocketState.UNKNOWN,
    job = Job(),
) {
    private val websocketApiClient: WebsocketApiClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val networkStateStore: NetworkStateStore by koinCtx.inject()
    private val appStateStore by koinCtx.inject<AppStateStore>()

    val initialize = handle { current ->
        console.log("WebsocketService initialized")
        if (current == WebsocketState.UNKNOWN) {
            WebsocketState.INITIALIZED
        } else current
    }

    suspend fun close() {
        websocketApiClient.closeSession()
        update(WebsocketState.STOPPED)
    }

    suspend fun restartWebSocket() {
        console.log("(re)-starting websocket")
        websocketApiClient.restartSession()
        update(WebsocketState.RUNNING)
    }

    private val refreshWebsocket = handle<Pair<NetworkState?, AppPhase>> { current, data ->
        val (networkState, appPhase) = data
        if (networkState == NetworkState.Online && appPhase == AppPhase.LoggedIn) {
            restartWebSocket()
        } else {
            close()
        }
        current
    }

    init {
        networkStateStore.data.combine(appStateStore.data) { networkState, appState ->
            Pair(networkState, appState.appPhase)
        }.debounce(200) handledBy refreshWebsocket
    }
}
