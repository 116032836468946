package websocket

import apiclient.markers.Marker
import apiclient.markers.MarkerTags
import apiclient.tags.getUniqueTag
import apiclient.tags.setUniqueTag
import apiclient.websocket.MarkersWebsocketModule
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import location.LocationUploadStore

class MarkerClientStore : RootStore<Map<String, Marker>>(
    initialData = emptyMap(),
    job = Job(),
) {
    private val markersWebsocketModule: MarkersWebsocketModule = koinCtx.get()
    private val apiUserStore: ApiUserStore by koinCtx.inject()

    val print = handle<Map<String, Marker>> { current, data ->
        val users = data.filterValues { it is Marker.UserMarker }.map { (it.value as Marker.UserMarker).userId }
        val objects = data.filterValues { it is Marker.ObjectMarker }.map { (it.value as Marker.ObjectMarker).id }
        console.log(
            "WEBSOCKET DATA ->",
            "UserMarkers (${users.size}):",
            users.toString(),
            "ObjectMarkers (${objects.size}):",
            objects.toString(),
        )
        current
    }

    fun isUserSharing(userId: String): Boolean {
        val locationUploadStore: LocationUploadStore by koinCtx.inject()
        return when (userId) {
            apiUserStore.current.userId -> {
                locationUploadStore.current.isActive
            }

            else -> {
                current[userId] is Marker.UserMarker
            }
        }
    }

    val clearOut = handle { markers ->
        if (markers.isNotEmpty()) {
            val clearedOutList = markers.filter { (_, marker) ->
                marker.tags?.getUniqueTag(MarkerTags.LocationLastUpdatedAt)?.let { time ->
                    (Clock.System.now() - Instant.parse(time)) < 10.seconds
                } ?: false
            }
            console.log("Clear out markers older than 40 seconds.")
            clearedOutList
        } else markers
    }

    init {
        markersWebsocketModule.markers.map { state ->
            val me = apiUserStore.current.userId
            // handle users
            state.values.filterIsInstance<Marker.UserMarker>().filter { it.userId != me }.map { marker ->
                marker.copy(
                    tags = (marker.tags ?: emptyList()).setUniqueTag(
                        MarkerTags.LocationLastUpdatedAt,
                        Clock.System.now().toString(),
                    ),
                )
            }.associateBy { value -> value.userId } +
                // handle objects
                state.values.filterIsInstance<Marker.ObjectMarker>().map { marker ->
                    marker.copy(
                        tags = (marker.tags ?: emptyList()).setUniqueTag(
                            MarkerTags.LocationLastUpdatedAt,
                            Clock.System.now().toString(),
                        ),
                    )
                }.associateBy { value -> value.id }
        } handledBy update

        CoroutineScope(CoroutineName("clear-out-markers-older-than-40-secs")).launch {
            while (true) {
                delay(30.seconds)
                clearOut(Unit)
            }
        }
    }
}
