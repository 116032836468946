package map.views

import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import auth.WorkspacesStore
import apiclient.groups.LayerMetaData
import apiclient.groups.LayerType
import apiclient.groups.heatmapLayersDefinitionsMap
import apiclient.markers.DefaultLayers
import apiclient.markers.SearchLayers
import apiclient.validations.parseEnumValue
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import map.Cards
import map.MapLayerUserSettingsStore
import model.isInternalAdminGroupAdminOrGroupOwner
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationUIIcons
import utils.getIcon
import webcomponents.cardSubtitle
import webcomponents.circleIconButton
import webcomponents.genericButton
import webcomponents.genericSwitch
import webcomponents.mapLayerButton

fun RenderContext.mapLayerList(layerMetaDataFlow: Flow<List<LayerMetaData>>) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val canEdit = apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()
//    val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    val mapLayerUserSettingsStore: MapLayerUserSettingsStore by koinCtx.inject()
    val activeKeywordLayerDefinitionStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    val activeHeatmapLayerDefinitionStore: ActiveHeatmapLayerDefinitionStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val workspacesStore: WorkspacesStore by koinCtx.inject()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()

    layerMetaDataFlow.render { layerMetaDataList ->
        featureFlagStore.data.render { features ->

            stackUp(
                {
                    width { full }
                    height { maxContent }
                    justifyContent { center }
                    alignItems { stretch }
                },
            ) {
                spacing { small }
                items {
                    layerMetaDataList.forEach { layerMetaData ->
                        if ((layerMetaData.layerType == LayerType.Heatmap && features[Features.AllowHeatMap] == true)
                            || layerMetaData.layerType != LayerType.Heatmap
                        ) {
                            mapLayerButton {
                                mapLayerUserSettingsStore.watchState(layerMetaData.id).render { layerState ->
                                    flexBox(
                                        {
                                            width { full }
                                            justifyContent { start }
                                            alignItems { center }
                                            margin { tiny }
                                            color {
                                                if (layerState) primary.main else FormationColors.GrayDisabled.color
                                            }
                                            fontSize { small }
                                            overflowX { hidden }
                                        },
                                    ) {
                                        // SWITCH
                                        genericSwitch(switchState = mapLayerUserSettingsStore.watchState(layerMetaData.id))
                                        // LAYER TITLE
                                        flexBox(
                                            {
                                                width { full }
                                                margins { left { smaller } }
                                                alignItems { center }
                                                justifyContent { spaceBetween }
                                                overflowX { hidden }
                                            },
                                        ) {
                                            cardSubtitle(
                                                title = if (layerMetaData.translatable) {
                                                    parseEnumValue<DefaultLayers>(layerMetaData.id)?.let { translation[it] }
                                                        ?: parseEnumValue<SearchLayers>(layerMetaData.id)?.let { translation[it] }
                                                        ?: flowOf("untitled (${layerMetaData.id})")
                                                } else if (layerMetaData.layerType == LayerType.Heatmap) {
                                                    workspacesStore.current.firstOrNull()?.heatmapLayersDefinitionsMap?.get(layerMetaData.id)
                                                        .takeIf { !it?.title.isNullOrBlank() }
                                                        ?.let {
                                                            flowOf(it.title)
                                                        } ?: flowOf(layerMetaData.title ?: layerMetaData.id)
                                                } else {
                                                    flowOf(layerMetaData.title ?: layerMetaData.id)
                                                },
                                                ellipses = true,
                                                iconFlow = flowOf(layerMetaData.defaultIconCategory.getIcon().icon),
                                            )
                                            if (apiUserStore.current.isInternalAdminGroupAdminOrGroupOwner()
                                                && (layerMetaData.layerType == LayerType.Keyword || layerMetaData.layerType == LayerType.Heatmap)
                                            ) {
                                                icon { fromTheme { if (layerMetaData.defaultOn) eye else eyeOff } }
                                            }
                                        }
                                        clicks.map { layerMetaData.id } handledBy mapLayerUserSettingsStore.flipLayer
                                    }
                                    // TODO remove check for layerType, when new version of manageMapLayer card is ready
                                    val editButtonSize = "32px"
                                    if (canEdit && (
                                            layerMetaData.layerType == LayerType.Keyword
                                                || (layerMetaData.layerType == LayerType.Heatmap
                                                && features[Features.DisableHeatmapEditUI] == false)
                                            )
                                    ) {
                                        // EDIT BUTTON
                                        div(
                                            {
                                                width { editButtonSize }
                                                height { editButtonSize }
                                                margin { tiny }
                                            },
                                        ) {
                                            circleIconButton(
                                                iconFlow = flowOf { FormationIcons.Edit.icon },
                                                size = { editButtonSize },
                                                iconSize = { normal },
                                                styleFlow = flowOf {
                                                    border {
                                                        color { if (layerState) primary.main else FormationColors.GrayDisabled.color }
                                                        width(FormationDefault.formationStyles.borderWidth)
                                                    }
                                                    color { if (layerState) primary.main else FormationColors.GrayDisabled.color }
                                                },
                                                hoverStyle = { background { color { FormationColors.GrayLight.color } } },
                                                tooltipText = translation[TL.General.EDIT].map { tooltip ->
                                                    tooltip.lowercase().replaceFirstChar {
                                                        if (it.isLowerCase()) it.titlecase() else it.toString()
                                                    }
                                                },
                                                value = layerMetaData,
                                                clickHandlers = when (layerMetaData.layerType) {
                                                    LayerType.Keyword -> listOf(activeKeywordLayerDefinitionStore.updateKeyWordStores)
                                                    LayerType.Heatmap -> listOf(activeHeatmapLayerDefinitionStore.updateHeatmapStores)
                                                    else -> listOf()
                                                },
                                                routingMap = mapOf(
                                                    "page" to Pages.Map.name,
                                                    "card" to Cards.MapLayers.name,
                                                    "mapLayer" to "edit",
                                                ),
                                            )
                                        }
                                    } else {
                                        div(
                                            {
                                                height { editButtonSize }
                                                margin { tiny }
                                            },
                                        ) { }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.addKeyWordLayerButton() {
    val translation: Translation by koinCtx.inject()

    div(
        {
            margins {
                vertical { small }
            }
        },
    ) {
        genericButton(
            title = translation[TL.CardMapLayers.ADD_LAYER],
            icon = { FormationUIIcons.Add.icon },
            width = { auto },
            styleFlow = flowOf(secondaryButtonStyleParams),
            styleType = { secondary },
            routingMap = mapOf(
                "page" to Pages.Map.name,
                "card" to Cards.MapLayers.name,
                "mapLayer" to "add",
            ),
            attributes = listOf(Pair("title", translation.getString(TL.CardMapLayers.ADD_LAYER_TOOLTIP))),
            value = Unit,
        )
    }
}
