// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package model

import apiclient.geoobjects.Content
import apiclient.users.UserPreferences
import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

fun MyProfile.Companion.firstName(): Lens<MyProfile, String> = lensOf(
    "firstName",
    { it.firstName },
    { p, v -> p.copy(firstName = v) },
)

fun MyProfile.Companion.lastName(): Lens<MyProfile, String> = lensOf(
    "lastName",
    { it.lastName },
    { p, v -> p.copy(lastName = v) },
)

fun MyProfile.Companion.jobTitle(): Lens<MyProfile, String> = lensOf(
    "jobTitle",
    { it.jobTitle },
    { p, v -> p.copy(jobTitle = v) },
)

fun MyProfile.Companion.emails(): Lens<MyProfile, String> = lensOf(
    "emails",
    { it.emails },
    { p, v -> p.copy(emails = v) },
)

fun MyProfile.Companion.password(): Lens<MyProfile, String> = lensOf(
    "password",
    { it.password },
    { p, v -> p.copy(password = v) },
)

fun MyProfile.Companion.phone(): Lens<MyProfile, String> = lensOf(
    "phone",
    { it.phone },
    { p, v -> p.copy(phone = v) },
)

fun MyProfile.Companion.preferences(): Lens<MyProfile, UserPreferences?> = lensOf(
    "preferences",
    { it.preferences },
    { p, v -> p.copy(preferences = v) },
)

//fun MyProfile.Companion.responseStatus(): Lens<MyProfile, StatusResult?> = lensOf(
//    "responseStatus",
//    { it.responseStatus },
//    { p, v -> p.copy(responseStatus = v) }
//)

fun MyProfile.Companion.profilePhoto(): Lens<MyProfile, Content.Image?> = lensOf(
    "profilePhoto",
    { it.profilePhoto },
    { p, v -> p.copy(profilePhoto = v) },
)

fun MyProfile.Companion.keywords(): Lens<MyProfile, List<String>> = lensOf(
    "keywords",
    { it.keywords },
    { p, v -> p.copy(keywords = v) },
)
