package search.nestedObjects

import apiclient.FormationClient
import apiclient.analytics.customFieldClause
import apiclient.geoobjects.BuildingAndFloorTags
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.ObjectVisibility
import apiclient.geoobjects.ReadOnlyTags
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.restSearch
import apiclient.tags.tag
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import data.objects.building.CurrentBuildingsStore
import data.objects.emptyGeoObjectDetails
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.mapNotNull
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import model.L
import model.NestedObjects
import model.SearchPage
import model.isInternalAdminGroupAdminOrGroupOwner
import overlays.BusyStore

class NestedObjectsResultsStore : RootStore<NestedObjects>(
    initialData = NestedObjects(),
    job = Job(),
) {

    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    private val searchPageStore: SearchPageStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()
    private val nestedObjectSearchInputFieldStore: NestedObjectSearchInputFieldStore = koinCtx.get()
    private val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore = koinCtx.get()
    private val activeNestedSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore = koinCtx.get()
    private val activeNestedObjectTypesStore: ActiveNestedObjectTypesStore = koinCtx.get()
    private val activeNestedObjectsReadOnlyKeywordsStore: ActiveNestedObjectsReadOnlyKeywordsStore = koinCtx.get()

    private suspend fun getNestedObjects(
        objectIdIn: String? = null,
        objectTypeIn: ObjectType? = null,
        ctxIn: SearchQueryContext? = null,
        pagedSearch: Boolean = false
    ): Boolean {
        val objectId = objectIdIn ?: current.activeParentObjectId
        val objectType = objectTypeIn ?: current.activeParentObjectType
        val ctx = ctxIn ?: generateCtx(objectIdIn = objectId, objectTypeIn = objectType)

        return if (!objectId.isNullOrBlank()
            && ctx != null
            // TODO review later: restrictions of objectTypes for nested objects search
            && when (objectType) {
                ObjectType.Building,
                ObjectType.Floor,
                ObjectType.Unit,
                ObjectType.Area,
                ObjectType.Zone,
                ObjectType.GeoFence -> true

                else -> false

            }
        ) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restSearch(searchQueryContext = ctx)
                },
                processResult = { res ->
//                    console.log("NESTED SEARCH CTX", ctx)
                    val results = res.hits
//                    console.log("Nested Object results", results.map { it.hit.title }.toString())
                    update(
                        NestedObjects(
                            activeParentObjectId = objectId,
                            activeParentObjectType = objectType,
                            total = res.total,
                            fetched = if (pagedSearch) (current.fetched + results) else results,
                        ),
                    )
                },
                processError = { throwable ->
                    console.log("Nested objects search failed.", throwable)
                },
            )
            true
        } else {
            reset()
            false
        }
    }

    val reset = handle {
        NestedObjects()
    }

    private fun resetCtx() {
        activeNestedSearchKeywordStore.reset()
        activeNestedSearchFieldValuesStore.reset()
        activeNestedObjectTypesStore.reset()
        activeNestedObjectsReadOnlyKeywordsStore.reset()
    }

    private val updateCtxWithObject = handle<GeoObjectDetails> { current, geoObject ->
        resetCtx()
        if (geoObject != emptyGeoObjectDetails) {
            console.log("Get nested objects (by object) -> ${geoObject.title}")
            if (getNestedObjects(objectIdIn = geoObject.id, objectTypeIn = geoObject.objectType)) {
                console.log("Fetched nested objects for ${geoObject.title}.")
            } else {
                console.log("${geoObject.title} can't have nested objects or there are missing requirements to fetch them.")
            }
        } else reset()
        current
    }

    private val updateCtxWithSearchPage = handle<SearchPage> { current, searchPage ->
        console.log("Get nested objects (by search page)")
        getNestedObjects(ctxIn = generateCtx(searchPageIn = searchPage), pagedSearch = true)
        current
    }

    private val searchNestedObjects = handle { current ->
        console.log("Get nested objects (by search filters)")
        getNestedObjects(ctxIn = generateCtx())
        current
    }

    private fun generateCtx(
        objectIdIn: String? = null,
        objectTypeIn: ObjectType? = null,
        searchPageIn: SearchPage? = null
    ): SearchQueryContext? {

        val parentObjectId = objectIdIn ?: current.activeParentObjectId
        val parentObjectType = objectTypeIn ?: current.activeParentObjectType
        val searchPageStore by koinCtx.inject<SearchPageStore>()
        val searchPage = searchPageIn //?: searchPageStore.current
        val currentBuildingsStore: CurrentBuildingsStore by koinCtx.inject()
        val building = currentBuildingsStore.current[parentObjectId]

        val groupIds = apiUserStore.current.apiUser?.groups?.map { it.groupId }
        val isAdmin = apiUserStore.current.apiUser?.isInternalAdminGroupAdminOrGroupOwner() == true
        return if (!groupIds.isNullOrEmpty()
            && parentObjectType != null
            && !parentObjectId.isNullOrBlank()
        ) {
            SearchQueryContext(
                groupIds = groupIds,
                from = searchPage?.from,
                size = searchPage?.size,
                text = nestedObjectSearchInputFieldStore.current,
                // filter out object types we do not want to see
                objectTypes = activeNestedObjectTypesStore.current.ifEmpty {
                    ObjectType.entries.filter { objType ->
                        when (objType) {
                            ObjectType.HistoryEntry,
                            ObjectType.Notification,
                            ObjectType.TransientMarker,
                            ObjectType.CurrentUserMarker,
                            ObjectType.Floor,
                            ObjectType.Unit -> false

                            else -> true
                        }
                    }
                },
                tags = when (parentObjectType) {
                    ObjectType.Building -> activeNestedSearchKeywordStore.current.map { keyword ->
                        ObjectTags.Keyword.tag(keyword)
                    } + activeNestedObjectsReadOnlyKeywordsStore.current.map { keywordTag ->
                        if (!keywordTag.readOnlyStringValue.isNullOrBlank()) {
                            when (keywordTag.readOnlyType) {
                                ReadOnlyTags.Assignee -> ReadOnlyTags.Assignee.tag(keywordTag.readOnlyStringValue)
                                ReadOnlyTags.Creator -> ReadOnlyTags.Creator.tag(keywordTag.readOnlyStringValue)
                                else -> keywordTag.fieldText
                            }
                        } else keywordTag.fieldText
                    }

                    else -> listOf(ObjectTags.ConnectedTo.tag(parentObjectId)) +
                        activeNestedSearchKeywordStore.current.map { keyword ->
                            ObjectTags.Keyword.tag(keyword)
                        } + activeNestedObjectsReadOnlyKeywordsStore.current.map { keywordTag ->
                        if (!keywordTag.readOnlyStringValue.isNullOrBlank()) {
                            when (keywordTag.readOnlyType) {
                                ReadOnlyTags.Assignee -> ReadOnlyTags.Assignee.tag(keywordTag.readOnlyStringValue)
                                ReadOnlyTags.Creator -> ReadOnlyTags.Creator.tag(keywordTag.readOnlyStringValue)
                                else -> keywordTag.fieldText
                            }
                        } else keywordTag.fieldText
                    }
                },
                // FIXME: mapping all current floor and unit ids as orTags as workaround to get all objects connected to the building, think of better solution
                orTags = when (parentObjectType) {
                    ObjectType.Building -> listOf(
                        BuildingAndFloorTags.BuildingId.tag(parentObjectId),
                        ObjectTags.ConnectedTo.tag(parentObjectId),
                    ) + (building?.floorData?.let { floorData ->
                        floorData.flatMap { (_, floors) ->
                            floors.map { BuildingAndFloorTags.FloorId.tag(it.floor.id) }
                        } + floorData.flatMap { (_, floors) ->
                            floors.map { ObjectTags.ConnectedTo.tag(it.floor.id) }
                        } + floorData.flatMap { (_, floors) ->
                            floors.flatMap {
                                (it.units?.map { unit ->
                                    BuildingAndFloorTags.ZoneId.tag(unit.id)
                                }.orEmpty()) + (it.units?.map { unit ->
                                    ObjectTags.ConnectedTo.tag(unit.id)
                                }.orEmpty())
                            }
                        }
                    } ?: emptyList())

                    else -> emptyList()
                },
                excludeTags = listOfNotNull(
                    ObjectTags.Visibility.tag(ObjectVisibility.Hidden),
                    ObjectTags.Archived.tag("true"),
                    if (isAdmin) null else ObjectTags.Flagged.tag("true"),
                ),
                fieldValues = activeNestedSearchFieldValuesStore.current.map { fieldValue ->
                    fieldValue.customFieldClause
                },
            )
        } else null
    }

    init {
        activeObjectStore.map(GeoObjectDetails.L.id).data.mapNotNull {
            if (it != current.activeParentObjectId) {
                activeObjectStore.current
            } else null
        } handledBy updateCtxWithObject
        searchPageStore.data.mapNotNull {
            if (it.from > 0) {
                it
            } else null
        } handledBy updateCtxWithSearchPage

        combine(
            nestedObjectSearchInputFieldStore.data,
            activeNestedSearchKeywordStore.data,
            activeNestedSearchFieldValuesStore.data,
            activeNestedObjectTypesStore.data,
            activeNestedObjectsReadOnlyKeywordsStore.data,
        ) { _, _, _, _, _ ->
            /* do nothing, just trigger */
        } handledBy searchNestedObjects
    }
}
