package map

import auth.ApiUserStore
import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.LayerStatus
import apiclient.groups.MapLayerUserSettings
import apiclient.users.restGetMapLayerSettings
import apiclient.users.restUpdateMapLayerSetting
import apiclient.markers.DefaultLayers
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import model.LayerType
import overlays.BusyStore
import search.searchlayer.MapLayerMetadataListStore
import search.searchlayer.MapSearchClientsStore

class MapLayerUserSettingsStore : RootStore<List<MapLayerUserSettings>>(
    initialData = emptyList(),
    job = Job(),
) {

    val formationClient: FormationClient by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    private val mapLayerMetaDataListStore: MapLayerMetadataListStore by koinCtx.inject()
    private val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    private val mapLayersStore: MapLayersStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()

    val initializeWithWorkspaceDefaults = handle<List<Group>> { _, groups ->
        val layerConfigs = groups.flatMap { group ->
            group.layerDefaultSettings ?: listOf()
        }
        val groupDefaults = layerConfigs.map { layerMetaData ->
            MapLayerUserSettings(
                layerId = layerMetaData.id,
                layerStatus = if (layerMetaData.defaultOn) LayerStatus.ON else LayerStatus.OFF,
            )
        }
        update(groupDefaults)
        if (!apiUserStore.current.isAnonymous) {
            console.log(
                "Initialize store with default MapLayerUserSettings from Group(s) ${groups.map { it.name }}:",
                groupDefaults.map { it.layerId to it.layerStatus }.toString(),
            )
            fetchFromServerAndUpdate()
        }
        console.log("layerIds:", current.map { it.layerId })
        current.ifEmpty { groupDefaults }
    }

    private val fetchFromServerAndUpdate = handle { current ->
        val apiUser = apiUserStore.current
        if (!apiUser.isAnonymous) {
            busyStore.handleApiCall(
                supplier = suspend {
                    // FIXME this only fetches settings that the user already had but not the missing ones from any new standard or keyword layers in the group
                    formationClient.restGetMapLayerSettings(userId = apiUser.userId)
                },
                processResult = { fetchedMapLayerSettings ->
                    console.log(
                        "Fetched user defined MapLayerUserSettings from server:",
                        fetchedMapLayerSettings.map { it.layerId to it.layerStatus }.toString(),
                    )
                    val updatedList = current.map { currentLayerSetting ->
                        if (currentLayerSetting.layerId in fetchedMapLayerSettings.map { it.layerId }) {
                            fetchedMapLayerSettings.firstOrNull { currentLayerSetting.layerId == it.layerId }?.layerStatus?.let {
                                currentLayerSetting.copy(layerStatus = it)
                            } ?: currentLayerSetting
                        } else currentLayerSetting
                    }
                    console.log(
                        "Update store with new list of MapLayerUserSettings:",
                        updatedList.map { it.layerId to it.layerStatus }.toString(),
                    )
                    update(updatedList)
                },
                processError = { e ->
                    console.error("getMapLayerSettings", e)
                },
            )
        }
        current
    }


    val flipLayer = handle<String> { current, layerId ->
        val newLayers = current.map { mapLayerUserSetting ->
            if (mapLayerUserSetting.layerId == layerId) {
                val newStatus = mapLayerUserSetting.flipStatus()
                busyStore.handleApiCall(
                    supplier = suspend {
                        formationClient.restUpdateMapLayerSetting(
                            apiUserStore.current.userId,
                            mapLayerUserSetting.copy(layerStatus = newStatus),
                        )
                    },
                    processResult = {
                        console.log("Switched MapLayerSetting", mapLayerUserSetting.copy(layerStatus = newStatus))
                    },
                    processError = { throwable ->
                        console.log("Switching MapLayerSetting failed", throwable)
                    },
                )
                when (layerId) {
                    DefaultLayers.ObjectMarkers.name -> mapLayersStore.flipLayer(
                        mapOf(
                            LayerType.MarkerClientObjects to newStatus.getBoolStatus(
                                layerId,
                            ),
                        ),
                    )

                    DefaultLayers.UserMarkers.name -> mapLayersStore.flipLayer(
                        mapOf(
                            LayerType.MarkerClientUsers to newStatus.getBoolStatus(
                                layerId,
                            ),
                        ),
                    )
                }
                mapLayerUserSetting.copy(layerStatus = newStatus)
            } else {
                mapLayerUserSetting
            }
        }
        mapSearchClientsStore.updateMapSearchClients(null)
        update(newLayers)
        newLayers
    }

    fun watchState(layerId: String): Flow<Boolean> {
        return data.map { data ->
            data.firstOrNull { it.layerId == layerId }?.getStatus() ?: false
        }
    }

    private fun MapLayerUserSettings.flipStatus() = when (this.layerStatus) {
        LayerStatus.ON -> LayerStatus.OFF
        LayerStatus.OFF -> LayerStatus.ON
        LayerStatus.DEFAULT -> {
            if (mapLayerMetaDataListStore.getDefaultState(this.layerId)) LayerStatus.OFF
            else LayerStatus.ON
        }

        else -> this.layerStatus
    }

}

fun LayerStatus.getBoolStatus(layerId: String): Boolean {
    val mapLayerMetadataListStore by koinCtx.inject<MapLayerMetadataListStore>()
    return when (this) {
        LayerStatus.ON -> true
        LayerStatus.OFF -> false
        LayerStatus.DEFAULT -> mapLayerMetadataListStore.getDefaultState(layerId)
        else -> false
    }
}

fun MapLayerUserSettings.getStatus() = layerStatus.getBoolStatus(layerId = layerId)
