package camera.nimiq

import camera.nfc.getIdFromScanContent
import camera.nfc.handleScanResult
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import localization.TL
import localization.Translation
import model.CodeTech
import model.ScanPurpose
import org.w3c.dom.HTMLVideoElement
import qrcode.ScannedCodeStore
import twcomponents.twRowOfJustifyBetween

class QRScanModalStore : RootStore<Boolean>(false, Job())

fun RenderContext.qrScanCamera(
    closeModal: SimpleHandler<Unit>,
    modalOpened: Flow<Boolean>,
    videoElement: HTMLVideoElement?,
    scanPurpose: ScanPurpose = ScanPurpose.OpenCode,
    codeStore: Store<String> = storeOf("")
) {

    val translation: Translation by koinCtx.inject()
    val browserCamera: BrowserCamera by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()

    scannedCodeStore.updateType(CodeTech.QR)

    val openCodeScanResultHandler = { code: QrScanner.ScanResult ->
        if (code.data.isNotBlank()) {
            browserCamera.stop(Unit)
            browserCamera.destroy()
            console.log("QR code scanned via browser webcam '${code}', -> open it")
            codeStore.update(code.data)
            handleScanResult(
                scanContent = code.data,
                fallback = code.data,
                type = CodeTech.QR,
                scanPurpose = ScanPurpose.OpenCode,
            )
            closeModal(Unit)
        }
    }

    val connectCodeScanResultHandler = { code: QrScanner.ScanResult ->
        if (code.data.isNotBlank()) {
            browserCamera.stop(Unit)
            browserCamera.destroy()
            console.log("QR code scanned via browser webcam '${code}', -> connect it")
            codeStore.update(code.data)
            handleScanResult(
                scanContent = code.data,
                fallback = code.data,
                type = CodeTech.QR,
                scanPurpose = ScanPurpose.ConnectQRToObject,
            )
            closeModal(Unit)
        }
    }

    val readCodeFromScanResultHandler = { scanResult: QrScanner.ScanResult ->
        val code = getIdFromScanContent(scanResult.data)
        if (!code.isNullOrBlank()) {
            codeStore.update(code)
            browserCamera.stop(Unit)
            browserCamera.destroy()
            console.log("QR code scanned via browser webcam '${code}', -> open it")
            closeModal(Unit)
        }
    }

    modalOpened handledBy { opened ->
        console.log("Modal open:", opened)
        if (opened) {
            if (videoElement != null) {
                when (scanPurpose) {
                    ScanPurpose.OpenCode -> {
                        console.log("Insert OpenCode camera:")
                        browserCamera.insertQRScanCamera(
                            videoElement,
                            resultHandler = openCodeScanResultHandler,
                        )
                    }

                    ScanPurpose.ConnectQRToObject -> {
                        console.log("Insert ConnectQRToObject camera:")
                        browserCamera.insertQRScanCamera(
                            videoElement,
                            resultHandler = connectCodeScanResultHandler,
                        )
                    }

                    ScanPurpose.ReadCodeAndStore -> {
                        console.log("Insert OpenCode camera:")
                        browserCamera.insertQRScanCamera(
                            videoElement,
                            resultHandler = readCodeFromScanResultHandler,
                        )
                    }

                    else -> console.log("Do not know what to do when ScanPurpose is ${scanPurpose.name}")
                }
            } else {
                console.log("VideoElement is null!")
            }
        } else {
            browserCamera.stop(Unit)
            browserCamera.destroy()
        }
    }

    p {
        translation[TL.CodeScanning.QR_SCANNER_PROMPT].renderText(into = this)
    }
    twRowOfJustifyBetween {
        cameraSelect()
        cameraButtons()
    }
}
