@file:Suppress("unused")

package data.objects.views

import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.ObjectType
import com.tryformation.localization.LocalizedTranslationBundleSequence
import com.tryformation.localization.Translatable
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import localization.TranslationStore
import mainmenu.RouterStore
import org.koin.dsl.module
import search.BoolQuery
import search.Document
import search.DocumentIndex
import search.MatchQuery
import search.TextFieldIndex
import search.filterButtonOffStyle
import search.filterButtonOnStyle
import search.search
import search.separationLine
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationUIIcons
import utils.getColorForIcon
import utils.getIcon
import utils.getIconGroup
import utils.getShape
import utils.isBrightColor
import utils.isDarkColor
import webcomponents.baseLayout
import webcomponents.genericInput
import webcomponents.inputIconButton
import webcomponents.inputLabelWrapper
import webcomponents.twoButtonFooter

fun cardCustomizationKoinModule() = module {
    single { PointStyleToolSelectorStore() }
    single { ColorSelectionStore() }
    single { IconSelectionStore() }
    single { ShapeSelectionStore() }
}

val MarkerIcon.aliasMessageId get() = messageId.replace("icons-", "icons-aliases-")

class IconIndexStore : RootStore<DocumentIndex>(
    initialData = DocumentIndex(mapping),
    job = Job(),
) {
    companion object {
        val mapping = mutableMapOf(
            "name" to TextFieldIndex(),
        )
    }

    val translationStore by koinCtx.inject<TranslationStore>()

    val updateTranslationBundleSequence = handle<LocalizedTranslationBundleSequence> { documentIndex, a ->
        coroutineScope {
            CoroutineScope(CoroutineName("icon-indexer")).launch {
                DocumentIndex(mapping).also { index ->
                    MarkerIcon.entries.forEach { icon ->
                        try {
                            val translations = a.bundles.mapNotNull { b ->
                                runCatching { b.translate(icon.messageId) }.getOrNull()
                            }
                            val aliases = a.bundles.flatMap { b ->
                                runCatching { b.translate(icon.aliasMessageId)?.split(';') ?: emptyList() }.getOrNull()
                                    ?: emptyList()
                            }

                            index.index(
                                Document(
                                    icon.name,
                                    mapOf(
                                        "id" to listOf(icon.name),
                                        "name" to translations,
                                        "aliases" to aliases,
                                    ),
                                ),
                            )
                        } catch (e: Exception) {
                            console.log(e, "Error indexing icon $icon")
                        }
                    }
                }.let {
                    update(it)
                }
            }
        }
        documentIndex
    }

    fun match(input: String): List<MarkerIcon> {
//        console.log("searching icons '$input' ${current.documents.size}")
        return if (input.isBlank()) {
            MarkerIcon.entries.toList()
        } else {
            current.search {
                query = BoolQuery(
                    should = listOf(
                        MatchQuery("id", input, boost = 3.0, prefixMatch = true),
                        MatchQuery("name", input, boost = 2.0, prefixMatch = true),
                        MatchQuery("aliases", input, boost = 1.0, prefixMatch = true),
                    ),
                )
            }.map { (icon, _) -> MarkerIcon.valueOf(icon) }
        }
    }

    init {
        translationStore.data handledBy updateTranslationBundleSequence
    }
}

fun RenderContext.cardCustomizeBadge() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val pointStyleToolSelectorStore: PointStyleToolSelectorStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    val filterInputStore = storeOf("")
    val iconIndexStore by koinCtx.inject<IconIndexStore>()
    baseLayout(
        header = {
            pointStyleToolBar()
        },
        content = {
            div(
                {
                    height { full }
                    width { full }
                    overflow { auto }
                },
            ) {
                pointStyleToolSelectorStore.data.render { tool ->
                    stackUp(
                        {
                            width { full }
                            justifyContent { spaceBetween }
                            alignItems { center }
                        },
                    ) {
                        spacing { smaller }
                        items {
                            if (tool == PointStyleTool.Icon) {
                                flexBox(
                                    {
                                        width { full }
                                        alignItems { center }
                                        paddings { horizontal { small } }
                                    },
                                ) {
                                    inputLabelWrapper(
                                        title = translation[TL.CardCustomizeBadge.ICON_SEARCH_PLACEHOLDER],
                                        visibilityFlow = filterInputStore.data.map { it.isNotBlank() },
                                    ) {
                                        genericInput(
                                            rightContentBox = {
                                                inputIconButton(
                                                    iconFlow = flowOf { close },
                                                ) {
                                                    className(filterInputStore.data.map { if (it.isBlank()) "invisible" else "visible" })
                                                    clicks.map { "" } handledBy filterInputStore.update
                                                }
                                            },
                                        ) {
                                            value(filterInputStore.data)
                                            placeholder(translation[TL.CardCustomizeBadge.ICON_SEARCH_PLACEHOLDER])
                                            inputs.values() handledBy filterInputStore.update
                                            type("text")
                                        }
                                    }
                                }
                            }
                            // calculate number of rows matching the given number of columns
                            val columns = 6
                            iconIndexStore.data.render {
                                filterInputStore.data.render { filter ->
                                    when (tool) {
                                        PointStyleTool.Color -> {
                                            val colors = PointCustomizationGrid.values().filter { it.color != null }
                                            val rest = colors.size % columns
                                            val rows = colors.size / columns + (if (rest > 0) 1 else 0)
                                            for (i in 0 until rows) {
                                                pointCustomizationGridRow(
                                                    items = colors.filter { it.ordinal in i * columns until i * columns + columns }
                                                        .sortedBy { it.ordinal },
                                                    columns = columns,
                                                    type = tool,
                                                )
                                            }
                                        }

                                        PointStyleTool.Shape -> {
                                            val shapes = PointCustomizationGrid.values().filter { it.shape != null }
                                            val rest = shapes.size % columns
                                            val rows = shapes.size / columns + (if (rest > 0) 1 else 0)
                                            for (i in 0 until rows) {
                                                pointCustomizationGridRow(
                                                    items = shapes.filter { it.ordinal in i * columns until i * columns + columns }
                                                        .sortedBy { it.ordinal },
                                                    columns = columns,
                                                    type = tool,
                                                )
                                            }
                                        }

                                        PointStyleTool.Icon -> {
                                            val iconGroups = IconGroup.values().associateWith { iconGroup ->
                                                // FIXME also implement filtering for colors/shapes
                                                val filteredIcons = iconIndexStore.match(filter).toSet()
                                                PointCustomizationGrid.values()
                                                    .filter { it.iconGroup == iconGroup && it.icon in filteredIcons }
                                            }
                                            iconGroups.forEach { (iconGroup, iconList) ->
                                                if (iconList.isNotEmpty()) {
                                                    separationLine(
                                                        translation[iconGroup],
                                                        margins = { top { smaller } },
                                                        verticalTextMargins = { none },
                                                    )
                                                    val restIcons = iconList.size % columns
                                                    val iconRows =
                                                        iconList.size / columns + (if (restIcons > 0) 1 else 0)
                                                    for (i in 0 until iconRows) {
                                                        val start = i * columns
                                                        val end =
                                                            if (i * columns + columns > iconList.size) iconList.size else i * columns + columns
                                                        pointCustomizationGridRow(
                                                            items = iconList.subList(start, end),
                                                            columns = columns,
                                                            type = tool,
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                secondaryTitle = translation[TL.General.BACK],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.updateChangeInputStores, routerStore.back),
                primaryTitle = translation[TL.General.SAVE],
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.readFromChangeInputStores, routerStore.back),
            )
        },
    )
}

enum class PointStyleTool { Color, Icon, Shape }

class PointStyleToolSelectorStore : RootStore<PointStyleTool>(
    initialData = PointStyleTool.Color,
    job = Job(),
) {

    val selectTool = handle<PointStyleTool> { _, tool ->
        update(tool)
        tool
    }

    val reset = handle {
        update(PointStyleTool.Color)
        PointStyleTool.Color
    }
}

class ColorSelectionStore : RootStore<MarkerColor>(
    initialData = MarkerColor.DarkBlue,
    job = Job(),
) {

    val resetTo = handle<ObjectType> { _, objType ->
        when (objType) {
            ObjectType.POI -> {
                update(MarkerColor.DarkBlue)
                MarkerColor.DarkBlue
            }

            ObjectType.ObjectMarker -> {
                update(MarkerColor.Black)
                MarkerColor.Black
            }

            ObjectType.Zone -> {
                update(MarkerColor.LightGrey)
                MarkerColor.LightGrey
            }

            else -> {
                update(MarkerColor.DarkBlue)
                MarkerColor.DarkBlue
            }
        }
    }
}

class IconSelectionStore : RootStore<MarkerIcon>(
    initialData = MarkerIcon.Location,
    job = Job(),
) {

    val resetTo = handle<ObjectType> { _, objType ->
        when (objType) {
            ObjectType.POI -> {
                update(MarkerIcon.Location)
                MarkerIcon.Location
            }

            ObjectType.ObjectMarker -> {
                update(MarkerIcon.TrackedObject)
                MarkerIcon.TrackedObject
            }

            ObjectType.Zone -> {
                update(MarkerIcon.Zone)
                MarkerIcon.Zone
            }

            else -> {
                update(MarkerIcon.Location)
                MarkerIcon.Location
            }
        }
    }
}

class ShapeSelectionStore : RootStore<MarkerShape>(
    initialData = MarkerShape.Circle,
    job = Job(),
) {

    val resetTo = handle<ObjectType> { _, objType ->
        when (objType) {
            ObjectType.POI -> {
                update(MarkerShape.Circle)
                MarkerShape.Circle
            }

            ObjectType.ObjectMarker -> {
                update(MarkerShape.SquareWithPointer)
                MarkerShape.SquareWithPointer
            }

            ObjectType.Zone -> {
                update(MarkerShape.Diamond)
                MarkerShape.Diamond
            }

            else -> {
                update(MarkerShape.Circle)
                MarkerShape.Circle
            }
        }
    }
}

enum class IconGroup : Translatable {
    Information, Emojis, Office, Tools, Facility, Health, Vehicles, Other, Hazards
    ;

    override val prefix = "icongroup"
}

enum class PointCustomizationGrid(
    colorDef: MarkerColor? = null,
    shapeDef: MarkerShape? = null,
    iconDef: MarkerIcon? = null,
) {
    G1(colorDef = MarkerColor.BlueMidnight, shapeDef = MarkerShape.Circle, iconDef = MarkerIcon.Location) {},
    G2(colorDef = MarkerColor.BlueSky, shapeDef = MarkerShape.Square, iconDef = MarkerIcon.LocationAlt) {},
    G3(colorDef = MarkerColor.BlueLavender, shapeDef = MarkerShape.Diamond, iconDef = MarkerIcon.Megaphone) {},
    G4(colorDef = MarkerColor.GraySilver, shapeDef = MarkerShape.TriangleUp, iconDef = MarkerIcon.Flag) {},
    G5(colorDef = MarkerColor.GraySteel, shapeDef = MarkerShape.TriangleDown, iconDef = MarkerIcon.Pin) {},
    G6(colorDef = MarkerColor.GraySlate, shapeDef = MarkerShape.RoundWithPointer, iconDef = MarkerIcon.Coffee) {},

    G7(colorDef = MarkerColor.GreenMoss, shapeDef = MarkerShape.SquareWithPointer, iconDef = MarkerIcon.Star) {},
    G8(colorDef = MarkerColor.GreenTurquoise, shapeDef = MarkerShape.Pentagon, iconDef = MarkerIcon.PaintBrush) {},
    G9(colorDef = MarkerColor.GreenMint, shapeDef = MarkerShape.ArrowUp, iconDef = MarkerIcon.Tools) {},
    G10(colorDef = MarkerColor.GreenSoft, shapeDef = MarkerShape.ArrowDown, iconDef = MarkerIcon.Toolbox) {},
    G11(colorDef = MarkerColor.GreenVibrant, shapeDef = MarkerShape.Egg, iconDef = MarkerIcon.Apple) {},
    G12(colorDef = MarkerColor.GreenFresh, shapeDef = MarkerShape.Trapezoid, iconDef = MarkerIcon.Block) {},

    G13(colorDef = MarkerColor.RedDarkCrimson, shapeDef = MarkerShape.Star, iconDef = MarkerIcon.Boxes) {},
    G14(colorDef = MarkerColor.RedFire, shapeDef = MarkerShape.Heart, iconDef = MarkerIcon.Food) {},
    G15(colorDef = MarkerColor.RedSalmon, shapeDef = MarkerShape.Cross, iconDef = MarkerIcon.Cupcake) {},
    G16(colorDef = MarkerColor.OrangePeach, iconDef = MarkerIcon.Desk) {},
    G17(colorDef = MarkerColor.OrangeAmber, iconDef = MarkerIcon.OfficeDesk) {},
    G18(colorDef = MarkerColor.OrangeRust, iconDef = MarkerIcon.Printer) {},

    G19(colorDef = MarkerColor.LightGreenAlt, iconDef = MarkerIcon.MeetingRoom) {},
    G20(colorDef = MarkerColor.LightGreen, iconDef = MarkerIcon.Bin) {},
    G21(colorDef = MarkerColor.Green, iconDef = MarkerIcon.NoAccess) {},
    G22(colorDef = MarkerColor.Turquoise, iconDef = MarkerIcon.UserGroup) {},
    G23(colorDef = MarkerColor.GreenAlt, iconDef = MarkerIcon.PassageForbidden) {},
    G24(colorDef = MarkerColor.DarkGreen, iconDef = MarkerIcon.NoSmoking) {},

    G25(colorDef = MarkerColor.LightGrey, iconDef = MarkerIcon.Toilet) {},
    G26(colorDef = MarkerColor.LightBlue, iconDef = MarkerIcon.Train) {},
    G27(colorDef = MarkerColor.AquaMarine, iconDef = MarkerIcon.AGV) {},
    G28(colorDef = MarkerColor.LightBlueAlt, iconDef = MarkerIcon.Car) {},
    G29(colorDef = MarkerColor.Blue, iconDef = MarkerIcon.Forklift) {},
    G30(colorDef = MarkerColor.DarkBlue, iconDef = MarkerIcon.Bicycle) {},

    G31(colorDef = MarkerColor.Yellow, iconDef = MarkerIcon.WiFi) {},
    G32(colorDef = MarkerColor.Orange, iconDef = MarkerIcon.Door) {},
    G33(colorDef = MarkerColor.DarkOrange, iconDef = MarkerIcon.Elevator) {},
    G34(colorDef = MarkerColor.Red, iconDef = MarkerIcon.NoPhone) {},
    G35(colorDef = MarkerColor.DarkRed, iconDef = MarkerIcon.WirelessDoorLock) {},
    G36(colorDef = MarkerColor.DarkMagenta, iconDef = MarkerIcon.FireExtinguisher) {},

    G37(colorDef = MarkerColor.Grey, iconDef = MarkerIcon.FirstAid) {},
    G38(colorDef = MarkerColor.Black, iconDef = MarkerIcon.Medical) {},
    G39(iconDef = MarkerIcon.Defibrillator) {},
    G40(iconDef = MarkerIcon.FaceMask) {},
    G41(iconDef = MarkerIcon.API) {},
    G42(iconDef = MarkerIcon.SecurityCamera) {},

    G43(iconDef = MarkerIcon.Server) {},
    G44(iconDef = MarkerIcon.QRCode) {},
    G45(iconDef = MarkerIcon.Information) {},
    G46(iconDef = MarkerIcon.Stairs) {},
    G47(iconDef = MarkerIcon.InformationAlt) {},
    G48(iconDef = MarkerIcon.Caution) {},

    G49(iconDef = MarkerIcon.WaterFaucet) {},
    G50(iconDef = MarkerIcon.Camera) {},
    G51(iconDef = MarkerIcon.AGVBosch) {},
    G52(iconDef = MarkerIcon.Booth) {},
    G53(iconDef = MarkerIcon.FoodTruck) {},
    G54(iconDef = MarkerIcon.FormationLogo) {},

    G55(iconDef = MarkerIcon.GeoCaching) {},
    G56(iconDef = MarkerIcon.Milkrun) {},
    G57(iconDef = MarkerIcon.Prize) {},
    G58(iconDef = MarkerIcon.Regal) {},
    G59(iconDef = MarkerIcon.SingelBox) {},
    G60(iconDef = MarkerIcon.Stage) {},

    G61(iconDef = MarkerIcon.NFC) {},
    G62(iconDef = MarkerIcon.TrackedObject) {},
    G63(iconDef = MarkerIcon.Container) {},
    G64(iconDef = MarkerIcon.Coil) {},
    G65(iconDef = MarkerIcon.CameraDrone) {},
    G66(iconDef = MarkerIcon.VRGlasses) {},

    G67(iconDef = MarkerIcon.Whiteboard) {},
    G68(iconDef = MarkerIcon.Computer) {},
    G69(iconDef = MarkerIcon.Scissors) {},
    G70(iconDef = MarkerIcon.Clipboard) {},
    G71(iconDef = MarkerIcon.Pen) {},
    G72(iconDef = MarkerIcon.Clip) {},

    G73(iconDef = MarkerIcon.Notebook) {},
    G74(iconDef = MarkerIcon.Tablet) {},
    G75(iconDef = MarkerIcon.Lightbulb) {},
    G76(iconDef = MarkerIcon.Projector) {},
    G77(iconDef = MarkerIcon.Flashlight) {},
    G78(iconDef = MarkerIcon.Flooding) {},

    G79(iconDef = MarkerIcon.ClearanceVehicle) {},
    G80(iconDef = MarkerIcon.Antenna) {},
    G81(iconDef = MarkerIcon.RoadBlock) {},
    G82(iconDef = MarkerIcon.Fire) {},
    G83(iconDef = MarkerIcon.Rubble) {},
    G84(iconDef = MarkerIcon.SixFeetApart) {},

    G85(iconDef = MarkerIcon.AirPortShuttle) {},
    G86(iconDef = MarkerIcon.Ambulance) {},
    G87(iconDef = MarkerIcon.Apartment) {},
    G88(iconDef = MarkerIcon.BabyChangingStation) {},
    G89(iconDef = MarkerIcon.Bed) {},
    G90(iconDef = MarkerIcon.PhoneChargingStation) {},

    G91(iconDef = MarkerIcon.Ferry) {},
    G92(iconDef = MarkerIcon.GenderFemale) {},
    G93(iconDef = MarkerIcon.GenderMale) {},
    G94(iconDef = MarkerIcon.GenderDiverse) {},
    G95(iconDef = MarkerIcon.Family) {},
    G96(iconDef = MarkerIcon.Hospital) {},

    G97(iconDef = MarkerIcon.Church) {},
    G98(iconDef = MarkerIcon.Embassy) {},
    G99(iconDef = MarkerIcon.FireFighter) {},
    G100(iconDef = MarkerIcon.PoliceOfficer) {},
    G101(iconDef = MarkerIcon.MoneyExchange) {},
    G102(iconDef = MarkerIcon.DangerExplosions) {},

    G103(iconDef = MarkerIcon.DangerAerialBombs) {},
    G104(iconDef = MarkerIcon.DangerAerialBombsAlt) {},
    G105(iconDef = MarkerIcon.Tank) {},
    G106(iconDef = MarkerIcon.Departures) {},
    G107(iconDef = MarkerIcon.BioHazard) {},
    G108(iconDef = MarkerIcon.Bricks) {},

    G109(iconDef = MarkerIcon.Exit) {},
    G110(iconDef = MarkerIcon.ArrowRight) {},
    G111(iconDef = MarkerIcon.Ladder) {},
    G112(iconDef = MarkerIcon.Setting) {},
    G113(iconDef = MarkerIcon.PowerPlug) {},
    G114(iconDef = MarkerIcon.OldTelephone) {},

    G115(iconDef = MarkerIcon.MeetingPoint) {},
    G116(iconDef = MarkerIcon.MeetingPointAlt) {},
    G117(iconDef = MarkerIcon.Pets) {},
    G118(iconDef = MarkerIcon.Translate) {},
    G119(iconDef = MarkerIcon.WheelChairPickup) {},
    G120(iconDef = MarkerIcon.TelephoneTypeWriter) {},

    G121(iconDef = MarkerIcon.HighVoltage) {},
    G122(iconDef = MarkerIcon.MetroStation) {},
    G123(iconDef = MarkerIcon.Vaccination) {},
    G124(iconDef = MarkerIcon.NightShelter) {},
    G125(iconDef = MarkerIcon.Passport) {},
    G126(iconDef = MarkerIcon.Barrier) {},

    G127(iconDef = MarkerIcon.BarrierAlt) {},
    G128(iconDef = MarkerIcon.RatTrap) {},
    G129(iconDef = MarkerIcon.Helmet) {},
    G130(iconDef = MarkerIcon.Gear) {},
    G131(iconDef = MarkerIcon.Shop) {},
    G132(iconDef = MarkerIcon.Folder) {},

    G133(iconDef = MarkerIcon.Security) {},
    G134(iconDef = MarkerIcon.Bolt) {},
    G135(iconDef = MarkerIcon.BandAids) {},
    G136(iconDef = MarkerIcon.AidTag) {},
    G137(iconDef = MarkerIcon.Chemicals) {},
    G138(iconDef = MarkerIcon.PaperTowels) {},

    G139(iconDef = MarkerIcon.Crane) {},
    G140(iconDef = MarkerIcon.HeartRate) {},
    G141(iconDef = MarkerIcon.DisplayGraph) {},
    G142(iconDef = MarkerIcon.Mortar) {},
    G143(iconDef = MarkerIcon.Mattress) {},
    G144(iconDef = MarkerIcon.TaskMedic) {},

    G145(iconDef = MarkerIcon.FolderMedic) {},
    G146(iconDef = MarkerIcon.Syringe) {},
    G147(iconDef = MarkerIcon.BloodPressure) {},
    G148(iconDef = MarkerIcon.Cardiogram) {},
    G149(iconDef = MarkerIcon.EyeDropper) {},
    G150(iconDef = MarkerIcon.HospitalBed) {},

    G151(iconDef = MarkerIcon.HospitalBedAlt) {},
    G152(iconDef = MarkerIcon.Lifter) {},
    G153(iconDef = MarkerIcon.ScaleAnalog) {},
    G154(iconDef = MarkerIcon.ScaleDigital) {},
    G155(iconDef = MarkerIcon.Pills) {},
    G156(iconDef = MarkerIcon.PillsBottle) {},

    G157(iconDef = MarkerIcon.PillsBox) {},
    G158(iconDef = MarkerIcon.HandTruck) {},
    G159(iconDef = MarkerIcon.ShoppingCart) {},
    G160(iconDef = MarkerIcon.Stethoscope) {},
    G161(iconDef = MarkerIcon.Thermostat) {},
    G162(iconDef = MarkerIcon.UpDown) {},

    G163(iconDef = MarkerIcon.Dingo) {},
    G164(iconDef = MarkerIcon.Eagle) {},
    G165(iconDef = MarkerIcon.Multi) {},
    G166(iconDef = MarkerIcon.Patriot) {},
    G167(iconDef = MarkerIcon.Wolf) {},
    G168(iconDef = MarkerIcon.Axle) {},

    G169(iconDef = MarkerIcon.WaitAT) {},
    G170(iconDef = MarkerIcon.WaitET) {},
    G171(iconDef = MarkerIcon.Gears) {},
    G172(iconDef = MarkerIcon.Engine) {},
    G173(iconDef = MarkerIcon.Zone) {},
    G174(iconDef = MarkerIcon.Parking) {},

    G175(iconDef = MarkerIcon.Cake) {},
    G176(iconDef = MarkerIcon.BirthdayCake) {},
    G177(iconDef = MarkerIcon.Cheese) {},
    G178(iconDef = MarkerIcon.Beer) {},
    G179(iconDef = MarkerIcon.Wineglass) {},
    G180(iconDef = MarkerIcon.Pint) {},

    G181(iconDef = MarkerIcon.Sausage) {},
    G182(iconDef = MarkerIcon.Music) {},
    G183(iconDef = MarkerIcon.Microphone) {},
    G184(iconDef = MarkerIcon.Box) {},
    G185(iconDef = MarkerIcon.SmileyHappy) {},
    G186(iconDef = MarkerIcon.SmileyAfraid) {},

    G187(iconDef = MarkerIcon.SmileyDead) {},
    G188(iconDef = MarkerIcon.SmileyLaugh) {},
    G189(iconDef = MarkerIcon.SmileyOk) {},
    G190(iconDef = MarkerIcon.SmileySmile) {},
    G191(iconDef = MarkerIcon.SmileySad) {},
    G192(iconDef = MarkerIcon.SmileyOuch) {},

    G193(iconDef = MarkerIcon.SmileyQuiet) {},
    G194(iconDef = MarkerIcon.SmileyUpsideDown) {},
    G195(iconDef = MarkerIcon.WheelChair) {},
    G196(iconDef = MarkerIcon.Wardrobe) {},
    G197(iconDef = MarkerIcon.Handbag) {},
    G198(iconDef = MarkerIcon.Hanger) {},

    G199(iconDef = MarkerIcon.WrenchSet) {},
    G200(iconDef = MarkerIcon.Screwdrivers) {},
    G201(iconDef = MarkerIcon.LadderAlt) {},
    G202(iconDef = MarkerIcon.JackStand) {},
    G203(iconDef = MarkerIcon.HandScanner) {},
    G204(iconDef = MarkerIcon.Hammer) {},

    G205(iconDef = MarkerIcon.GreaseGun) {},
    G206(iconDef = MarkerIcon.Drill) {},
    G207(iconDef = MarkerIcon.DiagnosticTool) {},
    G208(iconDef = MarkerIcon.AirCompressor) {},
    G209(iconDef = MarkerIcon.Drain) {},
    G210(iconDef = MarkerIcon.SmileyGood) {},

    G211(iconDef = MarkerIcon.SmileyAlright) {},
    G212(iconDef = MarkerIcon.SmileyNotGood) {},
    G213(iconDef = MarkerIcon.FluidDrop) {},
    G214(iconDef = MarkerIcon.WaterSupply) {},
    G215(iconDef = MarkerIcon.WaterBottle) {},
    G216(iconDef = MarkerIcon.WaterGlass) {},

    G217(iconDef = MarkerIcon.Drinks) {},
    G218(iconDef = MarkerIcon.Cocktail) {},
    G219(iconDef = MarkerIcon.WineBottle) {},
    ;

    val markerColor = colorDef
    val color = colorDef?.getColorForIcon()
    val isDarkColor = color?.let { isDarkColor(it) }
    val isBrightColor = color?.let { isBrightColor(it) }
    val shape = shapeDef
    val formationShape = shapeDef?.getShape()
    val icon = iconDef
    val formationIcon = iconDef?.getIcon()
    val iconGroup = iconDef?.getIconGroup()
}

fun RenderContext.pointStyleToolBar() {
    val pointStyleToolSelectorStore: PointStyleToolSelectorStore = koinCtx.get()
    val translation: Translation by koinCtx.inject()

    div(
        {
            width { full }
            paddings { vertical { small } }
        },
    ) {
        flexBox(
            {
                width { full }
                direction { row }
                justifyContent { spaceBetween }
                alignItems { center }
                border { color { primary.main }; width(FormationDefault.formationStyles.borderWidth) }
                radius(FormationDefault.formationStyles.inputRadius)
            },
        ) {
            pointStyleToolSelectorStore.data.render { tool ->
                with(PointStyleTool.Color) {
                    button({ if (tool == this@with) filterButtonOnStyle() else filterButtonOffStyle() }) {
                        translation[TL.CardCustomizeBadge.COLOUR].renderText(into = this)
                        clicks.map { this@with } handledBy pointStyleToolSelectorStore.selectTool
                    }
                }
                with(PointStyleTool.Icon) {
                    button({ if (tool == this@with) filterButtonOnStyle() else filterButtonOffStyle() }) {
                        translation[TL.CardCustomizeBadge.ICON].renderText(into = this)
                        clicks.map { this@with } handledBy pointStyleToolSelectorStore.selectTool
                    }
                }
                with(PointStyleTool.Shape) {
                    button({ if (tool == this@with) filterButtonOnStyle() else filterButtonOffStyle() }) {
                        translation[TL.CardCustomizeBadge.SHAPE].renderText(into = this)
                        clicks.map { this@with } handledBy pointStyleToolSelectorStore.selectTool
                    }
                }
            }
        }
    }
}

fun RenderContext.pointCustomizationGridRow(items: List<PointCustomizationGrid>, columns: Int, type: PointStyleTool) {

    val colorSelectionStore: ColorSelectionStore = koinCtx.get()
    val iconSelectionStore: IconSelectionStore = koinCtx.get()
    val shapeSelectionStore: ShapeSelectionStore = koinCtx.get()
    val translation: Translation by koinCtx.inject()

    val renderLine = when (type) {
        PointStyleTool.Color -> items.any { it.color != null }
        PointStyleTool.Icon -> items.any { it.formationIcon != null }
        PointStyleTool.Shape -> items.any { it.formationShape != null }
    }

    if (renderLine) {
        lineUp(
            {
                width { full }
                justifyContent { spaceEvenly }
                alignItems { center }
                margins { top { smaller } }
            },
        ) {
            spacing { tiny }
            items {
                items.forEach { gridElement ->
                    when (type) {
                        PointStyleTool.Color -> {
                            if (gridElement.color != null && gridElement.markerColor != null) {
                                colorSelectionStore.data.render { selectedColor ->
                                    button(
                                        {
                                            width { "40px" }
                                            height { "40px" }
                                            radius { full }
                                            margins { horizontal { tiny } }
                                            background { color { gridElement.color.color } }
                                            if (gridElement.isBrightColor == true) border { color { FormationColors.GrayDisabled.color }; width { "1px" } }
                                            hover {
                                                border {
                                                    color {
                                                        if (gridElement.isDarkColor == true) FormationColors.GrayDisabled.color
                                                        else primary.main
                                                    }
                                                    width { "3px" }
                                                }
                                            }
                                            if (gridElement.markerColor == selectedColor) {
                                                border {
                                                    width { "2px" }
                                                    color {
                                                        if (gridElement.isDarkColor == true) FormationColors.GrayDisabled.color
                                                        else primary.main
                                                    }
                                                }
                                                color {
                                                    if (gridElement.isDarkColor == true) FormationColors.GrayDisabled.color
                                                    else primary.main
                                                }
                                            }
                                        },
                                    ) {
                                        title(gridElement.color.name)
                                        if (gridElement.markerColor == selectedColor) {
                                            icon { FormationUIIcons.Check }
                                        }
                                        clicks.map { gridElement.markerColor } handledBy colorSelectionStore.update
                                    }
                                }
                            } else {
                                div(
                                    {
                                        width { "40px" }
                                        height { "40px" }
                                        margins { horizontal { tiny } }
                                    },
                                ) { }
                            }
                        }

                        PointStyleTool.Icon -> {
                            if (gridElement.formationIcon != null && gridElement.icon != null) {
                                iconSelectionStore.data.render { selectedIcon ->
                                    button(
                                        {
                                            width { "40px" }
                                            height { "40px" }
                                            radius { full }
                                            margins { horizontal { tiny } }
                                            border {
                                                color { FormationColors.GrayDisabled.color }
                                                width { "1px" }
                                            }
                                            hover {
                                                border {
                                                    color { primary.main }
                                                    width { "3px" }
                                                }
                                            }
                                            if (gridElement.icon == selectedIcon) {
                                                border {
                                                    width { "2px" }
                                                    color { primary.main }
                                                }
                                                color { secondary.main }
                                                background {
                                                    color { primary.main }
                                                }
                                            }
                                        },
                                    ) {
                                        title(translation[gridElement.icon])
                                        icon({ size { large } }) { fromTheme { gridElement.formationIcon.icon } }
                                        clicks.map { gridElement.icon } handledBy iconSelectionStore.update
                                    }
                                }
                            } else {
                                div(
                                    {
                                        width { "40px" }
                                        height { "40px" }
                                        margins { horizontal { tiny } }
                                    },
                                ) { }
                            }
                        }

                        PointStyleTool.Shape -> {
                            if (gridElement.formationShape != null && gridElement.shape != null) {
                                shapeSelectionStore.data.render { selectedShape ->
                                    button(
                                        {
                                            width { "40px" }
                                            height { "40px" }
                                            radius { full }
                                            margins { horizontal { tiny } }
                                            border {
                                                color { FormationColors.GrayDisabled.color }
                                                width { "1px" }
                                            }
                                            hover {
                                                border {
                                                    color { primary.main }
                                                    width { "3px" }
                                                }
                                            }
                                            if (gridElement.shape == selectedShape) {
                                                border {
                                                    width { "2px" }
                                                    color { primary.main }
                                                }
                                                color { secondary.main }
                                                background {
                                                    color { primary.main }
                                                }
                                            }
                                        },
                                    ) {
                                        title(gridElement.formationShape.shape.displayName)
                                        icon({ size { large } }) { fromTheme { gridElement.formationShape.shape } }
                                        clicks.map { gridElement.shape } handledBy shapeSelectionStore.update
                                    }
                                }
                            } else {
                                div(
                                    {
                                        width { "40px" }
                                        height { "40px" }
                                        margins { horizontal { tiny } }
                                    },
                                ) { }
                            }
                        }
                    }
                }
                if (items.size < columns) {
                    for (i in items.size until columns) {
                        div(
                            {
                                width { "40px" }
                                height { "40px" }
                                margins { horizontal { tiny } }
                            },
                        ) { }
                    }
                }
            }
        }
    }
}
