package search.searchFilterStores

import com.tryformation.localization.Translatable
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

enum class SearchFilterOption(val defaultValue: Boolean) : Translatable {
    IncludeArchived(defaultValue = false),
    ;

    override val prefix = "searchfilteroption"
}

open class ActiveSearchOptionsStore(init: Map<SearchFilterOption, Boolean>? = null) : RootStore<Map<SearchFilterOption, Boolean>>(
    initialData = init ?: SearchFilterOption.entries.associateWith { it.defaultValue }, job = Job(),
) {

    val checkOptions = handle<Map<SearchFilterOption, Boolean>> { current, options ->
        current + options
    }
}
