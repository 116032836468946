package webcomponents

import analyticsdashboard.ActiveHistoryPathSearchFieldValuesStore
import analyticsdashboard.ActiveHistoryPathSearchKeywordsStore
import analyticsdashboard.ActiveHistoryPathSearchObjectTypesStore
import analyticsdashboard.ActiveHistoryPathSearchReadOnlyKeywordsStore
import apiclient.customfields.FieldValue
import apiclient.geoobjects.ReadOnlyTags
import data.heatmaplayer.ActiveHeatmapLayerDefinitionStore
import data.keywordlayer.ActiveKeywordLayerDefinitionStore
import data.objects.ActiveObjectFieldValuesStore
import data.objects.ActiveObjectKeywordsStore
import data.objects.CurrentActiveFieldValueStore
import dev.fritz2.components.compat.Button
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import map.views.workplacetools.ActiveArchetypeSearchKeywordsStore
import model.KeywordTag
import org.w3c.dom.HTMLButtonElement
import search.global.ActiveSearchFieldValuesStore
import search.global.ActiveSearchKeywordsStore
import search.nestedObjects.ActiveNestedSearchFieldValuesStore
import search.nestedObjects.ActiveNestedSearchKeywordStore
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationUIIcons
import utils.makeRGBA
import utils.sortKeywordTags

enum class KeywordTagType { SearchTag, ObjectTag, AnalyticsPathViewTag, ArchetypeTag, NestedObjectTag, HeatmapLayerTag, KeywordLayerTag }
enum class KeywordTagActionType { Default, Add, Remove, ReadOnly, RemoveReadOnly, DefaultFieldValue, AddFieldValue, RemoveFieldValue }
enum class KeywordTagSize { Tiny, Normal }

fun RenderContext.keywordTag(
    tag: KeywordTag,
    keywordTagType: KeywordTagType,
    optRemoveHandler: SimpleHandler<String>? = null,
    searchable: Boolean = false,
    condensed: Boolean = false
): HtmlTag<HTMLButtonElement> {
    val currentActiveFieldValueStore: CurrentActiveFieldValueStore by koinCtx.inject()

    val activeObjectKeywordsStore: ActiveObjectKeywordsStore by koinCtx.inject()
    val activeObjectFieldValuesStore: ActiveObjectFieldValuesStore by koinCtx.inject()

    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()

    val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()
    val activeArchetypeSearchKeywordsStore: ActiveArchetypeSearchKeywordsStore by koinCtx.inject()

    val activeKeywordLayerDefinitionStore: ActiveKeywordLayerDefinitionStore by koinCtx.inject()
    val activeHeatmapLayerDefinitionStore: ActiveHeatmapLayerDefinitionStore by koinCtx.inject()

    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()

    val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore by koinCtx.inject()
    val activeNestedSearchFieldValuesStore: ActiveNestedSearchFieldValuesStore by koinCtx.inject()

    val addHandler: SimpleHandler<String>
    val removeHandler: SimpleHandler<String>
    val addFieldValueHandler: SimpleHandler<FieldValue?>
    val removeFieldValueHandler: SimpleHandler<FieldValue?>
    val addObjectTypeHandler: SimpleHandler<String?>?
    val removeObjectTypeHandler: SimpleHandler<String?>?
    val addReadOnlyKeywordHandler: SimpleHandler<KeywordTag>?
    val removeReadOnlyKeywordHandler: SimpleHandler<KeywordTag>?

    when (keywordTagType) {
        KeywordTagType.ObjectTag -> {
            addHandler = activeObjectKeywordsStore.add
            removeHandler = activeObjectKeywordsStore.remove
            addFieldValueHandler = activeObjectFieldValuesStore.add
            removeFieldValueHandler = activeObjectFieldValuesStore.remove
            addObjectTypeHandler = null
            removeObjectTypeHandler = null
            addReadOnlyKeywordHandler = null
            removeReadOnlyKeywordHandler = null
        }

        KeywordTagType.SearchTag -> {
            addHandler = activeSearchKeywordsStore.add
            removeHandler = activeSearchKeywordsStore.remove
            addFieldValueHandler = activeSearchFieldValuesStore.add
            removeFieldValueHandler = activeSearchFieldValuesStore.remove
            addObjectTypeHandler = activeObjectKeywordsStore.addToActiveSearchObjectTypes
            removeObjectTypeHandler = activeObjectKeywordsStore.removeFromActiveSearchObjectTypes
            addReadOnlyKeywordHandler = activeObjectKeywordsStore.addToActiveSearchReadOnlyTags
            removeReadOnlyKeywordHandler = activeObjectKeywordsStore.removeFromActiveSearchReadOnlyTags
        }

        KeywordTagType.AnalyticsPathViewTag -> {
            addHandler = activeHistoryPathSearchKeywordsStore.add
            removeHandler = activeHistoryPathSearchKeywordsStore.remove
            addFieldValueHandler = activeHistoryPathSearchFieldValuesStore.add
            removeFieldValueHandler = activeHistoryPathSearchFieldValuesStore.remove
            addObjectTypeHandler = activeHistoryPathSearchObjectTypesStore.add
            removeObjectTypeHandler = activeHistoryPathSearchObjectTypesStore.remove
            addReadOnlyKeywordHandler = activeHistoryPathSearchReadOnlyKeywordsStore.add
            removeReadOnlyKeywordHandler = activeHistoryPathSearchReadOnlyKeywordsStore.remove
        }

        KeywordTagType.ArchetypeTag -> {
            addHandler = activeArchetypeSearchKeywordsStore.add
            removeHandler = activeArchetypeSearchKeywordsStore.remove
            addFieldValueHandler = activeArchetypeSearchFieldValuesStore.add
            removeFieldValueHandler = activeArchetypeSearchFieldValuesStore.remove
            addObjectTypeHandler = null
            removeObjectTypeHandler = null
            addReadOnlyKeywordHandler = null
            removeReadOnlyKeywordHandler = null
        }

        KeywordTagType.NestedObjectTag -> {
            addHandler = activeNestedSearchKeywordStore.add
            removeHandler = activeNestedSearchKeywordStore.remove
            addFieldValueHandler = activeNestedSearchFieldValuesStore.add
            removeFieldValueHandler = activeNestedSearchFieldValuesStore.remove
            addObjectTypeHandler = activeObjectKeywordsStore.addToActiveNestedObjectTypesWithoutRouting
            removeObjectTypeHandler = activeObjectKeywordsStore.removeFromActiveNestedObjectTypes
            addReadOnlyKeywordHandler = activeObjectKeywordsStore.addToActiveNestedObjectReadOnlyTags
            removeReadOnlyKeywordHandler = activeObjectKeywordsStore.removeFromActiveNestedObjectReadOnlyTags
        }

        KeywordTagType.HeatmapLayerTag -> {
            addHandler = activeKeywordLayerDefinitionStore.addKeyWord
            removeHandler = activeKeywordLayerDefinitionStore.removeKeyWord
            addFieldValueHandler = activeHeatmapLayerDefinitionStore.addFieldValue
            removeFieldValueHandler = activeHeatmapLayerDefinitionStore.removeFieldValue
            addObjectTypeHandler = null
            removeObjectTypeHandler = null
            addReadOnlyKeywordHandler = null
            removeReadOnlyKeywordHandler = null
        }

        KeywordTagType.KeywordLayerTag -> {
            addHandler = activeKeywordLayerDefinitionStore.addKeyWord
            removeHandler = activeKeywordLayerDefinitionStore.removeKeyWord
            addFieldValueHandler = activeKeywordLayerDefinitionStore.addFieldValue
            removeFieldValueHandler = activeKeywordLayerDefinitionStore.removeFieldValue
            addObjectTypeHandler = null
            removeObjectTypeHandler = null
            addReadOnlyKeywordHandler = null
            removeReadOnlyKeywordHandler = null
        }
    }

    return button(
        {
            radius(formationStyles.tagRadius)
            margin { tiny }
            when (tag.actionType) {
                KeywordTagActionType.Add -> {
                    color { primary.main }
                    background {
                        color { secondary.main }
                    }
                    border {
                        color { primary.main }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { secondary.main }
                        background {
                            color { FormationColors.MarkerYou.color }
                        }
                        border {
                            color { FormationColors.MarkerYou.color }
                            width(formationStyles.borderWidth)
                        }
                    }
                    focus {
                        color { secondary.main }
                        background {
                            color { FormationColors.MarkerYou.color }
                        }
                        border {
                            color { FormationColors.MarkerYou.color }
                            width(formationStyles.borderWidth)
                        }
                    }
                }

                KeywordTagActionType.ReadOnly -> {
                    background {
                        color { FormationColors.GrayPrivate.color }
                    }
                    border {
                        color { FormationColors.GrayPrivate.color }
                        width(formationStyles.borderWidth)
                    }
                    if (searchable) {
                        hover {
                            background {
                                color { secondary.main }
                            }
                            border {
                                width(formationStyles.borderWidth)
                                color { FormationColors.GrayPrivate.color }
                            }
                        }
                        focus {
                            background {
                                color { secondary.main }
                            }
                            border {
                                width(formationStyles.borderWidth)
                                color { FormationColors.GrayPrivate.color }
                            }
                        }
                    } else {
                        css("cursor:default;")
                    }
                }

                KeywordTagActionType.RemoveReadOnly -> {
                    background {
                        color { FormationColors.GrayPrivate.color }
                    }
                    border {
                        color { FormationColors.GrayPrivate.color }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        background {
                            color { secondary.main }
                        }
                        border {
                            width(formationStyles.borderWidth)
                            color { FormationColors.GrayPrivate.color }
                        }
                    }
                    focus {
                        background {
                            color { secondary.main }
                        }
                        border {
                            width(formationStyles.borderWidth)
                            color { FormationColors.GrayPrivate.color }
                        }
                    }
                }

                KeywordTagActionType.AddFieldValue -> {
                    color { primary.main }
                    background {
                        color { secondary.main }
                    }
                    border {
                        color { primary.main }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { secondary.main }
                        background {
                            color { primary.main }
                        }
                        border {
                            color { primary.main }
                            width(formationStyles.borderWidth)
                        }
                    }
                    focus {
                        color { secondary.main }
                        background {
                            color { primary.main }
                        }
                        border {
                            color { primary.main }
                            width(formationStyles.borderWidth)
                        }
                    }
                }

                KeywordTagActionType.DefaultFieldValue, KeywordTagActionType.RemoveFieldValue -> {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                    border {
                        color { primary.main }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                    }
                    focus {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                    }
                }

                else -> { //KeywordTagType.Default, KeywordTagType.Remove
                    color { secondary.main }
                    background {
                        color { FormationColors.MarkerYou.color }
                    }
                    border {
                        color { FormationColors.MarkerYou.color }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { FormationColors.MarkerYou.color }
                        background {
                            color { secondary.main }
                        }
                        border {
                            width(formationStyles.borderWidth)
                            color { FormationColors.MarkerYou.color }
                        }
                    }
                    focus {
                        color { FormationColors.MarkerYou.color }
                        background {
                            color { secondary.main }
                        }
                        border {
                            width(formationStyles.borderWidth)
                            color { FormationColors.MarkerYou.color }
                        }
                    }
                }
            }
        },
    ) {
        flexBox(
            {
                direction { row }
                width { maxContent }
                height { maxContent }
                alignItems { center }
                justifyContent { center }
                radius(formationStyles.tagRadius)
                paddings {
                    vertical { tiny }
                    horizontal { smaller }
                }
            },
        ) {
            if (tag.valueText != null) {
                lineUp {
                    spacing { tiny }
                    items {
                        span(
                            {
                                fontWeight { lighter }
                                fontSize { smaller }
                                if (condensed) lineHeight { "1.2" }
                                when (tag.actionType) {
                                    KeywordTagActionType.Add,
                                    KeywordTagActionType.Remove,
                                    KeywordTagActionType.RemoveReadOnly,
                                    KeywordTagActionType.AddFieldValue,
                                    KeywordTagActionType.RemoveFieldValue -> margins { left { tiny } }

                                    else -> {}
                                }
                            },
                        ) { +"${tag.fieldText} //" }
                        span(
                            {
                                fontWeight { bold }
                                fontSize { smaller }
                                if (condensed) lineHeight { "1.2" }
                            },
                        ) { +tag.valueText }
                    }
                }
            } else {
                span(
                    {
                        fontWeight { bold }
                        fontSize { smaller }
                        if (condensed) lineHeight { "1.2" }
                        when (tag.actionType) {
                            KeywordTagActionType.Add,
                            KeywordTagActionType.Remove,
                            KeywordTagActionType.RemoveReadOnly,
                            KeywordTagActionType.AddFieldValue,
                            KeywordTagActionType.RemoveFieldValue -> margins { left { tiny } }

                            else -> {}
                        }
                    },
                ) { +tag.fieldText }
            }

            when (tag.actionType) {
                KeywordTagActionType.Add -> {
                    button(
                        {
                            margins { left { tiny } }
                            if (searchable) {
                                hover {
                                    color { makeRGBA(secondary.main, 0.5) }
                                }
                            }
                        },
                    ) {
                        attr("tabindex", "-1")
                        icon { fromTheme { FormationUIIcons.Add.icon } }
                        clicks.map { tag.fieldText } handledBy addHandler
                    }
                }

                KeywordTagActionType.Remove -> {
                    button(
                        {
                            margins { left { tiny } }
                            if (searchable) {
                                hover {
                                    color { makeRGBA(FormationColors.MarkerYou.color, 0.5) }
                                }
                            }
                        },
                    ) {
                        attr("tabindex", "-1")
                        icon { fromTheme { close } }
                        optRemoveHandler?.let {
                            clicks.map { tag.fieldText } handledBy it
                        } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                    }
                }

                KeywordTagActionType.RemoveReadOnly -> {
                    button(
                        {
                            margins { left { tiny } }
                            if (searchable) {
                                hover {
                                    color { makeRGBA(FormationColors.GrayPrivate.color, 0.7) }
                                }
                            }
                        },
                    ) {
                        attr("tabindex", "-1")
                        icon { fromTheme { close } }

                        when (keywordTagType) {
                            KeywordTagType.SearchTag,
                            KeywordTagType.AnalyticsPathViewTag,
                            KeywordTagType.NestedObjectTag -> {
                                when (tag.readOnlyType) {
                                    ReadOnlyTags.ObjectType -> {
                                        removeObjectTypeHandler?.let { handler ->
                                            clicks.map { tag.readOnlyStringValue } handledBy handler
                                        }
                                    }

                                    ReadOnlyTags.Assignee,
                                    ReadOnlyTags.Creator -> {
                                        removeReadOnlyKeywordHandler?.let { handler ->
                                            clicks.map { tag } handledBy handler
                                        }
                                    }

                                    else -> {
                                        optRemoveHandler?.let { handler ->
                                            clicks.map { tag.fieldText } handledBy handler
                                        } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                                    }
                                }
                            }

                            else -> {
                                optRemoveHandler?.let { handler ->
                                    clicks.map { tag.fieldText } handledBy handler
                                } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                            }
                        }
                    }
                }

                KeywordTagActionType.AddFieldValue -> {
                    button(
                        {
                            margins { left { tiny } }
                            if (searchable) {
                                hover {
                                    color { makeRGBA(secondary.main, 0.5) }
                                }
                            }
                        },
                    ) {
                        attr("tabindex", "-1")
                        icon { fromTheme { FormationUIIcons.Add.icon } }
                        when (keywordTagType) {
                            KeywordTagType.AnalyticsPathViewTag -> {
                                clicks.map { tag.customFieldValue } handledBy currentActiveFieldValueStore.addOrChangeHistoryPathFieldValue
                            }

                            else -> {
                                clicks.map { tag.customFieldValue } handledBy currentActiveFieldValueStore.addOrChangeFieldValue
                            }
                        }
                    }
                }

                KeywordTagActionType.RemoveFieldValue -> {
                    button(
                        {
                            margins { left { tiny } }
                            if (searchable) {
                                hover {
                                    color { makeRGBA(FormationColors.GrayPrivate.color, 0.7) }
                                }
                            }
                        },
                    ) {
                        attr("tabindex", "-1")
                        icon { fromTheme { close } }
                        clicks.map { tag.customFieldValue } handledBy removeFieldValueHandler
                    }
                }

                else -> {} // KeyWordTagType Default, ReadOnly
            }
        }
        if (searchable) {
            when (tag.actionType) {
                KeywordTagActionType.ReadOnly, KeywordTagActionType.RemoveReadOnly -> {
                    when (tag.readOnlyType) {
                        ReadOnlyTags.ObjectType -> {
                            when (keywordTagType) {
                                KeywordTagType.SearchTag,
                                KeywordTagType.AnalyticsPathViewTag,
                                KeywordTagType.NestedObjectTag -> {
                                    addObjectTypeHandler?.let { handler ->
                                        clicks.map { tag.readOnlyStringValue } handledBy handler
                                    }
                                }

                                else -> clicks.map { tag.readOnlyStringValue } handledBy activeObjectKeywordsStore.addToActiveSearchObjectTypes
                            }
                        }

                        ReadOnlyTags.Assignee, ReadOnlyTags.Creator -> {
                            when (keywordTagType) {
                                KeywordTagType.SearchTag,
                                KeywordTagType.AnalyticsPathViewTag,
                                KeywordTagType.NestedObjectTag -> {
                                    addReadOnlyKeywordHandler?.let { handler ->
                                        clicks.map { tag } handledBy handler
                                    }
                                }

                                else -> clicks.map { tag } handledBy activeObjectKeywordsStore.addToActiveSearchReadOnlyTags
                            }
                        }

                        else -> {
                            when (keywordTagType) {
                                KeywordTagType.AnalyticsPathViewTag, KeywordTagType.NestedObjectTag -> {
                                    clicks.map { tag.fieldText } handledBy addHandler
                                }

                                else -> clicks.map { tag.fieldText } handledBy activeObjectKeywordsStore.addToActiveSearchKeywords
                            }
                        }
                    }
                }

                KeywordTagActionType.DefaultFieldValue, KeywordTagActionType.AddFieldValue, KeywordTagActionType.RemoveFieldValue -> {
                    when (keywordTagType) {
                        KeywordTagType.SearchTag,
                        KeywordTagType.AnalyticsPathViewTag,
                        -> {
                            clicks.map { tag.customFieldValue } handledBy addFieldValueHandler
                        }

                        else -> {
                            clicks.map { tag.customFieldValue } handledBy activeSearchFieldValuesStore.addAndNavToSearch
                        }
                    }
                }

                else -> {
                    clicks.map { tag.fieldText } handledBy activeObjectKeywordsStore.addToActiveSearchKeywords
                }
            }
        } else {
            when (tag.actionType) {
                KeywordTagActionType.Add -> {
                    clicks.map { tag.fieldText } handledBy addHandler
                }

                KeywordTagActionType.Remove -> {
                    optRemoveHandler?.let {
                        clicks.map { tag.fieldText } handledBy it
                    } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                }

                KeywordTagActionType.RemoveReadOnly -> {
                    when (keywordTagType) {
                        KeywordTagType.SearchTag,
                        KeywordTagType.AnalyticsPathViewTag,
                        KeywordTagType.NestedObjectTag -> {
                            when (tag.readOnlyType) {
                                ReadOnlyTags.ObjectType -> {
                                    removeObjectTypeHandler?.let { handler ->
                                        clicks.map { tag.readOnlyStringValue } handledBy handler
                                    }
                                }

                                ReadOnlyTags.Assignee, ReadOnlyTags.Creator -> {
                                    removeReadOnlyKeywordHandler?.let { handler ->
                                        clicks.map { tag } handledBy handler
                                    }
                                }

                                else -> optRemoveHandler?.let {
                                    clicks.map { tag.fieldText } handledBy it
                                } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                            }
                        }

                        else -> {
                            optRemoveHandler?.let {
                                clicks.map { tag.fieldText } handledBy it
                            } ?: (clicks.map { tag.fieldText } handledBy removeHandler)
                        }
                    }
                }

                KeywordTagActionType.DefaultFieldValue -> {
                    clicks.map { tag.customFieldValue } handledBy addFieldValueHandler
                }
                // Add or change category tag
                KeywordTagActionType.AddFieldValue, KeywordTagActionType.RemoveFieldValue -> {
                    when (keywordTagType) {
                        KeywordTagType.AnalyticsPathViewTag -> {
                            clicks.map { tag.customFieldValue } handledBy currentActiveFieldValueStore.addOrChangeHistoryPathFieldValue
                        }

                        else -> {
                            clicks.map { tag.customFieldValue } handledBy currentActiveFieldValueStore.addOrChangeFieldValue
                        }
                    }
                }

                else -> {} // KeyWordTagType Default, ReadOnly
            }
        }
    }
}

fun RenderContext.tinyKeyWordTag(
    tag: KeywordTag,
    searchable: Boolean = false,
    tagActionType: KeywordTagActionType? = null,
    tagType: KeywordTagType
): Button {
    val activeObjectKeywordsStore: ActiveObjectKeywordsStore by koinCtx.inject()
    val activeArchetypeSearchKeywordsStore: ActiveArchetypeSearchKeywordsStore by koinCtx.inject()
    val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore by koinCtx.inject()
    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()
    val activeNestedSearchFieldValuesStore: ActiveNestedSearchFieldValuesStore by koinCtx.inject()

    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()

    val addKeywordHandler: SimpleHandler<String>
    val addFieldValueHandler: SimpleHandler<FieldValue?>
    val addReadOnlyKeywordHandler: SimpleHandler<KeywordTag>?
    val addObjectTypeHandler: SimpleHandler<String?>?

    when (tagType) {
        KeywordTagType.ArchetypeTag -> {
            addKeywordHandler = activeArchetypeSearchKeywordsStore.addToActiveArchetypeSearchKeywords
            addFieldValueHandler = activeArchetypeSearchFieldValuesStore.addAndNavToToolsOverview
            addReadOnlyKeywordHandler = null
            addObjectTypeHandler = null
        }

        KeywordTagType.AnalyticsPathViewTag -> {
            addKeywordHandler = activeHistoryPathSearchKeywordsStore.add
            addFieldValueHandler = activeHistoryPathSearchFieldValuesStore.add
            addReadOnlyKeywordHandler = activeHistoryPathSearchReadOnlyKeywordsStore.add
            addObjectTypeHandler = activeHistoryPathSearchObjectTypesStore.add
        }

        KeywordTagType.NestedObjectTag -> {
            addKeywordHandler = activeNestedSearchKeywordStore.add
            addFieldValueHandler = activeNestedSearchFieldValuesStore.add
            addReadOnlyKeywordHandler = activeObjectKeywordsStore.addToActiveNestedObjectReadOnlyTags
            addObjectTypeHandler = activeObjectKeywordsStore.addToActiveNestedObjectTypesWithoutRouting
        }

        else -> {
            addKeywordHandler = activeObjectKeywordsStore.addToActiveSearchKeywords
            addFieldValueHandler = activeSearchFieldValuesStore.addAndNavToSearch
            addReadOnlyKeywordHandler = activeObjectKeywordsStore.addToActiveSearchReadOnlyTags
            addObjectTypeHandler = activeObjectKeywordsStore.addToActiveSearchObjectTypes
        }
    }



    return button(
        {
            radius(formationStyles.tagRadius)
            margin { tiny }
            when (tagActionType) {
                KeywordTagActionType.ReadOnly -> {
                    background {
                        color { FormationColors.GrayPrivate.color }
                    }
                    border {
                        color { FormationColors.GrayPrivate.color }
                        width(formationStyles.borderWidth)
                    }
                    if (searchable) {
                        hover {
                            background {
                                color { secondary.main }
                            }
                        }
                        focus {
                            background {
                                color { secondary.main }
                            }
                        }
                    } else {
                        css("cursor:default;")
                    }
                }

                KeywordTagActionType.DefaultFieldValue -> {
                    color { secondary.main }
                    background {
                        color { primary.main }
                    }
                    border {
                        color { primary.main }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                    }
                    focus {
                        color { primary.main }
                        background {
                            color { secondary.main }
                        }
                    }
                }

                else -> {
                    color { secondary.main }
                    background {
                        color { FormationColors.MarkerYou.color }
                    }
                    border {
                        color { FormationColors.MarkerYou.color }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        color { FormationColors.MarkerYou.color }
                        background {
                            color { secondary.main }
                        }
                    }
                    focus {
                        color { FormationColors.MarkerYou.color }
                        background {
                            color { secondary.main }
                        }
                    }
                }
            }
        },
    ) {
        flexBox(
            {
                direction { row }
                width { maxContent }
                height { maxContent }
                alignItems { center }
                justifyContent { center }
                radius(formationStyles.tagRadius)
                paddings {
                    horizontal { smaller }
                }
            },
        ) {
            if (tag.valueText != null) {
                lineUp {
                    spacing { tiny }
                    items {
                        span(
                            {
                                fontWeight { bold }
                                fontSize { "9px" }
                            },
                        ) { +"${tag.fieldText} //" }
                        span(
                            {
                                fontWeight { lighter }
                                fontSize { "9px" }
                            },
                        ) { +tag.valueText }
                    }
                }
            } else {
                span(
                    {
                        fontWeight { bold }
                        fontSize { "9px" }
                    },
                ) { +tag.fieldText }
            }
            if (searchable) {
                when (tagActionType) {
                    KeywordTagActionType.ReadOnly -> {
                        when (tag.readOnlyType) {
                            ReadOnlyTags.ObjectType -> {
                                addObjectTypeHandler?.let { handler ->
                                    clicks.map {
                                        it.stopPropagation()
                                        tag.readOnlyStringValue
                                    } handledBy handler
                                }
                            }

                            ReadOnlyTags.Assignee, ReadOnlyTags.Creator -> {
                                addReadOnlyKeywordHandler?.let { handler ->
                                    clicks.map {
                                        it.stopPropagation()
                                        tag
                                    } handledBy handler
                                }
                            }

                            else -> clicks.map {
                                it.stopPropagation()
                                tag.fieldText
                            } handledBy addKeywordHandler
                        }
                    }

                    KeywordTagActionType.DefaultFieldValue -> {
                        clicks.map {
                            it.stopPropagation()
                            tag.customFieldValue
                        } handledBy addFieldValueHandler
                    }

                    else -> {
                        clicks.map {
                            it.stopPropagation()
                            tag.fieldText
                        } handledBy addKeywordHandler
                    }
                }
            }
        }
    }
}

fun RenderContext.keywordTagList(
    keywords: Flow<List<KeywordTag>>,
    keywordTagType: KeywordTagType,
    searchable: Boolean = false,
    tagSize: KeywordTagSize = KeywordTagSize.Normal
) {
    keywords.render { keywordList ->
        if (keywordList.isNotEmpty()) {
            flexBox(
                {
                    width { full }
                    height { maxContent }
                    wrap { wrap }
                },
            ) {
                keywordList.sortKeywordTags().forEach { keywordTag ->
                    when (tagSize) {
                        KeywordTagSize.Tiny -> tinyKeyWordTag(
                            tag = keywordTag,
                            searchable = searchable,
                            tagActionType = keywordTag.actionType,
                            tagType = keywordTagType,
                        )

                        KeywordTagSize.Normal -> keywordTag(
                            tag = keywordTag,
                            searchable = searchable,
                            keywordTagType = keywordTagType,
                        )
                    }
                }
            }
        }
    }
}
