package utils

import apiclient.auth.Token
import apiclient.geoobjects.ChangeType
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.HistoryArgs
import apiclient.geoobjects.LatLon
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.MarkerShape
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskState
import apiclient.localizations.LocalizationArg
import apiclient.localizations.TranslationKey
import apiclient.markers.DefaultLayers
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import camera.nfc.NFCStatus
import data.objects.views.IconGroup
import dev.fritz2.styling.params.ScaledValueProperty
import dev.fritz2.styling.theme.IconDefinition
import koin.koinCtx
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import location.geolocation.GeoPosition
import location.geolocation.GeolocationException
import model.CodeTech
import model.NotificationTagData
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationShapes
import theme.FormationUIIcons

fun <T> Result<T>.asGeolocationException(): GeolocationException? {
    return exceptionOrNull() as? GeolocationException
}

fun ObjectType.getName(): String {
    val translation: Translation by koinCtx.inject()

    return when (this) {
        ObjectType.POI -> translation.getString(TL.ObjectTypeTitle.POI)
        ObjectType.Task -> translation.getString(TL.ObjectTypeTitle.TASK)
        ObjectType.Event -> translation.getString(TL.ObjectTypeTitle.EVENT)
        ObjectType.UserMarker -> translation.getString(TL.ObjectTypeTitle.USER_MARKER)
        ObjectType.CurrentUserMarker -> translation.getString(TL.ObjectTypeTitle.CURRENT_USER_MARKER)
        ObjectType.ObjectMarker -> translation.getString(TL.ObjectTypeTitle.OBJECT_MARKER)
        ObjectType.Building -> translation.getString(TL.ObjectTypeTitle.BUILDING)
        ObjectType.Floor -> translation.getString(TL.ObjectTypeTitle.FLOOR)
        ObjectType.Unit -> translation.getString(TL.ObjectTypeTitle.UNIT)
        ObjectType.Area -> translation.getString(TL.ObjectTypeTitle.AREA)
        ObjectType.TransientMarker -> translation.getString(TL.ObjectTypeTitle.TRANSIENT_MARKER)
        ObjectType.Zone -> translation.getString(TL.ObjectTypeTitle.ZONE)
        ObjectType.Notification -> translation.getString(TL.ObjectTypeTitle.NOTIFICATION)
        ObjectType.GeoFence -> translation.getString(TL.ObjectTypeTitle.GEOFENCE)
        ObjectType.HistoryEntry -> translation.getString(TL.ObjectTypeTitle.HISTORY_ENTRY)
        ObjectType.GeneralMarker -> translation.getString(TL.ObjectTypeTitle.GENERAL_MARKER)
        else -> ""
    }
}

fun ObjectType.translateName(): Flow<String> {
    val translation: Translation by koinCtx.inject()

    return when (this) {
        ObjectType.POI -> translation[TL.ObjectTypeTitle.POI]
        ObjectType.Task -> translation[TL.ObjectTypeTitle.TASK]
        ObjectType.Event -> translation[TL.ObjectTypeTitle.EVENT]
        ObjectType.UserMarker -> translation[TL.ObjectTypeTitle.USER_MARKER]
        ObjectType.CurrentUserMarker -> translation[TL.ObjectTypeTitle.CURRENT_USER_MARKER]
        ObjectType.ObjectMarker -> translation[TL.ObjectTypeTitle.OBJECT_MARKER]
        ObjectType.Building -> translation[TL.ObjectTypeTitle.BUILDING]
        ObjectType.Floor -> translation[TL.ObjectTypeTitle.FLOOR]
        ObjectType.Unit -> translation[TL.ObjectTypeTitle.UNIT]
        ObjectType.Area -> translation[TL.ObjectTypeTitle.AREA]
        ObjectType.TransientMarker -> translation[TL.ObjectTypeTitle.TRANSIENT_MARKER]
        ObjectType.Zone -> translation[TL.ObjectTypeTitle.ZONE]
        ObjectType.Notification -> translation[TL.ObjectTypeTitle.NOTIFICATION]
        ObjectType.HistoryEntry -> translation[TL.ObjectTypeTitle.HISTORY_ENTRY]
        ObjectType.GeneralMarker -> translation[TL.ObjectTypeTitle.GENERAL_MARKER]
        else -> flowOf("")
    }
}

fun ObjectType.getCase(): String {

    return when (this) {
        ObjectType.POI -> "masculine"
        ObjectType.Task -> "feminine"
        ObjectType.Event -> "other"
        ObjectType.UserMarker -> "masculine"
        ObjectType.CurrentUserMarker -> "masculine"
        ObjectType.ObjectMarker -> "other"
        ObjectType.Building -> "other"
        ObjectType.Floor -> "masculine"
        ObjectType.Unit -> "masculine"
        ObjectType.Area -> "feminine"
        ObjectType.TransientMarker -> "masculine"
        ObjectType.Zone -> "feminine"
        ObjectType.Notification -> "feminine"
        ObjectType.HistoryEntry -> "other"
        ObjectType.GeneralMarker -> "masculine"
        else -> ""
    }
}

// TODO define markers for Floor, Unit, Area...
fun ObjectType.getIcon(): FormationIcons {
    return when (this) {
        ObjectType.POI -> FormationIcons.Location
        ObjectType.Task -> FormationIcons.TaskAlt
        ObjectType.Event -> FormationIcons.Time
        ObjectType.UserMarker -> FormationIcons.UserAlt
        ObjectType.CurrentUserMarker -> FormationIcons.UserAlt
        ObjectType.ObjectMarker -> FormationIcons.TrackedObject
        ObjectType.Building -> FormationIcons.Building
        ObjectType.Floor -> FormationIcons.Area
        ObjectType.Unit -> FormationIcons.Area
        ObjectType.Area -> FormationIcons.Area
        ObjectType.Zone -> FormationIcons.Zone
        ObjectType.TransientMarker -> FormationIcons.LocationAlt
        ObjectType.Notification -> FormationIcons.Information
        ObjectType.HistoryEntry -> FormationIcons.History
        ObjectType.GeneralMarker -> FormationIcons.GeoCaching
        else -> FormationIcons.Location
    }
}

fun TaskState.getTitle(): String {

    val translation: Translation by koinCtx.inject()

    return when (this) {
        TaskState.UnAssigned -> translation.getString(TL.TaskStateTitle.UNASSIGNED)
        TaskState.InProgress -> translation.getString(TL.TaskStateTitle.INPROGRESS)
        TaskState.Completed -> translation.getString(TL.TaskStateTitle.COMPLETED)
        TaskState.Pending -> translation.getString(TL.TaskStateTitle.PENDING)
        TaskState.Problem -> translation.getString(TL.TaskStateTitle.PROBLEM)
        else -> "?"
    }
}

fun TaskState.getSubtitle(): String {

    val translation: Translation by koinCtx.inject()

    return when (this) {
        TaskState.UnAssigned -> translation.getString(TL.TaskStateSubTitle.UNASSIGNED)
        TaskState.InProgress -> translation.getString(TL.TaskStateSubTitle.INPROGRESS)
        TaskState.Completed -> translation.getString(TL.TaskStateSubTitle.COMPLETED)
        TaskState.Pending -> translation.getString(TL.TaskStateSubTitle.PENDING)
        TaskState.Problem -> translation.getString(TL.TaskStateSubTitle.PROBLEM)
        else -> "?"
    }
}

fun TaskState.getIcon(): IconDefinition {
    val defaultTheme = koinCtx.get<FormationDefault>()
    return when (this) {
        TaskState.UnAssigned -> {
            defaultTheme.icons.circleHelp
        }

        TaskState.InProgress -> {
            FormationIcons.Wait.icon
        }

        TaskState.Completed -> {
            defaultTheme.icons.check
        }

        TaskState.Pending -> {
            FormationIcons.Busy.icon
        }

        TaskState.Problem -> {
            FormationIcons.Warning.icon
        }

        else -> {
            defaultTheme.icons.circleHelp
        }
    }
}

fun TaskState.getColor(): FormationColors {
    return when (this) {
        TaskState.UnAssigned -> FormationColors.GrayDisabled
        TaskState.Pending -> FormationColors.GrayDisabled
        TaskState.InProgress -> FormationColors.YellowDoing
        TaskState.Completed -> FormationColors.GreenActive
        TaskState.Problem -> FormationColors.RedError
        else -> FormationColors.GrayDisabled
    }
}

fun MeetingInvitationStatus.getIcon(): IconDefinition {
    val defaultTheme = koinCtx.get<FormationDefault>()
    return when (this) {
        MeetingInvitationStatus.Pending -> FormationIcons.Wait.icon
        MeetingInvitationStatus.Maybe -> defaultTheme.icons.remove
        MeetingInvitationStatus.Accepted -> defaultTheme.icons.check
        MeetingInvitationStatus.Rejected -> defaultTheme.icons.close
    }
}

fun MeetingInvitationStatus.getColor(): FormationColors {
    return when (this) {
        MeetingInvitationStatus.Pending -> FormationColors.BlueDeep
        MeetingInvitationStatus.Maybe -> FormationColors.YellowDoing
        MeetingInvitationStatus.Accepted -> FormationColors.GreenActive
        MeetingInvitationStatus.Rejected -> FormationColors.RedError
    }
}

fun MeetingInvitationStatus?.getName(): String {

    val translation: Translation by koinCtx.inject()

    return when (this) {
        MeetingInvitationStatus.Pending -> translation.getString(TL.MeetingInvitationStatus.PENDING)
        MeetingInvitationStatus.Maybe -> translation.getString(TL.MeetingInvitationStatus.MAYBE)
        MeetingInvitationStatus.Accepted -> translation.getString(TL.MeetingInvitationStatus.ACCEPTED)
        MeetingInvitationStatus.Rejected -> translation.getString(TL.MeetingInvitationStatus.REJECTED)
        else -> translation.getString(TL.MeetingInvitationStatus.UNKNOWN)
    }
}

fun MarkerColor?.getColorForIcon(): FormationColors {
    return with(this) {
        when (this) {
            MarkerColor.LightGrey -> FormationColors.GrayLightCustom
            MarkerColor.Grey -> FormationColors.GrayCustom
            MarkerColor.Black -> FormationColors.Black
            MarkerColor.LightGreen -> FormationColors.GreenLightCustom
            MarkerColor.Green -> FormationColors.GreenCustom
            MarkerColor.DarkGreen -> FormationColors.GreenDarkCustom
            MarkerColor.AquaMarine -> FormationColors.BlueAuqamarineCustom
            MarkerColor.Turquoise -> FormationColors.TurquoiseCustom
            MarkerColor.LightBlue -> FormationColors.BlueLightCustom
            MarkerColor.Blue -> FormationColors.BlueCustom
            MarkerColor.DarkBlue -> FormationColors.MarkerPoint //FormationColors.BlueDarkCustom
            MarkerColor.Yellow -> FormationColors.YellowCustom
            MarkerColor.Orange -> FormationColors.OrangeCustom
            MarkerColor.Red -> FormationColors.RedCustom
            MarkerColor.DarkRed -> FormationColors.RedDarkCustom
            MarkerColor.GreenAlt -> FormationColors.GreenAltCustom
            MarkerColor.DarkOrange -> FormationColors.DarkOrangeCustom
            MarkerColor.LightBlueAlt -> FormationColors.LightBlueAltCustom
            MarkerColor.LightGreenAlt -> FormationColors.LightGreenAltCustom
            MarkerColor.DarkMagenta -> FormationColors.DarkMagentaCustom
            MarkerColor.White -> FormationColors.White
            MarkerColor.BlueMidnight -> FormationColors.BlueMidnight
            MarkerColor.BlueSky -> FormationColors.BlueSky
            MarkerColor.BlueLavender -> FormationColors.BlueLavender
            MarkerColor.GraySilver -> FormationColors.GraySilver
            MarkerColor.GraySteel -> FormationColors.GraySteel
            MarkerColor.GraySlate -> FormationColors.GraySlate
            MarkerColor.GreenMoss -> FormationColors.GreenMoss
            MarkerColor.GreenTurquoise -> FormationColors.GreenTurquoise
            MarkerColor.GreenMint -> FormationColors.GreenMint
            MarkerColor.GreenSoft -> FormationColors.GreenSoft
            MarkerColor.GreenVibrant -> FormationColors.GreenVibrant
            MarkerColor.GreenFresh -> FormationColors.GreenFresh
            MarkerColor.RedDarkCrimson -> FormationColors.RedDarkCrimson
            MarkerColor.RedFire -> FormationColors.RedFire
            MarkerColor.RedSalmon -> FormationColors.RedSalmon
            MarkerColor.OrangePeach -> FormationColors.OrangePeach
            MarkerColor.OrangeAmber -> FormationColors.OrangeAmber
            MarkerColor.OrangeRust -> FormationColors.OrangeRust

            else -> FormationColors.BlueDeep
        }
    }
}

fun MarkerColor?.getColorForStatus(): FormationColors {
    return with(this) {
        when (this) {
            MarkerColor.LightGrey -> FormationColors.GrayLight
            MarkerColor.Grey -> FormationColors.GrayDisabled
            MarkerColor.Black -> FormationColors.Black
            MarkerColor.LightGreen -> FormationColors.GreenLight
            MarkerColor.Green -> FormationColors.GreenForest
            MarkerColor.DarkGreen -> FormationColors.GreenDeepForest
            MarkerColor.AquaMarine -> FormationColors.BlueMarine
            MarkerColor.Turquoise -> FormationColors.TurquoiseCustom
            MarkerColor.LightBlue -> FormationColors.BlueLight
            MarkerColor.Blue -> FormationColors.MarkerYou
            MarkerColor.DarkBlue -> FormationColors.BlueDeep
            MarkerColor.Yellow -> FormationColors.YellowDoing
            MarkerColor.Orange -> FormationColors.OrangeCustom
            MarkerColor.Red -> FormationColors.RedError
            MarkerColor.DarkRed -> FormationColors.RedDarkCustom
            else -> FormationColors.GrayDisabled
        }
    }
}

fun MarkerIcon.getIcon(defaultIcon: FormationIcons? = FormationIcons.Location): FormationIcons {
    return when (this) {
        MarkerIcon.Setting -> FormationIcons.Gear
        MarkerIcon.Toilet -> FormationIcons.Restroom
        MarkerIcon.Equipment -> FormationIcons.Location
        MarkerIcon.Gear -> FormationIcons.Gears
        MarkerIcon.Event -> FormationIcons.Time
        MarkerIcon.User -> FormationIcons.UserAlt
        MarkerIcon.Object -> FormationIcons.TrackedObject
        MarkerIcon.AGVBosch -> FormationIcons.AGVB
        MarkerIcon.SingelBox -> FormationIcons.SingleBox
        MarkerIcon.Heatmap -> FormationIcons.HeatmapAlt
        MarkerIcon.Electricity -> FormationIcons.Location // FIXME missing
        MarkerIcon.Default -> FormationIcons.Location // FIXME missing
        MarkerIcon.Cluster -> FormationIcons.Location // FIXME missing
        // the error here will only happen if you set the default to null
        // we use this in the test to be able to detect missing icons
        else -> parseEnumValue<FormationIcons>(name)
            ?: defaultIcon
            ?: error("no icon $name in FormationIcons")
    }
}

fun MarkerIcon.getIconGroup(): IconGroup {
    return with(this) {
        when (this) {
            MarkerIcon.Default -> IconGroup.Other
            MarkerIcon.Location -> IconGroup.Information
            MarkerIcon.Flag -> IconGroup.Information
            MarkerIcon.Megaphone -> IconGroup.Office
            MarkerIcon.Block -> IconGroup.Information
            MarkerIcon.Bolt -> IconGroup.Information
            MarkerIcon.Coffee -> IconGroup.Office
            MarkerIcon.Information -> IconGroup.Information
            MarkerIcon.PaintBrush -> IconGroup.Office
            MarkerIcon.Tools -> IconGroup.Facility
            MarkerIcon.Turkey -> IconGroup.Office
            MarkerIcon.Apple -> IconGroup.Office
            MarkerIcon.Badge -> IconGroup.Information
            MarkerIcon.Boxes -> IconGroup.Office
            MarkerIcon.Setting -> IconGroup.Information
            MarkerIcon.Cupcake -> IconGroup.Office
            MarkerIcon.Toilet -> IconGroup.Facility
            MarkerIcon.Shop -> IconGroup.Other
            MarkerIcon.FireExtinguisher -> IconGroup.Facility
            MarkerIcon.NoAccess -> IconGroup.Information
            MarkerIcon.Security -> IconGroup.Other
            MarkerIcon.Equipment -> IconGroup.Other
            MarkerIcon.Lightbulb -> IconGroup.Office
            MarkerIcon.Gear -> IconGroup.Other
            MarkerIcon.Electricity -> IconGroup.Facility // TODO
            MarkerIcon.Desk -> IconGroup.Office
            MarkerIcon.Printer -> IconGroup.Office
            MarkerIcon.Forklift -> IconGroup.Vehicles
            MarkerIcon.Helmet -> IconGroup.Other
            MarkerIcon.Car -> IconGroup.Vehicles
            MarkerIcon.Train -> IconGroup.Vehicles
            MarkerIcon.Bin -> IconGroup.Facility
            MarkerIcon.Folder -> IconGroup.Office
            MarkerIcon.Computer -> IconGroup.Office
            MarkerIcon.AGV -> IconGroup.Vehicles
            MarkerIcon.Robot -> IconGroup.Other
            MarkerIcon.Projector -> IconGroup.Office
            MarkerIcon.Toolbox -> IconGroup.Other
            MarkerIcon.API -> IconGroup.Office
            MarkerIcon.Bicycle -> IconGroup.Vehicles
            MarkerIcon.Door -> IconGroup.Facility
            MarkerIcon.Elevator -> IconGroup.Facility
            MarkerIcon.Exit -> IconGroup.Information
            MarkerIcon.FaceMask -> IconGroup.Health
            MarkerIcon.FirstAid -> IconGroup.Health
            MarkerIcon.Food -> IconGroup.Office
            MarkerIcon.GolfCart -> IconGroup.Vehicles
            MarkerIcon.Defibrillator -> IconGroup.Health
            MarkerIcon.Medical -> IconGroup.Health
            MarkerIcon.MeetingRoom -> IconGroup.Office
            MarkerIcon.NoPhone -> IconGroup.Information
            MarkerIcon.NoSmoking -> IconGroup.Information
            MarkerIcon.OfficeDesk -> IconGroup.Office
            MarkerIcon.Parking -> IconGroup.Facility
            MarkerIcon.QRCode -> IconGroup.Other
            MarkerIcon.SecurityCamera -> IconGroup.Facility
            MarkerIcon.Server -> IconGroup.Office
            MarkerIcon.Star -> IconGroup.Information
            MarkerIcon.UserGroup -> IconGroup.Office
            MarkerIcon.WiFi -> IconGroup.Office
            MarkerIcon.WirelessDoorLock -> IconGroup.Office
            MarkerIcon.Camera -> IconGroup.Other
            MarkerIcon.Stairs -> IconGroup.Facility
            MarkerIcon.Caution -> IconGroup.Information
            MarkerIcon.ArrowRight -> IconGroup.Information
            MarkerIcon.WaterFaucet -> IconGroup.Facility
            MarkerIcon.Event -> IconGroup.Office
            MarkerIcon.Task -> IconGroup.Office
            MarkerIcon.Building -> IconGroup.Facility
            MarkerIcon.User -> IconGroup.Office
            MarkerIcon.Object -> IconGroup.Other
            MarkerIcon.AGVBosch -> IconGroup.Vehicles
            MarkerIcon.Booth -> IconGroup.Other
            MarkerIcon.FoodTruck -> IconGroup.Vehicles
            MarkerIcon.FormationLogo -> IconGroup.Other
            MarkerIcon.GeoCaching -> IconGroup.Other
            MarkerIcon.Milkrun -> IconGroup.Vehicles
            MarkerIcon.Prize -> IconGroup.Other
            MarkerIcon.Regal -> IconGroup.Office
            MarkerIcon.SingelBox -> IconGroup.Other
            MarkerIcon.Stage -> IconGroup.Other
            MarkerIcon.NFC -> IconGroup.Other
            MarkerIcon.TrackedObject -> IconGroup.Other
            MarkerIcon.Container -> IconGroup.Other
            MarkerIcon.Coil -> IconGroup.Other
            MarkerIcon.Tablet -> IconGroup.Office
            MarkerIcon.CameraDrone -> IconGroup.Vehicles
            MarkerIcon.Clip -> IconGroup.Office
            MarkerIcon.Flashlight -> IconGroup.Other
            MarkerIcon.Notebook -> IconGroup.Office
            MarkerIcon.Scissors -> IconGroup.Office
            MarkerIcon.VRGlasses -> IconGroup.Other
            MarkerIcon.Clipboard -> IconGroup.Office
            MarkerIcon.Whiteboard -> IconGroup.Office
            MarkerIcon.Pen -> IconGroup.Office
            MarkerIcon.Flooding -> IconGroup.Hazards
            MarkerIcon.ClearanceVehicle -> IconGroup.Vehicles
            MarkerIcon.Antenna -> IconGroup.Hazards
            MarkerIcon.RoadBlock -> IconGroup.Hazards
            MarkerIcon.Fire -> IconGroup.Hazards
            MarkerIcon.Rubble -> IconGroup.Hazards
            MarkerIcon.SixFeetApart -> IconGroup.Health
            MarkerIcon.AirPortShuttle -> IconGroup.Vehicles
            MarkerIcon.Ambulance -> IconGroup.Vehicles
            MarkerIcon.Apartment -> IconGroup.Facility
            MarkerIcon.BabyChangingStation -> IconGroup.Health
            MarkerIcon.Bed -> IconGroup.Health
            MarkerIcon.PhoneChargingStation -> IconGroup.Other
            MarkerIcon.Ferry -> IconGroup.Vehicles
            MarkerIcon.Family -> IconGroup.Health
            MarkerIcon.GenderFemale -> IconGroup.Health
            MarkerIcon.GenderMale -> IconGroup.Health
            MarkerIcon.GenderDiverse -> IconGroup.Health
            MarkerIcon.Hospital -> IconGroup.Health
            MarkerIcon.NightShelter -> IconGroup.Health
            MarkerIcon.Church -> IconGroup.Other
            MarkerIcon.Embassy -> IconGroup.Other
            MarkerIcon.FireFighter -> IconGroup.Hazards
            MarkerIcon.InformationAlt -> IconGroup.Information
            MarkerIcon.MoneyExchange -> IconGroup.Other
            MarkerIcon.PoliceOfficer -> IconGroup.Hazards
            MarkerIcon.DangerExplosions -> IconGroup.Hazards
            MarkerIcon.DangerAerialBombs -> IconGroup.Hazards
            MarkerIcon.DangerAerialBombsAlt -> IconGroup.Hazards
            MarkerIcon.Tank -> IconGroup.Hazards
            MarkerIcon.Departures -> IconGroup.Vehicles
            MarkerIcon.BioHazard -> IconGroup.Hazards
            MarkerIcon.Bricks -> IconGroup.Hazards
            MarkerIcon.PassageForbidden -> IconGroup.Information
            MarkerIcon.HighVoltage -> IconGroup.Information
            MarkerIcon.Ladder -> IconGroup.Other
            MarkerIcon.LocationAlt -> IconGroup.Information
            MarkerIcon.PowerPlug -> IconGroup.Other
            MarkerIcon.OldTelephone -> IconGroup.Other
            MarkerIcon.MeetingPoint -> IconGroup.Information
            MarkerIcon.MeetingPointAlt -> IconGroup.Information
            MarkerIcon.Pets -> IconGroup.Health
            MarkerIcon.Translate -> IconGroup.Other
            MarkerIcon.TelephoneTypeWriter -> IconGroup.Other
            MarkerIcon.WheelChairPickup -> IconGroup.Health
            MarkerIcon.RatTrap -> IconGroup.Other
            MarkerIcon.Barrier -> IconGroup.Hazards
            MarkerIcon.BarrierAlt -> IconGroup.Hazards
            MarkerIcon.MetroStation -> IconGroup.Vehicles
            MarkerIcon.Vaccination -> IconGroup.Health
            MarkerIcon.Passport -> IconGroup.Other
            MarkerIcon.Pin -> IconGroup.Information
            MarkerIcon.AidBadge -> IconGroup.Health
            MarkerIcon.AidTag -> IconGroup.Health
            MarkerIcon.PaperTowels -> IconGroup.Health
            MarkerIcon.Chemicals -> IconGroup.Health
            MarkerIcon.Crane -> IconGroup.Vehicles
            MarkerIcon.HeartRate -> IconGroup.Health
            MarkerIcon.DisplayGraph -> IconGroup.Office
            MarkerIcon.Mortar -> IconGroup.Health
            MarkerIcon.Mattress -> IconGroup.Health
            MarkerIcon.TaskMedic -> IconGroup.Health
            MarkerIcon.FolderMedic -> IconGroup.Health
            MarkerIcon.Syringe -> IconGroup.Health
            MarkerIcon.BandAids -> IconGroup.Health
            MarkerIcon.BloodPressure -> IconGroup.Health
            MarkerIcon.Cardiogram -> IconGroup.Health
            MarkerIcon.EyeDropper -> IconGroup.Health
            MarkerIcon.HospitalBed -> IconGroup.Health
            MarkerIcon.HospitalBedAlt -> IconGroup.Health
            MarkerIcon.Lifter -> IconGroup.Vehicles
            MarkerIcon.ScaleAnalog -> IconGroup.Health
            MarkerIcon.Pills -> IconGroup.Health
            MarkerIcon.PillsBottle -> IconGroup.Health
            MarkerIcon.PillsBox -> IconGroup.Health
            MarkerIcon.HandTruck -> IconGroup.Other
            MarkerIcon.ScaleDigital -> IconGroup.Health
            MarkerIcon.ShoppingCart -> IconGroup.Health
            MarkerIcon.Stethoscope -> IconGroup.Health
            MarkerIcon.Thermostat -> IconGroup.Health
            MarkerIcon.UpDown -> IconGroup.Other
            MarkerIcon.Dingo -> IconGroup.Vehicles
            MarkerIcon.Eagle -> IconGroup.Vehicles
            MarkerIcon.Multi -> IconGroup.Vehicles
            MarkerIcon.Patriot -> IconGroup.Vehicles
            MarkerIcon.Wolf -> IconGroup.Vehicles
            MarkerIcon.Axle -> IconGroup.Vehicles
            MarkerIcon.WaitAT -> IconGroup.Vehicles
            MarkerIcon.WaitET -> IconGroup.Vehicles
            MarkerIcon.Gears -> IconGroup.Vehicles
            MarkerIcon.Engine -> IconGroup.Vehicles
            MarkerIcon.Zone -> IconGroup.Facility
            MarkerIcon.Cake -> IconGroup.Office
            MarkerIcon.BirthdayCake -> IconGroup.Office
            MarkerIcon.Cheese -> IconGroup.Office
            MarkerIcon.Sausage -> IconGroup.Office
            MarkerIcon.Beer -> IconGroup.Office
            MarkerIcon.Wineglass -> IconGroup.Office
            MarkerIcon.Pint -> IconGroup.Office
            MarkerIcon.Music -> IconGroup.Office
            MarkerIcon.Microphone -> IconGroup.Office
            MarkerIcon.Box -> IconGroup.Office
            MarkerIcon.SmileyGood -> IconGroup.Emojis
            MarkerIcon.SmileyAlright -> IconGroup.Emojis
            MarkerIcon.SmileyNotGood -> IconGroup.Emojis
            MarkerIcon.SmileyHappy -> IconGroup.Emojis
            MarkerIcon.SmileyAfraid -> IconGroup.Emojis
            MarkerIcon.SmileyDead -> IconGroup.Emojis
            MarkerIcon.SmileyLaugh -> IconGroup.Emojis
            MarkerIcon.SmileyOk -> IconGroup.Emojis
            MarkerIcon.SmileySmile -> IconGroup.Emojis
            MarkerIcon.SmileySad -> IconGroup.Emojis
            MarkerIcon.SmileyOuch -> IconGroup.Emojis
            MarkerIcon.SmileyQuiet -> IconGroup.Emojis
            MarkerIcon.SmileyUpsideDown -> IconGroup.Emojis
            MarkerIcon.Heatmap -> IconGroup.Other
            MarkerIcon.WheelChair -> IconGroup.Information
            MarkerIcon.Wardrobe -> IconGroup.Information
            MarkerIcon.Handbag -> IconGroup.Information
            MarkerIcon.Hanger -> IconGroup.Information
            MarkerIcon.Screwdrivers -> IconGroup.Tools
            MarkerIcon.LadderAlt -> IconGroup.Tools
            MarkerIcon.JackStand -> IconGroup.Tools
            MarkerIcon.HandScanner -> IconGroup.Tools
            MarkerIcon.Hammer -> IconGroup.Tools
            MarkerIcon.GreaseGun -> IconGroup.Tools
            MarkerIcon.Drill -> IconGroup.Tools
            MarkerIcon.DiagnosticTool -> IconGroup.Tools
            MarkerIcon.AirCompressor -> IconGroup.Tools
            MarkerIcon.Drain -> IconGroup.Tools
            MarkerIcon.FluidDrop -> IconGroup.Health
            MarkerIcon.WaterSupply -> IconGroup.Health
            MarkerIcon.WaterBottle -> IconGroup.Office
            MarkerIcon.WaterGlass -> IconGroup.Office
            MarkerIcon.Drinks -> IconGroup.Office
            MarkerIcon.Cocktail -> IconGroup.Office
            MarkerIcon.WineBottle -> IconGroup.Office
            else -> IconGroup.Other
        }
    }
}

fun MarkerShape.getShape(): FormationShapes {
    return with(this) {
        when (this) {
            MarkerShape.Circle -> FormationShapes.Circle
            MarkerShape.Square -> FormationShapes.Square
            MarkerShape.Diamond -> FormationShapes.Diamond
            MarkerShape.TriangleUp -> FormationShapes.TriangleUp
            MarkerShape.TriangleDown -> FormationShapes.TriangleDown
            MarkerShape.RoundWithPointer -> FormationShapes.CircleWithPointer
            MarkerShape.SquareWithPointer -> FormationShapes.SquarewithPointer
            MarkerShape.Pentagon -> FormationShapes.Pentagon
            MarkerShape.ArrowUp -> FormationShapes.ArrowUp
            MarkerShape.ArrowDown -> FormationShapes.ArrowDown
            MarkerShape.Egg -> FormationShapes.Egg
            MarkerShape.Star -> FormationShapes.Star
            MarkerShape.Heart -> FormationShapes.Heart
            MarkerShape.Cross -> FormationShapes.Cross
            MarkerShape.Trapezoid -> FormationShapes.Trapezoid
            else -> FormationShapes.Circle
        }
    }
}

fun FormationShapes.getOffset(): ScaledValueProperty? {
    return with(this) {
        when (this) {
            FormationShapes.Circle -> null
            FormationShapes.Square -> null
            FormationShapes.Diamond -> null
            FormationShapes.TriangleUp -> {
                { "-4px" }
            }

            FormationShapes.TriangleDown -> {
                { "4px" }
            }

            FormationShapes.CircleWithPointer -> {
                { "3px" }
            }

            FormationShapes.SquarewithPointer -> {
                { "3px" }
            }

            FormationShapes.Pentagon -> {
                { "-2px" }
            }

            FormationShapes.ArrowUp -> {
                { "1px" }
            }

            FormationShapes.ArrowDown -> {
                { "-1px" }
            }

            FormationShapes.Egg -> null
            FormationShapes.Star -> {
                { "-1px" }
            }

            FormationShapes.Heart -> null
            FormationShapes.Cross -> null
            FormationShapes.Trapezoid -> null
        }
    }
}

fun FormationShapes.getOffsetInt(): Int? {
    return with(this) {
        when (this) {
            FormationShapes.Circle -> null
            FormationShapes.Square -> null
            FormationShapes.Diamond -> null
            FormationShapes.TriangleUp -> -4
            FormationShapes.TriangleDown -> 4
            FormationShapes.CircleWithPointer -> 3
            FormationShapes.SquarewithPointer -> 3
            FormationShapes.Pentagon -> -2
            FormationShapes.ArrowUp -> 1
            FormationShapes.ArrowDown -> -1
            FormationShapes.Egg -> null
            FormationShapes.Star -> -1
            FormationShapes.Heart -> null
            FormationShapes.Cross -> null
            FormationShapes.Trapezoid -> null
        }
    }
}

fun Double.roundTo(numFractionDigits: Int): Double {
    val factor = 10.0.pow(numFractionDigits.toDouble())
    return (this * factor).roundToInt() / factor
}

fun Double?.getDistanceString(): String {
    return if (this != null) {
        when {
            this.toInt() in 0..999 -> "${this.roundTo(1)}m"
            this > 999.99 -> "${(this / 1000).roundTo(1)}km"
            else -> ""
        }
    } else ""
}

fun Int.px() = "${this}px"
fun Double.px() = "${this}px"

fun ObjectType.getMapLayersToClean(): List<String> {
    return when (this) {
        ObjectType.POI -> listOf(DefaultLayers.MyPoints, DefaultLayers.OtherPoints)
        ObjectType.Task -> listOf(DefaultLayers.MyTasks, DefaultLayers.MyCompletedTasks, DefaultLayers.OtherTasks)
        ObjectType.Event -> listOf(DefaultLayers.MyMeetings, DefaultLayers.OtherMeetings)
        ObjectType.Building -> listOf(DefaultLayers.Buildings)
        ObjectType.ObjectMarker -> listOf(DefaultLayers.ObjectMarkers)
        ObjectType.Area, ObjectType.Zone -> listOf(DefaultLayers.AreasAndZones)
        ObjectType.GeneralMarker -> listOf(DefaultLayers.GeneralMarkers)
        else -> listOf()
    }.map { it.mapLayerMetaData.id }
}

fun TranslationKey.getMessage(objResult: GeoObjectDetails): Flow<String> {
    val translation: Translation by koinCtx.inject()
    val title = objResult.tags.getUniqueTag(LocalizationArg.OBJECT_TITLE) ?: "obj_title"
    val triggeredByUser = objResult.tags.getUniqueTag(LocalizationArg.NOTIFICATION_TRIGGERED_BY_NAME) ?: "user_name"
    val newInviteStatus =
        parseEnumValue<MeetingInvitationStatus>(objResult.tags.getUniqueTag(LocalizationArg.MEETING_INVITE_STATUS))?.getName()
            ?: "invitation_status"
    val newTaskStatus =
        parseEnumValue<TaskState>(objResult.tags.getUniqueTag(LocalizationArg.TASK_STATUS))?.getTitle() ?: "task_status"
    val objType = objResult.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE) ?: "obj_type"
    val atTime = objResult.tags.getUniqueTag(LocalizationArg.OBJECT_AT_TIME) ?: "time_not_available"

    return when (this) {
        TranslationKey.MEETING_INVITED -> translation[
            TL.Notifications.MEETING_INVITED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.MEETING_INVITE_STATUS_CHANGE -> translation[
            TL.Notifications.MEETING_INVITE_STATUS_CHANGE,
            mapOf(
                "userName" to triggeredByUser,
                "objectTitle" to title,
                "status" to newInviteStatus,
            ),
        ]

        TranslationKey.MEETING_INVITE_REMOVED -> translation[
            TL.Notifications.MEETING_INVITE_REMOVED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.TASK_ASSIGNED -> translation[
            TL.Notifications.TASK_ASSIGNED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.TASK_UNASSIGNED -> translation[
            TL.Notifications.TASK_UNASSIGNED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.TASK_STATE_CHANGED -> translation[
            TL.Notifications.TASK_STATE_CHANGED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
                "newTaskState" to newTaskStatus,
            ),
        ]

        TranslationKey.OBJECT_MODIFIED -> translation[
            TL.Notifications.OBJECT_MODIFIED,
            mapOf(
                "objectTitle" to title,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.REMINDER -> when (parseEnumValue<ObjectType>(objType)) {
            ObjectType.Event -> translation[
                TL.Notifications.REMINDER_EVENT,
                mapOf(
                    "objectTitle" to title,
                    "atTime" to atTime,
                ),
            ]

            ObjectType.Task -> translation[
                TL.Notifications.REMINDER_TASK,
                mapOf(
                    "objectTitle" to title,
                    "atTime" to atTime,
                ),
            ]

            else -> {
                console.warn("missing LocalizationArg.OBJECT_TYPE on reminder notification")
                flowOf("Reminder")
            }
        }

        else -> {
            error("missing branch for $this")
        }
    }
}

fun TranslationKey.getShortMessage(objResult: GeoObjectDetails): Flow<String> {
    val translation: Translation by koinCtx.inject()
    val triggeredByUser = objResult.tags.getUniqueTag(LocalizationArg.NOTIFICATION_TRIGGERED_BY_NAME) ?: "user_name"
    val newInviteStatus =
        parseEnumValue<MeetingInvitationStatus>(objResult.tags.getUniqueTag(LocalizationArg.MEETING_INVITE_STATUS))?.getName()
            ?: "invitation_status"
    val newTaskStatus =
        parseEnumValue<TaskState>(objResult.tags.getUniqueTag(LocalizationArg.TASK_STATUS))?.getTitle() ?: "task_status"
    val objType = objResult.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE) ?: "obj_type"
    val atTime = objResult.tags.getUniqueTag(LocalizationArg.OBJECT_AT_TIME) ?: "time_not_available"

    return when (this) {
        TranslationKey.MEETING_INVITED -> translation[TL.Notifications.MEETING_INVITED_SHORT, mapOf("userName" to triggeredByUser)]
        TranslationKey.MEETING_INVITE_STATUS_CHANGE -> translation[
            TL.Notifications.MEETING_INVITE_STATUS_CHANGE_SHORT,
            mapOf(
                "userName" to triggeredByUser,
                "status" to newInviteStatus,
            ),
        ]

        TranslationKey.MEETING_INVITE_REMOVED -> translation[TL.Notifications.MEETING_INVITE_REMOVED_SHORT, mapOf("userName" to triggeredByUser)]
        TranslationKey.TASK_ASSIGNED -> translation[TL.Notifications.TASK_ASSIGNED_SHORT, mapOf("userName" to triggeredByUser)]
        TranslationKey.TASK_UNASSIGNED -> translation[TL.Notifications.TASK_UNASSIGNED_SHORT, mapOf("userName" to triggeredByUser)]
        TranslationKey.TASK_STATE_CHANGED -> translation[
            TL.Notifications.TASK_STATE_CHANGED_SHORT,
            mapOf(
                "newTaskState" to newTaskStatus,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.OBJECT_MODIFIED -> translation[
            TL.Notifications.OBJECT_MODIFIED_SHORT,
            mapOf(
                "objectType" to objType,
                "userName" to triggeredByUser,
            ),
        ]

        TranslationKey.REMINDER -> when (parseEnumValue<ObjectType>(objType)) {
            ObjectType.Event -> translation[TL.Notifications.REMINDER_EVENT_SHORT, mapOf("atTime" to atTime)]
            ObjectType.Task -> translation[TL.Notifications.REMINDER_TASK_SHORT, mapOf("atTime" to atTime)]
            else -> {
                console.warn("missing LocalizationArg.OBJECT_TYPE on reminder notification")
                flowOf("Reminder")
            }
        }

        else -> {
            error("missing branch for $this")
        }
    }
}

fun ChangeType.getObjectHistoryMessage(objHistoryResult: GeoObjectDetails): Flow<String>? {
    val translation: Translation by koinCtx.inject()
    val triggeredByUserName = objHistoryResult.tags.getUniqueTag(HistoryArgs.TriggeredByName) ?: "user"
    val objTitle = objHistoryResult.tags.getUniqueTag(HistoryArgs.TrackedObjectTitle) ?: "obj_title"
    val zoneName = objHistoryResult.tags.getUniqueTag(HistoryArgs.ZoneTitle) ?: "zone_name"
    val floorTitle = objHistoryResult.tags.getUniqueTag(HistoryArgs.FloorTitle) ?: "floor_name"
    val floorLevel = objHistoryResult.tags.getUniqueTag(HistoryArgs.FloorLevel) ?: "floor_level"
    val inviteStatus =
        parseEnumValue<MeetingInvitationStatus>(objHistoryResult.tags.getUniqueTag(HistoryArgs.InviteStatus)).getName()
    val taskStatus = parseEnumValue<TaskState>(objHistoryResult.tags.getUniqueTag(HistoryArgs.TaskStatus))?.getTitle()
    val taskAssigneeName = objHistoryResult.tags.getUniqueTag(HistoryArgs.TaskAssigneeName)
    val addedInviteeName = objHistoryResult.tags.getUniqueTag(HistoryArgs.AddedInviteeName) ?: "invitee_name"
    val removedInviteeName = objHistoryResult.tags.getUniqueTag(HistoryArgs.RemovedInviteeName) ?: "removed_user_name"

    return when (this) {
        ChangeType.Create -> translation[TL.ObjectHistory.CREATE, mapOf("userName" to triggeredByUserName)]
        ChangeType.Update -> translation[TL.ObjectHistory.UPDATE, mapOf("userName" to triggeredByUserName)]
        ChangeType.Delete -> translation[TL.ObjectHistory.DELETE, mapOf("userName" to triggeredByUserName)]
        ChangeType.TaskStateChanged -> translation[
            TL.ObjectHistory.TASK_STATE_CHANGED,
            mapOf(
                "userName" to triggeredByUserName,
                "taskState" to (taskStatus ?: ""),
            ),
        ]

        ChangeType.TaskAssignmentUpdate -> if (triggeredByUserName != taskAssigneeName) {
            if (taskAssigneeName != null) {
                translation[
                    TL.ObjectHistory.TASK_ASSIGNMENT_UPDATE,
                    mapOf(
                        "userName" to triggeredByUserName,
                        "assigneeName" to taskAssigneeName,
                    ),
                ]
            } else {
                translation[TL.ObjectHistory.TASK_ASSIGNMENT_REMOVED, mapOf("userName" to triggeredByUserName)]
            }
        } else translation[TL.ObjectHistory.TASK_ASSIGNMENT_UPDATE_SELF, mapOf("userName" to triggeredByUserName)]

        ChangeType.EventUpdateInviteStatus -> translation[
            TL.ObjectHistory.EVENT_UPDATE_INVITE_STATUS,
            mapOf(
                "userName" to triggeredByUserName,
                "inviteState" to inviteStatus,
            ),
        ]

        ChangeType.EventAddInvitees -> translation[
            TL.ObjectHistory.EVENT_ADD_INVITEES,
            mapOf(
                "userName" to triggeredByUserName,
                "addedInviteeName" to addedInviteeName,
            ),
        ]

        ChangeType.EventRemoveInvitees -> translation[
            TL.ObjectHistory.EVENT_REMOVE_INVITEES,
            mapOf(
                "userName" to triggeredByUserName,
                "removedInviteeName" to removedInviteeName,
            ),
        ]

        ChangeType.TrackedObjectLocationUpdate -> translation[TL.ObjectHistory.TRACKED_OBJECT_LOCATION_UPDATE, mapOf("userName" to triggeredByUserName)]
        ChangeType.TrackedObjectEnterZone -> translation[TL.ObjectHistory.TRACKED_OBJECT_ENTERED_ZONE, mapOf("zoneName" to zoneName)]
        ChangeType.TrackedObjectExitZone -> translation[TL.ObjectHistory.TRACKED_OBJECT_EXITED_ZONE, mapOf("zoneName" to zoneName)]
        ChangeType.OccupantEnterZone -> translation[TL.ObjectHistory.OCCUPANT_ENTERED_ZONE, mapOf("objectName" to objTitle)]
        ChangeType.OccupantExitZone -> translation[TL.ObjectHistory.OCCUPANT_EXITED_ZONE, mapOf("objectName" to objTitle)]
        ChangeType.BuildingAddFloor -> translation[
            TL.ObjectHistory.BUILDING_ADD_FLOOR,
            mapOf(
                "userName" to triggeredByUserName,
                "floorName" to floorTitle,
                "floorLevel" to floorLevel,
            ),
        ]

        ChangeType.Release -> translation[TL.ObjectHistory.TRACKED_OBJECT_RELEASED, mapOf("userName" to triggeredByUserName)]
//        ChangeType.Archive -> translation[TL.ObjectHistory.OBJECT_ARCHIVED, mapOf("userName" to triggeredByUserName)]
        ChangeType.ObjectMoved -> translation[TL.ObjectHistory.OBJECT_MOVED, mapOf("userName" to triggeredByUserName)]
        else -> null
    }
}

fun ChangeType?.getIcon(): FormationIcons {
    return when (this) {
        ChangeType.Create -> FormationIcons.Create
        ChangeType.EventAddInvitees -> FormationIcons.PersonAdd
        ChangeType.EventRemoveInvitees -> FormationIcons.PersonRemove
        ChangeType.BuildingAddFloor -> FormationIcons.Add
        ChangeType.TrackedObjectLocationUpdate -> FormationIcons.Update
        ChangeType.TrackedObjectEnterZone,
        ChangeType.OccupantEnterZone -> FormationIcons.EnteredZone

        ChangeType.TrackedObjectExitZone,
        ChangeType.OccupantExitZone -> FormationIcons.ExitedZone

        ChangeType.TaskAssignmentUpdate,
        ChangeType.EventUpdateInviteStatus -> FormationIcons.PersonChange

        ChangeType.TaskStateChanged -> FormationIcons.TaskStateChange
        ChangeType.Update -> FormationIcons.Edit
        ChangeType.Delete -> FormationIcons.Remove
        ChangeType.AttachmentOrder -> FormationIcons.Update // TODO choose icon for object history message
//        ChangeType.AttachmentAdd -> FormationIcons.Update // TODO choose icon for object history message
//        ChangeType.AttachmentDelete -> FormationIcons.Update // TODO choose icon for object history message
        ChangeType.Release -> FormationIcons.Archive
        ChangeType.ObjectMoved -> FormationIcons.Location
        null -> FormationIcons.Update
        else -> FormationIcons.Information // Handle case when we add new ChangeTypes without breaking
    }
}

fun GeoObjectDetails.isMe(): Boolean {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    return this.objectType == ObjectType.UserMarker && this.ownerId == apiUserStore.current.userId
}

fun Flow<String>.merge(flow: Flow<String>, separator: String? = null, asPrefix: Boolean = false): Flow<String> {
    return this.combine(flow) { thisFlow, otherFlow ->
        if (asPrefix) "$otherFlow${separator ?: " "}$thisFlow"
        else "$thisFlow${separator ?: " "}$otherFlow"
    }
}

fun NFCStatus.getName(): String {
    val translation: Translation by koinCtx.inject()
    return when (this) {
        NFCStatus.ENABLED -> {
            translation.getString(TL.Status.ENABLED)
        }

        NFCStatus.DISABLED -> {
            translation.getString(TL.Status.DISABLED)
        }

        NFCStatus.NOT_AVAILABLE -> {
            translation.getString(TL.Status.NOT_AVAILABLE)
        }

        NFCStatus.UNKNOWN -> {
            translation.getString(TL.Status.UNKNOWN)
        }
    }
}

fun CodeTech.getIcon(): IconDefinition {
    val defaultTheme = koinCtx.get<FormationDefault>()
    return when (this) {
        CodeTech.QR -> FormationIcons.QRCode.icon
        CodeTech.NFC -> FormationIcons.NFC.icon
        CodeTech.LINK -> FormationUIIcons.ExternalLink.icon
    }
}

fun GeoObjectDetails.getNotificationTagData(): NotificationTagData {
    return NotificationTagData(
        triggeredBy = this.tags.getUniqueTag(LocalizationArg.NOTIFICATION_TRIGGERED_BY),
        triggeredByName = this.tags.getUniqueTag(LocalizationArg.NOTIFICATION_TRIGGERED_BY_NAME),
        meetingInviteStatus = this.tags.getUniqueTag(LocalizationArg.MEETING_INVITE_STATUS),
        meetingInvitedBy = this.tags.getUniqueTag(LocalizationArg.MEETING_INVITED_BY),
        meetingInvitedByName = this.tags.getUniqueTag(LocalizationArg.MEETING_INVITED_BY_NAME),
        taskAssignedBy = this.tags.getUniqueTag(LocalizationArg.TASK_ASSIGNED_BY),
        taskAssignedByName = this.tags.getUniqueTag(LocalizationArg.TASK_ASSIGNED_BY_NAME),
        taskStatus = parseEnumValue<TaskState>(this.tags.getUniqueTag(LocalizationArg.TASK_STATUS)),
        COType = parseEnumValue<ObjectType>(this.tags.getUniqueTag(LocalizationArg.OBJECT_TYPE)),
        COTitle = this.tags.getUniqueTag(LocalizationArg.OBJECT_TITLE),

        COTitleOld = this.tags.getUniqueTag(LocalizationArg.OBJECT_TITLE_OLD),
        CODescription = this.tags.getUniqueTag(LocalizationArg.OBJECT_DESCRIPTION),
        //latLon =
        COConnectedTo = this.tags.getUniqueTag(LocalizationArg.OBJECT_CONNECTED),
        COColor = parseEnumValue<MarkerColor>(this.tags.getUniqueTag(LocalizationArg.OBJECT_COLOR)),
        COIconCategory = parseEnumValue<MarkerIcon>(this.tags.getUniqueTag(LocalizationArg.OBJECT_ICON_CATEGORY)),
        COShape = parseEnumValue<MarkerShape>(this.tags.getUniqueTag(LocalizationArg.OBJECT_SHAPE)),
        modifiedBy = this.tags.getUniqueTag(LocalizationArg.OBJECT_MODIFIED_BY),
        atTime = this.tags.getUniqueTag(LocalizationArg.OBJECT_AT_TIME),
    )
}

fun LatLon.toArray() = arrayOf(lat, lon)
fun LatLon.toReversedArray() = arrayOf(lon, lat)

fun GeoPosition?.isSameLocationAs(other: GeoPosition?): Boolean {
    return when {
        this != null
            && other != null
            && this.coords.latitude == other.coords.latitude
            && this.coords.longitude == other.coords.longitude
            && this.coords.altitude == other.coords.altitude
            && this.coords.accuracy == other.coords.accuracy
            && this.coords.altitudeAccuracy == other.coords.altitudeAccuracy -> true

        else -> false
    }
}

// TODO read deployment address (app or app-dev) and add dynamic link generation here
val Token.verificationLink get() = "https://app.tryformation.com/#token=${this.token}&o=verify"

val Token.isExpired get() = this.expiration < Clock.System.now().toEpochMilliseconds()
