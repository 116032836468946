import analytics.AnalyticsCategory
import analytics.AnalyticsService
import analyticsdashboard.analyticsDashboardModule
import apiclient.util.createHttpClient
import apiclient.websocket.WebsocketApiClient
import camera.cameraWrapper.cameraModule
import camera.nfc.NfcService
import camera.zxing.zxingModule
import com.tryformation.localization.LocalizedTranslationBundleSequenceProvider
import data.objects.views.IconIndexStore
import data.objects.views.cardCustomizationKoinModule
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import dev.fritz2.core.render
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.theme.Theme
import io.ktor.client.HttpClient
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.request.get
import io.ktor.client.statement.bodyAsText
import koin.analyticsModule
import koin.apiAndWsClient
import koin.archetypeModule
import koin.attachmentModule
import koin.bottomBarModule
import koin.browserCameraModule
import koin.codeGenerationModule
import koin.common
import koin.conectableShapeModule
import koin.geoLocationModule
import koin.geofenceEditorModule
import koin.globalSearchModule
import koin.hubModule
import koin.koinCtx
import koin.legacyKoinModuleThatIsWayTooBig
import koin.mapLayerModule
import koin.mapModule
import koin.nestedObjectSearchModule
import koin.objectHistoryModule
import koin.routingModule
import koin.searchModule
import koin.tagsModule
import koin.userModule
import koin.verificationModule
import koin.wizardModule
import kotlin.coroutines.EmptyCoroutineContext
import kotlin.js.Promise
import kotlin.time.Duration.Companion.minutes
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.coroutines.launch
import kotlinx.coroutines.promise
import localization.LocaleStore
import localization.Locales
import localization.TranslationStore
import login.loginKoinModule
import mainmenu.RouterStore
import mainmenu.pagesNavigator
import map.bottombar.standAloneBottomBar
import map.cardsNavigator
import map.maplibreMapComponent
import mu.KotlinLoggingConfiguration
import mu.KotlinLoggingLevel
import network.NetworkStateStore
import network.networkKoinModule
import org.koin.core.context.GlobalContext
import org.koin.core.context.startKoin
import org.w3c.dom.events.Event
import org.w3c.fetch.Response
import org.w3c.workers.ExtendableEvent
import org.w3c.workers.FetchEvent
import overlays.overlayKoinModule
import overlays.overlayNavigator
import routing.MainController
import theme.FormationDefault
import theme.themeModule
import utils.JsLogLevel
import utils.setJsLogLevel
import websocket.WebsocketService
import workspacetools.usermanagement.userManagementModule

val cacheName = "demoPWA-v1"
val filesToCache = arrayOf(
    "/",
    "/index.html",
    "/js/app.js",
    "/icons/pwa-256x256.png",
)

enum class LifeCycleEvent(val logAnalytics: Boolean = false) {
    Unknown(false),
    Starting(false),
    Started(true),
    DeviceReady,
    Pause(true),
    Resume(true),

    // these are device buttons ...
    BackButton(true),
    MenuButton(true),
    SearchButton(true),
    Activated(true),
    CallbackError(true),
    HealthCheck(false),
    Crashed(true),
    BeforeInstallPrompt(true),
    Fetch(true),
    Install(true),
    ;
}

// we need this store before koin is initialized so making it a global variable
val lifeCycleEventStore = LifeCycleEventStore()


class HealthCheck {
    fun check() {
//        console.log("healthcheck")
        // TODO check web socket is up, user credentials are valid, etc.
    }
}


class LifeCycleEventStore : RootStore<LifeCycleEvent>(
    initialData = LifeCycleEvent.Unknown,
    job = Job(),
) {
    // we can't koin inject stuff here because we create the store before koin is initialized

    // use lazy here so we can koin inject whatever we need in the HealthCheck
    private val healthCheck by lazy { HealthCheck() }

    private val lifeCycleScope = CoroutineScope(CoroutineName("lifecycle-events"))

    private val eventChannel = Channel<Pair<LifeCycleEvent, Event?>>()

    fun updateEventState(s: LifeCycleEvent, e: Event? = null) {
        lifeCycleScope.launch {
            eventChannel.send(s to e)
        }
    }

    private val lifeCycleEventHandler: SimpleHandler<Pair<LifeCycleEvent, Event?>> = handle { oldEvent, (event, e) ->
        console.log("$oldEvent -> $event event")
        // can't use inject here because koinCtx is not initialized here yet ...
        val websocketApiClient by lazy { koinCtx.get<WebsocketApiClient>() }
        val analyticsService by lazy { koinCtx.get<AnalyticsService>() }
        val nfcService by lazy { koinCtx.get<NfcService>() }
        val websocketService by lazy { koinCtx.get<WebsocketService>() }
        val networkStateStore by lazy { koinCtx.get<NetworkStateStore>() }

        try {
            when (event) {
                LifeCycleEvent.Starting -> {
                    initKoinAndStartApp()
                }

                LifeCycleEvent.DeviceReady -> {
                    initKoinAndStartApp()
                }

                LifeCycleEvent.HealthCheck -> {
                    healthCheck.check()
                }

                LifeCycleEvent.Started -> {
                    //This console output is just to initialise the WebsocketApiClient,
                    // so the AnalyticsService can send the "Started" event itself
                    console.log("Websocket Session: ", websocketApiClient.sessionIdProvider.invoke())
                    // noop
                }

                LifeCycleEvent.Crashed -> {
                    // restart the app, why not ...
                    console.error("CRASH")
                    initKoinAndStartApp()

                }

                LifeCycleEvent.Pause -> {
                    websocketApiClient.closeSession()
                }

                LifeCycleEvent.Resume -> {
                    nfcService.readIntentFromLocalStorage()
                    nfcService.checkAndUpdateNFCStatus()
                    networkStateStore.checkOnlineStatus()
                    networkStateStore.addNetworkListener()
                    websocketService.restartWebSocket()
                }

                LifeCycleEvent.BeforeInstallPrompt -> {
                    console.log("Before Install prompt")
                }

                LifeCycleEvent.Fetch -> {
                    console.log("[demoPWA - ServiceWorker] Fetch event fired.")
                    e as FetchEvent
                    e.respondWith(
                        Promise { resolve, _ ->
                            window.caches.match(e.request).then { cacheResponse ->
                                if (cacheResponse != null) {
                                    console.log("[demoPWA - ServiceWorker] Retrieving from cache...")
                                    resolve(cacheResponse as Response)
                                } else {
                                    console.log("[demoPWA - ServiceWorker] Retrieving from URL...")
                                    window.fetch(e.request)
                                }
                            }
                        },
                    )
                }

                LifeCycleEvent.Install -> {
                    console.log("[demoPWA - ServiceWorker] Install event fired.")
                    (e as ExtendableEvent).waitUntil(
                        Promise { resolve, _ ->
                            window.caches.open(cacheName).then { cache ->
                                resolve(cache.addAll(filesToCache))
                            }
                        },
                    )
                }
                // back button
                else -> {
                    console.error("unhandled event $event")
                }
            }
            analyticsService.withAnalytics(AnalyticsCategory.LifeCycle) {
                if (event.logAnalytics) {
                    this.appStateChange(target = event.name)
                }
            }
            event
        } catch (e: Exception) {
            console.error("fatal error while handling lifecycle event \"${event.name}\" -> $e")
            analyticsService.withAnalytics(AnalyticsCategory.LifeCycle) {
                exception(e, target = event.name)
            }
            LifeCycleEvent.Crashed
        }
    }

    init {
        lifeCycleScope.launch {
            while (true) {
                delay(2.minutes)
                lifeCycleEventStore.updateEventState(LifeCycleEvent.HealthCheck)
            }
        }

        eventChannel.receiveAsFlow() handledBy lifeCycleEventHandler
    }
}

fun main() {
    // disable trace and debug logging for any kotlin libraries using kotlin logging
    KotlinLoggingConfiguration.LOG_LEVEL = KotlinLoggingLevel.INFO

    //TODO: fix custom.js not being present
    setJsLogLevel(JsLogLevel.INFO)

    // Hack for IOS https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
    // in the category, maybe this will work ...
    window.onresize = {
        document.body?.style?.height = window.innerHeight.toString()
        // kotlin wants us to return a dynamic, so here's a null ...
        null
    }

    lifeCycleEventStore.updateEventState(LifeCycleEvent.Starting)
}

suspend fun fetchFtl(id: String): String? {
    return try {
        val baseUrl = window.location.let { l ->
            when (l.protocol) {
                "file:" -> {
                    "https://app.tryformation.com/lang"
                }

                else -> {
                    l.protocol + "//" + l.host + "/lang"
                }
            }
        }
        val client: HttpClient = createHttpClient() //by koinCtx.inject()
        val url = "${baseUrl}/${id}.ftl"
        val response = client.get(url)
        response.bodyAsText()
    } catch (e: ClientRequestException) {
        null
    }
}


private suspend fun initKoinAndStartApp() {

    try {
        // kill any old context if it was initialized; allows us to restart
        GlobalContext.stopKoin()
        startKoin {
            // list all used modules in order that they need each other
            // avoid having "forward references" from one module to another
            // avoid having cycles (aka. weird initialization bugs)
            modules(
                common(),
                cameraModule,
                zxingModule,
                themeModule,
                apiAndWsClient(),
                routingModule(),
                overlayKoinModule(),
                networkKoinModule(),
                userManagementModule,
                loginKoinModule(),
                userModule(),
                tagsModule(),
                attachmentModule(),
                browserCameraModule(),
                mapModule(),
                geoLocationModule(),
                legacyKoinModuleThatIsWayTooBig(),
                analyticsModule(),
                searchModule(),
                globalSearchModule(),
                hubModule(),
                bottomBarModule(),
                verificationModule(),
                mapLayerModule(),
                objectHistoryModule(),
                geofenceEditorModule(),
                conectableShapeModule(),
                archetypeModule(),
                nestedObjectSearchModule(),
                cardCustomizationKoinModule(),
                analyticsDashboardModule(),
                wizardModule(),
                codeGenerationModule(),
            )
        }
        // we have a few suspend things that we need to initialize
        val koinPromise = CoroutineScope(EmptyCoroutineContext).promise {
            val syncedUserPreferencesStore: SyncedUserPreferencesStore = koinCtx.get()

            val initialLocales = listOfNotNull(
                syncedUserPreferencesStore.current.languageCode,
                window.navigator.language,
            ) + window.navigator.languages

            val provider = LocalizedTranslationBundleSequenceProvider()
            val intialBundleSequence = provider.loadBundleSequence(
                locales = initialLocales,
                fallbackLocale = Locales.EN_GB.id,
                fetch = ::fetchFtl,
            )
            intialBundleSequence.bundles.firstNotNullOfOrNull {
                it.locale.first().let { localeCode ->
                    Locales.findByIdOrNull(localeCode)
                }
            }?.let {
                koinCtx.get<LocaleStore>().update(it)
            }

            koinCtx.declare(instance = provider)
            koinCtx.declare(instance = TranslationStore(intialBundleSequence, ::fetchFtl))
            koinCtx.declare(instance = MainController())
            koinCtx.declare(instance = RouterStore(router = koinCtx.get()))
            koinCtx.declare(instance = NfcService())
            koinCtx.declare(instance = NetworkStateStore())
            koinCtx.declare(instance = IconIndexStore())
        }
        koinPromise.then {
            startTheApp()
        }

    } catch (e: Exception) {
        console.error(e)
        // don't send crashed event here because that would be an endless reload loop
    }
}

fun startTheApp() {
    val router: MapRouter by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val nfcService: NfcService by koinCtx.inject()
    val networkStateStore: NetworkStateStore by koinCtx.inject()
    networkStateStore.checkOnlineStatus()
    networkStateStore.addNetworkListener()
    routerStore.redirectRoute = if (routerStore.isPreLoginPage(router.current)) null else router.current
    routerStore.preLoginRedirectRoute =
        if (routerStore.preLoginRedirect(router.current)) router.current else null
    console.log("Set redirect routes.", "Redirect:", routerStore.redirectRoute.toString(), "PreRedirect:", routerStore.preLoginRedirectRoute.toString())
    nfcService.readIntentFromLocalStorage()
    nfcService.checkAndUpdateNFCStatus()
    Theme.use(koinCtx.get<FormationDefault>())
    utils.require("@js-joda/timezone")

    render("#target") {
        // DONOT use position fixed/relative/etc. here: it does not work correctly in safari
        // flexBox removes the need for these.
        div("flex flex-col h-full justify-between") {
//            debugOverlay()
            maplibreMapComponent()
            standAloneBottomBar()
            overlayNavigator()
            pagesNavigator()
            cardsNavigator()
        }
        // used for some headless fritz2 components
        portalRoot()
    }
    window.dispatchEvent(Event("resize"))
    lifeCycleEventStore.updateEventState(LifeCycleEvent.Started)
}
