package data.users.views

import analytics.AnalyticsCategory
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.ActiveObjectStore
import data.users.ActiveUserStore
import dev.fritz2.components.flexBox
import dev.fritz2.core.RenderContext
import koin.koinCtx
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import map.Cards
import map.views.workplacetools.genericToolButton
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationIcons
import utils.merge
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.twoButtonFooter
import webcomponents.userOrStateIcon

fun RenderContext.cardMyUser() {

    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()


    combine(activeUserStore.data, apiUserStore.data) { a, u -> Pair(a, u) }.render { (activeUser, currentUser) ->

        baseLayout(
            header = {
                flexBox({
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                }) {
                    flexBox({
                        margins { top { tiny } }
                        overflowX { hidden }
                    }) {
                        // USER ICON, USERNAME AND JOBTITLE
                        cardTitleWithSubtitle(
                            title = if (activeUser.userId == currentUser.userId) {
                                translation[TL.General.YOU, mapOf("case" to "nominative")].map { you ->
                                    you.lowercase()
                                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                }
                                    .merge(flow = flowOf("("), separator = "", asPrefix = true)
                                    .merge(flowOf(")"), separator = "")
                                    .merge(flowOf("${activeUser.firstName} ${activeUser.lastName}"), asPrefix = true)
                            } else flowOf("${activeUser.firstName} ${activeUser.lastName}"),
                            subtitle = flowOf(activeUser.jobTitle ?: ""),
                            iconBox = {
                                userOrStateIcon(
                                    pixelSize = 48.0,
                                    shadow = false,
                                    picture = activeUser.profilePhoto?.href,
                                    initials = initials(activeUser.firstName, activeUser.lastName),
                                    icon = { FormationIcons.UserAlt.icon }
                                )
                            }
                        )
                    }
//                    secondaryMenuButton()
                }
            },
            content = {
                featureFlagStore.data.render { features ->
                    flexBox({
                        height { full }
                        direction { row }
                        justifyContent { center }
                        alignItems { center }
                        wrap { nowrap }
                        paddings { horizontal { normal } }
                    }) {
                        // STATUS
                        // TODO enable, when a User Status is implemented
//                    genericToolButton(
//                        icon = { circleCheck },
//                        displayName = translation[TL.CardMyUser.STATUS],
//                        toolTitle = translation[TL.CardMyUser.STATUS],
//                        disabled = flowOf(currentUser.isAnonymous),
//                        analyticsEventProvider = AnalyticsCategory.ChangeMyUserStatus.click(
//                            label = "Status button on MyUser card"
//                        )
//                    )
                        // SHARING
                        if (features[Features.DisableLocationSharing] == false) {
                            genericToolButton(
                                icon = { FormationIcons.SharingOn.icon },
                                displayName = translation[TL.CardMyUser.SHARING],
                                toolTitle = translation[TL.CardMyUser.SHARING],
                                disabled = flowOf(currentUser.isAnonymous),
                                value = Unit,
                                routingMap = Cards.Sharing.route,
                                analyticsEventProvider = AnalyticsCategory.ShowSharingOptionsFromMyUserCard.click(
                                    label = "Sharing button on MyUser card"
                                )
                            )
                        }
                        // SETTINGS
                        genericToolButton(
                            icon = { FormationIcons.Gears.icon },
                            displayName = translation[TL.CardMyUser.SETTINGS],
                            toolTitle = translation[TL.CardMyUser.SETTINGS],
                            disabled = flowOf(currentUser.isAnonymous),
                            value = Unit,
                            routingMap = Pages.UserSettings.route,
                            analyticsEventProvider = AnalyticsCategory.ShowSettingsFromMyUserCard.click(
                                label = "Settings button on MyUser card"
                            )
                        )
                    }
                }
            },
            footer = {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CLOSE],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap),
                    primaryTitle = translation[TL.UserProfile.VIEW_PROFILE],
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryRoutingMap = if (activeUser.userId == currentUser.userId) {
                        Pages.MyProfile.route
                    } else Pages.UserProfile.route
                )
            }
        )
    }
}
