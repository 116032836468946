package data.objects.views.directediting

import apiclient.geoobjects.Address
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.ObjectChanges
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.applyObjectChanges
import apiclient.geoobjects.distanceTo
import apiclient.geoobjects.extractAddress
import apiclient.geoobjects.pointCoordinates
import apiclient.markers.AIObjectClassification
import apiclient.markers.classifyObject
import apiclient.markers.objectChanges
import apiclient.tags.externalIds
import apiclient.tags.getUniqueTag
import apiclient.users.UserFeatureFlag
import apiclient.util.isNotNullOrEmpty
import apiclient.validations.parseEnumValue
import apiclient.validations.parseIsoDate
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geojson.humanReadable
import com.jillesvangurp.geojson.urlEncode
import data.objects.ActiveObjectStore
import data.users.PublicUserProfileCache
import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import js.errors.TypeError
import koin.withKoin
import kotlin.time.Duration.Companion.seconds
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.translate
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.MapState
import org.w3c.dom.asList
import org.w3c.dom.svg.SVGElement
import overlays.withBusyApiClient
import qrcode.userverification.toSvgQrCode
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.doIfUserFeatureFlagEnabled
import twcomponents.twButtonRow
import twcomponents.twCenteredLink
import twcomponents.twFlatBoxColStart
import twcomponents.twFlatBoxRowCenter
import twcomponents.twFlatButton
import twcomponents.twFlatCopyClipboardButton
import twcomponents.twFlatIconBox
import twcomponents.twFlatIconButton
import twcomponents.twIconMedium
import twcomponents.twMarkdownContent
import twcomponents.twPrimaryButton
import twcomponents.twRowOfNoGap
import twcomponents.twRowOfWrap
import twcomponents.twSecondaryButton
import twcomponents.twTagButton
import utils.formatDateTime
import utils.getColorForIcon
import utils.getIcon
import web.navigator.navigator

fun RenderContext.directEditingCardContent(typeSpecificContent: (RenderContext.() -> Unit)? = null) {
    div("flex flex-col w-full h-full justify-between overflow-y-auto px-2 pt-3") {

        // Top Section
        div("flex flex-col w-full") {
            div("-mx-2") { // ignore paddings of parent div for full width content
                editableImageIcon()
                editableDescription()
            }
            typeSpecificContent?.invoke(this)

            showExperimentalAIContentAdder()

            div("-mx-2") { // ignore paddings of parent div for full width content
                showContent()
            }

            addContentBar()

            commentSection()
        }

        // Bottom Section
        div("flex flex-col w-full") {
            tagsSection()

            div("-mx-2") {
                newObjectDetailsSummary()
            }
        }
    }
}

fun RenderContext.showExperimentalAIContentAdder() {
    div {
        withKoin {
            doIfUserFeatureFlagEnabled(UserFeatureFlag.EnableDevelopmentFeatures) {
                val activeObjectStore = get<ActiveObjectStore>()
                val currentWorkspaceStore = get<CurrentWorkspaceStore>()
                val objectId = activeObjectStore.current.id
                val workspace = currentWorkspaceStore.current ?: error("need a group")

                val objectClassificationStore = storeOf<AIObjectClassification?>(null)
                activeObjectStore.data.render { currentObject ->
                    if (currentObject.attachments.orEmpty().firstOrNull { it is Content.Image } != null) {
                        objectClassificationStore.data.render { classification ->
                            if (classification == null) {
                                twCenteredLink {
                                    +"Add content via AI (experimental)"
                                    clicks handledBy {
                                        withBusyApiClient(
                                            { client ->
                                                client.classifyObject(workspace.groupId, objectId)
                                            },
                                        ) {
                                            objectClassificationStore.update(it)
                                        }
                                    }
                                }
                            } else {
                                // FIXME quick and dirty preview, obviously
                                p {
                                    b {
                                        +classification.title
                                    }
                                }
                                (classification.iconColor ?: "Default").let { color ->
                                    parseEnumValue<MarkerColor>(color)?.getColorForIcon()?.let { formationColor ->
                                        twButtonRow {
                                            div("w-10 px-2 flex items-center justify-center") {
                                                inlineStyle("background-color: ${formationColor.color}; color: ${formationColor.inverseColor}")
                                                +formationColor.name
                                            }
                                            (classification.icon ?: "Default").let {
                                                parseEnumValue<MarkerIcon>(it)?.getIcon()?.icon?.let { icon ->
                                                    twIconMedium(icon)
                                                }
                                            }
                                        }
                                    }
                                }
                                if (classification.description.isNotNullOrEmpty()) {
                                    twMarkdownContent(classification.description)
                                }
                                if (classification.keywords.isNotEmpty()) {
                                    twRowOfWrap {
                                        classification.keywords.map {
                                            twTagButton(it, FormationIcons.Tag.icon) {
                                            }
                                        }
                                    }
                                }
                                twButtonRow {
                                    twSecondaryButton {
                                        +"Cancel"
                                        clicks handledBy {
                                            objectClassificationStore.update(null)
                                        }
                                    }
                                    twPrimaryButton {
                                        +"Apply Changes"

                                        clicks handledBy {
                                            withBusyApiClient(
                                                { client ->
                                                    client.applyObjectChanges(ObjectChanges(objectId, classification.objectChanges))
                                                },
                                            ) {
                                                activeObjectStore.updateActiveObject(it.first())
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun Address.formatMarkdown(): String {
    val builder = StringBuilder()

    name?.let {
        builder.appendLine(name).appendLine()
    }
    street?.let { street ->
        builder.append(street)
        houseNumber?.let { number ->
            builder.append(" ").append(number)
        }
        builder.appendLine()
    }
    postalCode?.let { builder.appendLine(it) }
    neighborhood?.let { builder.appendLine(it) }
    borrow?.let { builder.appendLine(it) }
    city?.let { builder.appendLine(it) }
    state?.let { builder.appendLine(it) }
    countryCode?.let { builder.appendLine(it) }

    return builder.toString()
}

fun RenderContext.newObjectDetailsSummary() {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        div("flex flex-col w-full justify-start gap-2 py-2 sm:py-5") {
            activeObjectStore.data.render { obj ->
                shareButton(obj)
                objectQRCode(obj)
                addressDetails(obj)
                position(obj)
                creationMetadata(obj)
            }
        }
    }
}

private fun RenderContext.position(obj: GeoObjectDetails) {
    withKoin {
        val mapStateStore = get<MapStateStore>()
        val maplibreMap = get<MaplibreMap>()
        val position = obj.latLon
        twRowOfNoGap {
            mapStateStore.data.render { mapState: MapState? ->
                if (position.distanceTo(mapState?.center ?: position) > 1) {
                    twFlatIconButton(FormationIcons.Position.icon) {
                        clicks handledBy {
                            maplibreMap.panTo(position, 1.seconds.inWholeMilliseconds)
                        }
                    }
                } else {
                    twFlatIconBox {
                        twIconMedium(FormationIcons.Location.icon)
                    }
                }
            }
            twFlatBoxRowCenter {
                p("text-xs") {
                    +position.pointCoordinates().humanReadable()
                }
            }
            twFlatCopyClipboardButton("${position.lat}, ${position.lon}")
        }
    }
}

private fun RenderContext.shareButton(obj: GeoObjectDetails) {
    withKoin {
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        currentWorkspaceStore.data.render { workspace ->
            val id = obj.tags.externalIds.firstOrNull() ?: obj.id
            val link = "https://app.tryformation.com/#id=${id.urlEncode()}&ws=${workspace?.name.orEmpty()}"

            twRowOfNoGap {
                twFlatIconBox {
                    twIconMedium(FormationUIIcons.Link.icon)
                }
                twFlatBoxRowCenter {
                    p("text-xs truncate") {
                        +link
                    }
                }
                twFlatCopyClipboardButton(link)
            }

            try {
                val shareData = utils.obj {
                    title = obj.title
                    url = link
                    text = "Share on FORMATION" // TODO translate
                }
                if (navigator.canShare(shareData)) {
                    twFlatButton {
                        +"Share link"
                        clicks handledBy {
                            navigator.share(shareData)
                        }
                    }
                }
            } catch (e: TypeError) {
                // Firefox does not support the share API on Desktop
            }
        }
    }
}

private fun RenderContext.addressDetails(obj: GeoObjectDetails) {
    obj.tags.extractAddress()?.let { address ->
        twFlatBoxRowCenter {
            twMarkdownContent(address.formatMarkdown())
        }
    }
}

private fun RenderContext.creationMetadata(
    obj: GeoObjectDetails
) {
    withKoin {
        val activeObjectStore: ActiveObjectStore = get()
        val publicUserProfileCache = get<PublicUserProfileCache>()
        twFlatBoxColStart {
            publicUserProfileCache.getProfile(activeObjectStore.data) { obj ->
                obj.createdBy
            }.render { createdBy ->
                val createdAt = obj.createdAt.parseIsoDate()
                p("text-xs") {
                    translate(
                        TL.CardInfo.CREATED_BY,
                        "user" to createdBy?.name.orEmpty(),
                        "time" to createdAt.formatDateTime(),
                    )
                }
            }
            publicUserProfileCache.getProfile(activeObjectStore.data) {
                it.updatedBy
            }.render { updatedBy ->
                val updatedAt = obj.updatedAt.parseIsoDate()
                p("text-xs") {
                    translate(
                        TL.CardInfo.UPDATED_BY,
                        "user" to updatedBy?.name.orEmpty(),
                        "time" to updatedAt.formatDateTime(),
                    )
                }

            }
        }
    }
}

private fun RenderContext.objectQRCode(
    obj: GeoObjectDetails
) {
    val externalId = obj.tags.getUniqueTag(ObjectTags.ExternalId)
    if (!externalId.isNullOrBlank()) {
        twFlatBoxRowCenter {
            flowOf(externalId).mapNotNull { it } handledBy { id ->
                val svgContent = toSvgQrCode("https://app.tryformation.com/#id=${id.urlEncode()}")
                div("flex flex-col h-full w-[50%]") {
                    div("flex max-h-max w-full object-scale-down") {
                        domNode.innerHTML = svgContent
                    }.also {
                        this.domNode.children.asList().firstOrNull { it is SVGElement }.also { svg ->
                            svg?.setAttribute("height", "100%")
                            svg?.setAttribute("width", "100%")
                        }
                    }
                }
                p("text-xs font-mono text-center text-wrap break-all h-full w-[50%] p-2") {
                    +id
                }
            }
        }
    }
}


