package data.objects.views.meeting

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MeetingInvitationStatus
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.components.compat.div
import koin.koinCtx
import kotlinx.coroutines.flow.flowOf
import localization.TL
import localization.Translation
import model.L
import mainmenu.RouterStore
import auth.ApiUserStore
import data.objects.ActiveObjectStore
import kotlinx.coroutines.flow.map
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import utils.*
import webcomponents.*

fun RenderContext.cardMeetingState() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val attendees = activeObjectStore.map(GeoObjectDetails.L.attendees)

    val stateHasChanged = attendees.data.map { list ->
        list?.firstOrNull {
            it.userId == apiUserStore.current.userId
        }?.meetingInvitationStatus !=
                activeObjectStore.initialObject.attendees?.firstOrNull {
                    it.userId == apiUserStore.current.userId
                }?.meetingInvitationStatus
    }

    val sortedStatusList = listOf(
        MeetingInvitationStatus.Accepted,
        MeetingInvitationStatus.Maybe,
        MeetingInvitationStatus.Rejected
    )

    baseLayout(
        header = {
            // TASK TITLE
            cardTitle(title.data)
            mainSubTitle(translation[TL.CardTaskState.CARD_TITLE])
        },
        content = {
            attendees.data.render { attendeeList ->
                val usersStatus = attendeeList?.firstOrNull { it.userId == apiUserStore.current.userId }?.meetingInvitationStatus
                div({
                    width { full }
                    height { maxContent }
                    overflow { auto }
                }) {
                    stackUp({
                        width { full }
                        height { maxContent }
                        justifyContent { center }
                        alignItems { stretch }
                        paddings { horizontal { normal } }
                    }) {
                        spacing { small }
                        items {
                            sortedStatusList.forEach { state ->
                                with(state) {
                                    stateSelectorButton(
                                        active = flowOf(this == usersStatus),
                                        icon = { this@with.getIcon() },
                                        iconBackground = { this@with.getColor().color },
                                        title = flowOf(this.getName()),
                                        textColor = { primary.main },
                                        value = this,
                                        valueHandlers = listOf(activeObjectStore.setMeetingStatus)
                                    )
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            twoButtonFooter(
                primaryTitle = translation[TL.General.UPDATE],
                primaryStyleParams = primaryButtonStyleParams,
                primaryState = stateHasChanged,
                primaryClickHandlers = listOf(activeObjectStore.changeMeetingStatus, routerStore.back),
                secondaryTitle = translation[TL.General.BACK],
                secondaryStyleParams = secondaryButtonStyleParams,
                primaryValue = Unit,
                secondaryClickHandlers = listOf(activeObjectStore.resetMeetingStatus, routerStore.back)
            )
        }
    )
}
