package mainmenu

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.users.UserFeatureFlag
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import auth.openAnotherDevicePopup
import com.tryformation.localization.Translatable
import data.users.profile.MyProfileStore
import dev.fritz2.components.compat.img
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.stackUp
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.href
import dev.fritz2.core.invoke
import dev.fritz2.core.src
import dev.fritz2.core.target
import dev.fritz2.styling.params.ColorProperty
import dev.fritz2.styling.theme.Colors
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import map.Cards
import map.bottombar.inlinedBottomBar
import model.isInternalAdminGroupAdminOrGroupOwner
import notifications.GlobalNotificationResultsStore
import org.w3c.dom.HTMLDivElement
import routing.DestinationRoute
import routing.MainController
import routing.stringify
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import twcomponents.doIfFeatureFlagEnabled
import twcomponents.doIfUserFeatureFlagEnabled
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.ellipseText
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.userOrStateIcon
import wizard.onbaording.onBoardingWizard
import wizard.onbaording.wizardModal

fun RenderContext.pageMainMenu() {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val mainController: MainController by koinCtx.inject()
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()
    val featureFlagStore by koinCtx.inject<FeatureFlagStore>()
    val currentWorkspaceStore by koinCtx.inject<CurrentWorkspaceStore>()

    featureFlagStore.setGroupAndRoleFlags()
    myProfileStore.fetchMyProfile()

    combine(apiUserStore.data, featureFlagStore.data) { a, f -> Pair(a.apiUser, f) }.render { (apiUser, features) ->

        val isNotAnonymous = apiUser?.isAnonymous == false
        val signUp = features[Features.EnableSignUpButton] == true
        val sharing = features[Features.DisableLocationSharing] == false
        val showQRTrackerDemo = features[Features.EnableTrackingOnboarding] == true

        baseLayout(
            expandable = false,
            content = {
                contentScrollBox(margins = { top { normal } }) {
                    flexBox({ flex { grow { "1" } } }) {}

                    img(
                        {
                            minHeight { "80px" }
                            maxHeight { "150px" }
                            margins {
                                vertical { larger }
                                horizontal { larger }
//                            left { auto }
//                            right { auto }
                            }
                            css("object-fit: contain;")
                            flex { grow { "1" } }
                        },
                    ) {
                        val ws = apiUserStore.current.apiUser?.workspaceName
                        when {
                            ws?.contains("hoffest") == true -> src("assets/images/LOGO_Formation+Hoffest.png")
                            else -> src("assets/images/logo-blue_1.svg")
                        }
                    }

                    flexBox(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { center }
                            margins { bottom { normal } }
                        },
                    ) {
                        cardTitle(currentWorkspaceStore.data.mapNotNull { it?.name })
                    }

                    flexBox({ flex { grow { "1" } } }) {}

                    stackUp(
                        {
                            width { full }
                            height { maxContent }
                            justifyContent { center }
                            alignItems { stretch }
                            margins { bottom { larger } }
                        },
                    ) {
                        spacing { small }
                        items {
                            if (isNotAnonymous) {
                                // YOUR PROFILE
                                myProfileStore.data.render { myProfile ->
                                    mainMenuButton(
                                        profilePicture = myProfile.profilePhoto?.href,
                                        icon = { FormationIcons.UserAlt.icon },
                                        subtitle = translation[TL.MainMenu.PROFILE_SUBTITLE],
                                        titleLiteral = myProfileStore.data.map { user ->
                                            translation.getString(
                                                TL.MainMenu.PROFILE,
                                                mapOf("name" to "${user.firstName} ${user.lastName}"),
                                            )
                                        },
                                        destinations = listOf(Pages.MyProfile),
                                    )
                                }
                            }
                            // HELP PAGE
                            if (apiUserStore.current.apiUser?.groups?.map { it.groupName }
                                    ?.contains("formation4ukraine") == true) {
                                mainMenuButton(
                                    icon = { circleHelp },
                                    title = TL.MainMenu.HELP,
                                    subtitle = translation[TL.MainMenu.HELP_SUBTITLE],
                                    destinations = listOf(Pages.Help),
                                )
                            }

                            // SETTINGS
                            mainMenuButton(
                                icon = { FormationIcons.Settings.icon },
                                title = TL.MainMenu.APPLICATION_SETTINGS,
                                subtitle = translation[TL.MainMenu.APPLICATION_SETTINGS_SUBTITLE],
                                destinations = listOf(Pages.UserSettings),
                            )
                            // DEBUGGING
//                            mainMenuButton(
//                                icon = { FormationIcons.FireExtinguisher.icon },
//                                titleLiteral = flowOf("Debugging"),
//                                subtitle = flowOf("Debug here"),
//                                destinations = listOf(Pages.Debug),
//                            )

                            if (isNotAnonymous) {
                                // ONBOARDING WIZARD
                                if (showQRTrackerDemo) {
                                    button {
                                        mainMenuButton(
                                            icon = { FormationIcons.QuickTour.icon },
                                            iconBackgroudColor = { FormationColors.MarkerYou.color },
                                            subtitle = translation[TL.MainMenu.QUICK_TOUR_SUBTITLE],
                                            title = TL.MainMenu.QUICK_TOUR,
                                            destinations = listOf(),
                                        )
                                        clicks handledBy wizardModal { closeHandler ->
                                            onBoardingWizard(closeHandler)
                                        }
                                    }
                                }
                                if (sharing) {
                                    // LOCATION SHARING
                                    mainMenuButton(
                                        icon = { FormationIcons.SharingOn.icon },
                                        title = TL.MainMenu.LOCATION_SHARING,
                                        subtitle = translation[TL.MainMenu.LOCATION_SHARING_SUBTITLE],
                                        destinations = listOf(Pages.Map, Cards.Sharing),
                                    )
                                }
                                // ADMIN DASHBOARD
                                if (apiUser?.isInternalAdminGroupAdminOrGroupOwner() == true) {
                                    mainMenuButton(
                                        icon = { FormationIcons.UserGroup.icon },
                                        title = TL.MainMenu.WORKPLACE_TOOLS,
                                        subtitle = translation[TL.MainMenu.WORKPLACE_TOOLS_SUBTITLE],
                                        destinations = listOf(Pages.AdminDashboard),
                                    )
                                    mainMenuButton(
                                        icon = { FormationIcons.DisplayGraph.icon },
                                        title = TL.MainMenu.INSIGHTS_SUITE,
                                        subtitle = translation[TL.MainMenu.INSIGHTS_SUITE_SUBTITLE],
                                        destinations = listOf(Pages.AnalyticsDashboard),
                                    )
                                }
                                // NOTIFICATION CENTER
                                mainMenuButton(
                                    icon = { FormationIcons.NotificationsOn.icon },
                                    titleLiteral = translation[TL.MainMenu.NOTIFICATION_CENTER].combine(
                                        globalNotificationResultsStore.data.map { it.unreadNumber },
                                    ) { title, unreadNumber ->
                                        "$title${if (unreadNumber > 0) " (${unreadNumber})" else ""}"
                                    },
                                    subtitle = translation[TL.MainMenu.NOTIFICATION_CENTER_SUBTITLE],
                                    destinations = listOf(Pages.NotificationCenter),
                                )
                            }

                            // FEEDBACK
                            mainMenuButton(
                                icon = { FormationIcons.Feedback.icon },
                                title = TL.MainMenu.FEEDBACK,
                                subtitle = translation[TL.MainMenu.FEEDBACK_SUBTITLE],
                                destinations = listOf(Pages.Feedback),
                            )
                            if (isNotAnonymous) {
                                // TERMS & CONDITIONS
                                mainMenuButton(
                                    icon = { FormationIcons.Rules.icon },
                                    title = TL.MainMenu.TC,
                                    subtitle = translation[TL.MainMenu.TC_SUBTITLE],
                                    destinations = listOf(Pages.ViewTerms),
                                )
                            }
                            // BACK TO WEBSITE
                            a("my-3") {
                                href("https://tryformation.com/")
                                target("_blank")
                                mainMenuButton(
                                    icon = { FormationIcons.Website.icon },
                                    title = TL.MainMenu.WEBSITE_LINK,
                                    subtitle = translation[TL.MainMenu.WEBSITE_LINK_SUBTITLE],
                                )
                            }
                            if (isNotAnonymous) {
                                div {
                                    doIfFeatureFlagEnabled(Features.AllowExperimentalFeatures) {
                                        div {
                                            mainMenuButton(
                                                icon = { FormationIcons.QRCode.icon },
                                                title = TL.MainMenu.LoginWithDifferentDevice,
                                                subtitle = translation[TL.MainMenu.LoginWithDifferentDeviceSubtitle],
                                            )
                                            clicks handledBy openAnotherDevicePopup()
                                        }
                                    }
                                }
                                // LOGOUT
                                mainMenuButton(
                                    icon = { FormationIcons.Logout.icon },
                                    title = TL.MainMenu.LOGOUT,
                                    subtitle = translation[TL.MainMenu.LOGOUT_SUBTITLE],
                                    destinations = listOf(Pages.SignOut),
                                )
                            } else {
                                // LOGIN
                                mainMenuButton(
                                    icon = { FormationIcons.Login.icon },
                                    title = TL.MainMenu.LOGIN,
                                    subtitle = translation[TL.MainMenu.LOGIN_SUBTITLE],
                                    clickHandlers = listOf(mainController.logoutWithParams),
                                    destinations = listOf(Pages.Login),
                                )
                                if (signUp) {
                                    // SIGN UP
                                    mainMenuButton(
                                        icon = { editAlt },
                                        title = TL.MainMenu.SIGN_UP,
                                        subtitle = translation[TL.MainMenu.SIGN_UP_SUBTITLE],
                                        clickHandlers = listOf(mainController.goToSignUp),
                                        destinations = listOf(Pages.SignUp),
                                    )
                                }
                            }
                        }
                    }
                }
            },
            footerPaddings = {},
            footer = {
                inlinedBottomBar()
            },
        )
    }
}

fun RenderContext.mainMenuButton(
    icon: (Icons.() -> IconDefinition),
    iconBackgroudColor: Colors.() -> ColorProperty = { primary.main },
    profilePicture: String? = null,
    subtitle: Flow<String>,
    destinations: List<DestinationRoute>? = null,
    title: Translatable? = null,
    titleLiteral: Flow<String>? = null,
    clickHandlers: List<SimpleHandler<Unit>>? = null,
    addOrReplaceRoute: Map<String, String>? = null,
    relative: Boolean = false,
    extraContent: (HtmlTag<HTMLDivElement>.() -> Unit)? = null
) {
    val routerStore: RouterStore by koinCtx.inject()
    val analyticsService: AnalyticsService by koinCtx.inject()
    val translation: Translation by koinCtx.inject()

    selectorButton(
        radius = formationStyles.buttonRadius,
        margins = { },
        additionalStyle = if (relative) {
            { position { relative { } } }
        } else null,
    ) {
        selectorContent {
            lineUp(
                {
                    margin { tiny }
                    alignItems { center }
                    overflowX { hidden }
                },
            ) {
                spacing { small }
                items {
                    // USER ICON ON CIRCLE
                    if (profilePicture != null) {
                        userOrStateIcon(
                            pixelSize = 48.0,
                            shadow = false,
                            picture = profilePicture,
                            icon = { FormationIcons.UserAlt.icon },
                            iconBackground = iconBackgroudColor,
                        )
                    } else {
                        flexBox(
                            {
                                width { "48px" }
                                height { "48px" }
                                color { secondary.main }
                                background { color(iconBackgroudColor) }
                                radius { full }
                                padding { smaller }
                                flex {
                                    grow { "0" }
                                    shrink { "0" }
                                    basis { "48px" }
                                }
                                alignItems { center }
                                justifyContent { center }
                            },
                        ) {
                            icon({ size { larger } }) { fromTheme(icon) }
                        }
                    }
                    // TITLE & SUBTITLE
                    stackUp(
                        {
                            overflowX { hidden }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            ellipseText(
                                styleParams = {
                                    width { full }
                                    fontSize { normal }
                                    fontWeight { bold }
                                    textAlign { left }
                                },
                            ) {
                                titleLiteral?.renderText(into = this) ?: translation[
                                    title
                                        ?: error("need a title here"),
                                ].renderText(into = this)
                            }
                            ellipseText(
                                styleParams = {
                                    width { full }
                                    fontSize { smaller }
                                    textAlign { left }
                                },
                            ) { subtitle.renderText(into = this) }
                        }
                    }
                }
            }
        }
        extraContent?.invoke(this)
        with(clicks) {
            clickHandlers?.forEach { handler ->
                this.map { } handledBy handler
            }

            destinations?.let { destinationsList ->
                this.map {
                    analyticsService.createEvent(AnalyticsCategory.MainMenu) {
                        click(
                            target = destinationsList.joinToString(",") { destination -> destination.stringify() },
                        )
                    }
                } handledBy analyticsService.analyticsEvents

                this.map {
                    destinationsList.associate { it.routeKey to it.name }
                } handledBy routerStore.addOrReplaceRoute
            }
            addOrReplaceRoute?.let { route ->
                this.map { route } handledBy routerStore.addOrReplaceRoute
            }
        }

    }
}
