package poll

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.GeoObjectDetails
import apiclient.polls.pollReset
import apiclient.polls.pollResults
import apiclient.polls.pollVote
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.SessionIdStore
import data.objects.ActiveObjectStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.invoke
import koin.koinCtx
import kotlinx.coroutines.Job
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import model.L
import overlays.AlertOverlayStore
import overlays.BusyStore

class SelectedPollOptionsStore : RootStore<Set<String>>(
    initialData = emptySet(),
    job = Job(),
) {
    val reset = handle { emptySet() }

    val toggleOption = handle<String> { current, optionId ->
        if (optionId in current) {
            current - optionId
        } else {
            current + optionId
        }
    }

    val toggleOrSetSingleOption = handle<String> { current, optionId ->
        if (optionId in current) {
            setOf()
        } else {
            setOf(optionId)
        }
    }
}

class ActivePollStore(initialValue: Content.Poll?) : RootStore<Content.Poll?>(
    initialData = initialValue,
    job = Job(),
) {

    val routerStore by koinCtx.inject<RouterStore>()
    val formationClient by koinCtx.inject<FormationClient>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    private val selectedPollOptionsStore by koinCtx.inject<SelectedPollOptionsStore>()
    val translation: Translation by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    private val sessionIdStore: SessionIdStore by koinCtx.inject()

    private val selectVotedOptions = SimpleHandler<Content.Poll?> { pollData, _ ->
        pollData handledBy { poll ->
            if (poll != null) {
                // If anonymous use sessionId, else userId
                val userOrSessionId = if (apiUserStore.current.isAnonymous) {
                    "anonymous-${sessionIdStore.current?.id}"
                } else {
                    apiUserStore.current.userId
                }
                // multiselect
                activeObjectStore.current.pollResults(poll.id)?.userVotes?.get(userOrSessionId)
                    ?.let { options ->
                        selectedPollOptionsStore.update(options.map { it.id }.toSet())
                    }
            }
        }
    }

    val submitVote = handle { current ->
        val workspaceId = currentWorkspaceStore.current?.groupId
        val optionValues = selectedPollOptionsStore.current
        if (current != null && workspaceId != null) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.pollVote(
                        objectId = activeObjectStore.current.id,
                        pollId = current.id,
                        optionValues = optionValues.toList(),
                    )
                },
                successMessage = translation[TL.Poll.VOTING_SUCCESSFUL],
                processResult = { result ->
                    console.log("Voting successful!", result)
                    // update tags and attachments of current active object
                    activeObjectStore.map(GeoObjectDetails.L.tags).update(result.tags)
                    activeObjectStore.map(GeoObjectDetails.L.attachments).update(result.attachments)
                    selectedPollOptionsStore.reset()
                },
                errorMessage = translation[TL.Poll.VOTING_FAILED],
                processError = { error ->
                    console.log("Voting failed", error.message)
                },
            )
        }
        current
    }

    val retractVote = handle { current ->
        val workspaceId = currentWorkspaceStore.current?.groupId
        if (current != null && workspaceId != null) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.pollVote(
                        objectId = activeObjectStore.current.id,
                        pollId = current.id,
                        optionValues = emptyList(),
                    )
                },
                successMessage = translation[TL.Poll.RETRACT_VOTE_SUCCESSFUL],
                processResult = { result ->
                    console.log("Retracted vote successfully!", result)
                    // update tags and attachments of current active object
                    activeObjectStore.map(GeoObjectDetails.L.tags).update(result.tags)
                    activeObjectStore.map(GeoObjectDetails.L.attachments).update(result.attachments)
                    selectedPollOptionsStore.reset()
                },
                errorMessage = translation[TL.Poll.RETRACT_VOTE_FAILED],
                processError = { error ->
                    console.log("Voting failed", error.message)
                },
            )
        }
        current
    }

    val resetPoll = handle { current ->
        val workspaceId = currentWorkspaceStore.current?.groupId
        if (current != null && workspaceId != null) {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.pollReset(
                        objectId = activeObjectStore.current.id,
                        pollId = current.id,
                    )
                },
                successMessage = translation[TL.Poll.RESET_POLL_SUCCESSFUL],
                processResult = { result ->
                    console.log("Reset poll successfully!", result)
                    // update tags and attachments of current active object
                    activeObjectStore.map(GeoObjectDetails.L.tags).update(result.tags)
                    activeObjectStore.map(GeoObjectDetails.L.attachments).update(result.attachments)
                    selectedPollOptionsStore.reset()
                },
                errorMessage = translation[TL.Poll.RESET_POLL_FAILED],
                processError = { error ->
                    console.log("Reset poll failed", error.message)
                },
            )
        }
        current
    }

    val reset = handle {
        selectedPollOptionsStore.reset()
        null
    }

    init {
        data handledBy selectVotedOptions
    }
}
