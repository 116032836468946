package twcomponents

import com.tryformation.localization.Translatable
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.ScopeContext
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.title
import dev.fritz2.styling.theme.IconDefinition
import koin.withKoin
import kotlinx.coroutines.flow.flowOf
import localization.translate
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import overlays.AlertOverlayStore
import theme.FormationUIIcons
import web.navigator.navigator

fun RenderContext.twMediumIconButton(icon: IconDefinition, block: HtmlTag<HTMLButtonElement>.() -> Unit) {
    button("cursor-pointer disabled:text-gray-300") {
        twIconMedium(icon)
        this.title(icon.displayName)
        block.invoke(this)
    }
}

fun RenderContext.twLargeIconButton(icon: IconDefinition, block: HtmlTag<HTMLButtonElement>.() -> Unit) {
    button("cursor-pointer disabled:text-gray-300") {
        twIconLarge(icon)
        this.title(icon.displayName)
        block.invoke(this)
    }
}

fun RenderContext.twMediumIconButtonRed(icon: IconDefinition, block: HtmlTag<HTMLButtonElement>.() -> Unit) {
    div("hover:text-red-500") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }
}

fun RenderContext.twMediumIconButtonHighlight(icon: IconDefinition, block: HtmlTag<HTMLButtonElement>.() -> Unit) {
    div("hover:text-highlight") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }
}

fun RenderContext.twMediumIconButtonNeutral(icon: IconDefinition, block: HtmlTag<HTMLButtonElement>.() -> Unit) {
    div("hover:text-gray-500") {
        twMediumIconButton(icon) {
            block.invoke(this)
        }
    }
}

fun RenderContext.twTagButton(
    text: String,
    icon: IconDefinition,
    title: String? = icon.displayName,
    inverted: Boolean = false,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer h-7.5 hover:border-highlight text-xs px-3 font-bold py-1 border rounded-full " +
            "flex flex-row gap-3 justify-between place-items-center disabled:opacity-50 transition ease-in-out delay-50",
    ) {
        if (inverted) {
            className("bg-highlight border-highlight text-white hover:bg-white hover:text-highlight")
        } else {
            className("bg-white hover:bg-highlight hover:text-white")
        }
        div {
            +text
        }
        twIconSmall(icon)
        title?.let { title ->
            title(title)
        }
        block.invoke(this)
    }
}

fun RenderContext.twPrimaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconDefinition? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer " +
            "px-10 py-2 h-10 " +
            "bg-formationBlack text-formationWhite " +
            "enabled:hover:bg-gray-700 " +
            "flex flex-row items-center justify-center gap-3 " +
            "disabled:opacity-50 " +
            "transition-color duration-300 ease-in-out " +
            "rounded-xl",
    ) {
        text?.let {
            div {
                translate(text, translationArgs)
            }
        }
        icon?.let {
            twIconMedium(icon)
        }
        block.invoke(this)
    }
}

fun RenderContext.twPrimaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconDefinition? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer px-5 py-1 h-8 text-sm rounded-xl bg-formationBlack text-formationWhite " +
            "enabled:hover:bg-gray-300 enabled:hover:text-formationBlack " +
            "flex flex-row items-center justify-center gap-3 disabled:opacity-50 transition ease-in-out delay-50 ",
    ) {
        text?.let {
            div {
                translate(text, translationArgs)
            }
        }
        icon?.let {
            twIconMedium(icon)
        }
        block.invoke(this)
    }
}

fun RenderContext.twSecondaryButton(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconDefinition? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer " +
            "px-10 py-2 h-10 " +
            "bg-formationWhite text-formationBlack " +
            "enabled:hover:bg-gray-300 " +
            "flex flex-row gap-3 disabled:opacity-50 " +
            "transition-color duration-300 ease-in-out " +
            "border border-formationBlack rounded-xl",
    ) {
        text?.let {
            div {
                translate(text, translationArgs)
            }
        }
        icon?.let {
            twIconMedium(icon)
        }
        block.invoke(this)
    }
}

fun RenderContext.twSecondaryButtonSmall(
    text: Translatable? = null,
    translationArgs: Map<String, Any>? = null,
    icon: IconDefinition? = null,
    block: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer " +
            "px-5 py-1 h-8 text-sm " +
            "bg-formationWhite text-formationBlack " +
            "enabled:hover:bg-gray-300 " +
            "flex flex-row gap-3 disabled:opacity-50 " +
            "transition-color duration-300 ease-in-out " +
            "border border-formationBlack rounded-xl " +
            "items-center justify-center ",
    ) {
        text?.let {
            div {
                translate(text, translationArgs)
            }
        }
        icon?.let {
            twIconMedium(icon)
        }
        block.invoke(this)
    }
}

fun RenderContext.twCenteredLink(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit

) {
    a(
        baseClass = "cursor-pointer underline text-center w-full block text-sm hover:opacity-25",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

fun RenderContext.twTextLinkButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLAnchorElement>.() -> Unit
) {
    a(
        baseClass = "h-min cursor-pointer underline block text-xs hover:text-highlight",
        id = id,
        scope = scope,
    ) {
        content(this)
    }
}

fun <T> RenderContext.twRevertButton(store: Store<T>, revertTo: T, block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) {
    twCloseButton {
        store.data.render { current ->
            disabled(current == revertTo)
        }
        clicks handledBy {
            store.update(revertTo)
        }
        block?.invoke(this)
    }
}

fun RenderContext.twCloseButton(block: (HtmlTag<HTMLButtonElement>.() -> Unit)? = null) {
    twMediumIconButton(FormationUIIcons.Close.icon) {
        block?.invoke(this)
    }
}

fun RenderContext.twCheckButton(
    id: String? = null,
    scope: (ScopeContext.() -> Unit) = {},
    content: HtmlTag<HTMLButtonElement>.() -> Unit
) {
    button(
        "cursor-pointer text-green-500 fill-green-500 disabled:fill-gray-300 disabled:text-gray-300 hover:shadow-lg transition-shadow duration-300 ease-in-out",
        id,
        scope,
    ) {
        twIconMedium(FormationUIIcons.Check.icon)
        title(FormationUIIcons.Check.icon.displayName)

        content(this)
    }
}


fun RenderContext.twCopyClipboardButton(block: () -> String) {
    twMediumIconButton(FormationUIIcons.Copy.icon) {
        clicks handledBy {
            withKoin {
                val content = block.invoke()
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }
}

fun RenderContext.twCopyClipboardButton(content: String) {
    twMediumIconButton(FormationUIIcons.Copy.icon) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }
}

fun RenderContext.twAddContentButton(block: (HtmlTag<HTMLDivElement>.() -> Unit)? = null) {
    div("flex flex-row w-full h-10 items-center justify-center hover:border-formationBlack my-2 p-2 border-2 border-dashed border-gray-100 rounded-xl cursor-pointer") {
        block?.invoke(this)
    }
}

fun RenderContext.twFlatBoxRowCenter(block: HtmlTag<HTMLDivElement>.() -> Unit) {
    div("flex flow-row items-center justify-center max-w-full grow p-2 disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }
}

fun RenderContext.twFlatBoxColStart(block: HtmlTag<HTMLDivElement>.() -> Unit) {
    div("flex flex-col items-start justify-center max-w-full grow p-2 disabled:text-gray-300 bg-gray-100 text-slate-500 overflow-hidden") {
        block.invoke(this)
    }
}

fun RenderContext.twFlatIconBox(block: HtmlTag<HTMLDivElement>.() -> Unit) {
    div("flex items-center justify-center max-w-min p-2 disabled:text-gray-300 bg-gray-100 text-slate-500") {
        block.invoke(this)
    }
}

fun RenderContext.twFlatButton(block: HtmlTag<HTMLDivElement>.() -> Unit) {
    twFlatBoxRowCenter {
        className("cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-slate-700")
        block.invoke(this)
    }
}

fun RenderContext.twFlatIconButton(icon: IconDefinition, block: HtmlTag<HTMLDivElement>.() -> Unit) {
    twFlatIconBox {
        className("cursor-pointer bg-gray-200 hover:bg-gray-300 hover:text-slate-700")
        twIconMedium(icon)
        block.invoke(this)
    }
}

fun RenderContext.twFlatCopyClipboardButton(content: String) {
    twFlatIconButton(FormationUIIcons.Copy.icon) {
        clicks handledBy {
            withKoin {
                val alertOverlayStore = get<AlertOverlayStore>()
                navigator.clipboard.writeText(content)
                alertOverlayStore.notify(flowOf("Copied $content"))
            }
        }
    }
}


