package map

import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import data.keywordlayer.cardManageMapLayer
import data.objects.ActiveObjectStore
import data.objects.views.Delete
import data.objects.views.attachments.cardImagePreview
import data.objects.views.attachments.cardMarkdownPreview
import data.objects.views.cardAttribution
import data.objects.views.cardClusterPreview
import data.objects.views.cardCreate
import data.objects.views.cardCustomData
import data.objects.views.cardCustomizeBadge
import data.objects.views.cardDateTime
import data.objects.views.cardDeleteConfirmation
import data.objects.views.cardDescription
import data.objects.views.cardDetails
import data.objects.views.cardEdit
import data.objects.views.cardEditPosition
import data.objects.views.cardManageFieldValueTag
import data.objects.views.cardManageKeywordTags
import data.objects.views.cardObjectHistory
import data.objects.views.cardSecondaryMenu
import data.objects.views.cardShowKeywordTags
import data.objects.views.cardViewDescription
import data.objects.views.cardinfo.cardInfo
import data.objects.views.meeting.cardAttendees
import data.objects.views.meeting.cardMeetingState
import data.objects.views.task.cardDueDate
import data.objects.views.task.cardTaskState
import data.users.ActiveUserStore
import data.users.views.cardMyUser
import data.users.views.cardOtherUser
import data.users.views.cardUserSelect
import dev.fritz2.components.compat.div
import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import geofenceeditor.cardEditZoneGeofence
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import localization.TL
import mainmenu.AppStateStore
import mainmenu.Pages
import map.views.cardMapLayerSelection
import map.views.cardSharing
import map.views.workplacetools.cardToolSelect
import map.views.workplacetools.cardToolsOverview
import model.AppPhase
import poll.ActivePollStore
import poll.cardPoll
import qrcode.cardConnectQR
import qrcode.cardCreateTrackedObject
import qrcode.cardTrackedObject
import routing.DestinationRoute
import search.nestedObjects.cardNestedObjects
import webcomponents.KeywordTagType
import webcomponents.expandCard
import webcomponents.fullCard
import webcomponents.fullPage
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer
import webcomponents.fullWidthCenterContainer
import webcomponents.halfCard

enum class Cards : DestinationRoute {
    Select,
    POI {
        override val objType = ObjectType.POI
    },
    Task {
        override val objType = ObjectType.Task
    },
    Event {
        override val objType = ObjectType.Event
    },
    Area {
        override val objType = ObjectType.Area
    },
    Zone {
        override val objType = ObjectType.Zone
    },
    GeoFence {
        override val objType = ObjectType.GeoFence
    },
    GeneralMarker {
        override val objType = ObjectType.GeneralMarker
    },
    Sharing,
    MapLayers,
    Tools,
    TrackedObject {
        override val objType = ObjectType.ObjectMarker
    },
    Track,
    CreateTrackedObject,
    ClusterPrev,
    ;

    open val objType: ObjectType? = null
    override val routeKey = "card"
    override val route = mapOf(routeKey to name)
}

fun RenderContext.cardsNavigator() {

    val router: MapRouter by koinCtx.inject()
    val appStateStore by koinCtx.inject<AppStateStore>()
    val apiUserStore by koinCtx.inject<ApiUserStore>()
    val activeObjectStore by koinCtx.inject<ActiveObjectStore>()
    val activeUserStore by koinCtx.inject<ActiveUserStore>()

    div({ zIndex { "1040" } }, prefix = "cards-nav") {

        combine(router.select("page"), appStateStore.data) { (page, params), state ->
            Triple(page, params, state.appPhase)
        }.distinctUntilChanged().render { (page, _, appPhase) ->
            if (parseEnumValue<Pages>(page) == Pages.Map && (appPhase == AppPhase.LoggedIn || appPhase == AppPhase.LoggedInAnonymous)) {
                router.select("id").render { (objId, _) ->
                    if (!objId.isNullOrBlank()) {
                        router.select("edit").render { (edit, _) ->
                            // try to render routing with current active object ids if possible
                            if (activeObjectStore.current.id == objId || activeObjectStore.current.tags.getUniqueTag(
                                    ObjectTags.ExternalId,
                                ) == objId
                            ) {
                                pathEditOrInfo(edit, router)
                            } else { // otherwise render routing when object in activeObjectStore changed
                                activeObjectStore.data.render { activeObject ->
                                    if (activeObject.id == objId || activeObject.tags.getUniqueTag(ObjectTags.ExternalId) == objId) {
                                        pathEditOrInfo(edit, router)
                                    }
                                }
                            }
                        }
                    } else {
                        router.select("add").render { (add, _) ->
                            when (add) {
                                Cards.POI.objType?.name -> pathCreate(ObjectType.POI)
                                Cards.Task.objType?.name -> pathCreate(ObjectType.Task)
                                Cards.Event.objType?.name -> pathCreate(ObjectType.Event)
                                Cards.Zone.objType?.name -> pathCreate(ObjectType.Zone)
                                Cards.TrackedObject.objType?.name -> pathCreate(ObjectType.ObjectMarker)
                                else -> {
                                    router.select("card").render { (card, _) ->
                                        when (card) {
                                            Cards.Select.name -> halfCard { cardToolSelect() }
                                            Cards.Tools.name -> {
                                                router.select("change").render { (change, _) ->
                                                    when (change) {
                                                        "tags" -> fullCard(prefix = change) {
                                                            fullWidthCenterContainer(prefix = "manage-archetype-keyword-tags") {
                                                                cardManageKeywordTags(type = KeywordTagType.ArchetypeTag)
                                                            }
                                                        }

                                                        "fieldValue" -> fullCard(prefix = change) {
                                                            fullWidthCenterContainer(prefix = "manage-archetype-field-value-tag") {
                                                                cardManageFieldValueTag(tagType = KeywordTagType.ArchetypeTag)
                                                            }
                                                        }

                                                        else -> fullCard { fullWidthCenterContainer { cardToolsOverview() } }
                                                    }
                                                }
                                            }

                                            Cards.Sharing.name -> halfCard { cardSharing() }
                                            Cards.MapLayers.name -> pathMapLayersAndStyles()
                                            Cards.Track.name -> halfCard { cardTrackedObject() }
//                                            Cards.NFC.name -> expandCard { cardNFC() }
                                            Cards.CreateTrackedObject.name -> halfCard { cardCreateTrackedObject() }
                                            Cards.ClusterPrev.name -> expandCard() { cardClusterPreview() }
                                            else -> {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                router.select("userId").render { (userId, _) ->
                    if (!userId.isNullOrBlank()) {
                        activeUserStore.data.render { activeUser ->
                            if (activeUser.userId == userId) {
                                expandCard() {
                                    if (userId == apiUserStore.current.userId) {
                                        cardMyUser()
                                    } else cardOtherUser()
                                }
                            } // else render nothing
                        }
                    }
                }
            }
        }
    }
}

private fun RenderContext.pathEditOrInfo(edit: String?, router: MapRouter) {
    when (edit) {
        Cards.POI.name -> pathEdit(ObjectType.POI)
        Cards.Task.name -> pathEdit(ObjectType.Task)
        Cards.Event.name -> pathEdit(ObjectType.Event)
        Cards.TrackedObject.objType?.name -> pathEdit(ObjectType.ObjectMarker)
        Cards.Area.objType?.name -> pathEdit(ObjectType.Area)
        Cards.Zone.objType?.name -> pathEdit(ObjectType.Zone)
        Cards.GeneralMarker.objType?.name -> pathEdit(ObjectType.GeneralMarker)
        else -> {
            router.select("editPosition").render { (editPosition, _) ->
                when (editPosition) {
                    Cards.TrackedObject.objType?.name -> expandCard {
                        cardEditPosition(
                            ObjectType.ObjectMarker,
                        )
                    }

                    else -> {
                        pathInfo()
                    }
                }
            }
        }
    }
}

fun RenderContext.pathInfo() {
    val router: MapRouter by koinCtx.inject()
    router.select("show").render { (show, _) ->
        when (show) {
            "menu" -> {
                expandCard { cardSecondaryMenu() }
            }

            "details" -> {
                expandCard { cardDetails() }
            }

            "attribution" -> {
                halfCard { cardAttribution() }
            }

            "description" -> {
                expandCard(prefix = show) {
                    fullWidthCenterContainer(prefix = "view-description") {
                        cardViewDescription()
                    }
                }
            }

            "taskState" -> {
                expandCard { cardTaskState() }
            }

            "meetingState" -> {
                expandCard { cardMeetingState() }
            }

            "attendees" -> {
                expandCard(openExpended = true, prefix = show) {
                    fullWidthCenterContainer(prefix = "attendees") {
                        cardAttendees()
                    }
                }
            }

            "objectHistory" -> {
                expandCard(prefix = show) {
                    fullWidthCenterContainer(prefix = "object-history") {
                        cardObjectHistory()
                    }
                }
            }

            "tags" -> {
                expandCard { cardShowKeywordTags() }
            }

            "objectData" -> {
                expandCard(prefix = show) {
                    fullWidthCenterContainer(prefix = "object-data", width = { "400px" }) {
                        cardCustomData()
                    }
                }
            }

            "nestedObjects" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "tags" -> fullCard(prefix = change) {
                            fullWidthCenterContainer(prefix = "manage-nested-object-keyword-tags") {
                                cardManageKeywordTags(type = KeywordTagType.NestedObjectTag)
                            }
                        }

                        "fieldValue" -> fullCard(prefix = change) {
                            fullWidthCenterContainer(prefix = "manage-nested-object-field-value-tag") {
                                cardManageFieldValueTag(tagType = KeywordTagType.NestedObjectTag)
                            }
                        }

                        else -> expandCard(openExpended = true, prefix = show) {
                            fullWidthCenterContainer(prefix = "nested-objects", width = { "400px" }) {
                                cardNestedObjects()
                            }
                        }
                    }
                }
            }

            "markdownPrev" -> {
                expandCard(openExpended = true, prefix = show) {
                    fullWidthCenterContainer(prefix = "markdown-prev", width = { "400px" }) {
                        cardMarkdownPreview()
                    }
                }
            }

            "imagePrev" -> {
                expandCard(openExpended = true, prefix = show) {
                    fullWidthCenterContainer(prefix = "image-prev", width = { "400px" }) {
                        cardImagePreview()
                    }
                }
            }

            "poll" -> {
                fullPage {
                    fullWidthCenterContainer(prefix = "poll", width = { "600px" }) {
                        val activePollStore: ActivePollStore by koinCtx.inject()
                        cardPoll(showBack = true, expandable = false, activePollStore = activePollStore)
                    }
                }
            }

            "connectQR" -> {
                expandCard { cardConnectQR() }
            }

            else -> {
                expandCard { cardInfo() }
            }
        }
    }
}

fun RenderContext.pathCreate(objType: ObjectType) {
    val router: MapRouter by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    router.select("change").render { (change, _) ->
        when (change) {
            "desc" -> halfCard { cardDescription() }
            "datetime" -> expandCard() { cardDateTime() }
            "duedate" -> expandCard() { cardDueDate() }
            "participants" -> expandCard(openExpended = true, prefix = change) {
                fullWidthCenterContainer(prefix = "user-select") {
                    cardUserSelect()
                }
            }

            "badge" -> expandCard { cardCustomizeBadge() }

            "geofence" -> expandCard(prefix = change) { cardEditZoneGeofence() }

            "tags" -> expandCard(openExpended = true, prefix = change) {
                fullWidthCenterContainer(prefix = "manage-keyword-tags") {
                    cardManageKeywordTags(type = KeywordTagType.ObjectTag)
                }
            }

            "fieldValue" -> expandCard(openExpended = true, prefix = change) {
                fullWidthCenterContainer(prefix = "manage-field-value-tag") {
                    cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
                }
            }

//            "attachments" -> expandCard(openExpended = true) { cardManageAttachments() }
//            AttachmentType.Image.name -> expandCard(openExpended = true) { cardManageImageAttachment() }
//            AttachmentType.Markdown.name -> expandCard {
//                fullWidthCenterContainer(prefix = "manage-markdown", width = { "400px" }) {
//                    cardManageMarkdownAttachment()
//                }
//            }
//
//            AttachmentType.WebLink.name -> expandCard(openExpended = true) { cardManageWebLinkAttachment() }
//            AttachmentType.GeoObject.name -> expandCard(openExpended = true) { cardManageGeoObjectAttachment() }
//            AttachmentType.Poll.name -> expandCard(openExpended = true) { cardManagePollAttachment() }
//            AttachmentType.ScanToCreateTask.name -> expandCard(openExpended = true) { cardManageScanToTaskAttachment() }
//            "takePhoto" -> fullCard { cardBrowserPhotoCamera(facingMode = FacingMode.Back) }
            else -> expandCard { cardCreate(objType) }
        }
    }
}

fun RenderContext.pathEdit(objType: ObjectType) {
    val router: MapRouter by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    router.select("change").render { (change, _) ->
        when (change) {
            "desc" -> halfCard { cardDescription() }
            "datetime" -> expandCard { cardDateTime() }
            "duedate" -> expandCard { cardDueDate() }
            "participants" -> {
                expandCard(openExpended = true, prefix = change) {
                    fullWidthCenterContainer(prefix = "user-select") {
                        cardUserSelect()
                    }
                }
            }

            "badge" -> expandCard(prefix = change) { cardCustomizeBadge() }

            "badge" -> expandCard(prefix = change) { cardCustomizeBadge() }

            "geofence" -> expandCard(prefix = change) { cardEditZoneGeofence() }

            "tags" -> expandCard(openExpended = true, prefix = change) {
                fullWidthCenterContainer(prefix = "manage-keyword-tags") {
                    cardManageKeywordTags(type = KeywordTagType.ObjectTag)
                }
            }

            "fieldValue" -> expandCard(openExpended = true, prefix = change) {
                fullWidthCenterContainer(prefix = "manage-field-value-tag") {
                    cardManageFieldValueTag(tagType = KeywordTagType.ObjectTag)
                }
            }

            "delete" -> {
                expandCard { cardEdit(objType) }
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.Object) } }
            }

//            "attachments" -> expandCard(openExpended = true, prefix = change) { cardManageAttachments() }
//            AttachmentType.Image.name -> expandCard(openExpended = true) { cardManageImageAttachment() }
//            AttachmentType.Markdown.name -> expandCard {
//                fullWidthCenterContainer(prefix = "manage-markdown", width = { "400px" }) {
//                    cardManageMarkdownAttachment()
//                }
//            }

//            AttachmentType.WebLink.name -> expandCard { cardManageWebLinkAttachment() }
//            AttachmentType.GeoObject.name -> expandCard { cardManageGeoObjectAttachment() }
//            AttachmentType.Poll.name -> expandCard { cardManagePollAttachment() }
//            AttachmentType.ScanToCreateTask.name -> expandCard(openExpended = true) { cardManageScanToTaskAttachment() }
//            "takePhoto" -> fullCard { cardBrowserPhotoCamera(facingMode = FacingMode.Back) }
            else -> expandCard { cardEdit(objType) }
        }
    }
}

fun RenderContext.pathMapLayersAndStyles() {

    val router: MapRouter by koinCtx.inject()

    router.select("mapLayer").render { (mapLayer, _) ->
        when (mapLayer) {
            "add" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "fieldValue" -> expandCard(openExpended = true, prefix = change) {
                            fullWidthCenterContainer(prefix = "manage-field-value-tag") {
                                cardManageFieldValueTag()
                            }
                        }

                        else -> expandCard(openExpended = true) {
                            fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.CREATE_NEW_LAYER) }
                        }
                    }
                }
            }

            "edit" -> {
                router.select("change").render { (change, _) ->
                    when (change) {
                        "fieldValue" -> expandCard(openExpended = true, prefix = change) {
                            fullWidthCenterContainer(prefix = "manage-field-value-tag") {
                                cardManageFieldValueTag()
                            }
                        }

                        else -> expandCard(openExpended = true) {
                            fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) }
                        }
                    }
                }
            }

            "deleteKeywordLayer" -> {
                expandCard { fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) } }
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.KeywordLayer) } }
            }

            "deleteHeatmapLayer" -> {
                expandCard { fullWidthCenterContainer { cardManageMapLayer(TL.LayerAction.EDIT_LAYER) } }
                fullPageConfirmation { fullPageConfirmationContainer { cardDeleteConfirmation(Delete.HeatmapLayer) } }
            }

            else -> expandCard { cardMapLayerSelection() }
        }
    }
}
