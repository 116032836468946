package workspacetools.workspaceoptions

import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import com.jillesvangurp.geojson.urlEncode
import apiclient.util.createHttpClient
import apiclient.FormationClient
import apiclient.groups.Group
import apiclient.groups.GroupDetails
import apiclient.groups.restUpdateGroupDetails
import apiclient.geoobjects.LatLon
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.Store
import dev.fritz2.core.disabled
import dev.fritz2.core.storeOf
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import koin.withKoin
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.flow.debounce
import localization.translate
import mainmenu.RouterStore
import org.w3c.dom.HTMLDivElement
import overlays.BusyStore
import twcomponents.twCheckButton
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMarkdownContent
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow

enum class NameState {
    NotModified,
    Checking,
    Invalid,
    Unavailable,
    Available
}

fun HtmlTag<HTMLDivElement>.workspaceNameEditor(group: Group) {
    val nameStore = storeOf(group.name)
    val nameStateStore = storeOf(NameState.NotModified)

    nameStore.data.debounce(200.milliseconds) handledBy {
        checkName(group.name, it.normalizeName(), nameStateStore)
    }

    h2 {
        translate(WorkspaceOptionsTexts.WorkspaceName)
    }
    twMarkdownContent(WorkspaceOptionsTexts.WorkspaceNameExplanation)

    div("w-full sm:w-[700px]") {
        twInputField(nameStore) {
            twInputTextField {}
            twRightAlignedButtonRow {
                nameStateStore.data.render { state ->
                    when (state) {
                        NameState.NotModified -> {
                            div("text-formationBlack") {}
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        NameState.Checking -> {
                            div {
                                +"Checking"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        NameState.Invalid -> {
                            div("text-red-500 whitespace-nowrap") {
                                +"Name invalid"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        NameState.Unavailable -> {
                            div("text-red-500 whitespace-nowrap") {
                                +"Not available"
                            }
                            twRevertButton(nameStore, group.name)
                            twCheckButton {
                                disabled(true)
                            }
                        }

                        NameState.Available -> {
                            nameStore.data.render { newName ->
                                div("text-green-500 whitespace-nowrap") {
                                    +"Available"
                                }
                                twRevertButton(nameStore, group.name)
                                twCheckButton {
                                    clicks handledBy {
                                        console.log("Set Group Name to $newName")
                                        setGroupName(group, newName)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private suspend fun setGroupName(group: Group, newName: String) {
    withKoin {
        val formationClient = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val currentWorkspaceStore = get<CurrentWorkspaceStore>()
        val router = get<RouterStore>()
        val apiUserStore = get<ApiUserStore>()
        busyStore.withBusy(
            {
                formationClient.restUpdateGroupDetails(
                    group.groupId,
                    GroupDetails(
                        newName,
                        // pick a sensible default of Ahoy
                        group.defaultMapCenter ?: LatLon(52.54114951642207, 13.390883791205976),
                        group.defaultMapZoomLevel ?: 16.0,
                    ),
                )
            },
        ) { modifiedGroup ->
            currentWorkspaceStore.update(modifiedGroup)
            // ws has changed so update the router
//            router.validateInternalRoute(WorkspaceToolsScreens.WorkspaceOptions.route)
            apiUserStore.refreshUser(Unit)
        }
    }
}

private fun String.normalizeName(): String {
    return trim().lowercase()
        .replace(" ", "-")
        .replace("_", "-")
}

private fun String.isValidSubdomain(): Boolean {
    return matches(Regex("[a-zA-Z0-9-]{1,63}"))
}

suspend fun checkName(existingName: String, newName: String, nameStateStore: Store<NameState>) {
    withKoin {
        val normalized = newName.normalizeName()
        when {
            existingName.normalizeName() == newName.normalizeName() -> {
                nameStateStore.update(NameState.NotModified)
            }

            normalized.isValidSubdomain() -> {
                nameStateStore.update(NameState.Checking)
                if (isGroupNameAvailable(normalized)) {
                    nameStateStore.update(NameState.Available)
                } else {
                    nameStateStore.update(NameState.Unavailable)
                }
            }

            else -> {
                nameStateStore.update(NameState.Invalid)
            }
        }
    }
}

suspend fun isGroupNameAvailable(name: String): Boolean {
    val client = createHttpClient()
    return try {
        // don't use FormationClient here to ensure we hit the right server for a name check
        client.head("https://${name}.tryformation.com/groups/${name.urlEncode()}/exists")
        // the name is in use
        false
    } catch (e: ClientRequestException) {
        if (e.response.status.value == 404) {
            true
        } else {
            console.error("unexpected status checking group name availability", e)
            // bias to unavailable
            false
        }
    } catch (e: Throwable) {
        // this can happen because of networking
        false
    }
}
