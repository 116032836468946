package camera.zxing

import camera.cameraWrapper.SelectedCameraDeviceStore
import camera.cameraWrapper.cameraControls
import camera.nfc.handleScanResult
import dev.fritz2.core.RenderContext
import dev.fritz2.core.muted
import dev.fritz2.core.storeOf
import koin.koinCtx
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineName
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import map.bottombar.inlinedBottomBar
import model.CodeTech
import model.ScanPurpose
import org.koin.dsl.module
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit

val zxingModule = module {
    single { ZXingCodeScanService() }
}
private val scanScope = CoroutineScope(CoroutineName("scanner"))
private val scanResultStore = storeOf<ZXingResult?>(null, job = Job())
val rejectedScanResultStore = storeOf<ZXingResult?>(null, job = Job())

fun RenderContext.zxingScanScreen() {
    val translation: Translation = koinCtx.get()
    val selectedCameraStore: SelectedCameraDeviceStore by koinCtx.inject()

    val videoElementId = "zxingvideo"

    div("flex flex-col justify-between w-full min-h-full") {
        val parent = domNode

        h3("mx-5 mt-2.5") {
            translation[TL.CodeScanning.QR_SCANNER_PROMPT].renderText(into = this)
        }
        div("flex flex-col gap-3") {

            video(
                baseClass = "w-full relative",
                id = videoElementId,
            ) {
                muted(true)
            }

            selectedCameraStore.data.render { device ->
                console.log("CAMERA CHANGED:", device?.label, device?.id)
                if (device?.mediaStream != null) {
                    initiateScanning(videoElementId, parent, device.id)
                }
            }

            p("p-2") {
                rejectedScanResultStore.data.render { result ->
                    if (result != null) {
                        translation[
                            TL.CodeScanning.CODE_REJECTED,
                            mapOf(
                                "codeType" to result.formatMeta.name,
                                "scanValue" to result.text,
                            ),
                        ].renderText(this)
                    }
                }
            }
            scanResultStore.data.render { scanResult ->
                if (scanResult != null) {
                    p {
                        +scanResult.text
                    }
                    p {
                        +"${scanResult.formatMeta} ${scanResult.formatMeta.policy}"
                    }
                }
            }
        }

        div {
            cameraControls()
            inlinedBottomBar()
        }
    }
}

private fun initiateScanning(videoElementId: String, parent: HTMLDivElement, selectedCameraId: String?) {
    console.log("Initiate Scanning")
    try {
        if (document.getElementById(videoElementId) != null) {
            scanRoutine(videoElementId, selectedCameraId)
        } else {
            MutationObserver { _, observer ->
                console.log("Mutation Observer startet")
                if (document.getElementById(videoElementId) != null) {
                    scanRoutine(videoElementId, selectedCameraId)
                    observer.disconnect()
                    console.log("disconnect observer")
                }
            }.observe(
                parent,
                MutationObserverInit(attributes = false, childList = true, characterData = false, subtree = true),
            )
        }
    } catch (e: Throwable) {
        console.log("ignore", e)
    }
}

private fun scanRoutine(videoElementId: String, cameraId: String?) {
    val zxingScanner = koinCtx.get<ZXingCodeScanService>()

    console.log("Scan Routine startet")

    scanResultStore.update(null)
    rejectedScanResultStore.update(null)
    scanScope.launch {
        console.log("Launch zxing scan")
        try {
            var result: ZXingResult? = null
            // USE SINGLE SCAN MODE
//            while (result == null || result.formatMeta.policy == ScanFormatPolicy.Reject) {
//                if (result?.formatMeta?.policy == ScanFormatPolicy.Reject && rejectedScanResultStore.current?.text != result.text) {
//                    rejectedScanResultStore.update(result)
//                }
//
//                result?.let { zxingScanner.reset() }
//                result = zxingScanner.scan(deviceId = cameraId, videoElementId)
//            }

            // USE CONTINUOUS SCAN MODE
            while (result == null) {
                result = zxingScanner.scanContinuousUntilAccepted(deviceId = cameraId, videoElementId).await()
            }
            scanResultStore.update(result)
            rejectedScanResultStore.update(null)
            zxingScanner.reset()
            handleScanResult(result.text, result.text, CodeTech.QR, ScanPurpose.OpenCode)
        } catch (e: Throwable) {
            console.log("zxing error", e)
        }
    }
}
