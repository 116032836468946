package data.objects.views

import analyticsdashboard.ActiveHistoryPathSearchFieldValuesStore
import analyticsdashboard.ActiveHistoryPathSearchKeywordsStore
import analyticsdashboard.ActiveHistoryPathSearchObjectTypesStore
import analyticsdashboard.ActiveHistoryPathSearchOptionsStore
import analyticsdashboard.ActiveHistoryPathSearchReadOnlyKeywordsStore
import apiclient.customfields.CustomFieldDefinition
import apiclient.customfields.FieldValue
import apiclient.geoobjects.ArchetypeDefinition
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MarkerFeatureConfiguration
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.groups.Group
import apiclient.groups.filterFieldDefinitionsByRestrictions
import apiclient.tags.getUniqueTag
import apiclient.tags.tag
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.ActiveObjectFieldValuesStore
import data.objects.ActiveObjectKeywordsStore
import data.objects.ActiveObjectStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.lineUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import mainmenu.settingsCategory
import map.views.workplacetools.ActiveArchetypeSearchFieldValuesStore
import map.views.workplacetools.ActiveArchetypeSearchKeywordsStore
import map.views.workplacetools.ArchetypesStore
import model.KeywordTag
import model.L
import model.SuggestedTagsContext
import model.prefix
import search.global.ActiveGlobalSearchOptionsStore
import search.global.ActiveSearchFieldValuesStore
import search.global.ActiveSearchKeywordsStore
import search.global.ActiveSearchObjectTypesStore
import search.global.ActiveSearchReadOnlyKeywordsStore
import search.nestedObjects.ActiveNestedObjectTypesStore
import search.nestedObjects.ActiveNestedObjectsReadOnlyKeywordsStore
import search.nestedObjects.ActiveNestedObjectsSearchOptionsStore
import search.nestedObjects.ActiveNestedSearchFieldValuesStore
import search.nestedObjects.ActiveNestedSearchKeywordStore
import search.searchFilterStores.ActiveSearchOptionsStore
import search.searchFilterStores.SearchFilterOption
import search.separationLine
import services.SuggestedTagsContextStore
import services.SuggestedTagsStore
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import utils.extractReadOnlyTags
import utils.mergeTagLists
import utils.toKeyWordTagsList
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.contentScrollBox
import webcomponents.genericInput
import webcomponents.genericSmallIconButton
import webcomponents.inputIconButton
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.oneButtonFooter
import webcomponents.switchBoxWithFlow
import webcomponents.twoButtonFooter

fun RenderContext.cardManageKeywordTags(type: KeywordTagType) {
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()


    baseLayout(
        header = null,
        content = {
            contentScrollBox {
                tagManagement(type)
            }
        },
        footer = {
            when (type) {
                KeywordTagType.ObjectTag -> {
                    twoButtonFooter(
                        secondaryTitle = translation[TL.General.CANCEL],
                        secondaryClickHandlers = listOf(
                            activeObjectStore.updateChangeInputStores,
                            suggestedTagsContextStore.resetPrefix,
                            routerStore.back,
                        ),
                        primaryTitle = translation[TL.General.SAVE],
                        primaryValue = Unit,
                        primaryClickHandlers = listOf(
                            activeObjectStore.readFromChangeInputStores,
                            suggestedTagsContextStore.resetPrefix,
                            routerStore.back,
                        ),
                    )
                }

                else -> { //KeywordTagType.SearchTag, KeywordTagType.ArchetypeTag, KeywordTagType.NestedObjectTag, KeywordTagType.AnalyticsPathViewTag
                    oneButtonFooter(
                        title = translation[TL.General.SET],
                        value = Unit,
                        clickHandlers = listOf(suggestedTagsContextStore.resetPrefix, routerStore.back),
                    )
                }
            }
        },
    )
}

fun getSuggestedFieldDefinitions(
    activeFieldValuesStore: Flow<List<FieldValue>>,
    workspaceStore: Store<Group?>,
    restrictFieldValues: Boolean = false,
    objectType: ObjectType? = null,
) = activeFieldValuesStore.combine(workspaceStore.data) { activeFieldValues, workspace ->
    // if object ist provided, filter out all CustomFieldDefinitions that are not allowed on that object
    // then, filter out all CustomFieldDefinitions that are already in the activeFieldValues
    (if (restrictFieldValues) {
        with(workspace?.filterFieldDefinitionsByRestrictions(activeFieldValues)) {
            if (objectType != null) {
                this?.filter {
                    val objectTypes = it.objectTypes
                    if (!objectTypes.isNullOrEmpty()) objectTypes.contains(objectType) else true
                }
            } else this
        }
    } else workspace?.fieldDefinitions)?.filterNot { fieldDefinition ->
        activeFieldValues.map { fieldValue -> fieldValue.field }.contains(fieldDefinition.name)
    } ?: emptyList()
}

fun getSuggestedFieldDefinitionsForArchetypes(
    activeFieldValuesStore: Flow<List<FieldValue>>,
    workspaceStore: Store<Group?>,
    restrictFieldValues: Boolean = false,
    objectType: ObjectType? = null,
    archetypeDefinitions: Flow<List<ArchetypeDefinition>?>,
) = combine(
    activeFieldValuesStore,
    workspaceStore.data,
    archetypeDefinitions,
) { activeFieldValues, workspace, archeTypeDefs ->
    // if object ist provided, filter out all CustomFieldDefinitions that are not allowed on that object
    // then, filter out all CustomFieldDefinitions that are already in the activeFieldValues
    (if (restrictFieldValues) {
        with(workspace?.filterFieldDefinitionsByRestrictions(activeFieldValues)) {
            if (objectType != null) {
                this?.filter {
                    val objectTypes = it.objectTypes
                    if (!objectTypes.isNullOrEmpty()) objectTypes.contains(objectType) else true
                }
            } else this
        }
    } else workspace?.fieldDefinitions)?.filter { fieldDefinition ->
        archeTypeDefs?.asSequence()
            ?.flatMap { it.sections }
            ?.flatMap { it.configurations }
            ?.filterIsInstance<MarkerFeatureConfiguration.CustomField>()
            ?.map { it.fieldName }?.contains(fieldDefinition.name) == true
            && !activeFieldValues.map { fieldValue -> fieldValue.field }.contains(fieldDefinition.name)
    } ?: emptyList()
}

fun showFieldValueSection(
    activeFieldValuesStore: Store<List<FieldValue>>,
    workspaceStore: Store<Group?>,
    objectType: ObjectType,
): Boolean {
    return with(workspaceStore.current?.filterFieldDefinitionsByRestrictions(activeFieldValuesStore.current)) {
        this?.filter {
            val objectTypes = it.objectTypes
            if (!objectTypes.isNullOrEmpty()) objectTypes.contains(objectType) else true
        }
    }?.isNotEmpty() == true || activeFieldValuesStore.current.isNotEmpty()
}

fun Flow<List<FieldValue>>.validateRestrictions(
    workspaceStore: Store<Group?>,
    fieldValueStore: Store<List<FieldValue>>,
): Flow<List<FieldValue>> {
    return this.combine(workspaceStore.data) { activeFieldValues, workspace ->
        val filteredList = activeFieldValues.filter { fieldValue ->
            with(workspace?.fieldDefinitions?.firstOrNull { it.name == fieldValue.field }?.requireFieldValues) {
                if (!this.isNullOrEmpty()) {
                    this.map {
                        activeFieldValues.contains(it)
                    }.reduceRight { l, r -> l && r }
                } else true
            }
        }
        fieldValueStore.update(filteredList)
        filteredList
    }
}

fun RenderContext.tagManagement(type: KeywordTagType) {
    // For ObjectTag
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val activeObjectKeywordsStore: ActiveObjectKeywordsStore by koinCtx.inject()
    val activeObjectFieldValuesStore: ActiveObjectFieldValuesStore by koinCtx.inject()
    // For SearchTag
    val activeSearchKeywordsStore: ActiveSearchKeywordsStore by koinCtx.inject()
    val activeSearchObjectTypesStore: ActiveSearchObjectTypesStore by koinCtx.inject()
    val activeSearchReadOnlyKeywordsStore: ActiveSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeSearchFieldValuesStore: ActiveSearchFieldValuesStore by koinCtx.inject()
    val activeGlobalSearchOptionsStore: ActiveGlobalSearchOptionsStore by koinCtx.inject()
    // For both
    val suggestedTagsContextStore: SuggestedTagsContextStore by koinCtx.inject()
    val tagPrefix = suggestedTagsContextStore.map(SuggestedTagsContext.prefix())
    val suggestedTagsStore: SuggestedTagsStore by koinCtx.inject()
    // For HistoryPathSearchTags
    val activeHistoryPathSearchKeywordsStore: ActiveHistoryPathSearchKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchObjectTypesStore: ActiveHistoryPathSearchObjectTypesStore by koinCtx.inject()
    val activeHistoryPathSearchReadOnlyKeywordsStore: ActiveHistoryPathSearchReadOnlyKeywordsStore by koinCtx.inject()
    val activeHistoryPathSearchFieldValuesStore: ActiveHistoryPathSearchFieldValuesStore by koinCtx.inject()
    val activeHistoryPathSearchOptionsStore: ActiveHistoryPathSearchOptionsStore by koinCtx.inject()
    // For ArchetypeTag
    val activeArchetypeSearchKeywordsStore: ActiveArchetypeSearchKeywordsStore by koinCtx.inject()
    val activeArchetypeSearchFieldValuesStore: ActiveArchetypeSearchFieldValuesStore by koinCtx.inject()
    // For NestedObjectTag
    val activeNestedSearchKeywordStore: ActiveNestedSearchKeywordStore by koinCtx.inject()
    val activeNestedSearchFieldValuesStore: ActiveNestedSearchFieldValuesStore by koinCtx.inject()
    val activeNestedObjectTypesStore: ActiveNestedObjectTypesStore by koinCtx.inject()
    val activeNestedObjectsReadOnlyKeywordsStore: ActiveNestedObjectsReadOnlyKeywordsStore by koinCtx.inject()
    val activeNestedObjectsSearchOptionsStore: ActiveNestedObjectsSearchOptionsStore by koinCtx.inject()
    val defaultTheme = koinCtx.get<FormationDefault>()

    val archetypesStore: ArchetypesStore by koinCtx.inject()

    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val currentWorkspaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val activeObjectType = activeObjectStore.current.objectType
    val readOnlyKeywordTags = activeObjectStore.map(GeoObjectDetails.L.tags).data.extractReadOnlyTags()

    val addedObjectTypesStore: Store<List<ObjectType>>
    val addedReadOnlyTagsStore: Store<List<KeywordTag>>
    val addedKeywordsStore: Store<List<String>>
    val addKeywordHandler: SimpleHandler<Unit>?
    val suggestedKeywordsFlow: Flow<List<String>>
    val addedTagsAmountFlow: Flow<Int>

    val addedFieldValuesFlow: Flow<List<FieldValue>>
    val suggestedFieldDefinitionsFlow: Flow<List<CustomFieldDefinition>>
    val searchOptionsStore: ActiveSearchOptionsStore

    when (type) {
        KeywordTagType.ObjectTag -> {
            suggestedTagsContextStore.resetCtxTags()
            addedObjectTypesStore = storeOf(emptyList())
            addedReadOnlyTagsStore = storeOf(emptyList())
            addedKeywordsStore = activeObjectKeywordsStore
            addKeywordHandler = activeObjectKeywordsStore.addKeywordFromInput
            suggestedKeywordsFlow =
                activeObjectKeywordsStore.data.combine(suggestedTagsStore.data) { selected, suggested ->
                    suggested - selected.toSet()
                }
            addedTagsAmountFlow = combine(readOnlyKeywordTags, addedKeywordsStore.data) { r, k -> (r.size) + k.size }
            addedFieldValuesFlow = activeObjectFieldValuesStore.data.validateRestrictions(
                currentWorkspaceStore,
                activeObjectFieldValuesStore,
            )
            val isFromArcheTypeId = activeObjectStore.current.tags.getUniqueTag(ObjectTags.ArchId)
            suggestedFieldDefinitionsFlow = if (isFromArcheTypeId != null) {
                getSuggestedFieldDefinitionsForArchetypes(
                    activeObjectFieldValuesStore.data,
                    currentWorkspaceStore,
                    restrictFieldValues = true,
                    objectType = activeObjectType,
                    archetypeDefinitions = archetypesStore.data.map { archetypes ->
                        archetypes.firstOrNull { it.id == isFromArcheTypeId }?.archetypeDefinition?.let { listOf(it) }
                    },
                )
            } else {
                getSuggestedFieldDefinitions(
                    activeObjectFieldValuesStore.data,
                    currentWorkspaceStore,
                    restrictFieldValues = true,
                    objectType = activeObjectType,
                )
            }
            searchOptionsStore = ActiveSearchOptionsStore(emptyMap())
        }

        KeywordTagType.AnalyticsPathViewTag -> {
            suggestedTagsContextStore.resetCtxTags()
            addedObjectTypesStore = activeHistoryPathSearchObjectTypesStore
            addedReadOnlyTagsStore = activeHistoryPathSearchReadOnlyKeywordsStore
            addedKeywordsStore = activeHistoryPathSearchKeywordsStore
            addKeywordHandler = activeHistoryPathSearchKeywordsStore.addHistoryPathKeywordFromInput
            suggestedKeywordsFlow =
                activeHistoryPathSearchKeywordsStore.data.combine(suggestedTagsStore.data) { selected, suggested ->
                    suggested - (selected).toSet()
                }
            addedTagsAmountFlow = combine(
                addedObjectTypesStore.data,
                addedReadOnlyTagsStore.data,
                addedKeywordsStore.data,
            ) { o, t, k -> o.size + t.size + k.size }
            addedFieldValuesFlow = activeHistoryPathSearchFieldValuesStore.data
            suggestedFieldDefinitionsFlow =
                getSuggestedFieldDefinitions(activeHistoryPathSearchFieldValuesStore.data, currentWorkspaceStore)
            searchOptionsStore = activeHistoryPathSearchOptionsStore
        }

        KeywordTagType.ArchetypeTag -> {
            // update suggestedTagsContext to only get tags from archetypes
            suggestedTagsContextStore.updateTags(listOf(ObjectTags.IsArchetype.tag("true")))
//            suggestedTagsContextStore.updateObjTypes(listOf(ObjectType.GeneralMarker))
            addedObjectTypesStore = storeOf(emptyList())
            addedReadOnlyTagsStore = storeOf(emptyList())
            addedKeywordsStore = activeArchetypeSearchKeywordsStore
            addKeywordHandler = activeArchetypeSearchKeywordsStore.addKeywordFromInput
            suggestedKeywordsFlow =
                activeArchetypeSearchKeywordsStore.data.combine(suggestedTagsStore.data) { selected, suggested ->
                    suggested - (selected).toSet()
                }
            addedTagsAmountFlow = addedKeywordsStore.data.map { it.size }
            addedFieldValuesFlow = activeArchetypeSearchFieldValuesStore.data
            suggestedFieldDefinitionsFlow = getSuggestedFieldDefinitionsForArchetypes(
                activeArchetypeSearchFieldValuesStore.data,
                currentWorkspaceStore,
                archetypeDefinitions = archetypesStore.data.map { archetypes ->
                    archetypes.mapNotNull { it.archetypeDefinition }
                },
            )
            searchOptionsStore = ActiveSearchOptionsStore(emptyMap())
        }

        KeywordTagType.NestedObjectTag -> {
            suggestedTagsContextStore.resetCtxTags()
            addedObjectTypesStore = activeNestedObjectTypesStore
            addedReadOnlyTagsStore = activeNestedObjectsReadOnlyKeywordsStore
            addedKeywordsStore = activeNestedSearchKeywordStore
            addKeywordHandler = activeNestedSearchKeywordStore.addKeywordFromInput
            suggestedKeywordsFlow =
                activeNestedSearchKeywordStore.data.combine(suggestedTagsStore.data) { selected, suggested ->
                    suggested - (selected).toSet()
                }
            addedTagsAmountFlow = combine(
                addedObjectTypesStore.data,
                addedReadOnlyTagsStore.data,
                addedKeywordsStore.data,
            ) { o, t, k -> o.size + t.size + k.size }
            addedFieldValuesFlow = activeNestedSearchFieldValuesStore.data
            suggestedFieldDefinitionsFlow = getSuggestedFieldDefinitions(
                activeNestedSearchFieldValuesStore.data,
                currentWorkspaceStore,
            )
            searchOptionsStore = activeNestedObjectsSearchOptionsStore
        }

        else -> { // type is KeywordTagType.SearchTag\
            suggestedTagsContextStore.resetCtxTags()
            addedObjectTypesStore = activeSearchObjectTypesStore
            addedReadOnlyTagsStore = activeSearchReadOnlyKeywordsStore
            addedKeywordsStore = activeSearchKeywordsStore
            addKeywordHandler = activeSearchKeywordsStore.addKeywordFromInput
            suggestedKeywordsFlow =
                activeSearchKeywordsStore.data.combine(suggestedTagsStore.data) { selected, suggested ->
                    suggested - (selected).toSet()
                }
            addedTagsAmountFlow = combine(
                addedObjectTypesStore.data,
                addedReadOnlyTagsStore.data,
                addedKeywordsStore.data,
            ) { o, t, k -> o.size + t.size + k.size }
            addedFieldValuesFlow = activeSearchFieldValuesStore.data
            suggestedFieldDefinitionsFlow = getSuggestedFieldDefinitions(
                activeSearchFieldValuesStore.data,
                currentWorkspaceStore,
            )
            searchOptionsStore = activeGlobalSearchOptionsStore
        }
    }

    featureFlagStore.data.render { features ->
        if (features[Features.AllowCustomFields] == true && (type != KeywordTagType.ObjectTag
                || showFieldValueSection(activeObjectFieldValuesStore, currentWorkspaceStore, activeObjectType)
                )
        ) {
            settingsCategory(
                title = translation[TL.KeywordTags.VALUE_TAGS],
                iconFlow = flowOf(defaultTheme.icons.grid),
                subtitle = null,
            ) {
                addedFieldValuesFlow.render { fieldValues ->
                    // Added fieldValue tags
                    if (fieldValues.isNotEmpty()) {
                        div(
                            {
                                css("align-self: start;")
                            },
                        ) {
                            lineUp(
                                {
                                    alignItems { center }
                                    justifyContent { start }
                                },
                            ) {
                                spacing { tiny }
                                items {
                                    span({ fontSize { normal } }) {
                                        translation[TL.KeywordTags.ADDED].renderText(into = this)
                                    }
                                    span(
                                        {
                                            color { primary.main }
                                            fontSize { normal }
                                            fontWeight { bold }
                                        },
                                    ) {
                                        addedFieldValuesFlow.map { it.size }.renderText(into = this)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            keywordTagList(
                keywords = addedFieldValuesFlow.map {
                    it.toKeyWordTagsList(KeywordTagActionType.RemoveFieldValue)
                },
                keywordTagType = type,
            )

            suggestedFieldDefinitionsFlow.render { workspaceFieldDefinitions ->
                // Suggested fieldValue tags (workspace specific)
                if (workspaceFieldDefinitions.isNotEmpty()) {
                    span(
                        {
                            fontSize { normal }
                            css("align-self: start;")
                        },
                    ) {
                        translation[TL.KeywordTags.SUGGESTED].renderText(into = this)
                    }
                    keywordTagList(
                        keywords = flowOf(
                            workspaceFieldDefinitions.toKeyWordTagsList(
                                KeywordTagActionType.AddFieldValue,
                            ),
                        ),
                        keywordTagType = type,
                    )
                }
            }
            separationLine(
                margins = {
                    top { small }
                    bottom { small }
                },
            )
        }
    }
    // Tags
    settingsCategory(
        title = translation[TL.KeywordTags.TAGS],
        iconFlow = flowOf(FormationIcons.Tag.icon),
        subtitle = null,
        spacing = { small },
    ) {
        // Added tags
        addedTagsAmountFlow.render { tagsAmount ->
            if (tagsAmount > 0) {
                lineUp(
                    {
                        width { full }
                        alignItems { center }
                        justifyContent { start }
                    },
                ) {
                    spacing { tiny }
                    items {
                        span({ fontSize { normal } }) {
                            translation[TL.KeywordTags.ADDED].renderText(into = this)
                        }
                        span(
                            {
                                color { FormationColors.MarkerYou.color }
                                fontSize { normal }
                                fontWeight { bold }
                            },
                        ) {
                            +tagsAmount.toString()
                        }
                    }
                }
            }
        }
    }

    keywordTagList(
        keywords = when (type) {
            KeywordTagType.ObjectTag -> mergeTagLists(
                readOnlyTags = readOnlyKeywordTags,
                keywords = addedKeywordsStore.data,
                keywordTagActionType = KeywordTagActionType.Remove,
            )

            KeywordTagType.SearchTag, KeywordTagType.AnalyticsPathViewTag, KeywordTagType.NestedObjectTag -> {
                combine(
                    addedObjectTypesStore.data.map { objTypes ->
                        objTypes.map { it.name }.toKeyWordTagsList(KeywordTagActionType.RemoveReadOnly)
                    },
                    addedReadOnlyTagsStore.data.map { otherTags -> otherTags.map { it.copy(actionType = KeywordTagActionType.RemoveReadOnly) } },
                    addedKeywordsStore.data.map { it.toKeyWordTagsList(KeywordTagActionType.Remove) },
                ) { objects, otherTags, keywords -> (objects + otherTags + keywords).distinct() }
            }

            else -> addedKeywordsStore.data.map { it.toKeyWordTagsList(KeywordTagActionType.Remove) }
        },
        keywordTagType = type,
    )
    if (type == KeywordTagType.SearchTag || type == KeywordTagType.NestedObjectTag) {
        // ObjectType tags
        span(
            {
                fontSize { normal }
                css("align-self: start;")
            },
        ) {
            translation[TL.KeywordTags.TYPE].renderText(into = this)
        }
        val allowedObjTypes = listOf(
            ObjectType.POI,
            ObjectType.ObjectMarker,
            ObjectType.UserMarker,
            ObjectType.Task,
            ObjectType.Event,
            ObjectType.Zone,
            ObjectType.Building,
            ObjectType.GeneralMarker,
        )
        keywordTagList(
            keywords = addedObjectTypesStore.data.map { objTypes ->
                ObjectType.entries.filter { it in allowedObjTypes }.minus(objTypes.toSet()).map {
                    it.name
                }.toKeyWordTagsList(KeywordTagActionType.ReadOnly)
            },
            keywordTagType = type,
            searchable = true,
        )
    }

    span(
        {
            fontSize { normal }
            css("align-self: start;")
        },
    ) {
        translation[TL.KeywordTags.SUGGESTED].renderText(into = this)
    }
    // Search input to filter suggested tags
    inputLabelWrapper(
        title = translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER],
        visibilityFlow = tagPrefix.data.map { it.isNotBlank() },
    ) {
        genericInput(
            value = tagPrefix.data,
            enterKeyHandlers = listOf(addKeywordHandler),
            rightContentBox = {
                inputIconButton(
                    iconFlow = flowOf { close },
                ) {
                    className(tagPrefix.data.map { if (it.isBlank()) "invisible" else "visible" })
                    clicks.map { "" } handledBy tagPrefix.update
                }
            },
        ) {
            id("inputKeywords")
            placeholder(translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER])
            autofocus(true)
            attr("tabindex", "0")
            inputs.values() handledBy tagPrefix.update
        }
    }

    // Create tag button
    flexBox(
        {
            width { full }
            alignItems { center }
            justifyContent { end }
            margins { top { small } }
        },
    ) {
        genericSmallIconButton(
            title = translation[TL.KeywordTags.CREATE_TAG],
            icon = { FormationIcons.Create.icon },
            iconPosition = Position.Left,
            hrefOrValue = Unit,
            clickHandlers = listOf(addKeywordHandler),
            disabledFlow = combine(
                tagPrefix.data,
                suggestedKeywordsFlow,
                addedKeywordsStore.data,
            ) { typed, suggested, added ->
                typed.isBlank() || suggested.contains(typed) || added.contains(typed)
            },
        )
    }

    // Suggested tags
    suggestedKeywordsFlow.render { suggestedTags ->
        if (suggestedTags.isNotEmpty()) {
            flexBox(
                {
                    width { full }
                    flex {
                        grow { "1" }
                        shrink { "1" }
                        basis { "50px" }
                    }
                    margins { top { small } }
                },
            ) {
                keywordTagList(
                    keywords = flowOf(suggestedTags.toKeyWordTagsList(KeywordTagActionType.Add)),
                    keywordTagType = type,
                )
            }
        } else {
            flexBox(
                {
                    width { full }
                    flex {
                        grow { "1" }
                        shrink { "1" }
                        basis { "50px" }
                    }
                    alignItems { center }
                    justifyContent { center }
                    direction { column }
                },
            ) {
                flexBox({ flex { grow { "1" } } }) { }
                span { translation[TL.Search.NO_RESULTS].renderText(into = this) }
                flexBox({ flex { grow { "3" } } }) { }
            }
        }
    }
    separationLine(
        margins = {
            top { small }
            bottom { small }
        },
    )
    if (type != KeywordTagType.ObjectTag) {
        // additional search options
        settingsCategory(
            title = translation[TL.SearchFilterTexts.OTHER_FILTER_OPTIONS_TITLE],
            iconFlow = flowOf(defaultTheme.icons.filterAlt),
            subtitle = null,
            spacing = { small },
            margins = {
                bottom { small }
            },
        ) {
            SearchFilterOption.entries.forEach { entry ->
                title(entry.name)
                switchBoxWithFlow(
                    valueFlow = searchOptionsStore.data.map { it[entry] ?: false },
                    clickValueFlow = searchOptionsStore.data.map { mapOf(entry to !(it[entry] ?: false)) },
                    valueUpdateHandler = searchOptionsStore.checkOptions,
                ) {
                    +translation.getString(entry)
                }
            }
        }
    }
}
