package data.objects.building

import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job

class ActiveFloorLevelStore : RootStore<Double?>(
    initialData = null,
    job = Job(),
)

class ActiveFloorStore : RootStore<String?>(
    initialData = null,
    job = Job(),
)

class ActiveBuildingStore : RootStore<String?>(
    initialData = null,
    job = Job(),
)

data class BuildingOverride(
    val buildingId: String,
    val floorId: String,
)

class ActiveBuildingOverrideStore : RootStore<BuildingOverride?>(
    initialData = null,
    job = Job(),
) {
    val reset = handle {
        null
    }
}
