package data.users.views

import data.objects.views.attachments.FileHandlerStore
import data.objects.views.attachments.FileStoreJS
import data.objects.views.attachments.ImageFileDataStore
import data.users.profile.MyProfileStore
import dev.fritz2.components.compat.input
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.accept
import dev.fritz2.core.files
import dev.fritz2.core.type
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import mainmenu.mainMenuButton
import model.MyProfile
import model.profilePhoto
import org.w3c.files.get
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import theme.FormationUIIcons
import webcomponents.baseLayout
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.fullCard
import webcomponents.twoButtonFooter
import workspacetools.usermanagement.confirm

fun RenderContext.cardManageProfilePhoto() {

    val routerStore by koinCtx.inject<RouterStore>()
    val translation by koinCtx.inject<Translation>()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val fileHandlerStore by koinCtx.inject<FileHandlerStore>()
    val fileStoreJS by koinCtx.inject<FileStoreJS>()
    val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()
    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())

    fileHandlerStore.initialize()

    fullCard {
        baseLayout(
            expandable = false,
            header = null,
            content = {
                contentScrollBox {
                    profilePhoto.data.combine(imageFileDataStore.data) { profilePhoto, uploadedPhoto ->
                        Pair(uploadedPhoto, uploadedPhoto.href ?: profilePhoto?.href)
                    }.render { (imgData, imageSrc) ->
                        // Profile Photo
                        flexBox(
                            {
                                position { relative { } }
                                alignItems { center }
                                justifyContent { center }
                                margins {
                                    vertical { normal }
                                }
                            },
                        ) {
                            flexBox(
                                {
                                    flex {
                                        grow { "0" }
                                        shrink { "0" }
                                        basis { "200px" }
                                    }
                                    width { "200px" }
                                    height { "200px" }
                                    radius { full }
                                    justifyContent { center }
                                    alignItems { center }
                                    textAlign { center }

                                    if (imageSrc != null) {
                                        background {
                                            image { imageSrc }
                                            size { cover }
                                            position { center }
                                        }
                                    } else {
                                        border {
                                            width(FormationDefault.formationStyles.borderWidth)
                                            color { FormationColors.GrayDisabled.color }
                                        }
                                        color { FormationColors.GrayDisabled.color }
                                    }
                                },
                            ) {
                                if (imageSrc == null) {
                                    icon(
                                        {
                                            size { "100px" }
                                        },
                                    ) { fromTheme { FormationIcons.UserAlt.icon } }
                                }
                            }
                        }
                        stackUp(
                            {
                                width { full }
                                height { maxContent }
                                justifyContent { center }
                                alignItems { stretch }
                            },
                        ) {
                            spacing { small }
                            items {
                                cardTitle(translation[TL.UserProfile.PROFILE_PHOTO])
                                if (imgData.prevBytes == null) {
                                    // Delete profile picture
                                    mainMenuButton(
                                        icon = { FormationIcons.DeleteAlt.icon },
                                        iconBackgroudColor = { FormationColors.RedError.color },
                                        titleLiteral = translation[TL.UserProfile.DELETE_PICTURE],
                                        subtitle = translation[TL.UserProfile.DELETE_PICTURE_SUBTITLE],
                                        clickHandlers = listOf(
                                            confirm(
                                                translation[TL.UserProfile.DELETE_ARE_YOU_SURE],
                                                listOf(myProfileStore.deleteProfilePhoto),
                                            ),
                                        ),
                                    )
                                }
                                // Upload profile picture
                                mainMenuButton(
                                    icon = { FormationIcons.Upload.icon },
                                    titleLiteral = translation[TL.UserProfile.UPLOAD_A_PICTURE],
                                    subtitle = translation[TL.UserProfile.UPLOAD_A_PICTURE_SUBTITLE],
                                    relative = true,
                                    extraContent = {
                                        input(
                                            {
                                                position {
                                                    absolute {
                                                        top { "0" }
                                                        right { "0" }
                                                        left { "0" }
                                                        bottom { "0" }
                                                    }
                                                }
                                                paddings { left { "100%" } }
                                                margin { "0" }
                                                opacity { "0" }
                                                css("filter: alpha(opacity=0);")
                                                css("cursor: pointer;")
                                            },
                                        ) {
                                            type("file")
                                            accept("image/png, image/gif, image/jpeg")
                                            changes.files().map { files ->
                                                files?.get(0)?.let { file ->
                                                    console.log("image picked:", file)
                                                    file
                                                }
                                            } handledBy fileStoreJS.update
                                        }
                                    },
                                )
                                // Take new profile picture
                                mainMenuButton(
                                    icon = { FormationIcons.CameraPlus.icon },
                                    titleLiteral = translation[TL.UserProfile.TAKE_A_PHOTO],
                                    subtitle = translation[TL.UserProfile.TAKE_A_PHOTO_SUBTITLE],
                                    addOrReplaceRoute = MyProfilePages.TakeProfilePhoto.route,
                                )
                            }
                        }
                    }
                }
            },
            footer = {
                imageFileDataStore.data.render { imgData ->
                    twoButtonFooter(
                        primaryTitle = if (imgData.prevBytes == null) translation[TL.General.BACK] else translation[TL.General.SET],
                        primaryStyleParams = primaryButtonStyleParams,
                        primaryValue = Unit,
                        primaryClickHandlers = listOf(routerStore.back),
                        secondaryTitle = translation[TL.General.REVERT],
                        secondaryIcon = { FormationUIIcons.Undo.icon },
                        secondaryState = flowOf(imgData.prevBytes != null),
                        secondaryStyleParams = secondaryButtonStyleParams,
                        secondaryClickHandlers = listOf(imageFileDataStore.reset),
                    )
                }
            },
        )
    }
}
