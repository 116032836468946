package data.objects.views.directediting

import apiclient.FormationClient
import apiclient.geoobjects.Content
import apiclient.geoobjects.DeleteContent
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.attachIconToGeoObject
import auth.ApiUserStore
import auth.Features
import camera.cameraWrapper.cameraModal
import camera.photo.photoCamera
import com.tryformation.localization.Translatable
import data.objects.ActiveObjectStore
import data.objects.views.attachments.FileHandlerStore
import data.objects.views.attachments.FileStoreFritz2
import data.objects.views.attachments.FileStoreJS
import data.objects.views.attachments.ImageFileDataStore
import data.objects.views.attachments.imagePrevData
import dev.fritz2.core.RenderContext
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.accept
import dev.fritz2.core.alt
import dev.fritz2.core.disabled
import dev.fritz2.core.files
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.type
import koin.koinCtx
import koin.withKoin
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import model.ImageFileData
import model.L
import model.getFirstGroupIdOrNull
import org.w3c.files.get
import overlays.BusyStore
import overlays.withBusyApplyContentChange
import svgmarker.SvgIconOptions
import svgmarker.renderSvgIcon
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.toggleClassOnElement
import twcomponents.twAddContentButton
import twcomponents.twColOf
import twcomponents.twContentBoxOf
import twcomponents.twFeatureFlagDiv
import twcomponents.twIconMedium
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twMediumIconButtonRed
import twcomponents.twRowOfJustifyBetween
import twcomponents.twSecondaryButtonSmall
import utils.insertObjectInCachesAndMap
import utils.isMobileOrTabletBrowser
import utils.roundTo
import workspacetools.usermanagement.confirm


fun RenderContext.iconEditor(
    objectId: String,
    content: Content.Icon? = null,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val fileStoreJS: FileStoreJS by koinCtx.inject()
    val fileStoreFritz2: FileStoreFritz2 by koinCtx.inject()
    val fileHandlerStore: FileHandlerStore by koinCtx.inject()
    val imageFileDataStore by koinCtx.inject<ImageFileDataStore>()

    fileHandlerStore.initialize()
    imageFileDataStore.reset(Unit)

    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonHighlight(icon = FormationUIIcons.Save.icon) {
                if (content == null) {
                    combine(fileStoreJS.data, fileStoreFritz2.data) { f1, f2 ->
                        f1 == null && f2 == null
                    }.render { noFile ->
                        disabled(noFile)
                    }
                }

                clicks handledBy {
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                    attachOrReplaceIcon(objectId, content?.id, imageFileDataStore.current)
                    imageFileDataStore.reset(Unit)
                }
            }
            twMediumIconButtonNeutral(icon = FormationUIIcons.Close.icon) {
                clicks handledBy {
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                    imageFileDataStore.reset(Unit)
                }
            }
        }

        // IF IN IMAGE EDIT MODE, DISPLAY ORIGINAL IMAGE, WHICH WILL BE REPLACED BY NEW IMAGE
        if (content != null) {
            img("w-max max-w-full object-scale-down rounded-xl") {
                src(content.href)
                alt("icon ${content.width}x${content.height}")
            }
        }

        // PREVIEW THE TAKEN OR UPLOADED PICTURE
        imageFileDataStore.data.debounce(500).render { fileData ->
            fileStoreFritz2.data.combine(fileStoreJS.data) { f, j -> Pair(f, j) }
                .render { (fileFritz2, fileJS) ->
                    if (fileFritz2 != null || fileJS != null) {
                        val fileName = fileFritz2?.name ?: fileJS?.name ?: ""
                        val fileSize = fileFritz2?.size ?: fileJS?.size ?: 0
                        val fileType = fileFritz2?.type ?: fileJS?.type ?: ""

                        val size = fileSize.toDouble() / 1024 / 1024
                        if (fileData.href != null) {
                            if (content != null) {
                                p { +"Replace with:" } // TODO translate
                            }
                            img("w-max max-w-full mt-2 object-scale-down rounded-xl") {
                                src(fileData.href)
                            }
                            div("flex flex-row flex-wrap w-full items-center justify-start") {
                                imagePrevData(value = fileName)
                                imagePrevData(value = fileType.removePrefix("image/"))
                                imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                                imagePrevData(
                                    value = if (size < 1.0) {
                                        "${(size * 1024).roundTo(0)} KB"
                                    } else {
                                        "${size.roundTo(2)} MB"
                                    },
                                )
                            }
                        }
                    } else if (fileData.href != null) {
                        val fileSize = (fileData.prevBytes?.size?.toDouble() ?: 0.0) / 1024 / 1024
                        img("max-w-96 rounded-xl") {
                            src(fileData.href)
                        }
                        div("flex flex-row flex-wrap max-w-full md:max-w-96 items-center justify-start") {
                            if (fileData.prevName.isNotBlank()) {
                                imagePrevData(value = fileData.prevName)
                            }
                            imagePrevData(value = fileData.mimeType?.removePrefix("image/") ?: "")
                            imagePrevData(value = "${fileData.width} x ${fileData.height} px")
                            if (fileSize > 0.0) {
                                imagePrevData(
                                    value = if (fileSize < 1.0) {
                                        "${(fileSize * 1024).roundTo(0)} KB"
                                    } else {
                                        "${fileSize.roundTo(2)} MB"
                                    },
                                )
                            }
                        }
                    }
                }
        }

        twRowOfJustifyBetween {
            // TAKE PHOTO BUTTON FOR MOBILE BROWSER ONLY - Starts devices camera directly (input attr > capture)
            if (isMobileOrTabletBrowser()) {
                twSecondaryButtonSmall(
                    text = TL.Attachments.TAKE_A_PHOTO,
                    icon = FormationIcons.CameraPlus.icon,
                ) {
                    className("relative")
                    input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                        type("file")
                        accept("image/png, image/gif, image/jpeg")
                        attr("capture", "environment")
                        changes.files().map { files ->
                            files?.get(0)?.let { file ->
                                console.log("image picked:", file)
                                file
                            }
                        } handledBy fileStoreJS.update
                    }
                }
            } else {
                // TAKE PHOTO BUTTON FOR DESKTOP  - Opens camera modal
                val toggleCamera = storeOf(false, Job())
                cameraModal(toggleCamera) { close, opened, video, canvas ->
                    photoCamera(close, opened, video, canvas)
                }

                twSecondaryButtonSmall(
                    text = TL.Attachments.TAKE_A_PHOTO,
                    icon = FormationIcons.CameraPlus.icon,
                ) {
                    clicks handledBy {
                        toggleCamera.update(true)
                    }
                }
            }

            // UPLOAD IMAGE BUTTON
            twSecondaryButtonSmall(
                text = TL.Attachments.UPLOAD_IMAGE,
                icon = FormationIcons.Upload.icon,
            ) {
                className("relative overflow-hidden")
                input("absolute top-0 bottom-0 left-0 right-0 pl-[100%] opacity-0 m-0 cursor-pointer") {
                    type("file")
                    accept("image/png, image/gif, image/jpeg")
                    changes.files().map { files ->
                        files?.get(0)?.let { file ->
                            console.log("image picked:", file)
                            file
                        }
                    } handledBy fileStoreJS.update
                }
            }
        }
    }
}

suspend fun attachOrReplaceIcon(objectId: String, replaceImgId: String? = null, imageData: ImageFileData) {
    withKoin {
        val apiUserStore: ApiUserStore by koinCtx.inject()
        val client = get<FormationClient>()
        val busyStore = get<BusyStore>()
        val activeObjectStore = get<ActiveObjectStore>()
        val groupId = apiUserStore.current.getFirstGroupIdOrNull()
        val attachments = activeObjectStore.map(GeoObjectDetails.L.attachments)
        groupId?.let { gId ->
            imageData.prevBytes?.let { bytes ->
                busyStore.withBusy(
                    block = suspend {
                        client.attachIconToGeoObject(
                            ownerId = gId,
                            objectId = objectId,
                            imageBytes = bytes,
                            attachmentId = replaceImgId,
                        )
                    },
                    processResult = { geoObject ->
                        geoObject.attachments?.let { attachmentList ->
                            attachments.update(attachmentList)
                        }
                        insertObjectInCachesAndMap(geoObject)
                    },
                    processError = { error ->
                        console.log(error)
                    },
                )
            }
        }
    }
}

fun RenderContext.editableImageIcon() {
    withKoin {
        val activeObjectStore = get<ActiveObjectStore>()
        val objectId = activeObjectStore.current.id
        val isEditable = activeObjectStore.current.editable
        val objAttachmentsSub = activeObjectStore.map(GeoObjectDetails.L.attachments)

        twFeatureFlagDiv(flag = Features.AllowBitmapMarkerIcons) {
            objAttachmentsSub.data.render { attachments ->
                val icon = attachments.orEmpty().firstOrNull {
                    it is Content.Icon
                }?.let {
                    it as Content.Icon
                }

                val editingStore = storeOf(false)
                editingStore.data.render { editing ->
                    if (editing) {
                        div("p-2") {
                            iconEditor(
                                objectId = objectId,
                                content = icon,
                                editorCloseHandler = SimpleHandler { data, _ -> data handledBy { editingStore.update(false) } },
                                header = AddContentTexts.Icon,
                            )
                        }
                    } else {
                        if (icon == null) {
                            if (isEditable) {
                                div("p-2") {
                                    twAddContentButton {
                                        twIconMedium(icon = FormationUIIcons.Add.icon)
                                        +"Add an image icon"

                                        clicks handledBy {
                                            editingStore.update(true)
                                        }
                                    }
                                }
                            }
                        } else {
                            twContentBoxOf {
                                className("px-2 pb-2")
                                val editButtonId = Random.nextULong().toString()
                                // Edit/Delete buttons
                                if (isEditable) {
                                    div("static flex flex-row h-0 w-full items-center justify-end") {
                                        div(
                                            "invisible flex flex-row mt-4 py-2 px-4 gap-4 place-items-center bg-gray-200 rounded-xl shadow",
                                            id = editButtonId,
                                        ) {
                                            twMediumIconButtonNeutral(FormationIcons.Edit.icon) {
                                                clicks handledBy {
                                                    editingStore.update(true)
                                                }
                                            }
                                            twMediumIconButtonRed(FormationIcons.DeleteAlt.icon) {
                                                clicks handledBy confirm(
                                                    flowOf("Are you sure you want to delete this image icon?"), // TODO translate
                                                    okHandlers = listOf(
                                                        SimpleHandler { data, _ ->
                                                            data handledBy {
                                                                withBusyApplyContentChange(objectId, DeleteContent(icon.id))
                                                            }
                                                        },
                                                    ),
                                                )
                                            }
                                        }
                                    }
                                }
                                renderSvgIcon(
                                    svgIconOptions = SvgIconOptions(
                                        bitmapPicture = icon.href,
                                    ),
                                )
                                toggleClassOnElement(
                                    className = "invisible",
                                    elementId = editButtonId,
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}
