package map.views

import apiclient.geoobjects.AttachmentType
import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.TaskTemplate
import apiclient.tags.getUniqueTag
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.SessionIdStore
import auth.WorkspaceOptionsStore
import auth.WorkspacesStore
import auth.emptyUser
import data.objects.ActiveObjectStore
import data.objects.views.ColorSelectionStore
import data.objects.views.IconSelectionStore
import data.objects.views.ShapeSelectionStore
import data.objects.views.attachments.TaskTemplateStore
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.routing.MapRouter
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import login.HostConfigStore
import login.WorkspaceInputStore
import mainmenu.AppStateStore
import map.Cards
import model.L
import model.title
import notifications.GlobalNotificationResultsStore
import search.searchlayer.MapSearchClientsStore
import search.separationLine
import svgmarker.SvgIconOptions
import svgmarker.areaMarkerSvgIconOptions
import svgmarker.areaSize
import svgmarker.connectableShapeSvgIconOptions
import svgmarker.defaultMarkerSize
import svgmarker.eventSize
import svgmarker.eventSvgIconOptions
import svgmarker.geoFenceMarkerSvgIconOptions
import svgmarker.geoFenceSize
import svgmarker.makeSvgMarker
import svgmarker.objectMarkerSvgIconOptions
import svgmarker.pointSize
import svgmarker.pointSvgIconOptions
import svgmarker.taskSize
import svgmarker.taskSvgIconOptions
import svgmarker.zoneSize
import theme.FormationColors
import utils.getColor
import utils.getIcon
import utils.makeRGBA
import websocket.WebsocketService
import websocket.WebsocketState

fun RenderContext.pointerRoute() {
    val router: MapRouter by koinCtx.inject()
    val taskTemplateStore: TaskTemplateStore by koinCtx.inject()
    val keys = listOf("add", "edit", "editPosition")

    div {
        router.select("change").render { (keyValue, _) ->
            if (keyValue == AttachmentType.ScanToCreateTask?.name) {
                pointerOverlay(
                    ObjectType.Task,
                    optionalTitleFlow = taskTemplateStore.map(TaskTemplate.title()).data.map { it ?: "" },
                )
            } else {
                keys.forEach { key ->
                    router.select(key).render { (keyValue, _) ->
                        when (keyValue) {
                            Cards.POI.objType?.name -> pointerOverlay(ObjectType.POI)
                            Cards.Task.objType?.name -> pointerOverlay(ObjectType.Task)
                            Cards.Event.objType?.name -> pointerOverlay(ObjectType.Event)
                            Cards.TrackedObject.objType?.name -> pointerOverlay(ObjectType.ObjectMarker)
                            Cards.Area.objType?.name -> pointerOverlay(ObjectType.Area)
                            Cards.Zone.objType?.name -> pointerOverlay(ObjectType.Zone)
                            Cards.GeoFence.objType?.name -> pointerOverlay(ObjectType.GeoFence)
                            Cards.GeneralMarker.objType?.name -> pointerOverlay(ObjectType.GeneralMarker)
                            else -> {}
                        }
                    }
                }
            }
        }
    }
}

fun RenderContext.pointerOverlay(type: ObjectType, optionalTitleFlow: Flow<String>? = null) {

    val colorSelectionStore: ColorSelectionStore by koinCtx.inject()
    val iconSelectionStore: IconSelectionStore by koinCtx.inject()
    val shapeSelectionStore: ShapeSelectionStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val currentUserId = apiUserStore.current.userId
    val globalNotificationResultsStore: GlobalNotificationResultsStore by koinCtx.inject()

    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val taskState = activeObjectStore.map(GeoObjectDetails.L.taskState)
    val meetingstate = activeObjectStore.map(GeoObjectDetails.L.attendees).data.map { attendees ->
        attendees?.firstOrNull { it.userId == currentUserId }?.meetingInvitationStatus
    }
    val notificationState = globalNotificationResultsStore.data.map { globalNotificationResultsStore.isOrHasUnreadNotification(activeObjectStore.current) }

    val tags = activeObjectStore.map(GeoObjectDetails.L.tags)

    val archived = tags.data.map { it.getUniqueTag(ObjectTags.Archived).toBoolean() }
    val flagged = tags.data.map { it.getUniqueTag(ObjectTags.Flagged).toBoolean() }

    div(
        {
            position { absolute { top { "50%" }; left { "50%" } } }
            css("transform: translate(-50%, -50%);")
            zIndex { "1020" }
            css(
                "animation: hover 0.8s infinite ease-in;\n" +
                    "@keyframes hover {\n" +
                    "    0% {\n" +
                    "        margin-top: 0px;\n" +
                    "    }\n" +
                    "\n" +
                    "    50% {\n" +
                    "       margin-top: -8px;\n" +
                    "    }\n" +
                    "}",
            )
        },
    ) {
        when (type) {
            ObjectType.POI, ObjectType.ObjectMarker, ObjectType.Zone -> {
                combine(
                    colorSelectionStore.data,
                    iconSelectionStore.data,
                    shapeSelectionStore.data,
                ) { color, icon, shape ->
                    Triple(color, icon, shape)
                }.render { (color, icon, shape) ->
                    combine(notificationState, archived, flagged) { n, a, f -> Triple(n, a, f) }.render { (notification, archived, flagged) ->
                        when (type) {
                            ObjectType.POI -> {
                                makeSvgPointer(
                                    ObjectType.POI,
                                    pointSvgIconOptions(
                                        size = pointSize,
                                        color = color,
                                        icon = icon,
                                        shape = shape,
                                        hasNotification = notification,
                                        archived = archived,
                                        flagged = flagged,
                                    ),
                                    titleFlow = optionalTitleFlow ?: title.data,
                                )
                            }

                            ObjectType.ObjectMarker -> {
                                makeSvgPointer(
                                    ObjectType.ObjectMarker,
                                    objectMarkerSvgIconOptions(
                                        size = defaultMarkerSize,
                                        color = color,
                                        icon = icon,
                                        shape = shape,
                                        hasNotification = notification,
                                        archived = archived,
                                        flagged = flagged,
                                    ),
                                    titleFlow = optionalTitleFlow ?: title.data,
                                )
                            }

                            ObjectType.Zone -> {
                                makeSvgPointer(
                                    ObjectType.Zone,
                                    objectMarkerSvgIconOptions(
                                        size = zoneSize,
                                        color = color,
                                        icon = icon,
                                        shape = shape,
                                        hasNotification = notification,
                                        archived = archived,
                                        flagged = flagged,
                                    ),
                                    titleFlow = optionalTitleFlow ?: title.data,
                                )
                            }

                            else -> {}
                        }
                    }
                }
            }

            ObjectType.Task -> {
                taskState.data.combine(notificationState) { t, n -> Pair(t, n) }.render { (taskState, notification) ->
                    combine(archived, flagged) { a, f -> Pair(a, f) }.render { (archived, flagged) ->
                        makeSvgPointer(
                            ObjectType.Task,
                            taskSvgIconOptions(
                                size = taskSize,
                                hasNotification = notification,
                                archived = archived,
                                flagged = flagged,
                                stateColor = taskState?.getColor(),
                                stateIcon = taskState?.getIcon(),
                            ),
                            titleFlow = optionalTitleFlow ?: title.data,
                        )
                    }
                }
            }

            ObjectType.Event -> {
                meetingstate.combine(notificationState) { m, n -> Pair(m, n) }.render { (meetingState, notification) ->
                    combine(archived, flagged) { a, f -> Pair(a, f) }.render { (archived, flagged) ->
                        makeSvgPointer(
                            ObjectType.Event,
                            eventSvgIconOptions(
                                size = eventSize,
                                hasNotification = notification,
                                archived = archived,
                                flagged = flagged,
                                stateColor = meetingState?.getColor(),
                                stateIcon = meetingState?.getIcon(),
                            ),
                            titleFlow = optionalTitleFlow ?: title.data,
                        )
                    }
                }
            }

            ObjectType.Area -> {
                combine(archived, flagged) { a, f -> Pair(a, f) }.render { (archived, flagged) ->
                    makeSvgPointer(
                        ObjectType.Area,
                        areaMarkerSvgIconOptions(
                            size = areaSize,
                            archived = archived,
                            flagged = flagged,
                        ),
                        titleFlow = optionalTitleFlow ?: title.data,
                    )
                }
            }

            ObjectType.GeoFence -> {
                combine(archived, flagged) { a, f -> Pair(a, f) }.render { (archived, flagged) ->
                    makeSvgPointer(
                        ObjectType.GeoFence,
                        geoFenceMarkerSvgIconOptions(
                            size = geoFenceSize,
                            archived = archived,
                            flagged = flagged,
                        ),
                        titleFlow = optionalTitleFlow ?: title.data,
                    )
                }
            }

            ObjectType.GeneralMarker -> {
                combine(archived, flagged) { a, f -> Pair(a, f) }.render { (archived, flagged) ->
                    makeSvgPointer(
                        ObjectType.GeneralMarker,
                        connectableShapeSvgIconOptions(
                            size = defaultMarkerSize,
                            color = activeObjectStore.current.color,
                            icon = activeObjectStore.current.iconCategory,
                            shape = activeObjectStore.current.shape,
                            archived = archived,
                            flagged = flagged,
                        ),
                        titleFlow = optionalTitleFlow ?: title.data,
                    )
                }
            }

            else -> div { }
        }
    }
}

fun RenderContext.makeSvgPointer(objectType: ObjectType, svgIconOptions: SvgIconOptions, titleFlow: Flow<String>) {
    titleFlow.render { title ->
        makeSvgMarker(
            objectId = "pointer",
            objectType = objectType,
            title = title,
            svgIconOptions = svgIconOptions,
            showTitle = true,
        )
    }
}

/**
 * The crossHair function is used for debugging - do not delete
 */

fun RenderContext.crossHair() {
    val router: MapRouter by koinCtx.inject()

    router.select("card").render { (cardKeyValue, _) ->
        when {
            (cardKeyValue == Cards.Select.name || cardKeyValue == Cards.Tools.name)
                && router.current["add"].isNullOrBlank() -> {
                div(
                    {
                        position { absolute { top { "50%" }; left { "50%" } } }
                        zIndex { "1020" }
                        width { "100px" }
                        height { "2px" }
                        background { color { FormationColors.BlueDeep.color } }
                        css("transform: translate(-50%, 0%);")
//        css("box-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;")
                    },
                    id = "cross-hair",
                ) { }
                div(
                    {
                        position { absolute { top { "50%" }; left { "50%" } } }
                        zIndex { "1020" }
                        width { "2px" }
                        height { "100px" }
                        background { color { FormationColors.BlueDeep.color } }
                        css("transform: translate(0%, -50%);")
//        css("box-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;")
                    },
                ) { }
            }

            else -> {}
        }
    }
}

fun RenderContext.debugOverlay() {
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val appStateStore: AppStateStore by koinCtx.inject()
    val sessionIdStore: SessionIdStore by koinCtx.inject()
    val workspacesStore: WorkspacesStore by koinCtx.inject()
    val currentWorkSpaceStore: CurrentWorkspaceStore by koinCtx.inject()
    val workSpaceInputStore: WorkspaceInputStore by koinCtx.inject()
    val workspaceOptionsStore: WorkspaceOptionsStore by koinCtx.inject()
    val mapSearchClientsStore: MapSearchClientsStore by koinCtx.inject()
    val websocketService: WebsocketService by koinCtx.inject()
    val hostConfigStore: HostConfigStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    div(
        {
            position { absolute { top { "5%" }; right { "5%" } } }
            zIndex { "9020" }
            background { color { makeRGBA(primary.main, 0.8) } }
            color { secondary.main }
            maxWidth { "500px" }
            radius { huge }
            maxHeight { "90vh" }
            overflowY { auto }
            css("pointer-events: none;")
        },
        id = "cross-hair",
    ) {
        stackUp(
            {
                padding { small }
                width { full }
                fontSize { small }
            },
        ) {
            spacing { none }
            items {
                separationLine(flowOf("Debug info"), verticalTextMargins = { tiny })

                appStateStore.data.render { appState ->
                    span(
                        {
                            width { full }
                            color { FormationColors.MarkerYou.color }
                        },
                    ) {
                        +"App phase: ${appState.appPhase.name} (${appState.appPhase.ordinal})"
                    }
                }
                separationLine()
                workSpaceInputStore.data.render { workspace ->
                    span({ width { full } }) {
                        +"WorkspaceInputStore: $workspace"
                    }
                }
                separationLine()
                hostConfigStore.data.render { hostConfig ->
                    span({ width { full } }) {
                        +"Hostname: ${hostConfig.host}"
                    }
                }
                separationLine()
                websocketService.data.render { websocketState ->
                    boolText("Websocket: ${websocketState.name}", websocketState == WebsocketState.RUNNING, showBoolean = false)
                }
                separationLine()
                sessionIdStore.data.render { sessionId ->
                    span({ width { full } }) { +"Session id: ${sessionId?.id}" }
                }
                separationLine()
                apiUserStore.anonymousUserStore.data.render { anonymousApiUser ->
                    boolText("Anonymous Login", anonymousApiUser != null)
                }
                separationLine()
                apiUserStore.data.render { user ->
                    span({ width { full } }) { +"User: ${if (user != emptyUser) "${user.firstName} ${user.lastName} (${user.userId})" else "no user"}" }
                    boolText("is valid", user.valid)
                    boolText("is anonymous", user.isAnonymous)
                    span({ width { full } }) { +"ApiUser: ${if (user.apiUser != null) "${user.apiUser.name} (${user.apiUser.userId})" else "no ApiUser"}" }
                    span({ width { full } }) { +"ApiUser workspace: ${user.apiUser?.workspaceName}" }
//                    div({
//                        margins { vertical { tiny } }
//                    }) {
//                        genericSmallIconButton(
//                            title = flowOf("fetch workspaces"),
//                            value = user,
//                            clickHandlers = listOf(workspacesStore.fetchWorkspaces)
//                        )
//                    }
                }
                separationLine()
                appStateStore.data.render { loginState ->
                    boolText("Cookies accepted", loginState.acceptCookies == true)
                    boolText("Disclaimer read", loginState.acceptDisclaimer == true)
                    boolText("Agreed terms", loginState.agreedTerms)
                    boolText("Activated", loginState.isActivated)
                }
                separationLine()
                workspacesStore.data.render { groups ->
                    span({ width { full } }) {
                        +"WorkspacesStore: ${groups.map { it.name }}"
                    }
                }
                separationLine()
                currentWorkSpaceStore.data.render { workspace ->
                    span({ width { full } }) {
                        +"CurrentWorkspaceStore: ${workspace?.name}"
                    }
                }
                separationLine()
                workspaceOptionsStore.data.render { wsOptions ->
                    span({ width { full } }) { +"WorkspaceOptions fetched for: ${wsOptions?.name}" }
                    boolText("Anonymous Access", wsOptions?.anonymousAccessAllowed ?: false)
                    boolText("Open for SignUps", wsOptions?.signupsAllowed ?: false)
                }
                separationLine()
                mapSearchClientsStore.data.render { layerClients ->
                    boolText("Got search layer clients", layerClients.isNotEmpty())
                }
                separationLine()
                featureFlagStore.data.render { flags ->
                    flags.forEach { flag ->
                        boolText(flag.key.name, flag.value)
                    }
                }
            }
        }
    }
}

fun RenderContext.boolText(text: String, boolean: Boolean, showBoolean: Boolean = true) {
    span(
        {
            width { full }
            color {
                if (boolean) FormationColors.GreenActive.color else FormationColors.RedError.color
            }
        },
    ) { +"$text${if (showBoolean) " : $boolean" else ""}" }
}
