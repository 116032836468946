package data.users.views

import analytics.AnalyticsCategory
import analytics.AnalyticsService
import apiclient.FormationClient
import apiclient.auth.Token
import apiclient.geoobjects.SearchQueryContext
import apiclient.geoobjects.keywordsForQueryContext
import apiclient.geoobjects.newContext
import apiclient.users.requestDeleteAccount
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.CurrentWorkspaceStore
import auth.FeatureFlagStore
import auth.Features
import camera.photo.cardBrowserPhotoCamera
import com.tryformation.localization.Translatable
import data.objects.views.attachments.ImageFileDataStore
import data.users.profile.MyProfileStore
import data.users.profile.UpdatePasswordStore
import data.users.profile.VerificationTokenStore
import dev.fritz2.components.clickButton
import dev.fritz2.components.compat.a
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.img
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.lineUp
import dev.fritz2.components.modal
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.Store
import dev.fritz2.core.autofocus
import dev.fritz2.core.disabled
import dev.fritz2.core.href
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import dev.fritz2.core.title
import dev.fritz2.core.type
import dev.fritz2.core.value
import dev.fritz2.core.values
import dev.fritz2.routing.MapRouter
import dev.fritz2.styling.theme.IconDefinition
import dev.fritz2.styling.theme.Icons
import io.ktor.util.encodeBase64
import koin.koinCtx
import kotlin.math.min
import kotlin.text.isLowerCase
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.plus
import kotlinx.datetime.Clock
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.KeywordTag
import model.MyProfile
import model.StatusResult
import model.SuggestedTagsContext
import model.ValidatePassword
import model.emails
import model.firstName
import model.firstPass
import model.isValid
import model.jobTitle
import model.keywords
import model.lastName
import model.name
import model.oldPass
import model.password
import model.phone
import model.profilePhoto
import model.secondPass
import org.w3c.dom.get
import overlays.BusyStore
import overlays.ConfirmationOverlayStore
import qrcode.userverification.QrCodeLinkStore
import routing.DestinationRoute
import routing.MainController
import signup.passwordStrengthIndicator
import styling.genericButtonStyleParams
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twMediumIconButton
import twcomponents.twTagButton
import utils.focusInputObserver
import utils.isExpired
import utils.makeRGBA
import utils.merge
import utils.verificationLink
import webcomponents.BigButtonOption
import webcomponents.KeywordTagActionType
import webcomponents.KeywordTagType
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.circleIconButton
import webcomponents.contentScrollBox
import webcomponents.fullCard
import webcomponents.fullPageConfirmation
import webcomponents.fullPageConfirmationContainer
import webcomponents.fullWidthCenterContainer
import webcomponents.genericBigButtonSwitch
import webcomponents.genericInput
import webcomponents.genericSmallIconButton
import webcomponents.inputIconButton
import webcomponents.inputIconToggleButton
import webcomponents.inputLabelWrapper
import webcomponents.keywordTagList
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

enum class MyProfilePages : DestinationRoute {
    EditMyProfile,
    ChangePasswordEnter,
    ChangePasswordNew,
    ChangePasswordConfirm,
    ManageProfilePhoto,
    TakeProfilePhoto
    ;

    override val routeKey = "myProfile"
    override val route = mapOf(routeKey to name)
}


fun RenderContext.pageMyProfile() {

    val router: MapRouter by koinCtx.inject()

    router.select("myProfile").render { (myProfile, _) ->
        when (parseEnumValue<MyProfilePages>(myProfile)) {
            MyProfilePages.EditMyProfile -> fullWidthCenterContainer { editMyProfile() }
            MyProfilePages.ChangePasswordEnter -> fullWidthCenterContainer { changePasswordFlow1() }
            MyProfilePages.ChangePasswordNew -> fullWidthCenterContainer { changePasswordFlow2() }
            MyProfilePages.ChangePasswordConfirm -> fullWidthCenterContainer { changePasswordFlow3() }
            MyProfilePages.ManageProfilePhoto -> fullWidthCenterContainer { cardManageProfilePhoto() }
            MyProfilePages.TakeProfilePhoto -> fullWidthCenterContainer { cardBrowserPhotoCamera() }
            else -> myProfile()
        }
    }
}

enum class ProfileContent : Translatable {
    Details {
        override val icon: Icons.() -> IconDefinition = { email }
    },
    VCard {
        override val icon: Icons.() -> IconDefinition = { creditCard }
    },
    Verification {
        override val icon: Icons.() -> IconDefinition = { FormationIcons.QRCode.icon }
    },
    ;

    abstract val icon: Icons.() -> IconDefinition
    override val prefix = "profilecontent"

}

fun RenderContext.myProfile() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val verificationTokenStore: VerificationTokenStore by koinCtx.inject()

    val firstName = myProfileStore.map(MyProfile.firstName())
    val lastName = myProfileStore.map(MyProfile.lastName())
    val jobTitle = myProfileStore.map(MyProfile.jobTitle())
    val emails = myProfileStore.map(MyProfile.emails())
    val phoneNumbers = myProfileStore.map(MyProfile.phone())
    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())
    val keywords = myProfileStore.map(MyProfile.keywords())

    val verificationQrCodeStore = QrCodeLinkStore(verificationTokenStore) { it?.verificationLink }

    val profileswitchStore = storeOf(ProfileContent.Details)

    myProfileStore.fetchMyProfile()
    fullCard {
        baseLayout(
            expandable = false,
            header = null,
            content = {
                contentScrollBox {
                    profilePhoto.data.render { image ->
                        // Profile Photo
                        flexBox(
                            {
                                alignItems { center }
                                justifyContent { center }
                                margins {
                                    vertical { normal }
                                }
                            },
                        ) {
                            flexBox(
                                {
                                    flex {
                                        grow { "0" }
                                        shrink { "0" }
                                        basis { "200px" }
                                    }
                                    width { "200px" }
                                    height { "200px" }
                                    radius { full }
                                    justifyContent { center }
                                    alignItems { center }
                                    textAlign { center }
                                    if (image != null) {
                                        background {
                                            image { image.href }
                                            size { cover }
                                            position { center }
                                        }
                                    } else {
                                        border {
                                            width(formationStyles.borderWidth)
                                            color { FormationColors.GrayDisabled.color }
                                        }
                                        color { FormationColors.GrayDisabled.color }
                                    }
                                },
                            ) {
                                if (image == null) {
                                    icon(
                                        {
                                            size { "100px" }
                                        },
                                    ) { fromTheme { FormationIcons.UserAlt.icon } }
                                }
                            }
                        }
                    }
                    stackUp(
                        {
                            width { full }
                            alignItems { center }
                            justifyContent { center }
                            margins {
                                bottom { small }
                            }
                        },
                    ) {
                        spacing { tiny }
                        items {
                            cardTitle(firstName.data.merge(lastName.data))
                            cardSubtitle(jobTitle.data)
                        }
                    }
                    genericBigButtonSwitch(
                        store = profileswitchStore,
                        options = ProfileContent.entries.map {
                            BigButtonOption(
                                title = translation[it],
                                icon = it.icon,
                                value = it,
                                selectHandler = profileswitchStore.update,
                            )
                        },
                    )
                    profileswitchStore.data.render { content ->
                        when (content) {
                            ProfileContent.Details -> {
                                combine(emails.data, phoneNumbers.data) { e, p ->
                                    Pair(e, p)
                                }.render { (mails, numbers) ->
                                    stackUp(
                                        {
                                            width { full }
                                            alignItems { start }
                                            justifyContent { center }
                                            margins { top { large } }
                                        },
                                    ) {
                                        spacing { small }
                                        items {
                                            if (mails.isNotBlank()) {
                                                val mailList = mails.split(",")
                                                mailList.forEach { address ->
                                                    iconInfoFieldButton(
                                                        icon = { FormationIcons.Mail.icon },
                                                        title = flowOf(address),
                                                        href = "mailto:$address",
                                                        value = Unit,
                                                    )
                                                }
                                            }
                                            if (numbers.isNotBlank()) {
                                                val phoneList = numbers.split(",")
                                                phoneList.forEach { number ->
                                                    iconInfoFieldButton(
                                                        icon = { FormationIcons.Phone.icon },
                                                        title = flowOf(number),
                                                        href = "tel:$number",
                                                        value = Unit,
                                                    )
                                                }
                                            }
                                            userKeywords(keywords)
                                        }
                                    }
                                }
                            }

                            ProfileContent.VCard -> {
                                myProfileStore.data.render { profile ->
                                    combine(
                                        myProfileStore.myVcardStore.data.filterNotNull(),
                                        myProfileStore.myVcardQrCodeStore.data.filterNotNull(),
                                    ) { vcard, svg ->
                                        Pair(vcard, svg)
                                    }.render { (vcard, svgContent) ->
                                        console.log(vcard)
                                        stackUp(
                                            {
                                                width { full }
                                                alignItems { center }
                                                justifyContent { center }
                                            },
                                        ) {
                                            spacing { small }
                                            items {
                                                img(
                                                    {
                                                        css(
                                                            """
                                                        animation: verifyqrcode 1 2s ease;
                                                        @keyframes verifyqrcode {
                                                            0% { opacity: 0; }
                                                            100% { opacity: 1; }
                                                        }
                                                        """.trimIndent(),
                                                        )
                                                        margins {
                                                            horizontal { auto }
                                                        }
                                                        width { full }
                                                        maxWidth { "300px" }
                                                    },
                                                ) {
                                                    src(svgContent)
                                                }
                                                genericSmallIconButton(
                                                    icon = { download },
                                                    title = translation[TL.General.DOWNLOAD],
                                                    hrefOrValue = "data:text/vcard;charset=utf-8;base64,${vcard.encodeBase64()}",
                                                    isLink = true,
                                                    downloadValue = "${profile.name}.vcard",
                                                )
                                            }
                                        }
                                    }
                                }
                            }

                            ProfileContent.Verification -> {
                                verificationQrCodeStore.data.render { qrCode ->
                                    stackUp(
                                        {
                                            width { full }
                                            alignItems { start }
                                            justifyContent { center }
                                        },
                                    ) {
                                        spacing { small }
                                        items {
                                            if (!qrCode.isNullOrBlank()) {
                                                img(
                                                    {
                                                        css(
                                                            """
                                                        animation: verifyqrcode 1 2s ease;
                                                        @keyframes verifyqrcode {
                                                            0% { opacity: 0; }
                                                            100% { opacity: 1; }
                                                        }
                                                        """.trimIndent(),
                                                        )
                                                        margins {
                                                            horizontal { auto }
                                                        }
                                                        width { full }
                                                        maxWidth { "300px" }
                                                    },
                                                ) {
                                                    src(qrCode)
                                                }
                                                verificationTokenStore.data.render { token ->
                                                    flexBox(
                                                        {
                                                            width { full }
                                                            alignItems { center }
                                                            justifyContent { center }
                                                        },
                                                    ) {
                                                        verificationClock(token)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            footer = {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.BACK],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(routerStore.back),
                    primaryTitle = translation[TL.UserProfile.EDIT_PROFILE],
                    primaryState = null,
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryClickHandlers = null,
                    primaryRoutingMap = Pages.MyProfile.route + MyProfilePages.EditMyProfile.route,
                )
            },
        )
    }
}

fun RenderContext.userKeywords(keywords: Store<List<String>>) {
    val translation: Translation by koinCtx.inject()
    val defaultTheme = koinCtx.get<FormationDefault>()

    val editMode = storeOf(false)

    editMode.data.render { editing ->
        div("flex flex-row w-full justify-between place-items-center mt-5 mb-2.5") {
            cardTitle(translation[TL.KeywordTags.CARD_TITLE]) { FormationIcons.Tag.icon }

            if (editing) {
                twMediumIconButton(defaultTheme.icons.close) {
                    clicks handledBy {
                        editMode.update(!editing)
                    }
                }
            } else {
                twMediumIconButton(FormationIcons.Edit.icon) {
                    clicks handledBy {
                        editMode.update(!editing)
                    }
                }
            }
        }
        if (editing) {
            userKeywordEditor()
        } else {
            keywords.data.renderIf({ it.isNotEmpty() }) {
                keywordTagList(
                    keywords = keywords.data.map { keywordList -> keywordList.map { KeywordTag(it, actionType = KeywordTagActionType.Default) } },
                    keywordTagType = KeywordTagType.SearchTag,
                    searchable = true,
                )
            }
        }
    }
}

private fun RenderContext.userKeywordEditor() {
    val translation: Translation by koinCtx.inject()

    val myProfileStore = koinCtx.get<MyProfileStore>()
    val defaultTheme = koinCtx.get<FormationDefault>()
    val userKeywords = myProfileStore.map(MyProfile.keywords())

    // use anonymous store here because our genericInput cannot deal with the addKeyword handler directly
    // no need to make this a class, koin inject it, or add more clutter
    val tagPrefix = object : RootStore<String>("", job) {
        val addKeyword = handle {
            if (!myProfileStore.current.keywords.contains(current)) {
                myProfileStore.addKeyword(current)
            } else {
                console.warn("duplicate keyword $current")
            }
            ""
        }
    }

    userKeywords.data.render { keywords ->
        div("flex flex-col gap-3") {
            div("flex flex-row flex-wrap gap-3") {
                keywords.forEach { kw ->
                    twTagButton(text = kw, icon = defaultTheme.icons.close, inverted = true) {
                        clicks.map { kw } handledBy myProfileStore.removeKeyword
                    }
                }
            }
        }

        inputLabelWrapper(
            title = translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER],
            visibilityFlow = tagPrefix.data.map { it.isNotBlank() },
        ) {
            genericInput(
                value = tagPrefix.data,
                enterKeyHandlers = listOf(tagPrefix.addKeyword),
                rightContentBox = {
                    inputIconButton(
                        iconFlow = flowOf { close },
                    ) {
                        className(tagPrefix.data.map { if (it.isBlank()) "invisible" else "visible" })
                        clicks.map { "" } handledBy tagPrefix.update
                    }
                },
            ) {
                id("inputKeywords")
                placeholder(translation[TL.KeywordTags.TAG_INPUT_PLACEHOLDER])
                autofocus(true)
                attr("tabindex", "0")
                inputs.values() handledBy tagPrefix.update
            }
        }

        div("flex flex-row w-full items-center justify-end mt-2") {
            genericSmallIconButton(
                title = translation[TL.KeywordTags.CREATE_TAG],
                icon = { FormationIcons.Create.icon },
                iconPosition = Position.Left,
                hrefOrValue = Unit,
                clickHandlers = listOf(tagPrefix.addKeyword),
                disabledFlow = combine(
                    tagPrefix.data,
                    userKeywords.data,
                ) { typed, added ->
                    typed.isBlank() || added.contains(typed)
                },
            )
        }

        div {
            suggestedTags(tagPrefix)
        }
    }
}

private fun RenderContext.suggestedTags(tagPrefix: Store<String>) {
    val translation: Translation by koinCtx.inject()
    val workspaceStore = koinCtx.get<CurrentWorkspaceStore>()
    val formationClient = koinCtx.get<FormationClient>()
    val myProfileStore = koinCtx.get<MyProfileStore>()
    val defaultTheme = koinCtx.get<FormationDefault>()

    val userKeywords = myProfileStore.map(MyProfile.keywords())

    val suggestionsStore = storeOf(listOf<String>())
    val suggestionContextStore = storeOf(
        SuggestedTagsContext(
            SearchQueryContext.newContext(
                listOf(
                    workspaceStore.current!!.groupId,
                ),
            ),
            "",
            limit = 20,
        ),
        job,
    )
    tagPrefix.data handledBy { prefix ->
        suggestionContextStore.update(suggestionContextStore.current.copy(prefix = prefix))
    }
    suggestionContextStore.data
        .debounce(100.milliseconds) handledBy { keywordSuggestionContext ->
        formationClient.keywordsForQueryContext(
            searchQueryContext = keywordSuggestionContext.ctx,
            prefix = keywordSuggestionContext.prefix,
            numberOfKeywords = keywordSuggestionContext.limit,
        ).fold(
            {
                suggestionsStore.update(it)
            },
            {
                console.error(it, keywordSuggestionContext)
            },
        )
    }

    div("flex flex-col space-y-3") {
        suggestionsStore.data.renderIf({ it.isNotEmpty() }) {
            div {
                translation[TL.KeywordTags.SUGGESTED].renderText(this)
            }
        }
        div("flex flex-row flex-wrap gap-3") {
            combine(userKeywords.data, suggestionsStore.data) { userKws, suggestions ->
                (suggestions - userKws.toSet()).distinct().let {
                    it.slice(0..min(it.size - 1, 9))
                }
            }.renderIf({ it.isNotEmpty() }) { keywords ->
                keywords.forEach { kw ->
                    twTagButton(text = kw, icon = FormationUIIcons.Add.icon) {
                        clicks.map { kw } handledBy myProfileStore.addKeyword
                    }
                }
            }
        }
    }
}

fun RenderContext.verificationClock(token: Token?) {
    val translation: Translation by koinCtx.inject()
    flow {
        while (token?.isExpired == false) {
            emit(((token.expiration - Clock.System.now().toEpochMilliseconds()) / 1000).toInt())
            delay(1000)
        }
    }.render { time ->
        span {
            if (time > 0) {
                translation[TL.UserVerification.NEW_QR_IN_X_SECS, mapOf("seconds" to time)].renderText(into = this)
            } else translation[TL.UserVerification.QR_CODE_EXPIRED].renderText(into = this)
        }
    }
}

fun RenderContext.editMyProfile() {

    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val formationClient: FormationClient by koinCtx.inject()
    val myProfileStore: MyProfileStore by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val imageFileDataStore: ImageFileDataStore by koinCtx.inject()
    val analyticsService by koinCtx.inject<AnalyticsService>()
    val firstName = myProfileStore.map(MyProfile.firstName())
    val lastName = myProfileStore.map(MyProfile.lastName())
    val jobTitle = myProfileStore.map(MyProfile.jobTitle())
    val emails = myProfileStore.map(MyProfile.emails())
    val password = myProfileStore.map(MyProfile.password())
    val phone = myProfileStore.map(MyProfile.phone())
    val profilePhoto = myProfileStore.map(MyProfile.profilePhoto())

    flexBox(
        {
            direction { column }
            width { full }
            height { full }
            justifyContent { spaceBetween }
            alignItems { center }
        },
    ) {
        fullCard {
            baseLayout(
                expandable = false,
                header = null,
                content = {
                    contentScrollBox {
                        profilePhoto.data.combine(imageFileDataStore.data) { profilePhoto, uploadedPhoto ->
                            uploadedPhoto.href ?: profilePhoto?.href
                        }.render { imageSrc ->
                            // Profile Photo
                            flexBox(
                                {
                                    position { relative { } }
                                    alignItems { center }
                                    justifyContent { center }
                                    margins {
                                        vertical { normal }
                                    }
                                },
                            ) {
                                flexBox(
                                    {
                                        flex {
                                            grow { "0" }
                                            shrink { "0" }
                                            basis { "200px" }
                                        }
                                        width { "200px" }
                                        height { "200px" }
                                        radius { full }
                                        justifyContent { center }
                                        alignItems { center }
                                        textAlign { center }

                                        if (imageSrc != null) {
                                            background {
                                                image { imageSrc }
                                                size { cover }
                                                position { center }
                                            }
                                        } else {
                                            border {
                                                width(formationStyles.borderWidth)
                                                color { FormationColors.GrayDisabled.color }
                                            }
                                            color { FormationColors.GrayDisabled.color }
                                        }
                                    },
                                ) {
                                    if (imageSrc == null) {
                                        icon(
                                            {
                                                size { "100px" }
                                            },
                                        ) { fromTheme { FormationIcons.UserAlt.icon } }
                                    }
                                }
                            }
                            div(
                                {
                                    position {
                                        absolute {
                                            left { large }
                                            top { large }
                                        }
                                    }
                                },
                            ) {
                                circleIconButton(
                                    size = { "50px" },
                                    iconFlow = flowOf { FormationIcons.CameraAlt.icon },
                                    iconSize = { huge },
                                    styleFlow = flowOf {
                                        background { color { primary.main } }
                                        color { secondary.main }
                                    },
                                    hoverStyle = {
                                        background { color { secondary.main } }
                                        color { primary.main }
                                        border {
                                            width(formationStyles.borderWidth)
                                            color { primary.main }
                                        }
                                    },
                                    value = String,
                                    addOrReplaceRoute = mapOf("myProfile" to MyProfilePages.ManageProfilePhoto.name),
                                )
                            }
                        }
                        stackUp(
                            {
                                width { full }
                                alignItems { center }
                                justifyContent { center }
                                margins {
                                    bottom { small }
                                }
                            },
                        ) {
                            spacing { tiny }
                            items {
                                cardTitle(firstName.data.merge(lastName.data))
                                cardSubtitle(jobTitle.data)
                            }
                        }
                        stackUp {
                            spacing { small }
                            items {
                                lineUp(
                                    {
                                        justifyContent { spaceBetween }
                                        alignItems { center }
                                        width { full }
                                    },
                                ) {
                                    spacing { smaller }
                                    items {
                                        inputLabelWrapper(
                                            title = translation[TL.UserProfile.FIRST_NAME],
                                            visibilityFlow = firstName.data.map { it.isNotBlank() },
                                        ) {
                                            genericInput(
                                                value = firstName.data,
                                            ) {
                                                id(firstName.id)
                                                placeholder(translation[TL.UserProfile.FIRST_NAME])
                                                autofocus(true)
                                                attr("tabindex", "1")
                                                inputs.values() handledBy firstName.update
                                            }
                                        }

                                        inputLabelWrapper(
                                            title = translation[TL.UserProfile.LAST_NAME],
                                            visibilityFlow = lastName.data.map { it.isNotBlank() },
                                        ) {
                                            genericInput(
                                                value = lastName.data,
                                            ) {
                                                id(lastName.id)
                                                placeholder(translation[TL.UserProfile.LAST_NAME])
                                                attr("tabindex", "2")
                                                inputs.values() handledBy lastName.update
                                            }
                                        }
                                    }
                                }
                                // Job title
                                inputLabelWrapper(
                                    title = translation[TL.UserProfile.JOB_TITLE],
                                    visibilityFlow = jobTitle.data.map { it.isNotBlank() },
                                ) {
                                    genericInput(
                                        value = jobTitle.data,
                                    ) {
                                        id(jobTitle.id)
                                        placeholder(translation[TL.UserProfile.JOB_TITLE])
                                        attr("tabindex", "3")
                                        inputs.values() handledBy jobTitle.update
                                    }
                                }
                                // Disabled Email
                                genericInput(
                                    value = emails.data,
                                    disabled = true,
                                    rightContentBox = {
                                        inputIconButton(
                                            iconFlow = flowOf { FormationIcons.Lock.icon },
                                            iconColor = flowOf(FormationColors.GrayDisabled.color),
                                        ) {
                                            disabled(true)
                                        }
                                    },
                                ) {
                                    id(emails.id)
                                    placeholder(translation[TL.UserProfile.EMAIL])
                                    type("text")
                                }
                                // Disabled Password
                                genericInput(
                                    disabled = true,
                                    type = "password",
                                    rightContentBox = {
                                        inputIconButton(
                                            iconFlow = flowOf { FormationIcons.Edit.icon },
                                        ) {
                                            title(
                                                translation[TL.General.CHANGE].map { text ->
                                                    text.lowercase()
                                                        .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                },
                                            )
                                            clicks.map {
                                                Pages.MyProfile.route + MyProfilePages.ChangePasswordEnter.route
                                            } handledBy routerStore.validateInternalRoute
                                            clicks.map {
                                                AnalyticsCategory.ChangePasswordButton.click()()
                                            } handledBy analyticsService.analyticsEvent
                                        }
                                    },
                                ) {
                                    id(password.id)
                                    value(password.data)
                                }

                                // Phone
                                inputLabelWrapper(
                                    title = translation[TL.UserProfile.PHONE],
                                    visibilityFlow = phone.data.map { it.isNotBlank() },
                                ) {
                                    genericInput(
                                        value = phone.data,
                                    ) {
                                        id(phone.id)
                                        placeholder(translation[TL.UserProfile.PHONE])
                                        attr("tabindex", "4")
                                        inputs.values() handledBy phone.update
                                    }
                                }
                            }
                        }
                        // Delete Account button
                        apiUserStore.current.apiUser?.emails?.firstOrNull()?.let { email ->
                            deleteAccountButton(email = email, client = formationClient)
                        }
                    }
                },
                footer = {
                    twoButtonFooter(
                        secondaryTitle = translation[TL.General.CANCEL],
                        secondaryStyleParams = secondaryButtonStyleParams,
                        primaryValue = Unit,
                        secondaryClickHandlers = listOf(myProfileStore.fetchMyProfile, routerStore.back),
                        primaryTitle = translation[TL.UserProfile.SAVE_PROFILE],
                        primaryState = firstName.data.map { it.isNotBlank() }, //firstName.data.combine(lastName.data){f, l -> f.isNotBlank() && l.isNotBlank()},
                        primaryStyleParams = primaryButtonStyleParams,
                        primaryClickHandlers = listOf(myProfileStore.updateMyProfile, routerStore.back),
                    )
                },
            )
        }
    }
}

/**
 * functions to create generic mailto, tel and sms links / buttons
 */


fun RenderContext.mailToLink(emails: String) {
    val addresses = emails.split(",")
    lineUp {
        items {
            addresses.forEach { address ->
                a(
                    {
                        color { FormationColors.BlueLight.color }
                        textDecoration { underline }
                    },
                ) {
                    title("mailto:$address")
                    href("mailto:$address")
                    +address
                }
            }
        }
    }
}

fun RenderContext.telLink(phoneNumbers: String) {
    val numbers = phoneNumbers.split(",")
    lineUp {
        items {
            numbers.forEach { number ->
                a(
                    {
                        color { FormationColors.BlueLight.color }
                        textDecoration { underline }
                    },
                ) {
                    title("tel:$number")
                    href("tel:$number")
                    +number
                }
            }
        }
    }
}

fun RenderContext.phoneLinkButton(type: String, data: String, icon: Icons.() -> IconDefinition) {
    with(data.split(",").first()) {
        a(
            {
                color { primary.main }
                hover { color { primary.main } }
            },
        ) {
            title("$type:${this@with}")
            href("$type:${this@with}")
            button(
                {
                    width { "32px" }
                    height { "32px" }
                    radius { full }
                    border {
                        color { primary.main }
                        width(formationStyles.borderWidth)
                    }
                    hover {
                        background { color { FormationColors.GrayLight.color } }
                    }
                    padding { tiny }
                    justifyContent { center }
                    alignItems { center }
                },
            ) {
                icon(
                    {
                        size { normal }
                    },
                ) { fromTheme(icon) }
            }
        }
    }
}


/**
 * CHANGE PASSWORD VIEWS
 */


fun RenderContext.changePasswordFlow1() {
    val updatePasswordStore: UpdatePasswordStore by koinCtx.inject()
    val oldPassword = updatePasswordStore.map(ValidatePassword.oldPass())
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    val noBackButton = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                div("flex grow") { }

                flexBox(
                    {
                        width { full }
                        justifyContent { center }
                        flex { grow { "1" } }
                    },
                ) { cardTitle(translation[TL.UserProfile.PASSWORD_VIEW1_HEADER]) }

                flexBox(
                    {
                        direction { column }
                        width { full }
                        alignItems { center }
                        justifyContent { spaceEvenly }
                        padding { normal }
                        flex { grow { "1" } }
                    },
                ) {
                    p { translation[TL.UserProfile.PASSWORD_VIEW1_TITLE].renderText(into = this) }
                    inputLabelWrapper(
                        title = translation[TL.UserProfile.OLD_PASSWORD],
                        visibilityFlow = oldPassword.data.map { it.isNotBlank() },
                    ) {
                        val showOldPWStore = storeOf(false, job)
                        genericInput(
                            value = oldPassword.data,
                            type = "password",
                            rightContentBox = {
                                inputIconToggleButton(
                                    iconFalse = { eye },
                                    iconTrue = { eyeOff },
                                    boolStore = showOldPWStore,
                                    attributes = listOf("tabindex" to "-1"),
                                )
                            },
                        ) {
                            id("oldPasswordInput")
                            placeholder(translation[TL.UserProfile.OLD_PASSWORD])
                            type(showOldPWStore.data.map { if (it) "text" else "password" })
                            inputs.values() handledBy oldPassword.update
                        }
                    }
                    focusInputObserver({ js("document.getElementById('oldPasswordInput').focus()") }, domNode)
                }
                div("flex grow") { }
            }
        },
        footer = {
            if (noBackButton) {
                oneButtonFooter(
                    title = translation[TL.General.NEXT],
                    state = oldPassword.data.map { it.isNotBlank() },
                    styleParams = primaryButtonStyleParams,
                    value = Unit,
                    clickHandlers = listOf(updatePasswordStore.validateCurrentPassword),
                    routingMap = null,
                )
            } else {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.BACK],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(updatePasswordStore.reset),
                    secondaryRoutingMapBackTo = Pages.MyProfile.route + MyProfilePages.EditMyProfile.route,
                    primaryTitle = translation[TL.General.NEXT],
                    primaryState = oldPassword.data.map { it.isNotBlank() },
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryClickHandlers = listOf(updatePasswordStore.validateCurrentPassword),
                    primaryRoutingMap = null,
                )
            }
        },
    )
    updatePasswordStore.data.render { store ->
        if (store.responseStatus is StatusResult.Success) {
            updatePasswordStore.update(updatePasswordStore.current.copy(responseStatus = null))
            routerStore.addOrReplaceRoute(Pages.MyProfile.route + MyProfilePages.ChangePasswordNew.route)
        }
    }
}

fun RenderContext.changePasswordFlow2() {
    val updatePasswordStore = koinCtx.get<UpdatePasswordStore>()
    val firstPass = updatePasswordStore.map(ValidatePassword.firstPass())
    val secondPass = updatePasswordStore.map(ValidatePassword.secondPass())
    val isValid = updatePasswordStore.map(ValidatePassword.isValid())
    val routerStore: RouterStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()

    val cancelToPWNeededPage = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                div("flex grow") { }
                flexBox(
                    {
                        width { full }
                        justifyContent { center }
                        flex { grow { "1" } }
                    },
                ) { cardTitle(translation[TL.UserProfile.PASSWORD_VIEW2_HEADER]) }

                flexBox(
                    {
                        direction { column }
                        width { full }
                        alignItems { center }
                        justifyContent { spaceEvenly }
                        padding { normal }
                        flex { grow { "1" } }
                    },
                ) {
                    p { translation[TL.UserProfile.PASSWORD_VIEW2_TITLE1].renderText(into = this) }
                    inputLabelWrapper(
                        title = translation[TL.UserProfile.NEW_PASSWORD],
                        visibilityFlow = firstPass.data.map { it.isNotBlank() },
                    ) {
                        val showFirstPassStore = storeOf(false, job)
                        genericInput(
                            value = firstPass.data,
                            type = "password",
                            rightContentBox = {
                                inputIconToggleButton(
                                    iconFalse = { eye },
                                    iconTrue = { eyeOff },
                                    boolStore = showFirstPassStore,
                                    attributes = listOf("tabindex" to "-1"),
                                )
                            },
                        ) {
                            id("firstPasswordInput")
                            placeholder(translation[TL.UserProfile.NEW_PASSWORD])
                            attr("tabindex", "1")
                            type(showFirstPassStore.data.map { if (it) "text" else "password" })
                            inputs.values() handledBy firstPass.update
                        }
                        passwordStrengthIndicator(updatePasswordStore.data)
                    }
                    focusInputObserver({ js("document.getElementById('firstPasswordInput').focus()") }, domNode)
                }
                flexBox(
                    {
                        direction { column }
                        width { full }
                        alignItems { center }
                        justifyContent { spaceEvenly }
                        padding { normal }
                        flex { grow { "1" } }
                    },
                ) {
                    p { translation[TL.UserProfile.PASSWORD_VIEW2_TITLE2].renderText(into = this) }
                    inputLabelWrapper(
                        title = translation[TL.UserProfile.NEW_PASSWORD_RE],
                        visibilityFlow = secondPass.data.map { it.isNotBlank() },
                    ) {
                        val showSecondPassStore = storeOf(false, job)
                        genericInput(
                            value = secondPass.data,
                            type = "password",
                            rightContentBox = {
                                inputIconToggleButton(
                                    iconFalse = { eye },
                                    iconTrue = { eyeOff },
                                    boolStore = showSecondPassStore,
                                    attributes = listOf("tabindex" to "-1"),
                                )
                            },
                        ) {
                            id("inputPassword-Re-enter")
                            placeholder(translation[TL.UserProfile.NEW_PASSWORD_RE])
                            attr("tabindex", "2")
                            type(showSecondPassStore.data.map { if (it) "text" else "password" })
                            inputs.values() handledBy secondPass.update
                        }
                    }
                }
                div(
                    {
                        css("align-self: center;")
                        height { "25px" }
                        margins { top { small } }
                    },
                ) {
                    span(
                        {
                            color { FormationColors.RedError.color }
                            fontSize { smaller }
                            textAlign { center }
                            background {
                                color { makeRGBA(FormationColors.RedError.color, 0.1) }
                            }
                            radius { larger }
                            paddings {
                                vertical { tiny }
                                horizontal { smaller }
                            }
                        },
                    ) {
                        className(
                            updatePasswordStore.data.map { pwStack ->
                                if (pwStack.firstPass.isNotBlank()
                                    && pwStack.secondPass.isNotBlank()
                                    && !pwStack.isMatching
                                ) {
                                    "visible"
                                } else {
                                    "invisible"
                                }
                            },
                        )
                        translation[TL.UserProfile.PASSWORD_NO_MATCH].renderText(into = this)
                    }
                }
                div("flex grow") { }
            }
        },
        footer = {
            if (cancelToPWNeededPage) {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CANCEL],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(updatePasswordStore.reset),
                    secondaryRoutingMapBackTo = Pages.NewPasswordNeeded.route,
                    secondaryAttributes = listOf("tabindex" to "3"),
                    primaryTitle = translation[TL.UserProfile.SET_PASSWORD],
                    primaryState = isValid.data,
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryClickHandlers = listOf(updatePasswordStore.updatePassword),
                    primaryRoutingMap = null,
                    primaryAttributes = listOf("tabindex" to "4"),
                )
            } else {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CANCEL],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(updatePasswordStore.reset),
                    secondaryRoutingMapBackTo = Pages.MyProfile.route + MyProfilePages.EditMyProfile.route,
                    secondaryAttributes = listOf("tabindex" to "3"),
                    primaryTitle = translation[TL.UserProfile.SET_PASSWORD],
                    primaryState = isValid.data,
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryClickHandlers = listOf(updatePasswordStore.updatePassword),
                    primaryRoutingMap = null,
                    primaryAttributes = listOf("tabindex" to "4"),
                )
            }
        },
    )

    firstPass.data.map { } handledBy updatePasswordStore.updateValidation
    secondPass.data.map { } handledBy updatePasswordStore.updateValidation

    updatePasswordStore.data.render { store ->
        if (store.responseStatus is StatusResult.Success) {
            updatePasswordStore.update(ValidatePassword())
            routerStore.addOrReplaceRoute(Pages.MyProfile.route + MyProfilePages.ChangePasswordConfirm.route)
        }
    }
}

fun RenderContext.changePasswordFlow3() {
    val translation: Translation by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()

    val newPWRequired = featureFlagStore.current[Features.NewPasswordNeeded] == true

    baseLayout(
        expandable = false,
        content = {
            contentScrollBox(margins = { top { normal } }) {
                div("flex grow") { }
                flexBox(
                    {
                        width { full }
                        justifyContent { center }
                        flex { grow { "1" } }
                    },
                ) { cardTitle(translation[TL.UserProfile.PASSWORD_VIEW3_HEADER]) }
                flexBox(
                    {
                        width { full }
                        alignItems { center }
                        justifyContent { center }
                        textAlign { center }
                        padding { normal }
                        flex { grow { "1" } }
                    },
                ) {
                    p {
                        translation[TL.UserProfile.PASSWORD_VIEW3_TITLE].renderText(into = this)
                    }
                }
                div("flex grow") { }
            }
        },
        footer = {
            if (newPWRequired) {
                oneButtonFooter(
                    title = translation[TL.MainMenu.LOGIN],
                    styleParams = primaryButtonStyleParams,
                    value = Unit,
                    clickHandlers = listOf(apiUserStore.refreshUser),
                )
            } else {
                oneButtonFooter(
                    title = translation[TL.General.DONE],
                    styleParams = primaryButtonStyleParams,
                    routingMapBackTo = Pages.MyProfile.route + MyProfilePages.EditMyProfile.route,
                    value = Unit,
                )
            }
        },
    )
}

fun RenderContext.deleteAccountButton(email: String, client: FormationClient) {
    val translation by koinCtx.inject<Translation>()
    val mainController: MainController by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    val confirmationOverlayStore: ConfirmationOverlayStore by koinCtx.inject()

    val pwStore = storeOf("")

    val requestHandler: SimpleHandler<Unit> = SimpleHandler { flow, job ->
        flow.onEach {
            busyStore.handleApiCall(
                supplier = {
                    client.requestDeleteAccount(email, pwStore.current)
                },
                processResult = { result ->
                    console.log("Account deleted", result)
                    pwStore.update("")
                    mainController.logout()
                    confirmationOverlayStore.reset()
                },
                successMessage = translation[TL.AlertNotifications.ACCOUNT_DELETED_SUCCESSFULLY],
                processError = { error ->
                    pwStore.update("")
                    console.log("Account deletion failed!", error.message)
                },
                errorMessage = translation[TL.AlertNotifications.ACCOUNT_DELETION_FAILED],
            )
        }.launchIn(MainScope() + job)
    }

    // delete account button
    clickButton(
        {
            height(formationStyles.buttonHeight)
            width { auto }
            padding { tiny }
            margins { top { larger } }
            radius(formationStyles.buttonRadius)
            color { danger.main }
            background {
                color { secondary.main }
            }
            border {
                width(formationStyles.borderWidth)
                color { danger.main }
            }
            hover {
                color { secondary.main }
                background {
                    color { danger.main }
                }
            }
        },
    ) {
        text(translation[TL.DeleteAccount.DELETE_ACCOUNT_TITLE])
        icon { FormationIcons.Delete.icon }
        element {
            title(translation[TL.General.DELETE])
        }
    } handledBy modal(
        {
            width { none }
            height { none }
        },
    ) {
        placement { center }
        hasCloseButton(false)
        content { close ->
            fullPageConfirmation {
                fullPageConfirmationContainer(width = { "360px" }) {
                    stackUp(
                        {
                            width { full }
                        },
                    ) {
                        spacing { small }
                        items {
                            cardTitle(translation[TL.DeleteAccount.DELETE_ACCOUNT_TITLE]) { FormationIcons.Delete.icon }
                            p("my-5") {
                                translation[TL.DeleteAccount.DELETE_ACCOUNT_TEXT_A].renderText(into = this)
                            }
                            p("my-5") {
                                translation[TL.DeleteAccount.DELETE_ACCOUNT_TEXT_B].renderText(into = this)
                            }

                            // PASSWORD CONFIRMATION
                            stackUp(
                                {
                                    width { full }
                                    display { flex }
                                    alignItems { stretch }
                                },
                            ) {
                                spacing { smaller }
                                items {
                                    inputLabelWrapper(
                                        title = translation[TL.Login.PASSWORD],
                                        visibilityFlow = pwStore.data.map { it.isNotBlank() },
                                    ) {
                                        val showPWStore = storeOf(false, job)
                                        genericInput(
                                            value = pwStore.data,
                                            type = "password",
                                            rightContentBox = {
                                                inputIconToggleButton(
                                                    iconFalse = { eye },
                                                    iconTrue = { eyeOff },
                                                    boolStore = showPWStore,
                                                    attributes = listOf("tabindex" to "-1"),
                                                )
                                            },
                                        ) {
                                            id("inputPassword")
                                            placeholder(translation[TL.Login.PASSWORD])
                                            attr("tabindex", "4")
                                            type(showPWStore.data.map { if (it) "text" else "password" })
                                            inputs.values() handledBy pwStore.update
                                        }
                                    }
                                }
                            }

                            twoButtonFooter(
                                primaryTitle = translation[TL.DeleteAccount.DELETE_ACCOUNT_BUTTON],
                                primaryValue = Unit,
                                primaryClickHandlers = listOf(close, requestHandler),
                                primaryStyleParams = {
                                    genericButtonStyleParams()
                                    color { danger.main }
                                    background {
                                        color { secondary.main }
                                    }
                                    border {
                                        width(formationStyles.borderWidth)
                                        color { danger.main }
                                    }
                                    hover {
                                        color { secondary.main }
                                        background {
                                            color { danger.main }
                                        }
                                    }
                                },
                                primaryState = pwStore.data.map { it.isNotBlank() },
                                secondaryTitle = translation[TL.General.CANCEL],
                                secondaryClickHandlers = listOf(close),
                                secondaryStyleParams = secondaryButtonStyleParams,
                            )
                        }
                    }
                }
            }
        }
    }
}
