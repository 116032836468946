package measuringTool

import dev.fritz2.core.RenderContext
import dev.fritz2.core.storeOf
import dev.fritz2.styling.params.plus
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import map.views.sideButtonStyle
import maplibreGL.MaplibreMap
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twMediumIconButton
import webcomponents.circleIconButton

fun RenderContext.measuringToolButton() {

    val measuringToolStore = MeasuringToolStore()

    val measureEditStore = storeOf(false)


    withKoin {
        val maplibreMap: MaplibreMap = koinCtx.get()

        combine(measuringToolStore.data, measureEditStore.data) { s, e -> Pair(s, e) }.render { (toolState, editState) ->
            div("mt-2") {
                if (toolState) {
                    div("flex flex-row w-max items-center justify-between gap-5 pl-4 rounded-3xl shadow-xl") {
                        if (editState) {
                            className("bg-red-200")
                            twMediumIconButton(icon = FormationUIIcons.Close.icon) {
                                clicks handledBy {
                                    maplibreMap.stopMeasurement()
                                    measureEditStore.update(false)
                                }
                            }
                        } else {
                            className("bg-formationWhite")
                            twMediumIconButton(icon = FormationIcons.Edit.icon) {
                                clicks handledBy {
                                    maplibreMap.resumeMeasurement()
                                    measureEditStore.update(true)
                                }
                            }
                        }
                        twMediumIconButton(icon = FormationUIIcons.Undo.icon) {
                            clicks handledBy {
                                maplibreMap.resetMeasureFeatureData()
                            }
                        }
                        circleIconButton(
                            iconFlow = flowOf { FormationUIIcons.MeasureTool.icon },
                            iconSize = { large },
                            size = formationStyles.sideButtonSize,
                            styleFlow = measuringToolStore.data.map { state ->
                                if (state) sideButtonStyle.plus {
                                    color { secondary.main }
                                    background { color { FormationColors.GraySlate.color } }
                                    hover {
                                        background { color { FormationColors.GraySlate.color } }
                                        color { gray100 }
                                    }
                                }
                                else sideButtonStyle
                            },
                            tooltipText = flowOf("Toggle Measuring tool"),
                            value = Unit,
                            clickHandlers = listOf(measuringToolStore.toggle),
                        )
                    }
                } else {
                    circleIconButton(
                        iconFlow = flowOf { FormationUIIcons.MeasureTool.icon },
                        iconSize = { large },
                        size = formationStyles.sideButtonSize,
                        styleFlow = measuringToolStore.data.map { state ->
                            if (state) sideButtonStyle.plus {
                                color { secondary.main }
                                background { color { FormationColors.GraySlate.color } }
                                hover {
                                    background { color { FormationColors.GraySlate.color } }
                                    color { gray100 }
                                }
                            }
                            else sideButtonStyle
                        },
                        tooltipText = flowOf("Toggle Measuring tool"),
                        value = Unit,
                        clickHandlers = listOf(measuringToolStore.toggle),
                    )
                }
            }
        }
    }
}
