package localization

import apiclient.users.UserPreferences
import com.tryformation.localization.Locale
import com.tryformation.localization.LocalizedTranslationBundleSequence
import com.tryformation.localization.LocalizedTranslationBundleSequenceProvider
import com.tryformation.localization.Translatable
import com.tryformation.localization.TranslatedValue
import data.users.settings.SyncedUserPreferencesStore
import dev.fritz2.core.HtmlTag
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import koin.koinCtx
import koin.withKoin
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import model.languageCode
import org.w3c.dom.HTMLElement

class LocaleStore(
    syncedUserPreferencesStore: SyncedUserPreferencesStore,
    initialLocale: Locale
) : RootStore<Locale>(
    initialData = initialLocale,
    job = Job(),
) {

    init {
        syncedUserPreferencesStore.data.map { it.languageCode?.let { code -> Locales.findByIdOrNull(code) } ?: current } handledBy update
    }

}

private val missingTranslations = mutableSetOf<Translatable>()

fun RenderContext.showMissingTranslations() {
    pre {
        missingTranslations.sortedBy { it.messageId }.forEach { translatable ->
            +"${translatable.messageId} = ${translatable.humanReadable}\n"
        }
    }
}

val TranslatedValue.value: String
    get() {
        if (this.noTranslationFound) {
            console.warn("missing translation $messageId")
            translatable?.let {
                missingTranslations.add(it)
            }
        }

        return this.message
    }

typealias Translation = TranslationStore
private val Translatable.humanReadable: String
    get() {
        val postFix = messageId.replace("$prefix-","")

        return postFix.split('-') // Split on CamelCase or underscore
            .joinToString(" ") { it.lowercase().replaceFirstChar { char -> char.uppercase() } }
    }

class TranslationStore(
    bundleSequence: LocalizedTranslationBundleSequence,
    fetchFtl: suspend (String) -> String?
) : RootStore<LocalizedTranslationBundleSequence>(
    initialData = bundleSequence,
    job = Job(),
) {
    private val localizedTranslationBundleSequenceProvider by koinCtx.inject<LocalizedTranslationBundleSequenceProvider>()
    private val syncedUserPreferencesStore: SyncedUserPreferencesStore by koinCtx.inject()
    private val languageCode = syncedUserPreferencesStore.map(UserPreferences.languageCode())

    operator fun get(translatable: Translatable): Flow<String> = data.map { it.format(
        translatable = translatable,
        args = null,
        fallback = translatable.humanReadable
    ).value }
    operator fun get(translatable: Translatable, args: Map<String, Any>): Flow<String> = data.map { it.format(
        translatable = translatable,
        args = args,
        fallback = translatable.humanReadable
    ).value }
    operator fun get(translatable: Translatable, args: Flow<Map<String, Any>>): Flow<String> =
        data.combine(args) { tl, json -> tl.format(translatable = translatable, args = json, fallback = translatable.humanReadable).value }


    fun get(stringId: String): Flow<String> = data.map { it.format(stringId, null).value }
    fun get(stringId: String, args: Map<String, Any>): Flow<String> = data.map { it.format(stringId, args).value }
    fun get(stringId: String, args: Flow<Map<String, Any>>): Flow<String> = data.combine(args) { tl, json -> tl.format(stringId, json).value }

    fun getString(translatable: Translatable, json: Map<String, Any>? = null): String = current.format(translatable, json, translatable.humanReadable).value
    fun getString(stringId: String, json: Map<String, Any>? = null): String = current.format(stringId, json).value

    private val setLocale = handle<String?> { current, locale ->
        if (locale != null) {
            console.log("switching locale", locale)
            localizedTranslationBundleSequenceProvider.loadBundleSequence(listOf(locale), fallbackLocale = Locales.EN_GB.id, fetchFtl)
        } else current
    }

    init {
        languageCode.data handledBy setLocale
    }
}

private val translationStore by lazy {
    withKoin {
        get<TranslationStore>()
    }
}

fun HtmlTag<HTMLElement>.translate(translatable: Translatable, args: Map<String, Any>? = null) =
    translationStore[translatable,args?: mapOf()].renderText()

fun HtmlTag<HTMLElement>.translate(translatable: Translatable, vararg args: Pair<String, Any>) =
    translationStore[translatable, mapOf(*args)].renderText()

fun Translatable?.getString(args: Map<String, Any>? = null) = this?.let {
    translationStore.getString(it, args?: mapOf())
}

fun Translatable?.getString(vararg args: Pair<String, Any>) = this?.let {
    translationStore.getString(it, mapOf(*args))
}

fun Translatable.getTranslationFlow(args: Map<String, Any>?=null)  = translationStore[this,args.orEmpty()]
