package data.users.profile

import apiclient.FormationClient
import apiclient.users.PublicUserProfile
import apiclient.users.verifyAccessControlToken
import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import overlays.BusyStore

class VerifiedUserStore : RootStore<PublicUserProfile?>(
    initialData = null,
    job = Job(),
) {
    val formationClient: FormationClient by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    suspend fun verifyAccessControlToken(token: String) {
        busyStore.handleApiCall(
            supplier = suspend {
                formationClient.verifyAccessControlToken(token)
            },
            processResult = { res ->
                if (res.verified) {
                    update(res.publicProfile)
                } else update(null)
            },
            processError = { error ->
                console.log("Verify access of user failed.", error.message)
                update(null)
            }
        )
    }
}
