package login

import com.jillesvangurp.serializationext.DEFAULT_JSON
import apiclient.apiversion.Platform
import apiclient.FormationClient
import dev.fritz2.core.RootStore
import generated.ServerConfig
import io.ktor.client.HttpClient
import io.ktor.client.request.get
import koin.koinCtx
import koin.setHttpHostConfig
import kotlinx.coroutines.Job

data class HostConfig(val host: String, val port: Int, val ssl: Boolean = true)

class HostConfigStore(initialConfig: HostConfig) : RootStore<HostConfig>(
    initialData = initialConfig,
    job = Job(),
) {
    private val httpClient by koinCtx.inject<HttpClient>()
    private val defaultHostConfig by koinCtx.inject<HostConfig>()

    val updateHostConfig = handle<String> { _, prefix ->
        if (defaultHostConfig.ssl && prefix.isNotBlank()) {
            try {
                val baseUrl = "https://$prefix.tryformation.com/hi"
                httpClient.get(baseUrl)
                defaultHostConfig.copy(host = "$prefix.tryformation.com")
            } catch (e: Exception) {
                defaultHostConfig
            }
        } else {
            // localhost should not use dns
            defaultHostConfig
        }
    }

    fun anonymousClient() = FormationClient(
        urlBuilder = {
            this.setHttpHostConfig(current)
        },
        httpClient = koinCtx.get(),
        json = DEFAULT_JSON,
        platform = Platform.web.name,
        version = ServerConfig.apiVersion
    )
}
