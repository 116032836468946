package data.objects.views.directediting

import apiclient.geoobjects.Content
import apiclient.geoobjects.MarkerColor
import apiclient.geoobjects.MarkerIcon
import apiclient.geoobjects.UpsertPoll
import apiclient.polls.PollOption
import camera.cameraWrapper.cameraModal
import camera.nimiq.qrScanCamera
import com.tryformation.localization.Translatable
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.placeholder
import dev.fritz2.core.storeOf
import kotlin.random.Random
import kotlin.random.nextULong
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.getTranslationFlow
import localization.translate
import model.ScanPurpose
import overlays.withBusyApplyContentChange
import theme.FormationIcons
import theme.FormationUIIcons
import twcomponents.twAddContentButton
import twcomponents.twCheckButton
import twcomponents.twColOf
import twcomponents.twIconMedium
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twMediumIconButton
import twcomponents.twMediumIconButtonHighlight
import twcomponents.twMediumIconButtonNeutral
import twcomponents.twMediumIconButtonRed
import twcomponents.twRowOfJustifyBetween
import twcomponents.twRowOfWrap
import twcomponents.twSecondaryButtonSmall
import webcomponents.inputLabelWrapper

enum class PollEditor: Translatable {
    Question,
    Option,
    Explanation,
    AddOption,
    ActionCode,
    ;

    override val prefix = "poll-editor"
}

class PolloptionsStore(initialValue: List<PollOption>) : RootStore<List<PollOption>>(initialValue, Job()) {

    val moveOptionUp = handle<String> { current, optionId ->
        val mutable = current.toMutableList()
        val index = mutable.map { it.id }.indexOf(optionId)
        if (index > 0) {
            val option = mutable[index]
            mutable.removeAt(index)
            mutable.add(index - 1, option)
        }
        mutable
    }

    val moveOptionDown = handle<String> { current, optionId ->
        val mutable = current.toMutableList()
        val index = mutable.map { it.id }.indexOf(optionId)
        if (index < mutable.size) {
            val option = mutable[index]
            mutable.removeAt(index)
            mutable.add(index + 1, option)
        }
        mutable
    }

    val addOption = handle { current ->
        current + PollOption("")
    }

    val removeOption = handle<String> { current, optionId ->
        val mutable = current.toMutableList()
        val index = current.map { it.id }.indexOf(optionId)
        mutable.removeAt(index)
        mutable
    }
}

fun RenderContext.pollEditor(
    objectId: String,
    content: Content.Poll,
    editorCloseHandler: SimpleHandler<Unit>? = null,
    header: Translatable? = null,
    isCreateNew: Boolean = false,
) {
    val titleStore = storeOf(content.title)
    val actionIdStore = storeOf(content.actionId ?: "")
    val pollOptionsStore = PolloptionsStore(content.options)

    twColOf {
        contentCreateHeader(if (isCreateNew) editorCloseHandler else null, header) {
            twMediumIconButtonHighlight(icon = FormationUIIcons.Save.icon) {
                titleStore.data.render { title ->
                    pollOptionsStore.data.render { options ->
                        disabled(title.isBlank() || options.isEmpty())
                    }
                }
                clicks handledBy {
                    withBusyApplyContentChange(
                        objectId,
                        UpsertPoll(
                            id = content.id.takeIf { it.isNotBlank() } ?: Random.nextULong().toString(),
                            title = titleStore.current,
                            pollOptions = pollOptionsStore.current,
                            actionId = if (content.actionId != null && actionIdStore.current.isBlank()) null else actionIdStore.current,
                            maxVotesPerUser = content.maxVotesPerUser,
                        ),
                    )
                    editorCloseHandler?.let { closeEditor ->
                        closeEditor(Unit)
                    }
                }
            }
        }
        inputLabelWrapper(
            title = PollEditor.Question.getTranslationFlow(),
            visibilityFlow = titleStore.data.map { it.isNotBlank() },
        ) {
            twInputField(titleStore) {
                twInputTextField {
                    placeholder(PollEditor.Question.getTranslationFlow())
                }
            }
        }
        twRowOfWrap {
            PrefabPolls.entries.forEach { prefab ->
                twSecondaryButtonSmall {
                    translate(prefab)
                    clicks handledBy {
                        if (titleStore.current.isBlank()) {
                            titleStore.update(prefab.poll.title)
                        }
                        pollOptionsStore.update(prefab.poll.options)
                    }
                }
            }
        }
        ul {
            pollOptionsStore.data.render { options ->
                options.forEachIndexed { index, pollOption ->
                    li("bg-white rounded-xl border-1 border-highlight shadow-lg flex flex-col gap-1 my-4 p-2") {
                        val optionTitleField = storeOf(pollOption.title)
                        val optionSubTitleField = storeOf(pollOption.subtitle ?: "")
                        div("static flex flex-row h-0 w-full pr-2 items-center justify-end") {
                            div("relative flex flex-row w-max gap-5 bg-formationWhite py-2 px-4 rounded-xl shadow-xl", id = id) {
                                // Combined undo and check buttons for both poll option inputs
                                twMediumIconButton(icon = FormationUIIcons.Undo.icon) {
                                    combine(optionTitleField.data, optionSubTitleField.data) { t, s ->
                                        (t.isBlank() && s.isBlank()) || (t == pollOption.title && s == pollOption.subtitle)
                                    }.render {
                                        disabled(it)
                                    }
                                    clicks handledBy {
                                        optionTitleField.update(pollOption.title)
                                    }
                                    clicks handledBy {
                                        optionSubTitleField.update(pollOption.subtitle.orEmpty())
                                    }
                                }
                                twCheckButton {
                                    combine(optionTitleField.data, optionSubTitleField.data) { t, s ->
                                        (t.isBlank() && s.isBlank()) || (t == pollOption.title && s == pollOption.subtitle)
                                    }.render {
                                        disabled(it)
                                    }
                                    clicks handledBy {
                                        pollOptionsStore.update(
                                            options.map {
                                                if (it.id == pollOption.id) {
                                                    it.copy(
                                                        title = optionTitleField.current,
                                                        subtitle = optionSubTitleField.current,
                                                    )
                                                } else {
                                                    it
                                                }
                                            },
                                        )
                                    }
                                }
                                twMediumIconButtonNeutral(FormationUIIcons.ArrowUp.icon) {
                                    clicks handledBy {
                                        // move poll option up
                                        pollOptionsStore.moveOptionUp(pollOption.id)
                                    }
                                }
                                twMediumIconButtonNeutral(FormationUIIcons.ArrowDown.icon) {
                                    clicks handledBy {
                                        // move poll option down
                                        pollOptionsStore.moveOptionDown(pollOption.id)
                                    }
                                }
                                twMediumIconButtonRed(FormationIcons.DeleteAlt.icon) {
                                    clicks handledBy {
                                        // remove poll option
                                        pollOptionsStore.removeOption(pollOption.id)
                                    }
                                }
                            }
                        }
                        // Input Option Title
                        inputLabelWrapper(
                            title = PollEditor.Option.getTranslationFlow(mapOf("index" to index+1)),
                            visibilityFlow = optionTitleField.data.map { it.isNotBlank() },
                        ) {
                            twInputField(optionTitleField) {
                                twInputTextField {
                                    placeholder(PollEditor.Option.getTranslationFlow(mapOf("index" to index+1))) // TODO translate
                                }
                            }
                        }
                        // Input Option Explanation / Subtitle
                        inputLabelWrapper(
                            title = PollEditor.Explanation.getTranslationFlow(),
                            visibilityFlow = optionSubTitleField.data.map { it.isNotBlank() },
                        ) {
                            twInputField(optionSubTitleField) {
                                twInputTextField {
                                    placeholder(PollEditor.Explanation.getTranslationFlow())
                                }
                            }
                        }
                    }
                }
            }
        }
        twAddContentButton {
            twIconMedium(icon = FormationUIIcons.Add.icon)
            translate(PollEditor.AddOption)
            clicks handledBy pollOptionsStore.addOption
        }
        // Action Code
        // QR Scan Camera Modal
        val toggleCamera = storeOf(false, Job())
        cameraModal(toggleCamera) { close, opened, video, _ ->
            qrScanCamera(
                closeModal = close,
                modalOpened = opened,
                videoElement = video,
                scanPurpose = ScanPurpose.ReadCodeAndStore,
                codeStore = actionIdStore,
            )
        }
        // Action Code Input
        twRowOfJustifyBetween {
            inputLabelWrapper(
                title = PollEditor.ActionCode.getTranslationFlow(),
                visibilityFlow = actionIdStore.data.map { it.isNotBlank() },
            ) {
                twInputField(actionIdStore) {
                    twInputTextField {
                        placeholder(PollEditor.ActionCode.getTranslationFlow())
                    }
                }
            }
            // Action Code Scan Button
            twSecondaryButtonSmall(
                text = TL.BottomBar.SCAN,
                icon = FormationIcons.QRCode.icon,
            ) {
                className("self-end")
                clicks handledBy {
                    toggleCamera.update(true)
                }
            }
        }
    }
}

enum class PrefabPolls(val poll: Content.Poll) : Translatable {
    RateTheFood(
        Content.Poll(
            id = "",
            title = "Rate the food",
            actionId = "",
            listOf(
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", "Loved it! Absolutely delicious!"),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", " Really enjoyed it!"),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", "It was good."),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D", "It was okay."),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D", "Didn't like it."),
            ),
        ),
    ),
    YesNoQuestion(
        Content.Poll(
            "",
            "Do you agree?",
            "",
            listOf(
                PollOptionStyles.Yes.pollOption("Yes", "I'm into this!"),
                PollOptionStyles.No.pollOption("No", "I disagree with statement."),
            ),
        ),
    ),
    TrafficLightForToilet(
        Content.Poll(
            "",
            "How do you judge the cleanliness?",
            "",
            listOf(
                PollOptionStyles.TrafficLightSmileyHappy.pollOption("Clean", "Spotless. Great Job!"),
                PollOptionStyles.TrafficLightSmileyMeh.pollOption("Acceptable", "Cleaning might be needed soon but it can wait."),
                PollOptionStyles.TrafficLightSmileyUnhappy.pollOption("Dirty", "Somebody should clean this"),
            ),
        ),
    ),
    RateTheKitchen(
        Content.Poll(
            id = "",
            title = "How do you like this Kitchen",
            actionId = "",
            listOf(
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", "It's amazing, it has everything I need!"),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", "It's fine but it could be better."),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D\uD83D\uDC4D", "It's OK"),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D\uD83D\uDC4D", "It could be better (tell us how)"),
                PollOptionStyles.NeutralNoIcon.pollOption("\uD83D\uDC4D", "This kitchen has some problems (tell us what you don't like)"),
            ),
        ),

    )
    ;

    override val prefix = "prefab-polls"
}

// predefine a few sane styles so we don't have to build a complicated editor
enum class PollOptionStyles(val icon: MarkerIcon?, val color: MarkerColor) {
    Yes(MarkerIcon.SmileyLaugh, MarkerColor.Green),
    Confused(MarkerIcon.SmileyUpsideDown, MarkerColor.DarkMagenta),
    No(MarkerIcon.SmileyNotGood, MarkerColor.Red),
    TrafficLightSmileyHappy(MarkerIcon.SmileyHappy, MarkerColor.Green),
    TrafficLightSmileyMeh(MarkerIcon.SmileyQuiet, MarkerColor.Yellow),
    TrafficLightSmileyUnhappy(MarkerIcon.SmileySad, MarkerColor.Red),
    NeutralNoIcon(MarkerIcon.Food, MarkerColor.Black)
    ;

    fun pollOption(title: String, subTitle: String) = PollOption(title, subTitle, icon, color = color)
}
