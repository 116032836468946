package analytics

import apiclient.geoobjects.LatLon
import apiclient.tags.TagEnum
import apiclient.tags.TagList
import apiclient.tags.addMultivaluedTag
import apiclient.tags.removeTag
import apiclient.tags.setUniqueTag
import apiclient.tags.tag
import apiclient.websocket.AnalyticsModule
import apiclient.websocket.MessageToServer
import auth.ApiUserStore
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import map.MapStateStore
import utils.graphqlScope

class AnalyticsService : RootStore<Boolean>(
    initialData = true,
    job = Job(),
) {
    // fake store so we can have a handler
    private val analyticsModule: AnalyticsModule by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()

    private val mapStateStore: MapStateStore by koinCtx.inject()

    private var userTags: TagList = listOf()

    private fun TagList?.enrichTags() =
        (this
            ?: listOf()) + userTags + listOfNotNull(mapStateStore.current?.zoom?.let { AnalyticsTags.Zl.tag(it.toString()) })

    private fun MessageToServer.AnalyticsEvent.enrich() = this.copy(
        tags = tags.enrichTags(),
        location = mapStateStore.current?.center
    )

    suspend fun record(
        category: AnalyticsCategory,
        action: AnalyticsAction,
        target: String? = null,
        value: Double? = null,
        label: String? = null,
        tags: TagList = listOf()
    ) {
        if (apiUserStore.current.apiUser != null) {
            analyticsModule.send(
                MessageToServer.AnalyticsEvent(
                    category = category.name,
                    action = action.name,
                    label = label,
                    value = value,
                    target = target,
                    tags = tags,
                ).enrich()
            )
        }
    }

    val analyticsEvents: SimpleHandler<List<MessageToServer.AnalyticsEvent>> = handle { _, events ->

        events.map {
            it.enrich()
        }.forEach {
            graphqlScope.launch {
                if (userTags.isEmpty()) {
                    refreshUser()
                }
                if (apiUserStore.current.apiUser != null) {
                    analyticsModule.send(it)
                }
            }
        }
        true
    }

    val analyticsEvent: SimpleHandler<MessageToServer.AnalyticsEvent> = handle { _, event ->
        graphqlScope.launch {
            if (userTags.isEmpty()) {
                refreshUser()
            }
            val enriched = event.enrich()
            if (apiUserStore.current.apiUser != null) {
                console.log("sending", enriched)
                analyticsModule.send(enriched)
                console.log("sent!")
            }
        }
        true
    }

    suspend fun withAnalytics(
        category: AnalyticsCategory,
        tags: TagList = listOf(),
        block: suspend AnalyticsContext.() -> Unit
    ) {
        val analyticsContext = AnalyticsContext(category, tags)
        block.invoke(analyticsContext)
        analyticsContext.events
            .forEach {
                if (userTags.isEmpty()) {
                    refreshUser()
                }
                if (apiUserStore.current.apiUser != null) {
                    analyticsModule.send(it.enrich())
                }
            }
    }

    suspend fun createEvent(
        category: AnalyticsCategory,
        tags: TagList = listOf(),
        block: (suspend AnalyticsContext.() -> Unit)? = null,
    ): List<MessageToServer.AnalyticsEvent> {
        val analyticsContext = AnalyticsContext(category, tags)
        block?.invoke(analyticsContext)
        return if (analyticsContext.events.isEmpty()) {
            listOf(
                MessageToServer.AnalyticsEvent(
                    category = category.name,
                    action = AnalyticsAction.Click.name,
                    tags = tags
                )
            )
        } else {
            analyticsContext.events
        }
    }

    private fun refreshUser() {
        val apiUser = apiUserStore.current.apiUser
        if (apiUser != null) {
            userTags = if (apiUser.isAdmin) {
                userTags.setUniqueTag(AnalyticsTags.Adm, "true")
            } else {
                userTags.removeTag(AnalyticsTags.Adm)
            }
            userTags = userTags.removeTag(AnalyticsTags.Gr)
            apiUser.groups.map { m -> m.groupId }.forEach { groupId ->
                userTags = userTags.addMultivaluedTag(AnalyticsTags.Gr, groupId)
            }
        }
        println("TAGS: " + userTags.joinToString(","))
    }

    init {
        apiUserStore.data.filter { it.valid }.map {
            // may not happen due to lazy init (api user is already set by the time we need the analytics service for the first event
            refreshUser()
        }
    }
}

class AnalyticsContext(
    private val category: AnalyticsCategory,
    tags: TagList = listOf()
) {
    private val _tags: MutableList<String> = tags.toMutableList()
    internal val events = mutableListOf<MessageToServer.AnalyticsEvent>()

    fun tags(vararg tags: String?) {
        tags.filterNotNull().forEach {
            if (it.isNotBlank()) {
                _tags.add(it)
            }
        }
    }

    fun search(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.Search.name,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags
            )
        )
    }

    fun click(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.Click.name,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags
            )
        )
    }

    fun on(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.ON.name,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags
            )
        )
    }

    fun off(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.OFF.name,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags
            )
        )
    }

    fun appStateChange(target: String? = null, value: Double? = null, label: String? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.AppState.name,
                target = target,
                value = value,
                label = label,
                tags = _tags + tags
            )
        )
    }

    fun exception(e: Throwable, target: String? = null, value: Double? = null, tags: TagList = listOf()) {
        events.add(
            MessageToServer.AnalyticsEvent(
                category = category.name,
                action = AnalyticsAction.Error.name,
                label = e::class.simpleName,
                value = value,
                target = target,
                tags = _tags + listOfNotNull(AnalyticsTags.Exc.tag(e.message)) + tags
            )
        )
    }
}

enum class AnalyticsTags : TagEnum {
    Exc,
    Adm,
    Zl,
    Gr,
    Evt,
    Kw,
    ;

}

enum class AnalyticsAction {
    Click,
    Select,
    Open,
    Close,
    ON,
    OFF,
    Error,
    AppState,
    Search,
}

enum class AnalyticsCategory {
    MainMenu,
    Map,
    FloorButton,
    FollowMeButton,
    SharingButton,
    MapLayersSelector,
    ZoomInButton,
    ZoomOutButton,
    AddContentButton,
    ScanContentButton,
    MenuButton,
    HubButton,
    MapButton,
    ScanButton,
    SearchButton,
    CloseButton,
    LocShare,
    LifeCycle,
    CreatePointButton,
    CreateTaskButton,
    CreateEventButton,
    CreateZoneButton,
    CreateEventFromUserCardButton,
    CreateTaskFromUserCardButton,
    ChangePasswordButton,
    QuickUpdateTrackedObjectLocation,
    CreateObjectFromTrackingEvent,
    SendFeedback,
    DiscardFeedback,
    ChangeMyUserStatus,
    ShowSettingsFromMyUserCard,
    ShowSharingOptionsFromMyUserCard,
    SelectSearchLayer,
    AddKeyWord,
    AddObjectType,
    AddAssignee,
    AddCreator,
    DoSearch,
    CreateFromArchetype
    ;

    fun provider(
        action: AnalyticsAction,
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ): () -> MessageToServer.AnalyticsEvent {
        return {
            MessageToServer.AnalyticsEvent(
                category = this.name,
                action = action.name,
                label = label,
                value = value,
                target = target,
                location = location,
                tags = tags
            )
        }
    }

    fun click(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Click, label, value, target, location, tags)

    fun open(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Open, label, value, target, location, tags)

    fun close(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Close, label, value, target, location, tags)

    fun on(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.ON, label, value, target, location, tags)

    fun off(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.OFF, label, value, target, location, tags)

    fun error(
        label: String? = null,
        value: Double? = null, // numeric values like durations, counts, etc.
        target: String? = null, // target of the action id or similar identifier of an object
        location: LatLon? = null,
        tags: TagList? = null,
    ) = provider(AnalyticsAction.Error, label, value, target, location, tags)

}
