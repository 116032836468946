package data.users.views

import analytics.AnalyticsCategory
import auth.ApiUserStore
import apiclient.geoobjects.ObjectType
import data.objects.ActiveObjectStore
import data.users.ActiveUserStore
import dev.fritz2.components.compat.button
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.core.RenderContext
import dev.fritz2.core.disabled
import dev.fritz2.core.title
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import map.views.workplacetools.objectToolButton
import map.views.workplacetools.toolButtonHeight
import map.views.workplacetools.toolButtonIconSize
import map.views.workplacetools.toolButtonRadius
import map.views.workplacetools.toolButtonWidth
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationIcons
import webcomponents.baseLayout
import webcomponents.cardTitleWithSubtitle
import webcomponents.twoButtonFooter
import webcomponents.userOrStateIcon

fun RenderContext.cardOtherUser() {

    val translation: Translation by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()

    combine(activeUserStore.data, apiUserStore.data) { a, u -> Pair(a, u) }.render { (activeUser, currentUser) ->

        baseLayout(
            header = {
                flexBox({
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { start }
                    width { full }
                    height { maxContent }
                }) {
                    flexBox({
                        margins { top { tiny } }
                        overflowX { hidden }
                    }) {
                        // USER ICON, USERNAME AND JOBTITLE
                        cardTitleWithSubtitle(
                            title = flowOf("${activeUser.firstName} ${activeUser.lastName}"),
                            subtitle = flowOf(activeUser.jobTitle ?: ""),
                            iconBox = {
                                userOrStateIcon(
                                    pixelSize = 48.0,
                                    shadow = false,
                                    picture = activeUser.profilePhoto?.href,
                                    initials = initials(activeUser.firstName, activeUser.lastName),
                                    icon = { FormationIcons.UserAlt.icon }
                                )
                            }
                        )
                    }
//                    secondaryMenuButton()
                }
            },
            content = {
                flexBox({
                    height { full }
                    direction { row }
                    justifyContent { center }
                    alignItems { center }
                    wrap { nowrap }
                    paddings { horizontal { normal } }
                }) {
                    // MEET, TASK, CHAT BUTTONS
                    objectToolButton(
                        ObjectType.Event,
                        disabled = flowOf(currentUser.isAnonymous),
                        listOf(activeUserStore.setAsAttendee),
                        analyticsEventProvider = AnalyticsCategory.CreateEventFromUserCardButton.click(
                            label = "cardTool"
                        )
                    )
                    objectToolButton(
                        ObjectType.Task,
                        disabled = flowOf(currentUser.isAnonymous),
                        listOf(activeUserStore.setAsAssignee),
                        analyticsEventProvider = AnalyticsCategory.CreateTaskFromUserCardButton.click(
                            label = "cardTool"
                        )

                    )
//                    chatButton()
                }
            },
            footer = {
                twoButtonFooter(
                    secondaryTitle = translation[TL.General.CLOSE],
                    secondaryStyleParams = secondaryButtonStyleParams,
                    secondaryClickHandlers = listOf(activeObjectStore.resetActiveObjectAndUserToMap),
                    primaryTitle = translation[TL.UserProfile.VIEW_PROFILE],
                    primaryStyleParams = primaryButtonStyleParams,
                    primaryValue = Unit,
                    primaryRoutingMap = if (activeUser.userId == currentUser.userId) {
                        mapOf("page" to Pages.MyProfile.name)
                    } else mapOf("page" to Pages.UserProfile.name)
                )
            }
        )
    }
}

fun RenderContext.chatButton() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    button({
        display { flex }
        justifyContent { center }
        alignItems { center }
        margin { "6px" }
        width { "${toolButtonWidth}px" }
        height { "${toolButtonHeight}px" }
        background { color { primary.main } }
        color { secondary.main }
        radius { "${toolButtonRadius}px" }
        padding { small }

        // TODO disabled look, remove later
        background { color { FormationColors.GrayPrivate.color } }
    }) {
        // TODO disabled for now, enable later
        disabled(true)

        flexBox({
            direction { column }
            justifyContent { center }
            alignItems { center }
        }) {
            attr("onClick", "blur();")
            title(translation.getString(TL.UserProfile.CHAT_TOOLTIP))
            icon({
                size { toolButtonIconSize }
            }) { fromTheme { message } }
            span({ fontSize { smaller }; fontWeight { lighter }; margins { top { small } } }) {
                translation[TL.WorkPlaceTools.CHAT].renderText(
                    into = this
                )
            }
        }
        clicks.map {
            mapOf("page" to Pages.Map.name)
        } handledBy routerStore.validateInternalRoute
    }
}
