package data.users.views

import apiclient.users.PublicUserProfile
import data.users.ActiveUserStore
import dev.fritz2.components.compat.img
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.src
import dev.fritz2.core.storeOf
import io.ktor.util.*
import koin.koinCtx
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.L
import styling.primaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault
import theme.FormationIcons
import webcomponents.BigButtonOption
import webcomponents.Position
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.genericBigButtonSwitch
import webcomponents.genericSmallIconButton
import webcomponents.oneButtonFooter

fun RenderContext.pagePublicUserProfile() {

    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val activeUserStore: ActiveUserStore by koinCtx.inject()
    val firstName = activeUserStore.map(PublicUserProfile.L.firstName)
    val lastName = activeUserStore.map(PublicUserProfile.L.lastName)
    val jobTitle = activeUserStore.map(PublicUserProfile.L.jobTitle)
    val emails = activeUserStore.map(PublicUserProfile.L.emailAddresses).data.map { it.joinToString(",") }
    val phoneNumbers = activeUserStore.map(PublicUserProfile.L.phoneNumbers).data.map { it.joinToString(",") }
    val profilePhoto = activeUserStore.map(PublicUserProfile.L.profilePhoto)

    val profileswitchStore = storeOf(ProfileContent.Details)

    baseLayout(
        expandable = false,
        header = null,
        content = {
            contentScrollBox {
                profilePhoto.data.render { image ->
                    // Profile Photo
                    flexBox(
                        {
                            alignItems { center }
                            justifyContent { center }
                            margins {
                                vertical { normal }
                            }
                        },
                    ) {
                        flexBox(
                            {
                                flex {
                                    grow { "0" }
                                    shrink { "0" }
                                    basis { "200px" }
                                }
                                width { "200px" }
                                height { "200px" }
                                radius { full }
                                justifyContent { center }
                                alignItems { center }
                                textAlign { center }
                                if (image != null) {
                                    background {
                                        image { image.href }
                                        size { cover }
                                        position { center }
                                    }
                                } else {
                                    border {
                                        width(FormationDefault.formationStyles.borderWidth)
                                        color { FormationColors.GrayDisabled.color }
                                    }
                                    color { FormationColors.GrayDisabled.color }
                                }
                            },
                        ) {
                            if (image == null) {
                                icon(
                                    {
                                        size { "100px" }
                                    },
                                ) { fromTheme { FormationIcons.UserAlt.icon } }
                            }
                        }
                    }
                }
                stackUp(
                    {
                        width { full }
                        alignItems { center }
                        justifyContent { center }
                        margins {
                            bottom { small }
                        }
                    },
                ) {
                    spacing { tiny }
                    items {
                        cardTitle(firstName.data.combine(lastName.data) { f, l -> "$f $l" })
                        cardSubtitle(jobTitle.data.mapNotNull { it })
                    }
                }
                genericBigButtonSwitch(
                    store = profileswitchStore,
                    options = listOf(
                        BigButtonOption(
                            title = translation[ProfileContent.Details],
                            icon = ProfileContent.Details.icon,
                            value = ProfileContent.Details,
                            selectHandler = profileswitchStore.update,
                        ),
                        BigButtonOption(
                            title = translation[ProfileContent.VCard],
                            icon = ProfileContent.VCard.icon,
                            value = ProfileContent.VCard,
                            selectHandler = profileswitchStore.update,
                        ),
                    ),
                )
                profileswitchStore.data.render { content ->
                    when (content) {
                        ProfileContent.Details -> {
                            emails.combine(phoneNumbers) { e, p -> Pair(e, p) }.render { (mails, numbers) ->
                                stackUp(
                                    {
                                        width { full }
                                        alignItems { start }
                                        justifyContent { center }
                                        margins { top { large } }
                                    },
                                ) {
                                    spacing { small }
                                    items {
                                        if (mails.isNotBlank()) {
                                            val mailList = mails.split(",")
                                            mailList.forEach { address ->
                                                iconInfoFieldButton(
                                                    icon = { FormationIcons.Mail.icon },
                                                    title = flowOf(address),
                                                    href = "mailto:$address",
                                                    value = Unit,
                                                )
                                            }
                                        }
                                        if (numbers.isNotBlank()) {
                                            val phoneList = numbers.split(",")
                                            phoneList.forEach { number ->
                                                iconInfoFieldButton(
                                                    icon = { FormationIcons.Phone.icon },
                                                    title = flowOf(number),
                                                    href = "tel:$number",
                                                    value = Unit,
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        ProfileContent.VCard -> {
                            activeUserStore.data.render { profile ->
                                combine(
                                    activeUserStore.vcardStore.data.filterNotNull(),
                                    activeUserStore.vcardQrCodeStore.data.filterNotNull(),
                                ) { vcard, svg ->
                                    Pair(vcard, svg)
                                }.render { (vcard, svgContent) ->
//                                        console.log(vcard)
                                    stackUp(
                                        {
                                            width { full }
                                            alignItems { center }
                                            justifyContent { center }
                                        },
                                    ) {
                                        spacing { small }
                                        items {
                                            img(
                                                {
                                                    css(
                                                        """
                                                    animation: verifyqrcode 1 2s ease;
                                                    @keyframes verifyqrcode {
                                                        0% { opacity: 0; }
                                                        100% { opacity: 1; }
                                                    }
                                                    """.trimIndent(),
                                                    )
                                                    margins {
                                                        horizontal { auto }
                                                    }
                                                    width { full }
                                                    maxWidth { "300px" }
                                                },
                                            ) {
                                                src(svgContent)
                                            }
                                            genericSmallIconButton(
                                                icon = { download },
                                                iconPosition = Position.Left,
                                                title = translation[TL.General.DOWNLOAD],
                                                hrefOrValue = "data:text/vcard;charset=utf-8;base64,${vcard.encodeBase64()}",
                                                isLink = true,
                                                downloadValue = "${profile.name}.vcard",
                                            )
                                        }
                                    }
                                }
                            }
                        }

                        else -> {}
                    }
                }
            }
        },
        footer = {
            oneButtonFooter(
                title = translation[TL.General.CLOSE],
                styleParams = primaryButtonStyleParams,
                routingMap = Pages.Login.route,
                value = Unit,
//                primaryClickHandlers = listOf(routerStore.back),
            )
        },
    )
}
