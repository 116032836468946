package data.objects.views

import apiclient.geoobjects.GeoObjectDetails
import apiclient.geoobjects.MeetingInvitationStatus
import apiclient.geoobjects.ObjectTags
import apiclient.geoobjects.ObjectType
import apiclient.geoobjects.pointGeometry
import apiclient.tags.getUniqueTag
import apiclient.validations.parseEnumValue
import auth.ApiUserStore
import auth.FeatureFlagStore
import auth.Features
import data.objects.ActiveObjectStore
import data.objects.AssigneeSelectorStore
import data.objects.AttendeesSelectorStore
import data.objects.views.attachments.AttachmentsStore
import data.objects.views.attachments.PreAttachmentsStore
import data.objects.views.cardinfo.TagButtonType
import data.objects.views.cardinfo.keywordTagsButton
import dev.fritz2.components.compat.div
import dev.fritz2.components.compat.span
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.pushButton
import dev.fritz2.core.RenderContext
import dev.fritz2.core.id
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import geofenceeditor.inputSliderDouble
import koin.koinCtx
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import localization.TL
import localization.Translation
import mainmenu.RouterStore
import map.MapStateStore
import maplibreGL.MaplibreMap
import model.CodeTech
import model.L
import styling.primaryButtonStyleParams
import styling.secondaryButtonStyleParams
import theme.FormationColors
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.focusInputObserver
import utils.formatEventTimeSpan
import utils.getIcon
import utils.getName
import utils.insertObjectInCachesAndMap
import utils.merge
import utils.roundTo
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.cardTitle
import webcomponents.contentScrollBox
import webcomponents.ellipseText
import webcomponents.genericInput
import webcomponents.inputLabelWrapper
import webcomponents.selectorButton
import webcomponents.selectorContent
import webcomponents.twoButtonFooter

fun RenderContext.cardEdit(type: ObjectType) {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val mapStateStore: MapStateStore by koinCtx.inject()
    val maplibreMap: MaplibreMap by koinCtx.inject()
    val attendeesSelectorStore: AttendeesSelectorStore by koinCtx.inject()
    val assigneeSelectorStore: AssigneeSelectorStore by koinCtx.inject()
    val activeObjectStore: ActiveObjectStore by koinCtx.inject()
    val apiUserStore: ApiUserStore by koinCtx.inject()
    val featureFlagStore: FeatureFlagStore by koinCtx.inject()
    val objectType = activeObjectStore.map(GeoObjectDetails.L.objectType)
    val title = activeObjectStore.map(GeoObjectDetails.L.title)
    val description = activeObjectStore.map(GeoObjectDetails.L.description)
    val startTime = activeObjectStore.map(GeoObjectDetails.L.atTime)
    val endTime = activeObjectStore.map(GeoObjectDetails.L.endTime)
    val keywords = activeObjectStore.map(GeoObjectDetails.L.keywords)
    val attendees = activeObjectStore.map(GeoObjectDetails.L.attendees)
    val canManage = activeObjectStore.map(GeoObjectDetails.L.canManage)
    val externalId = activeObjectStore.map(GeoObjectDetails.L.tags).data.map { it.getUniqueTag(ObjectTags.ExternalId) }
    val geoReferencedConnectableObject = activeObjectStore.map(GeoObjectDetails.L.geoReferencedConnectableObject)
    val externalCodeTech =
        keywords.data.map { parseEnumValue<CodeTech>(it?.firstOrNull { keyword -> keyword in CodeTech.entries.map { type -> type.name } }) }
    val titlePlaceholder: Flow<String> = when (type) {
        ObjectType.POI -> translation[TL.CardEdit.POI_NAME]
        ObjectType.Task -> translation[TL.CardEdit.TASK_NAME]
        ObjectType.Event -> translation[TL.CardEdit.MEETING_NAME]
        ObjectType.ObjectMarker -> translation[TL.CardCreate.TRACKED_OBJECT_PLACEHOLDER] // TODO change the three above to PLACEHOLDER
        ObjectType.Area -> translation[TL.ObjectTypeTitle.AREA] // TODO change this to real PLACEHOLDER
        ObjectType.Zone -> translation[TL.ObjectTypeTitle.ZONE] // TODO change this to real PLACEHOLDER
        ObjectType.GeoFence -> translation[TL.ObjectTypeTitle.GEOFENCE] // TODO change this to real PLACEHOLDER
        ObjectType.GeneralMarker -> translation[TL.ObjectTypeTitle.GENERAL_MARKER] // TODO change this to real PLACEHOLDER
        else -> flowOf("Name")
    }
    val attachmentsStore: AttachmentsStore by koinCtx.inject()
    val preAttachmentsStore: PreAttachmentsStore by koinCtx.inject()

    utils.require("nouislider/dist/nouislider.css")
    utils.require("nouislider/dist/nouislider.min.css")

    flowOf(type) handledBy objectType.update

    baseLayout(
        header = {
            flexBox(
                {
                    direction { row }
                    justifyContent { spaceBetween }
                    alignItems { stretch }
                    width { full }
                    height { auto }
                },
            ) {
                // CARD TITLE
                cardTitle(translation[TL.CardEdit.EDIT_EVENT, mapOf("objectType" to type.getName())])
                canManage.data.render { canDelete ->
                    if (canDelete) {
                        // DELETE BUTTON
                        pushButton(
                            {
                                radius { full }
                                padding { tiny }
                            },
                        ) {
                            size { small }
                            type { danger }
                            variant { outline }
                            icon { FormationIcons.DeleteAlt.icon }
                            events {
                                clicks.map {
                                    mapOf("change" to "delete")
                                } handledBy routerStore.addOrReplaceRoute
                            }
                        }

                    }
                }
            }

            externalId.render { extId ->
                if (type == ObjectType.ObjectMarker && extId != null) {
                    div(
                        {
                            margins { bottom { small } }
                        },
                    ) {
                        cardSubtitle(flowOf(extId), externalCodeTech.map { it?.getIcon() })
                    }
                }
            }
        },
        content = {
            contentScrollBox {
                // TITLE INPUT
                inputLabelWrapper(
                    title = titlePlaceholder,
                    visibilityFlow = title.data.map { it.isNotBlank() },
                ) {
                    genericInput(
                        value = title.data,
                        leftContentBox = {
                            icon(
                                {
                                    size(formationStyles.inputIconSize)
                                    margins {
                                        left { small }
                                        right { smaller }
                                    }
                                },
                            ) { fromTheme { type.getIcon().icon } }
                        },
                    ) {
                        id("editTitleField")
                        placeholder(titlePlaceholder)
                        inputs.values() handledBy title.update
                    }
                }

                // Observer to set autofocus on title input field, when not on mobile
                focusInputObserver({ js("document.getElementById('editTitleField').focus()") }, domNode)

                // DESCRIPTION BUTTON
//                description.data.render { desc ->
//                    selectorButton {
//                        selectorContent {
//                            icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Description.icon } }
//                            if (!desc.isNullOrBlank()) {
//                                ellipseText { +desc }
//                            } else translation[TL.General.DESCRIPTION].renderText()
//                        }
//                        clicks.map {
//                            mapOf("change" to "desc")
//                        } handledBy routerStore.addOrReplaceRoute
//                    }
//                }
                // DELETE DESCRIPTION BUTTON
                description.data.render { desc ->
                    if (!desc.isNullOrBlank()) {
                        selectorButton(
                            selectorIcon = { FormationIcons.DeleteAlt.icon },
                            selectorIconColor = { FormationColors.RedError.color },
                            selectorIconMargins = { horizontal { smaller } },
                        ) {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Description.icon } }
                                span(
                                    {
                                        color { FormationColors.RedError.color }
                                    },
                                ) {
                                    translation[TL.General.DELETE].map { d ->
                                        d.lowercase()
                                            .replaceFirstChar { c -> if (c.isLowerCase()) c.titlecase() else c.toString() }
                                    }.merge(translation[TL.General.DESCRIPTION]).renderText(into = this)
                                }
                            }
                            clicks.map {
                                description.update(null)
                            } handledBy activeObjectStore.readFromChangeInputStores
                        }
                    }
                }
                when (type) {
                    ObjectType.GeneralMarker -> {
                        span(
                            {
                                width { full }
                                fontWeight { bold }
                                margins { top { small } }
                            },
                        ) { +"Shape Orientation:" }
//                        geoReferencedConnectableObject.data.render { geoRefObject ->
                        inputSliderDouble(
                            id = "connectableRotationInput",
                            title = translation[TL.CardZoneEditor.ROTATION],
                            minValue = -180.0,
                            maxValue = 180.0,
                            initValue = geoReferencedConnectableObject.current?.rotation?.roundTo(2)
                                ?: 0.0,// geoRefObject?.rotation?.roundTo(1)?: 0.0,
                            valueStream = geoReferencedConnectableObject.data.mapNotNull { it?.rotation },
                            valueStreamFromInput = activeObjectStore.lastInputRotation.data.mapNotNull { it },
                            valueFromInputHandler = activeObjectStore.setConnectedObjectRotationFromInput,
                            valueFromSliderHandler = activeObjectStore.setConnectedObjectRotation,
                        )
//                        }
                    }

                    ObjectType.POI, ObjectType.ObjectMarker, ObjectType.Zone -> {
                        // CUSTOMIZE BADGE BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { filter } }
                                ellipseText { translation[TL.CardEdit.CUSTOMIZE_BADGE].renderText(into = this) }
                            }
                            clicks.map {
                                mapOf("change" to "badge")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                        if (type == ObjectType.Zone) {
                            // EDIT ZONE SHAPE BUTTON
                            featureFlagStore.data.render { features ->
                                if (features[Features.AllowZoneEditing] == true) {
                                    selectorButton {
                                        selectorContent {
                                            icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Zone.icon } }
                                            ellipseText { translation[TL.CardEdit.CHANGE_GEOFENCE].renderText(into = this) }
                                        }
                                        clicks.map {
                                            mapOf("change" to "geofence")
                                        } handledBy routerStore.addOrReplaceRoute
                                    }
                                }
                            }
                        }
                    }

                    ObjectType.Event -> {
                        // DATE/TIME BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Calendar.icon } }
                                ellipseText {
                                    formatEventTimeSpan(
                                        startTime.data,
                                        endTime.data,
                                    ).renderText(into = this)
                                }
                            }
                            clicks.map {
                                mapOf("change" to "datetime")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                        // ATTENDEE BUTTON
                        selectorButton {
                            attendeesSelectorStore.data.combine(attendees.data) { s, a -> Pair(s, a) }
                                .render { (selected, currentAttendees) ->
                                    selectorContent {
                                        icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                        ellipseText {
                                            if (!currentAttendees.isNullOrEmpty()) {
                                                translation[
                                                    TL.CardEdit.ATTENDEES,
                                                    mapOf(
                                                        "numberOfAttendees" to if (selected.isNullOrEmpty()) translation.getString(
                                                            TL.General.NONE,
                                                        ) else selected.size,
                                                    ),
                                                ].merge(
                                                    flowOf(
                                                        "${
                                                            currentAttendees.filter {
                                                                it.meetingInvitationStatus == MeetingInvitationStatus.Accepted
                                                            }.size
                                                        }",
                                                    ),
                                                    separator = ", ",
                                                )
                                                    .merge(translation[TL.MeetingInvitationStatus.ACCEPTED])
                                                    .renderText(into = this)
                                            } else {
                                                translation[
                                                    TL.CardEdit.ATTENDEES,
                                                    mapOf(
                                                        "numberOfAttendees" to if (selected.isNullOrEmpty()) translation.getString(
                                                            TL.General.NONE,
                                                        ) else selected.size,
                                                    ),
                                                ].renderText(into = this)
                                            }
                                        }
                                    }
                                }
                            clicks.map {
                                mapOf("change" to "participants")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }

                    ObjectType.Task -> {
                        // DUE DATE BUTTON
                        selectorButton {
                            selectorContent {
                                icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.Calendar.icon } }
                                ellipseText {
                                    formatEventTimeSpan(
                                        startTime.data,
                                        flowOf(null),
                                    ).renderText(into = this)
                                }
                            }
                            clicks.map {
                                mapOf("change" to "duedate")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                        // ASSIGNEE BUTTON
                        selectorButton {
                            assigneeSelectorStore.data.render { userList ->
                                selectorContent {
                                    icon({ margins { horizontal { smaller } }; size { normal } }) { fromTheme { FormationIcons.UserAlt.icon } }
                                    ellipseText {
                                        translation[
                                            TL.CardEdit.ASSIGNEE,
                                            mapOf(
                                                "user" to
                                                    (if (userList.isNullOrEmpty()) {
                                                        translation.getString(TL.CardUserSelect.ANYONE)
                                                    } else {
                                                        userList.firstOrNull()?.firstName?.let { "$it ${userList.firstOrNull()?.lastName ?: ""}" }
                                                            ?: userList.firstOrNull()?.userId.let {
                                                                if (it == apiUserStore.current.userId) translation.getString(
                                                                    TL.General.YOU,
                                                                    mapOf("case" to "nominative"),
                                                                ) else it
                                                            }
                                                    } ?: ""),
                                            ),
                                        ].renderText(into = this)
                                    }
                                }
                            }
                            clicks.map {
                                mapOf("change" to "participants")
                            } handledBy routerStore.addOrReplaceRoute
                        }
                    }

                    else -> {
                    }
                }
                // KEYWORD TAG BUTTON
                keywordTagsButton(type = TagButtonType.EDIT)

                // ATTACHMENTS BUTTON
//                combine(attachmentsStore.data, preAttachmentsStore.data) { a, p ->
//                    Pair(a.respectFeatureFlags().size, p.respectFeatureFlags().size)
//                }.render { (oldAttachments, newAttachments) ->
//                    selectorButton {
//                        selectorContent(margins = { horizontal { tiny } }) {
//                            icon(
//                                {
//                                    margins { horizontal { smaller } }
//                                    size { normal }
//                                },
//                            ) { fromTheme { FormationIcons.Clip.icon } }
//                            ellipseText {
//                                translation[TL.Attachments.ATTACHMENTS].merge(
//                                    flowOf(
//                                        (if (oldAttachments > 0) "(${oldAttachments})" else "")
//                                            + (if (newAttachments > 0) " + $newAttachments" else ""),
//                                    ),
//                                ).renderText(into = this)
//                            }
//                        }
//                        clicks.map {
//                            mapOf("change" to "attachments")
//                        } handledBy routerStore.addOrReplaceRoute
//                    }
//                }
            }
        },
        footer = {
            // CARD BUTTONS FOOTER
            twoButtonFooter(
                secondaryTitle = translation[TL.General.CANCEL],
                secondaryStyleParams = secondaryButtonStyleParams,
                secondaryClickHandlers = listOf(activeObjectStore.revertChanges, routerStore.back),
                primaryTitle = translation[TL.General.UPDATE],
                primaryState = title.data.map { it.isNotBlank() },
                primaryStyleParams = primaryButtonStyleParams,
                primaryValue = Unit,
                primaryClickHandlers = listOf(activeObjectStore.edit),
//                primaryClickHandlers = when(type) {
//                    ObjectType.GeneralMarker -> listOf(activeObjectStore.editConnectableShape)
//                    else -> listOf(activeObjectStore.edit)
//                }
            )
        },
    )

    mapStateStore.data.render { mapState ->
        if (mapState != null) {
            val obj = activeObjectStore.current.copy(latLon = mapState.center)
            when (type) {
                ObjectType.Zone, ObjectType.GeneralMarker -> {
                    maplibreMap.addGeometryCenterOverride(obj, mapState.center.pointGeometry())
                }


                else -> {}
            }
            insertObjectInCachesAndMap(obj)
        }
    }

}
