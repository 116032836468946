package measuringTool

import dev.fritz2.core.RootStore
import koin.koinCtx
import kotlinx.coroutines.Job
import maplibreGL.MaplibreMap

class MeasuringToolStore : RootStore<Boolean>(false, Job()) {

    val maplibreMap: MaplibreMap by koinCtx.inject()

    val toggle = handle { current ->
        if (!current) {
            maplibreMap.enableMeasuringTool()
        } else {
            maplibreMap.disableMeasuringTool()
        }
        !current
    }

    init {
        maplibreMap.disableMeasuringTool()
    }
}
