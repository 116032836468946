package signup

import auth.ApiUserStore
import apiclient.FormationClient
import apiclient.groups.createAccountWithInvitation
import apiclient.users.Signup
import apiclient.users.restActivate
import apiclient.users.restCreateAccount
import apiclient.users.restResendActivationCode
import apiclient.users.restSignupEmailAddressAvailable
import apiclient.passwords.evaluatePassword
import dev.fritz2.core.RootStore
import dev.fritz2.core.invoke
import dev.fritz2.tracking.tracker
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import localization.TL
import localization.Translation
import login.HostConfigStore
import mainmenu.Pages
import mainmenu.RouterStore
import model.Overlay
import model.SignUpFromInvite
import model.SplashType
import model.ValidatePassword
import overlays.AlertOverlayStore
import overlays.BusyStore
import overlays.SplashOverlayStore

class SignupStore(
//    loginAndTokenRefreshService: LoginAndTokenRefreshService,
    formationClient: FormationClient,
) : RootStore<Signup>(
    initialData = Signup(workspace = "formation-sandbox", email = "", password = ""),
    job = Job(),
) {
    private val routerStore: RouterStore by koinCtx.inject()
    private val apiUserStore: ApiUserStore by koinCtx.inject()
    private val splashOverlayStore: SplashOverlayStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    val translation: Translation by koinCtx.inject()
    private val validatePasswordStore: ValidatePasswordStore by koinCtx.inject()
    private val activationCodeStore: ActivationCodeStore by koinCtx.inject()
    private val busyStore: BusyStore by koinCtx.inject()

    private val hostConfigStore = koinCtx.get<HostConfigStore>()

    private val signUpGraphQLClient = hostConfigStore.anonymousClient()

    val signingUpTracker = tracker()
    val activationTracker = tracker()
    val resendCodeTracker = tracker()

    val createAccount = handle { current ->
        val activeData = current
        if (validatePasswordStore.current.isValid && activeData.password.isNotBlank()) {
            signingUpTracker.track {
                // CHECK IF EMAIL ADDRESS IS IN USE
                busyStore.handleApiCall(
                    supplier = suspend {
                        signUpGraphQLClient.restSignupEmailAddressAvailable(activeData.email)
                    },
                    processResult = { emailCheck ->
                        if (emailCheck) {
                            console.log("Success! Email address is available in workspace. Account can be created.")
                            // CREATE ACCOUNT
                            busyStore.handleApiCall(
                                supplier = suspend {
                                    signUpGraphQLClient.restCreateAccount(signup = activeData)
                                },
                                successMessage = translation[TL.AlertNotifications.ACCOUNT_CREATED_SUCCESSFULLY],
                                processResult = { apiUser ->
                                    routerStore.resetPreLoginRedirect()
                                    routerStore.addOrReplaceRoute(
                                        mapOf(
                                            "ws" to activeData.workspace,
                                            "email" to activeData.email,
                                        ),
                                    )
//                                    console.log("Account created for ${activeData.firstName} ${activeData.lastName}.")
                                    apiUser.let { newApiUser ->
                                        console.log("Account created for ${newApiUser.firstName} ${newApiUser.lastName}.")
                                        console.log("New ApiUser:", newApiUser.toString())
                                        apiUserStore.set(newApiUser)
                                    }
                                },
                                errorMessage = translation[TL.AlertNotifications.OTHER_FAILURE],
                                processError = { throwable ->
                                    console.error("Account creation failed.", throwable)
                                },
                            )
                        } else {
                            console.log("Failure! Email address is already existing in workspace. Account can not be created.")
                            alertOverlayStore.errorNotify(translation[TL.AlertNotifications.EMAIL_ALREADY_EXISTS])
                        }
                    },
                    errorMessage = translation[TL.AlertNotifications.EMAIL_ALREADY_EXISTS],
                    processError = { throwable ->
                        console.log("Failure! Email address is already existing in workspace. Account can not be created.", throwable)
                    },
                )
            }
        }
        if (apiUserStore.getIfExists() != null) {
            Signup("", "", "")
        } else current

    }

    val activateAccount = handle { current ->
        val code = activationCodeStore.current
        if (code.isNotBlank() && code.length == 6 && ".*\\d.*".toRegex().matches(code)) {
            activationTracker.track {
                busyStore.handleApiCall(
                    supplier = suspend {
                        formationClient.restActivate(code = code)
                    },
                    successMessage = translation[TL.AlertNotifications.ACCOUNT_ACTIVATED_SUCCESSFULLY],
                    processResult = { activatedUser ->
                        console.log("account activated for ${activatedUser.name}")
                        splashOverlayStore.show(
                            Overlay.SplashScreen(
                                splashType = SplashType.AppStartSplash,
                                durationSeconds = 3,
                            ),
                        )
                        activationCodeStore.reset()
                        apiUserStore.set(activatedUser)
//                        mainController.apiUserChangedHandler(activatedUser.toUser())
//                        apiUserStore.refreshUser()
//                        apiUserStore.logoutWithParams()
                    },
                    errorMessage = flowOf("Account activation failed. Wrong code?"), // TODO translate
                    processError = { throwable ->
                        console.log("Account activation failed. Wrong code?", throwable)
                    },
                )
            }
        }
        current
    }

    val resendActivationCode = handle { current ->
        resendCodeTracker.track {
            busyStore.handleApiCall(
                supplier = suspend {
                    formationClient.restResendActivationCode()
                },
                successMessage = translation[TL.AlertNotifications.RESEND_CODE_SUCCESSFULLY],
                processResult = {
                    console.log("A new activation code was send to ${current.email}.")
                },
                errorMessage = translation[TL.AlertNotifications.OTHER_FAILURE],
                processError = { throwable ->
                    console.error("Sending a new activation code failed.", throwable)
                },
            )
        }
        current
    }

    val reset = handle {
        Signup("formation-sandbox", "", "")
    }

    private val updatePassword = handle<String> { current, newPassword ->
        current.copy(password = newPassword)
    }

    fun watchInputs(): Flow<Boolean> {
        return data.combine(validatePasswordStore.data) { signupData, validateData ->
            (signupData.workspace.isNotBlank()
                && signupData.email.isNotBlank()
                && !signupData.firstName.isNullOrBlank()
                && !signupData.lastName.isNullOrBlank()
                && signupData.password.isNotBlank()
                && validateData.firstPass.isNotBlank()
                && validateData.secondPass.isNotBlank()
                && validateData.isValid
                )
        }
    }

    init {
        validatePasswordStore.data.map { if (it.isValid) it.firstPass else "" } handledBy updatePassword
    }
}

class ValidatePasswordStore : RootStore<ValidatePassword>(
    initialData = ValidatePassword(),
    id = "signUpValidatePassword",
    job = Job(),
) {

    val reset = handle {
        ValidatePassword()
    }

    private val validatePasswords = handle { current ->
        val evaluation = evaluatePassword(current.firstPass)

        if (current.secondPass != current.firstPass && (current.secondPass.isNotBlank() && current.firstPass.isNotBlank())) {
            current.copy(passwordEval = evaluation, isMatching = false, isValid = false)
        } else current.copy(passwordEval = evaluation, isMatching = true, isValid = !evaluation.reject())
    }

    init {
        data.map { } handledBy validatePasswords
    }
}

class SignupFromInviteStore : RootStore<SignUpFromInvite>(
    initialData = SignUpFromInvite.EMPTY,
    job = Job(),
) {
    private val hostConfigStore = koinCtx.get<HostConfigStore>()

    private val signUpGraphQLClient = hostConfigStore.anonymousClient()

    val activeObjectStore by koinCtx.inject<ApiUserStore>()
    private val busyStore by koinCtx.inject<BusyStore>()
    val alertOverlayStore by koinCtx.inject<AlertOverlayStore>()
    private val validatePasswordStore by koinCtx.inject<ValidatePasswordStore>()
    val translation by koinCtx.inject<Translation>()
    val routerStore by koinCtx.inject<RouterStore>()

    val signingUpTracker = tracker()

    val signUpFromInvite = handle { current ->
        val activeData = current
        if (validatePasswordStore.current.isValid && activeData.password.isNotBlank()) {
            signingUpTracker.track {
                busyStore.handleApiCall(
                    suspend {
                        signUpGraphQLClient.createAccountWithInvitation(
                            invitationToken = current.inviteToken,
                            password = current.password,
                            firstName = current.firstName,
                            lastName = current.lastName,
                        )
                    },
                    successMessage = translation[TL.AlertNotifications.ACCOUNT_CREATED_SUCCESSFULLY],
                    processResult = { apiUser ->
                        activeObjectStore.set(apiUser)
                        console.log("Account created for ${activeData.firstName} ${activeData.lastName}.")
                        routerStore.validateInternalRoute(Pages.Map.route)
                    },
                    errorMessage = translation[TL.AlertNotifications.OTHER_FAILURE],
                    processError = { e ->
                        console.log("Error creating account from invite", e.message)
                    },
                    withBusyState = true,
                    busyStateMessage = flowOf("Creating account..."),
                )
            }
        }
        current
    }

    fun watchInputs(): Flow<Boolean> {
        return data.combine(validatePasswordStore.data) { signupData, validateData ->
            (signupData.firstName.isNotBlank()
                && signupData.lastName.isNotBlank()
                && signupData.password.isNotBlank()
                && validateData.firstPass.isNotBlank()
                && validateData.secondPass.isNotBlank()
                && validateData.isValid
                )
        }
    }

    private val updatePassword = handle<String> { current, newPassword ->
        current.copy(password = newPassword)
    }

    init {
        validatePasswordStore.data.map { if (it.isValid) it.firstPass else "" } handledBy updatePassword
    }
}
