package login

import apiclient.auth.requestLoginEmail
import apiclient.users.restSignupEmailAddressAvailable
import apiclient.util.isNotNullOrEmpty
import dev.fritz2.core.Lens
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.SimpleHandler
import dev.fritz2.core.disabled
import dev.fritz2.core.lensOf
import dev.fritz2.core.placeholder
import dev.fritz2.core.type
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.map
import kotlinx.serialization.Serializable
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import org.w3c.dom.HTMLInputElement
import overlays.AlertOverlayStore
import overlays.BusyStore
import twcomponents.twCardSectionH1
import twcomponents.twCenteredLink
import twcomponents.twInputField
import twcomponents.twInputTextField
import twcomponents.twPrimaryButton
import twcomponents.twRevertButton
import twcomponents.twRightAlignedButtonRow
import webcomponents.inputLabelWrapper

@Serializable
data class EmailLoginState(
    val emailAddress: String = "",
    val emailExists: Boolean = false,
    val loginLinkSend: Boolean = false,
    val errorOccurred: Boolean = false
)

fun EmailLoginState.Companion.emailAddress(): Lens<EmailLoginState, String> = lensOf(
    "emailAddress",
    { it.emailAddress },
    { p, v -> p.copy(emailAddress = v) },
)

fun EmailLoginState.Companion.emailExists(): Lens<EmailLoginState, Boolean> = lensOf(
    "emailExists",
    { it.emailExists },
    { p, v -> p.copy(emailExists = v) },
)

fun EmailLoginState.Companion.loginLinkSend(): Lens<EmailLoginState, Boolean> = lensOf(
    "loginLinkSend",
    { it.loginLinkSend },
    { p, v -> p.copy(loginLinkSend = v) },
)

fun EmailLoginState.Companion.errorOccurred(): Lens<EmailLoginState, Boolean> = lensOf(
    "errorOccurred",
    { it.errorOccurred },
    { p, v -> p.copy(errorOccurred = v) },
)

class EmailLoginService : RootStore<EmailLoginState>(EmailLoginState(), Job()) {
    val hostConfigStore: HostConfigStore by koinCtx.inject()
    val busyStore: BusyStore by koinCtx.inject()
    val alertOverlayStore: AlertOverlayStore by koinCtx.inject()
    private val anonymousFormationClient = hostConfigStore.anonymousClient()


    val lookUpEmailExists = SimpleHandler<String> { data, _ ->
        data handledBy { email ->
            busyStore.handleApiCall(
                supplier = {
                    anonymousFormationClient.restSignupEmailAddressAvailable(email)
                },
                processResult = { isNotExisting ->
                    console.log("E-mail address ($email) is ${if (isNotExisting) "not" else ""} existing!")
                    update(current.copy(emailExists = !isNotExisting))
                },
                processError = { error ->
                    console.log("Error checking email availability.", error.message)
                    update(current.copy(emailExists = false, errorOccurred = true))
                },
            )
        }
    }

    val sendLoginLink = SimpleHandler<Unit> { data, _ ->
        data handledBy { _ ->
            if (current.emailAddress.isNotNullOrEmpty()) {
                busyStore.handleApiCall(
                    supplier = {
                        current.emailAddress.takeIf { it.isNotBlank() }?.let {
                            anonymousFormationClient.requestLoginEmail(it)
                        }
                    },
                    processResult = { result ->
                        console.log("Login E-mail was send to ${current.emailAddress}", result)
                        update(current.copy(loginLinkSend = true))
                    },
                    processError = { error ->
                        console.log("Error sending login email.", error.message)
                        update(current.copy(loginLinkSend = false))
                    },
                )
            }
        }
    }

    val resetEmailLoginState = handle { current ->
        current.copy(
            loginLinkSend = false,
            errorOccurred = false,
        )
    }
}

fun RenderContext.pageLoginWithEmail() {
    val translation: Translation by koinCtx.inject()
    val emailLoginService: EmailLoginService by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()

    val emailAddress = emailLoginService.map(EmailLoginState.emailAddress())
    val emailExists = emailLoginService.map(EmailLoginState.emailExists())
    val loginLinkSend = emailLoginService.map(EmailLoginState.loginLinkSend())
    val errorOccurred = emailLoginService.map(EmailLoginState.errorOccurred())

    div("flex flex-col w-full h-full items-center justify-center") {
        div("flex flex-col h-full w-full sm:w-[500px] items-center justify-between overflow-y-auto") {
            // Title Formation
            span(
                "flex flex-grow items-center justify-center text-2xl sm:text-3xl font-thin tracking-widest pl-2",
            ) {
                +"F O R M A T I O N"
            }

            // sign in
            twCardSectionH1 {
                translation[TL.Login.SIGN_IN].renderText()
            }

            // rerendered section
            div("flex flex-grow flex-col items-center w-full gap-4 px-4") {
                div("flex flex-col items-center w-full gap-2") {
                    className(loginLinkSend.data.map { mailSend -> if (!mailSend) "block" else "hidden" })
                    // input with validation and clear button + sign in button
                    inputLabelWrapper(
                        title = translation[TL.Login.EMAIL],
                        visibilityFlow = emailAddress.data.map { it.isNotBlank() },
                    ) {
                        twInputField(emailAddress) {
                            twInputTextField {
                                placeholder(translation[TL.Login.EMAIL])
                                type("email")
                            }
                            twRightAlignedButtonRow {
                                combine(emailAddress.data, emailExists.data) { email, exists ->
                                    Pair(email.isNotBlank(), exists)
                                }.render { (isNotBlank, exists) ->
                                    if (isNotBlank) {
                                        if (exists) {
                                            div("text-green-500 whitespace-nowrap") {
                                                +"Found"
                                            }
                                            twRevertButton(emailAddress, "")
                                        } else {
                                            div("text-red-500 whitespace-nowrap") {
                                                +"Not found"
                                            }
                                            twRevertButton(emailAddress, "")
                                        }
                                    }
                                }
                            }
                            inputs.debounce(500) handledBy {
                                val element = it.target as HTMLInputElement
                                emailLoginService.lookUpEmailExists(element.value)
                            }
                        }
                    }

                    // sign in button
                    twPrimaryButton(
                        text = TL.Login.SIGN_IN,
                    ) {
                        disabled(
                            combine(emailAddress.data, emailExists.data) { email, exists ->
                                email.isBlank() || !exists
                            },
                        )
                        clicks handledBy emailLoginService.sendLoginLink
                    }
                }

                div("flex flex-col flex-grow items-center justify-center gap-4") {
                    className(loginLinkSend.data.map { mailSend -> if (mailSend) "block" else "hidden" })
                    // login link send message
                    p("text-sm md:text-xl text-center") {
                        translation[TL.Login.SIGN_IN_EMAIL_SEND_TEXT].renderText(into = this)
                    }

                    p("text-xs md:text-sm font-light text-center") {
                        translation[TL.Login.SIGN_IN_CHECK_SPAM].renderText(into = this)
                    }
                }

                div("flex flex-col flex-grow items-center justify-center gap-4") {
                    className(loginLinkSend.data.map { mailSend -> if (mailSend) "block" else "hidden" })
                    // link buttons
                    twCenteredLink {
                        translation[TL.Login.SIGN_IN_DID_NOT_GET_EMAIL_BUTTON].renderText(into = this)
                        clicks handledBy emailLoginService.sendLoginLink
                    }

                    twCenteredLink {
                        translation[TL.Login.SIGN_IN_USE_DIFFERENT_EMAIL_BUTTON].renderText(into = this)
                        clicks handledBy emailLoginService.resetEmailLoginState
                    }
                }
                div("flex flex-col flex-grow items-center justify-center gap-4") {
                    // Go back to login with password
                    twCenteredLink {
                        translation[TL.Login.SIGN_IN_BACK_TO_LOGIN_WITH_PASSWORD_BUTTON].renderText(into = this)
                        clicks.map { Pages.Login.route } handledBy routerStore.addOrReplaceRoute
                    }
                }
            }
        }
    }
}
