package qrcode

import camera.nfc.NfcService
import camera.nimiq.BrowserCamera
import dev.fritz2.components.compat.div
import dev.fritz2.components.flexBox
import dev.fritz2.components.icon
import dev.fritz2.components.stackUp
import dev.fritz2.core.RenderContext
import dev.fritz2.core.RootStore
import dev.fritz2.core.id
import dev.fritz2.core.invoke
import dev.fritz2.core.placeholder
import dev.fritz2.core.values
import koin.koinCtx
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import localization.TL
import localization.Translation
import mainmenu.Pages
import mainmenu.RouterStore
import model.CodeTech
import signup.textButton
import styling.secondaryButtonStyleParams
import theme.FormationDefault.Companion.formationStyles
import theme.FormationIcons
import utils.focusInputObserver
import utils.getName
import webcomponents.baseLayout
import webcomponents.cardSubtitle
import webcomponents.contentScrollBox
import webcomponents.ellipseText
import webcomponents.genericInput
import webcomponents.oneButtonFooter
import webcomponents.twoButtonFooter

class ManualEnterStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    val enable = handle { true }
    val disable = handle { false }
    val reset = handle { false }
}

class HasCameraStore : RootStore<Boolean>(
    initialData = false,
    job = Job(),
) {
    private val browserCamera: BrowserCamera by koinCtx.inject()

    val check = handle {
        coroutineScope {
            launch {
                update(browserCamera.hasCamera().await())
            }
        }
        it
    }

    init {
        check()
    }
}

fun RenderContext.cardTrackedObject() {
    val translation: Translation by koinCtx.inject()
    val routerStore: RouterStore by koinCtx.inject()
    val scannedCodeStore: ScannedCodeStore by koinCtx.inject()
    val nfcService: NfcService by koinCtx.inject()
    val manualEnterStore: ManualEnterStore by koinCtx.inject()
    val hasCameraStore: HasCameraStore by koinCtx.inject()

    nfcService.checkAndUpdateNFCStatus(showAlerts = true)
    scannedCodeStore.updateType(CodeTech.QR)
    hasCameraStore.check()

    baseLayout(
        expandable = false,
        header = {
            flexBox(
                {
                    width { maxContent }
                    alignItems { center }
                    margins { right { small } }
                    overflowX { hidden }
                },
            ) {
                icon(
                    {
                        margins { right { smaller } }
                        size { "42px" }
                    },
                ) { fromTheme { FormationIcons.TrackedObject.icon } }
                stackUp(
                    {
                        width { maxContent }
                        alignItems { flexStart }
                        overflowX { hidden }
                    },
                ) {
                    spacing { none }
                    items {
                        ellipseText(
                            {
                                width { full }
                                fontSize { large }
                                fontWeight { bold }
                            },
                        ) { translation[TL.ObjectTypeTitle.OBJECT_MARKER].renderText(into = this) }
                        flexBox(
                            {
                                direction { row }
                                width { full }
                                alignItems { center }
                                justifyContent { flexStart }
                            },
                        ) {
                            nfcService.data.render { nfcStatus ->
                                cardSubtitle(flowOf("NFC ${nfcStatus.getName()}"), flowOf(FormationIcons.NFC.icon))
                                div(
                                    {
                                        width { "10px" }
                                        height { "10px" }
                                        flex {
                                            grow { "0" }
                                            shrink { "0" }
                                            basis { "10px" }
                                        }
                                        radius { full }
                                        background { color(nfcStatus.color) }
                                        margins { horizontal { smaller } }
                                    },
                                ) { }
                            }
                        }
                    }
                }
            }
        },
        content = {
            contentScrollBox {
                flexBox(
                    {
                        direction { column }
                        width { full }
                        height { full }
                        alignItems { center }
                        justifyContent { spaceAround }
                    },
                ) {
                    hasCameraStore.data.render { hasCamera ->
                        cardSubtitle(title = translation[TL.CodeScanning.INSTRUCTIONS_OBJECT_SCANNER])
                        oneButtonFooter(
                            title = translation[TL.CodeScanning.START_SCAN],
                            state = flowOf(hasCamera),
                            routingMap = Pages.Scan.route,
                            value = Unit,
                            analyticsEventProvider = null,
                        )
                        if (!hasCamera) {
                            cardSubtitle(flowOf("Camera not found or not ready."))
                        }
                        // MANUAL CODE INPUT
                        manualEnterStore.data.render { manualEnter ->
                            if (manualEnter) {
                                genericInput(
                                    value = scannedCodeStore.data.map { it.extOrIntObjIdOrActionId ?: "" },
                                ) {
                                    id("enterCodeManuallyInput")
                                    placeholder(translation[TL.CodeScanning.INSERT_CODE_MANUALLY])
                                    inputs.values() handledBy scannedCodeStore.updateCode
                                }
                                focusInputObserver(
                                    { js("document.getElementById('enterCodeManuallyInput').focus()") },
                                    domNode,
                                )
                            } else {
                                flexBox(
                                    {
                                        width { full }
                                        height(formationStyles.inputHeight)
                                        alignItems { center }
                                        justifyContent { flexStart }
                                    },
                                ) {
                                    textButton(
                                        text = translation[TL.CodeScanning.INSTRUCTIONS_ENTER_MANUALLY],
                                        clickHandlers = listOf(manualEnterStore.enable),
                                    )
                                }
                            }
                        }
                    }
                }
            }
        },
        footer = {
            manualEnterStore.data.render { manualEnter ->
                if (manualEnter) {
                    twoButtonFooter(
                        primaryTitle = translation[TL.General.NEXT],
                        primaryValue = Unit,
                        primaryClickHandlers = listOf(scannedCodeStore.checkManuallyEnteredCode),
                        primaryState = scannedCodeStore.data.map { it.extOrIntObjIdOrActionId != null },
                        primaryAnalyticsEventProvider = null,
                        secondaryTitle = translation[TL.General.CANCEL],
                        secondaryClickHandlers = listOf(scannedCodeStore.reset, routerStore.back),
                        secondaryAnalyticsEventProvider = null,
                    )
                } else {
                    oneButtonFooter(
                        title = translation[TL.General.CANCEL],
                        styleParams = secondaryButtonStyleParams,
                        value = Unit,
                        clickHandlers = listOf(scannedCodeStore.reset, routerStore.back),
                        analyticsEventProvider = null,
                    )
                }
            }

        },
    )
}
